import NoItemsPage from '../../../NoItems'
import SurveyButton from './SurveyButton'

const NoSurveysCompleted = () => (
  <NoItemsPage
    message="No Previous Surveys Found"
    href="https://support.coinapp.co/hc/en-us/articles/360042771634"
    buttonText="Read more about surveys"
  >
    <SurveyButton />
  </NoItemsPage>
)

export default NoSurveysCompleted
