import { useFindInmarketRewardsQuery } from '@coin-microservices/coin-inmarket-react-hooks/dist/client.generated'
import { Refresh } from '@mui/icons-material'
import { IconButton, TablePagination } from '@mui/material'
import times from 'lodash/times'
import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'

import { CustomList, CustomListHeader, TableCursorActions, useScrollContext } from '../../../components'
import logger from '../../../Log'
import { localJSONStore } from '../../../utils'
import InmarketListItem from './Item'
import { NoRewardedScans, NoRewardedVisits } from './NoItemsPages'

const inmarketDisclaimer = localJSONStore<{ time: number }>('inmarket-disclaimer')

export const InmarketList = ({ type }: { type: string }) => {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const variables = useMemo(() => {
    const filter: { type?: { EQ: string } } = {}
    if (type !== 'all') filter.type = { EQ: type }
    return {
      filter,
      pagination: { page, perPage },
      sort: { field: 'date', order: -1 },
    }
  }, [page, perPage, type])
  const { data, loading, refetch } = useFindInmarketRewardsQuery({
    notifyOnNetworkStatusChange: true,
    variables,
  })
  const { remoteRowCount, rowData } = useMemo(() => {
    const remoteRowCount = data?.findInmarketRewards?.total ?? 0
    const rowData = data?.findInmarketRewards?.data ?? []
    return { remoteRowCount, rowData }
  }, [data])

  const changePage = (p: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const showDisclaimer = async () => {
    // inmarketDisclaimer.to({ time: Date.now() })
    // await swalWarning(
    //   'Warning',
    //   'Be sure to answer the surveys truthfully. If the Pollfish system detects you are randomly answering the questions, you may not get rewarded for your time or may even be rejected from their system and not be allowed to take any further surveys.',
    // )
  }

  useEffect(() => {
    const seen = inmarketDisclaimer.from()
    if (seen && seen.time) {
      // pollFish.initialize()
      return
    }
    showDisclaimer()
      // .then(() => pollFish.initialize())
      .catch((e) => logger().error(e))
  }, [])

  return (
    <CustomList
      header={
        <CustomListHeader title={type === 'visit' ? 'Rewarded Visits' : 'Rewarded Scans'}>
          <IconButton disabled={loading} onClick={() => refetch(variables)}>
            <Refresh />
          </IconButton>
        </CustomListHeader>
      }
    >
      {loading ? (
        times(perPage).map((a, i) => <InmarketListItem key={i} loading={true} />)
      ) : (rowData || []).length ? (
        (rowData || []).map((reward) => (reward ? <InmarketListItem key={reward?.id} reward={reward} /> : null))
      ) : type === 'visit' ? (
        <NoRewardedVisits />
      ) : type === 'scan' ? (
        <NoRewardedScans />
      ) : null}
      {remoteRowCount ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={remoteRowCount}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TableCursorActions}
        ></TablePagination>
      ) : null}
    </CustomList>
  )
}
