import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { FlexCol } from '../components/Flex'

import { config, ExplorerMotionGraphic, FadeIn } from '../components'
import CustomBasePage from '../CustomBasePage'
import { Link } from 'react-router-dom'

export default () => (
  <CustomBasePage>
    <FadeIn config={config.wobbly}>
      <Container maxWidth="sm" sx={{ py: 4 }}>
          <Paper>
            <FlexCol padding={3}>
              <ExplorerMotionGraphic />
              <Typography marginTop={2} variant="h4">
                Page not found
              </Typography>
              <Button LinkComponent={Link} sx={{ my: 2 }} variant="outlined" {...({ to: "/" })}>
                Go Home
              </Button>
            </FlexCol>
          </Paper>
      </Container>
    </FadeIn>
  </CustomBasePage>
)
