import Fab from '@mui/material/Fab'
import { BoxProps } from '@mui/material/Box'
import { FlexCol, FlexRow } from '../../components/Flex'
import { useLocation, useNavigate } from 'react-router-dom'

import adPlay from '../../assets/adPlay.png'
import audioPlay from '../../assets/audioPlay.png'
import bigWinStars from '../../assets/bigWinStars@3x.png'
import hodlCoins from '../../assets/coinsOrange@3x.png'
import dropsImage from '../../assets/drops.png'
import claimsImage from '../../assets/geoclaim.png'
import axeImage from '../../assets/pickaxe.png'
import bot from '../../assets/bot.png'
import sweepstakes from '../../assets/sweepstakesIcon.png'
import { RewardIcon } from '../Rewards'

interface NavItemProps extends BoxProps {
  to: string
  img?: string
  selectedColor?: string
}

const NavItem: React.FC<React.PropsWithChildren<NavItemProps>> = ({ children, img, to, selectedColor = '#eeeeee', ...props }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const selected = location.pathname.endsWith(to)
  return (
    <FlexRow {...props}>
      <Fab
        variant="circular"
        onClick={() => navigate(to)}
        size="small"
        style={{ backgroundColor: selected ? selectedColor : '#444444' }}
      >
        {img ? <img src={img} width={20} /> : null}
        {children}
      </Fab>
    </FlexRow>
  )
}

const SideNav: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <FlexCol position="absolute" sx={{ display: { xl: 'flex', xs: 'none' } }} left={16} top={80} zIndex={1}>
      <NavItem title="Geoclaims" to="/geoclaims" img={claimsImage} margin={1} />
      <NavItem title="Geomines" to="/geomines" img={axeImage} margin={1} />
      <NavItem title="Geodrops" to="/geodrops" img={dropsImage} margin={1} />
      <NavItem title="Background Mining" to="/background" img={bot} margin={1} />
      <NavItem title="Ad Plays" to="/videoads" img={adPlay} margin={1} />
      <NavItem title="Audio Plays" to="/audioads" img={audioPlay} margin={1} />
      {/* <NavItem title="Big Wins" to="/bigwins" img={bigWinStars} margin={1} /> */}
      <NavItem title="Surveys" to="/surveys" margin={1}>
        <RewardIcon style={{ width: 20 }} type="surveys" variant="green" alt="Surveys" />
      </NavItem>
      <NavItem title="Rewarded Visits" to="/rewarded/visits" margin={1}>
        <RewardIcon style={{ width: 20 }} type="visits" variant="green" alt="Rewarded Visits" />
      </NavItem>
      <NavItem title="Rewarded Scans" to="/rewarded/scans" margin={1}>
        <RewardIcon style={{ width: 20 }} type="scans" variant="green" alt="Rewarded Scans" />
      </NavItem>
      <NavItem title="Sweepstakes History" to="/sweepstakes/history" img={sweepstakes} margin={1} />
      <NavItem title="HODL History" to="/hodl/history" img={hodlCoins} margin={1} />
      {/* <NavItem title="Rewarded Verificationss" to="/rewarded/verifications" img={verifications} margin={1} /> */}
    </FlexCol>
  )
}

export default SideNav
