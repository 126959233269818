import { AddPaymentDocument, AddPaymentMutationVariables, CustomerInput } from '@xyo-network/api-checkout-apollo'
import get from 'lodash/get'

import { checkoutClient } from '../../lib'

const addPaymentCard = async (paymentToken: string, customer: CustomerInput, authorize = false) => {
  const variables: AddPaymentMutationVariables = {
    authorize,
    customer,
    paymentToken,
  }
  const result = await checkoutClient.mutate({
    mutation: AddPaymentDocument,
    variables,
  })
  const paymentCardId = get(result, 'data.addPayment.defaultPaymentInstrument.paymentCardId')
  return paymentCardId
}

export default addPaymentCard
