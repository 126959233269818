import React, { useContext, useRef, useState } from 'react'

import SnackbarContext, { SnackbarOptions } from './SnackbarContext'

const defaultValue: SnackbarOptions = {
  action: null,
  loading: false,
  message: null,
}

const SnackbarProvider = ({ children }: React.PropsWithChildren) => {
  const [value, setValue] = useState<SnackbarOptions>(defaultValue)
  const ref = useRef(defaultValue)
  const closeSnackbar = () => {
    ref.current = defaultValue
    setValue(defaultValue)
  }
  const setSnackbar = (v: SnackbarOptions) => {
    setValue(v)
    ref.current = v
    return () => {
      if (ref.current === v) {
        setValue(defaultValue)
      }
    }
  }
  return <SnackbarContext.Provider value={[value, { closeSnackbar, setSnackbar }]}>{children}</SnackbarContext.Provider>
}

export const useSnackbar = () => {
  return useContext(SnackbarContext)
}

export default SnackbarProvider
