import { ApolloProvider } from '@apollo/client'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { parse } from 'querystring'
import { FC } from 'react'

import { getClient } from './apollo'
import { checkoutClient } from '../lib'

const DEVELOPMENT_URI = 'http://localhost:8083'
const PRODUCTION_URI = 'https://prod.coinapp.co/manager'

const coinBossClient = getClient(
  // process.env.NODE_ENV === 'development' ? DEVELOPMENT_URI : PRODUCTION_URI,
  PRODUCTION_URI,
)

export const getBossClient = () => {
  return coinBossClient
}

export const BossProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <ApolloProvider client={coinBossClient}>
      <ApolloHooksProvider client={coinBossClient as any}>{children}</ApolloHooksProvider>
    </ApolloProvider>
  )
}

const NfcRefistrationDocument = gql`
  query NfcRegistration($nfcId: String!) {
    nfcRegistration(nfcId: $nfcId) {
      inviteUrl
    }
  }
`
export const getNfcRefistrationInviteUrl = async () => {
  const bossClient = await getBossClient()
  const { uid } = parse(window.location.search.replace('?', ''))
  const [nfcId] = ((uid ?? '') as any).split('x')
  const response = await bossClient.query({
    query: NfcRefistrationDocument,
    variables: { nfcId },
  })
  const inviteUrl = response?.data?.nfcRegistration?.inviteUrl
  if (!inviteUrl) {
    throw new Error('Registration not found')
  }
  return inviteUrl
}

const VerifyRedeemDocument = gql`
  mutation verifyRedeemRequest($code: String!, $shippingSku: String) {
    verifyRedeemRequest(code: $code, shippingSku: $shippingSku) {
      type
      reference
      state
      xyoCost
      shippingSku
      options {
        id
        title
        imageUrl
        variants {
          name
          description
          shippingSku
        }
      }
    }
  }
`

export const getVerificationCode = () => {
  const { code, verification_code } = parse(window.location.search.replace('?', ''))
  if (Array.isArray(code)) return code[0]
  if (Array.isArray(verification_code)) return verification_code[0]
  return code || verification_code
}

export const verifyRedeem = async (code: string, shippingSku: string) => {
  const bossClient = await getBossClient()
  if (!code) throw new Error('Code is required')
  const response = await bossClient.mutate({
    mutation: VerifyRedeemDocument,
    variables: { code, shippingSku },
  })
  const redeem = response?.data?.verifyRedeemRequest
  return redeem
}

const VerifyAddressHistoryDocument = gql`
  mutation verifyAddressHistoryRequest($code: String!) {
    verifyAddressHistoryRequest(code: $code) {
      id
      uid
      ip
      date
      type
      phoneNumber
      shipFullName
      shipStreetAddress
      shipStreetAddress2
      shipCity
      shipState
      shipZipcode
      shipCountry
      shipPhoneNumber
      coinbaseEmail
      ethAddress
      verification_code
      verified
      state
      email
    }
  }
`

export const verifyAddressHistory = async (code: string) => {
  const bossClient = await getBossClient()
  const response = await bossClient.mutate({
    mutation: VerifyAddressHistoryDocument,
    variables: { code },
  })

  await checkoutClient.clearStore()

  const redeem = response?.data?.verifyAddressHistoryRequest
  return redeem
}

const VerifyDeviceDocument = gql`
  mutation verifyDeviceRequest($code: String!) {
    verifyDeviceRequest(code: $code) {
      type
      state
    }
  }
`

export const verifyDevice = async (code: string) => {
  const bossClient = await getBossClient()
  const response = await bossClient.mutate({
    mutation: VerifyDeviceDocument,
    variables: { code },
  })
  const redeem = response?.data?.verifyDeviceRequest
  return redeem
}

const UpdateProfileImageDocument = gql`
  mutation UpdateProfileImage($file: String!) {
    updateProfileImage(file: $file) {
      profileImageUrl
      userInfo {
        ownerUID
        profileImageExists
        profileImageUpdated
      }
    }
  }
`

export const updateProfileImage = async (file: string) => {
  const bossClient = await getBossClient()
  const response = await bossClient.mutate({
    mutation: UpdateProfileImageDocument,
    variables: { file: file.split('base64,')[1] },
  })
  const update = response?.data?.updateProfileImage
  return update
}
