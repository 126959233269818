import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

export const GoToWithdraw = ({
  children,
}: {
  children: (goTo: (item: string | { asset: string }) => void) => ReactElement
}) => {
  const navigate = useNavigate()
  const goToWithdraw = (item: string | { asset: string }) => {
    const asset = (typeof item === 'string' ? item : item.asset || '').toLowerCase()
    if (!asset || asset === 'xyo') {
      navigate('/redeem')
    } else {
      navigate(`/redeem/${asset}`)
    }
  }
  return children(goToWithdraw)
}
