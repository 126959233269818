import { Cancel } from '@mui/icons-material'
import { Button, DialogActions, DialogContent, DialogContentText, IconButton, Link } from '@mui/material'
import get from 'lodash/get'
import over from 'lodash/over'

import { VerifyProvider } from '../../../context'
import { getCoinPlusPlanDetails, getCoinPlusPlanFHRDetails, getCoinProPlanDetails } from '../../../plans'
import { SignatureVerification } from '../../../SignatureVerification'
import { useUpgrade } from '../../../store'
import { purchaseTracker } from '../../../utils'
import { Checkout } from '../Checkout'
import useHandleSuccess from './useHandleSuccess'

const UpgradeModalContent = ({ open }: { open: string }) => {
  const { confirmPlusFHR, confirmProFHR, upgradePlusFHR, upgradeProFHR, buyPlusFHR, closeUpgrade } = useUpgrade()[1]
  const plusPlan = getCoinPlusPlanDetails()
  const proPlan = getCoinProPlanDetails()
  const purchasePlusFHRDetails = getCoinPlusPlanFHRDetails()
  purchaseTracker(plusPlan)
  purchaseTracker(proPlan)
  const fhrTracker = purchaseTracker(purchasePlusFHRDetails)
  const handleSuccess = useHandleSuccess(purchasePlusFHRDetails, fhrTracker)
  switch (open) {
    case confirmPlusFHR.type:
    case confirmProFHR.type:
      return (
        <>
          <DialogContent>
            <DialogContentText>
              FHR members are eligible for 12 months of Plus for free or 12 months of Pro for a discounted rate of{' '}
              {purchasePlusFHRDetails.amount}/mo. To get started click continue below to verify your FHR wallet address.{' '}
              <Link
                href="https://coinappco.zendesk.com/hc/en-us/articles/360050977274"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Link>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" disableElevation onClick={closeUpgrade}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              sx={{ marginLeft: 1 }}
              onClick={open === confirmPlusFHR.type ? upgradePlusFHR : upgradeProFHR}
            >
              Continue
            </Button>
          </DialogActions>
        </>
      )
    case upgradePlusFHR.type:
    case upgradeProFHR.type:
      return (
        <VerifyProvider>
          <DialogContent>
            <IconButton onClick={closeUpgrade}>
              <Cancel />
            </IconButton>
            <SignatureVerification onSuccess={closeUpgrade} onError={closeUpgrade} />
          </DialogContent>
        </VerifyProvider>
      )
    case buyPlusFHR.type:
      return (
        <Checkout
          onCancel={closeUpgrade}
          onSuccess={over([closeUpgrade, handleSuccess])}
          onDisplay={fhrTracker.view}
          onCustomerInfoComplete={() => fhrTracker.step(1)}
          onShippingInfoComplete={() => fhrTracker.step(2)}
          onPaymentInfoComplete={() => fhrTracker.step(3)}
        />
      )
    default:
      return null
  }
}

export default UpgradeModalContent
