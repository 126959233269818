import { Maybe, PaymentCard } from '@xyo-network/api-checkout-apollo'

import { makeContext } from '../../../../utils'

type IUpdgradeTableItem = {
  planId?: Maybe<string>
  description?: Maybe<string>
  type?: Maybe<string>
  unitPriceAmount?: Maybe<number>
  unitPrice?: Maybe<number>
}

export type IUpdgradeTableData = {
  lineItemSubtotal?: Maybe<number>
  lineItems?: Maybe<Maybe<Maybe<IUpdgradeTableItem | undefined>[] | undefined>>
  items?: Maybe<Maybe<Maybe<IUpdgradeTableItem | undefined>[] | undefined>>
}

export function isUpdgradeTableItem(v: unknown): v is IUpdgradeTableItem {
  if (!v || typeof v !== 'object') return false
  const value = v as IUpdgradeTableItem
  if (typeof value.unitPriceAmount === 'number') return true
  if (typeof value.unitPrice === 'number') return true
  return false
}

const ConfirmUpgradeContext = makeContext<{
  loading?: boolean
  error?: string
  open: boolean
  title?: string
  cards?: Maybe<Maybe<PaymentCard>[]>
  data?: Maybe<IUpdgradeTableData>
  done?: (value?: { method: string; paymentCardId: string }) => void
}>({ open: false }, 'ConfirmUpgradeContext')

export default ConfirmUpgradeContext
