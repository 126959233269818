import { TextField, TextFieldProps } from '@mui/material'
import { CustomerInput } from '@xyo-network/api-checkout-apollo'
import { ChangeEvent, ChangeEventHandler, FC, forwardRef, useMemo, useState } from 'react'
import MaskedInput, { conformToMask } from 'react-text-mask'

import { Form, FormField } from '../../components'

const accountInfoNamesValidator = {
  firstName: (v: string) => (v ? '' : 'Please enter first name'),
  lastName: (v: string) => (v ? '' : 'Please enter last name'),
}
const accountInfoValidator = {
  email: (v: string) => (v ? '' : 'Please enter email'),
  ...accountInfoNamesValidator,
}

interface IInfoFormProps {
  id: string
  onlyName?: boolean
  onSubmit: (data: CustomerInput) => void
  initial?: Partial<CustomerInput> | null
}

export const UserInfoForm = ({ onSubmit, initial, id, onlyName }: IInfoFormProps) => {
  const validator = onlyName ? accountInfoNamesValidator : accountInfoValidator
  return (
    <Form id={id} onSubmitForm={(values) => onSubmit(values as CustomerInput)} initial={initial} validator={validator}>
      {onlyName === true ? null : (
        <FormField
          name="email"
          component={TextField}
          label="Email"
          autoComplete="email"
          InputProps={{ type: 'email' }}
          variant="outlined"
          margin="normal"
          fullWidth
          type="text"
          helperText="Email"
        />
      )}
      {onlyName === true ? null : (
        <FormField
          name="phone"
          component={PhoneNumberMaskField}
          label="Phone Number"
          helperText="(Optional)"
          autoComplete="phone"
          InputProps={{ type: 'tel' }}
          variant="outlined"
          margin="normal"
          fullWidth
          type="text"
        />
      )}
      <FormField
        style={{ color: '#000' }}
        name="firstName"
        component={TextField}
        autoComplete="given-name"
        label="First Name"
        variant="outlined"
        margin="normal"
        fullWidth
        type="text"
        helperText="First Name"
      />
      <FormField
        name="lastName"
        component={TextField}
        autoComplete="family-name"
        label="Last Name"
        variant="outlined"
        margin="normal"
        fullWidth
        type="text"
        helperText="Last Name"
      />
    </Form>
  )
}

const phoneMask = ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

type OnChangeHandler = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => void

const PhoneNumberMaskField = ({ onChange, ref, ...props }: TextFieldProps & { onChange?: OnChangeHandler }) => {
  const value = useMemo(
    () => conformToMask(props.value as string, phoneMask, { guide: true }).conformedValue,
    [props.value]
  )
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (ev) => {
    const value = ev.target.value.replace(/\D/g, '')
    if (onChange) {
      onChange(ev, value)
    }
  }
  return <TextField {...props} value={value} onChange={handleChange} InputProps={{ inputComponent: PhoneNumberMask, ref }} />
}

const PhoneNumberMask: FC<React.PropsWithChildren<unknown>> = forwardRef((props, ref) => {
  const [showMask, setShowMask] = useState(false)
  return (
    <MaskedInput
      onFocus={() => setShowMask(true)}
      onBlur={() => setShowMask(false)}
      {...props}
      mask={phoneMask}
      showMask={showMask}
    />
  )
}
)