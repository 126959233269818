import {
  ListItemText,
  ListItemTextProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default (props: ListItemTextProps) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <ListItemText
      {...props}
      style={isMdUp ? { ...props.style, flex: "initial" } : props.style}
      primaryTypographyProps={{ style: isMdUp ? { fontSize: "1.6em" } : {} }}
    />
  );
};
