import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import React, { createContext, useContext } from 'react'

import ErrorAlertAction from './ErrorAlertAction'

export const ErrorBannerContext = createContext({})

export const useErrorBanner = () => {
  return useContext(ErrorBannerContext)
}

export const ErrorBanner = () => {
  const { error, removeError } = useErrorBanner() as any
  return (
    <ErrorAlertAction
      error={error}
      action={
        <Button onClick={removeError}>
          <i className="fa fa-times" />
        </Button>
      }
    />
  )
}

export const AWSError: React.FC<React.PropsWithChildren<{ error?: Error }>> = () => (
  <div className="list-group-item py-5 rounded-0-sm">
    <Typography>
      <strong>FYI</strong>: Functionality is currently limited due to some Amazon Web Services issues. This will not
      affect the COIN Application, and should be resolved once Amazon addresses their outages!
    </Typography>
  </div>
)
