import cx from 'classnames'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

interface AssetTitleIconProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  icon?: string
  title?: string
  className?: string
}

const AssetTitleIcon: React.FC<React.PropsWithChildren<AssetTitleIconProps>> = ({ className, icon, title }) => (
  <span className={cx('d-flex align-items-center', className)}>
    {icon ? (
      <img
        src={icon}
        style={{
          height: '1em',
          marginRight: 4,
          objectFit: 'contain',
          width: '1em',
        }}
      />
    ) : (
      <></>
    )}
    {title}
  </span>
)

export default AssetTitleIcon
