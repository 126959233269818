import Link from '@mui/material/Link'
import Toolbar, { ToolbarProps } from '@mui/material/Toolbar'
import { Link as ReactLink, useMatch } from 'react-router-dom'

import { NavLogo } from '../Logo'

const ContextToolbar: React.FC<React.PropsWithChildren<ToolbarProps>> = (props) => {
  const isHome = useMatch('/')
  return (
    <Toolbar {...props}>
      {isHome ? (
        <a target="_blank" rel="noreferrer noopener" href="https://coinapp.co">
          <NavLogo />
        </a>
      ) : (
        <Link to="/" component={ReactLink}>
          <NavLogo />
        </Link>
      )}
    </Toolbar>
  )
}

export default ContextToolbar
