import Container from '@mui/material/Container'
import { BoxProps } from '@mui/material/Box'
import { FlexCol, FlexRow } from '../Flex'
import { Copyright, FooterLinks, FooterLogo } from './components'

export interface FooterProps extends BoxProps {
  container?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  transparent?: boolean
  menus?: boolean
}


export const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({ children, transparent = false, container = 'lg', menus = true, ...props }) => {
  return (
    <FlexRow {...props}>
      <Container maxWidth={container}>
        <FlexCol component="footer" padding={2}>
          <FooterLogo sx={{ p: 2 }} />
          {children}
          <Copyright paddingX={2} />
          <FooterLinks paddingY={2} />
        </FlexCol>
      </Container>
    </FlexRow>
  )
}
