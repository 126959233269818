import { Badge, Box, Skeleton } from '@mui/material'

import { Token } from './types'
import { formatName, getChainLogo, getLogo, getChainName } from './utils'

export const TokenAvatar = ({ token }: { amount?: string; token?: Token }) => {
  if (!token) return <Skeleton variant="circular" height={12} width={12} style={{ marginRight: 8 }} />

  const image = <img style={{ height: '1.25em', width: '1.25em', objectFit: 'contain', display: 'block' }} alt={formatName(token)} src={getLogo(token)} />

  if (token.chainId && getChainLogo(token.chainId)) {
    const chainImage = <img style={{ height: '0.75em', width: '0.75em', objectFit: 'contain', display: 'block' }} alt={getChainName(token.chainId)} src={getChainLogo(token.chainId)} />
    return <Box mr={1}>
      <Badge badgeContent={chainImage} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} overlap='circular'>
        {image}
      </Badge>
    </Box>
  }

  return <Box mr={1}>{image}</Box>
}