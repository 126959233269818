import { Box, Typography } from '@mui/material'
import { memo, ReactElement } from 'react'

import { parseError } from '../../utils'

const ErrorAlertAction = memo<{ className?: string; error?: Error | string; action: ReactElement }>(
  ({ error, action }) => {
    const message = parseError(error)
    return message ? (
      <Box>
        <Typography paragraph>{message}</Typography>
        {action}
      </Box>
    ) : null
  }
)

export default ErrorAlertAction
