import NoItemsPage from '../../../NoItems'

const NoGeoDrops = () => (
  <NoItemsPage
    message="No Geodrops Found"
    href="https://support.coinapp.co/hc/en-us/categories/360002099633"
    buttonText="Read more about Geodrops"
  />
)

export default NoGeoDrops
