import { useAllCurrentSweepstakeOptionsQuery } from '@coin-microservices/sweepstakes-react-hooks'
import { Avatar, Box } from '@mui/material'

import { useSweepstakesOptions } from '../options'
import DarkSkeleton from './DarkSkeleton'

const SweepstakesDetailsAvatar = ({ size, ...props }: { size: number; loading?: boolean }) => {
  const options = useSweepstakesOptions()
  const { loading } = useAllCurrentSweepstakeOptionsQuery()
  return (
    <Box>
      {props.loading || loading ? (
        <DarkSkeleton height={size} width={size} />
      ) : (
        <Avatar
          variant="square"
          src={options?.icon ?? ''}
          alt={`${options?.title ?? ''} - ${options?.subtitle ?? ''}`}
          imgProps={{ style: { objectFit: 'contain' } }}
          style={{ height: size, width: size }}
        >
          {(options?.item ?? '').slice(0, 2).toUpperCase()}
        </Avatar>
      )}
    </Box>
  )
}

export default SweepstakesDetailsAvatar
