import {
  AccountDocument,
  AddressInput,
  CheckoutDocument,
  CheckoutMutationVariables,
  CustomerInput,
} from '@xyo-network/api-checkout-apollo'
import md5 from 'md5'

import { checkoutClient } from '../../lib'
import { trackWarning } from '../../utils'
import redeemSubscription from './redeemSubscription'

const checkoutApollo = async (
  planId: string,
  paymentCardId: string,
  customer: CustomerInput,
  billing: AddressInput,
  shipping: AddressInput
) => {
  const variables: CheckoutMutationVariables = {
    billing,
    customer,
    paymentCardId,
    planId, //: omit(billing, ['email', 'firstName', 'lastName']),
    shipping, //: omit(shipping, ['email', 'firstName', 'lastName']),
    customJson: {
      emailHash: md5(customer.email || '')
    }
  }
  let result
  try {
    result = await checkoutClient.mutate({
      mutation: CheckoutDocument,
      refetchQueries: [{ query: AccountDocument }],
      variables,
    })
  } catch (e) {
    const error = e as Error
    trackWarning('Checkout Error', error.message)
    throw e
  }
  try {
    await redeemSubscription(customer.email)
    await checkoutClient.query({
      fetchPolicy: 'network-only',
      query: AccountDocument,
    })
  } catch (e) {
    const error = e as Error
    trackWarning('Redeem Error', error.message)
    throw e
  }
  return result
}

export default checkoutApollo
