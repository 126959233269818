import { ApolloProvider } from '@apollo/client'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './themes'

import client from './store'

const Main = ({ children }: React.PropsWithChildren) => {
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client as any}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  )
}

export default Main
