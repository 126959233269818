import { AlertColor } from '@mui/material'

import getPrecisionMessageOpts from './getPrecisionMessageOpts'

function getMessageOpts(
  opts: {
    type?: AlertColor
    title?: string
    provider?: string
  } = {}
): {
  message?: JSX.Element | null
  severity?: AlertColor
  title?: string
} {
  const provider = (opts.provider || '').toLowerCase()
  switch (provider) {
    case 'precision':
      return getPrecisionMessageOpts(opts)
    default:
      return {}
  }
}

export default getMessageOpts
