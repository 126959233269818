import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react'

import logger from '../Log'

export function makeContext<V>(ctxInitial: V, name: string) {
  type IContext = [V, Dispatch<SetStateAction<V>>]
  const setInitial = () => logger().warn(`Provider for ${name} not supplied`)
  const Context = createContext<IContext>([ctxInitial, setInitial])
  const Provider = ({ children, initial = ctxInitial }: React.PropsWithChildren<{ initial?: V }>) => {
    const value = useState(initial)
    return <Context.Provider value={value}>{children}</Context.Provider>
  }
  // const Provider =
  //   process.env.NODE_ENV === 'development'
  //     ? makeProfiler(`${name} Provider`, _Provider)
  //     : _Provider
  return {
    Context,
    Provider,
    useContext: () => useContext(Context),
  }
}

/*
function makeProfiler<P>(id: string, Component: ComponentType<P>) {
  // https://reactjs.org/docs/profiler.html

  const totals = {
    actualDuration: 0,
    baseDuration: 0,
    interactions: 0,
  }
  const onRender: ProfilerOnRenderCallback = (
    id, // the "id" prop of the Profiler tree that has just committed
    phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration, // time spent rendering the committed update
    baseDuration, // estimated time to render the entire subtree without memoization
    // startTime, // when React began rendering this update
    // commitTime, // when React committed this update
    interactions // the Set of interactions belonging to this update
  ) => {
    totals.baseDuration += baseDuration
    totals.actualDuration += actualDuration
    logger().log(`${phase} callback`, id)
    logger().log(`${id} duration`)
    logger().log(`actual       ${actualDuration}`)
    logger().log(`base         ${baseDuration}`)
    logger().log(`acts         ${interactions}`)
    logger().log(`total actual ${actualDuration}`)
    logger().log(`total base   ${baseDuration}`)
    logger().log(`total acts   ${baseDuration}`)
    logger().log('')
  }

  return (props: P) => {
    return (
      <Profiler id={id} onRender={onRender}>
        <Component {...props} />
      </Profiler>
    )
  }
}
*/
