import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { BoxProps } from '@mui/material/Box'
import { FlexCol, FlexRow } from '../Flex'

import {
  BalanceCard,
  CollectedCard,
  DroppedCard,
  GeoclaimedCard,
  GeominesCard,
  RedeemedCard,
  SurveyCard,
  SweepstakesCard,
} from './Cards'

const UserTotalsDashboard: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  return (
    <FlexCol alignItems="stretch" {...props}>
      <FlexCol marginY={4}>
        <Grid container spacing={2}>
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <BalanceCard />
          </Grid>
          <Grid item xs={12} md={5}>
            <RedeemedCard />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </FlexCol>
      <FlexCol alignItems="stretch">
        <Typography component={FlexRow} variant="h4" marginY={4} justifyContent="flex-start">
          Collected
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <GeominesCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <CollectedCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <SurveyCard />
          </Grid>
        </Grid>
      </FlexCol>
      <FlexCol alignItems="stretch">
        <Typography component={FlexRow} variant="h4" marginY={4} justifyContent="flex-start">
          Spent
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <GeoclaimedCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <DroppedCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <SweepstakesCard />
          </Grid>
        </Grid>
      </FlexCol>
    </FlexCol>
  )
}

export default UserTotalsDashboard
