import { Close } from '@mui/icons-material'
import { CardHeader, Divider, Drawer, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { useUser, useUserInfo } from '../../User'
import { useBitlabs } from './BitlabsProvider'
const deps = {
  script: 'https://sdk.bitlabs.ai/bitlabs-sdk-v0.0.2.js',
  styles: 'https://sdk.bitlabs.ai/bitlabs-sdk-v0.0.2.css',
  token: '7d293ae7-2375-4c6c-a0f3-f2bf129152c1',
  // token: '678c564f-62a3-4331-a018-0bf7ee2c885b',
}

interface BitlabsSdk {
  init: (token: string, userId: string, options?: { username?: string; source?: string }) => Promise<void>
  showWidget: (id: string, type: string, position: string) => void
}

// let sdkPromise: Promise<BitlabsSdk>
// function loadBitlabsSdk() {
//   if (sdkPromise) return sdkPromise

//   sdkPromise = new Promise((res, rej) => {
//     const script = document.createElement('script')
//     const link = document.createElement('link')
//     script.src = deps.script
//     link.href = deps.styles
//     link.rel = 'stylesheet'

//     document.head.appendChild(link)
//     document.head.appendChild(script)

//     script.onload = () => res((window as any).bitlabsSDK)
//     script.onerror = (err) => rej(err)
//   })

//   return sdkPromise
// }

const BitlabsDialog = () => {
  const { user } = useUser()
  const { userInfo } = useUserInfo()
  const [open, setOpen] = useBitlabs()
  const close = () => setOpen(false)
  const [loading, setLoading] = useState(false)
  const username = userInfo?.username ?? undefined

  // useEffect(() => {
  //   if (user && open) {
  //     setLoading(true)
  //     loadBitlabsSdk()
  //       .then(async (bitlabsSDK) => {
  //         await bitlabsSDK.init(deps.token, user.uid, { username, source: 'web' })
  //         bitlabsSDK.showWidget('#bitlabs-root', 'simple', 'top-left')
  //       })
  //       .catch((err) => {
  //         console.log('Error loading bitlabs', err)
  //       })
  //       .finally(() => {
  //         setLoading(false)
  //       })
  //   }
  // }, [user, open, username])

  if (!user || !user.uid) return null

  return (
    <Drawer open={open} onClose={close} style={{ zIndex: 98 }} anchor="left">
      <CardHeader
        title="Bitlabs"
        action={
          <Tooltip title={<Typography>Return to COIN</Typography>}>
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          </Tooltip>
        }
      />
      {loading ? <LinearProgress variant="indeterminate" /> : <Divider />}
      {/* <div id="bitlabs-root" style={{ maxWidth: '100%', textAlign: 'center', width: 300 }} /> */}
      <iframe src={`https://web.bitlabs.ai/?uid=${user.uid}&token=${deps.token}&tags=source%3Dweb`} width='400px' height='100%' frameBorder='0' />
    </Drawer>
  )
}

export default BitlabsDialog
