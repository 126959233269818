import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { BoxProps } from '@mui/material/Box'
import { FlexCol } from './components/Flex'

interface Props extends BoxProps {
  message: string
  buttonText: string
  href: string
}

const NoItems: React.FC<React.PropsWithChildren<Props>> = ({ children, message, buttonText, href, ...props }) => {
  return (
    <FlexCol alignItems="center" paddingY={2} {...props}>
      <Typography marginY={2} variant="h6">
        {message}
      </Typography>
      {children}
      <Button sx={{ my: 3 }} variant="outlined" href={href} target="_blank">
        {buttonText}
      </Button>
    </FlexCol>
  )
}

export default NoItems
