import { findMainSubscription, useAccountQuery } from '@xyo-network/api-checkout-apollo'
import get from 'lodash/get'
import toPairs from 'lodash/toPairs'
import { useMemo } from 'react'

import { checkoutClient } from '../lib'
import { useUser } from '../User'

type Sub = { startDate: { seconds: number }; isActive: boolean; inTrial: boolean }

const useMySubscription = () => {
  const user = useUser()
  const accountQuery = useAccountQuery({ client: checkoutClient })
  const { userInfo, subscription } = useMemo(() => {
    const userInfo = accountQuery.data?.account?.userInfo
    const subscriptions = accountQuery.data?.account?.customer?.subscriptions || []
    const subscription = findMainSubscription(subscriptions)
    return { subscription, userInfo }
  }, [accountQuery.data])
  const { activeSubscription, subscriptions, isFHR, inTrial } = useMemo(() => {
    const activeSubscription = userInfo?.activeSubscription ?? 'basic'
    const subscriptions = toPairs(userInfo?.subscriptionInfo)
      .map(([id, value]: [id: string, value: unknown]) => ({ id, ...(value as Sub) }))
      .sort((a, b) => get(b, 'startDate.seconds') - get(a, 'startDate.seconds'))
    const isFHR = subscriptions.some(({ id, isActive }) => isActive && id.indexOf('fhr') === 0)
    const inTrial = subscriptions.some(({ isActive, inTrial }) => isActive && inTrial)
    return { activeSubscription, inTrial, isFHR, subscriptions }
  }, [userInfo?.activeSubscription, userInfo?.subscriptionInfo])
  return {
    ...accountQuery,
    ...user,
    activeSubscription,
    inTrial,
    isFHR,
    subscription,
    subscriptions,
    userInfo,
  }
}

export default useMySubscription
