import { Close, Poll } from '@mui/icons-material'
import { Box, Button, CardHeader, Dialog, IconButton, Tooltip, Typography } from '@mui/material'

import bitlabsLogo from '../../assets/bitlabs.svg'
import inbrainLogo from '../../assets/inbrain.png'
import pollfishLogo from '../../assets/pollfish.png'
import { usePollFish } from '../../context'
import {
  trackSurveyBitlabsEvent,
  trackSurveyDialogCloseEvent,
  trackSurveyInbrainEvent,
  trackSurveyPollfishEvent,
  trackSurveyPrecisionEvent,
} from '../../utils'
import { useBitlabs } from './BitlabsProvider'
import { useInbrain } from './InbrainProvider'
import { usePrecisionSample } from './PrecisionSampleProvider'
import { useSurveyProvider } from './SurveyProviderProvider'
import { SurveyButtons } from './SurveyButtons'
import { showDisclaimer } from './showDisclaimer'

const SurveyProviderDialog = () => {
  const [open, setOpen] = useSurveyProvider()
  const setPrecisionOpen = usePrecisionSample()[1]
  const setInbrainOpen = useInbrain()[1]
  const setBitlabsOpen = useBitlabs()[1]
  const pollFish = usePollFish()
  const close = () => {
    setOpen(false)
    trackSurveyDialogCloseEvent('', {})
  }
  const handleInbrain = async () => {
    setOpen(false)
    await showDisclaimer()
    setInbrainOpen(true)
    trackSurveyInbrainEvent('dialog', {})
  }
  const handleBitlabs = async () => {
    setOpen(false)
    await showDisclaimer()
    setBitlabsOpen(true)
    trackSurveyBitlabsEvent('dialog', {})
  }
  const handlePrecision = async () => {
    setOpen(false)
    await showDisclaimer()
    setPrecisionOpen(true)
    trackSurveyPrecisionEvent('dialog', {})
  }
  const handlePollfish = async () => {
    setOpen(false)
    if (pollFish) {
      await showDisclaimer()
      pollFish
        .initialize()
        .then(() => pollFish.showFullSurvey())
        .catch((err) => console.warn(err))
      trackSurveyPollfishEvent('dialog', {})
    }
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={close}>
      <CardHeader
        title="Survey Providers"
        action={
          <Tooltip title={<Typography>Close</Typography>}>
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          </Tooltip>
        }
      />
      <Box px={2}>
        <SurveyButtons />
      </Box>
    </Dialog>
  )
}

export default SurveyProviderDialog
