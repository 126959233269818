import { getUpgradeToProDetails } from '../plans'
import ChangeSubscription from './ChangeSubscription'
import useMySubscription from './useMySubscription'

const UpgradePlanToPro = () => {
  const { data, subscription } = useMySubscription()
  const upgradePlan = getUpgradeToProDetails()
  const planId =
    subscription && subscription.planId === 'COIN-PLUS-ANNUAL-249-50' && upgradePlan.annualPlanId
      ? upgradePlan.annualPlanId
      : upgradePlan.planId
  return data?.account ? (
    <ChangeSubscription subscription={subscription} cards={data?.account?.customer?.paymentCards} planId={planId}>
      {upgradePlan.buttonText}
    </ChangeSubscription>
  ) : (
    <></>
  )
}

export default UpgradePlanToPro
