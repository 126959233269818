import { collectedCoinFeatureImage } from './features'
import { StatCard, StatCardProps } from '../Stats'

import { useUserInfo } from '../../../User'

const BalanceCard: React.FC<React.PropsWithChildren<StatCardProps>> = (props) => {
  const { userInfo } = useUserInfo()

  return (
    <StatCard
      title="Balance"
      subtitle="Current"
      actionTo="/geomines"
      action="View More"
      image={collectedCoinFeatureImage}
      description="COIN"
      value={userInfo?.xyoCollected ?? undefined}
      {...props}
    ></StatCard>
  )
}

export default BalanceCard
