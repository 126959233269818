import { FlexCol } from '../../../components/Flex'

import { InmarketProvider } from '../../../context'
import CustomBasePage from '../../../CustomBasePage'
import { InmarketList } from './List'

const InmarketHistory = ({ type }: { type: string }) => {
  return (
    <CustomBasePage>
      <FlexCol marginY={2} alignItems="stretch">
        <InmarketList type={type} />
      </FlexCol>
    </CustomBasePage>
  )
}

export const RewardedVisitsPage = () => {
  return (
    <InmarketProvider>
      <FlexCol marginY={2} alignItems="stretch">
        <InmarketHistory type="visit" />
      </FlexCol>
    </InmarketProvider>
  )
}

export const RewardedScansPage = () => {
  return (
    <InmarketProvider>
      <FlexCol marginY={2} alignItems="stretch">
        <InmarketHistory type="scan" />
      </FlexCol>
    </InmarketProvider>
  )
}
