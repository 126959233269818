import 'firebase/storage'

import { BoxProps } from '@mui/material/Box'

import { useUserInfo } from '../hooks'
import UpdateableProfileImage from './UpdateableProfileImage'

type IUserInfo = {
  ownerUID?: string | null
  profileImageUpdated?: string | null
  profileImageExists?: boolean | null
  activeSubscription?: string | null
  kycComplete?: boolean | null
  profileMultiplier?: number | null
}

const getProfileImageUrl = ({ userInfo }: { userInfo?: IUserInfo | null }) => {
  if (userInfo && userInfo.profileImageExists) {
    return `https://firebasestorage.googleapis.com/v0/b/xyo-network-1522800011804.appspot.com/o/coin%2Fimages%2F${userInfo.ownerUID}%2Fprofile150.jpg?alt=media&updated${userInfo.profileImageUpdated}`
  }
  return ''
}

const getPlan = ({ userInfo }: { userInfo?: IUserInfo | null }) => {
  if (userInfo && userInfo.activeSubscription) {
    return userInfo.activeSubscription
  }
  return ''
}

const getKycComplete = ({ userInfo }: { userInfo?: IUserInfo | null }) => {
  if (userInfo && userInfo.kycComplete) {
    return userInfo.kycComplete
  }
  return false
}

const SmartProfileImage: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  const { userInfo, loading } = useUserInfo()
  const kycComplete = getKycComplete({ userInfo })
  const src = getProfileImageUrl({ userInfo })
  const plan = getPlan({ userInfo })
  const profileMultiplier = userInfo?.profileMultiplier ?? 1
  
  return <UpdateableProfileImage loading={loading} profileImage={src} plan={plan} kycComplete={kycComplete} profileMultiplier={profileMultiplier} {...props} />
}

export default SmartProfileImage
