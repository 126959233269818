import { Dialog, DialogContent } from '@mui/material'
import get from 'lodash/get'
import { FC, useState } from 'react'

import { PopoverButton } from '../../../components'
import { stopPropagation } from '../../../lib'
import { getCoinProPlanDetails } from '../../../plans'
import { useUpgrade } from '../../../store'
import { purchaseTracker } from '../../../utils'
import { CheckoutApolloProvider } from '../../contexts'
import { Checkout } from '../Checkout'
import Popover from './BuyNow'
import useHandleSuccess from './useHandleSuccess'

const PurchasePro: FC<React.PropsWithChildren<{ popover?: boolean }>> = ({ popover, ...props }) => {
  const [open, setOpen] = useState(false)
  const [, { confirmProFHR }] = useUpgrade()
  const proPlan = getCoinProPlanDetails()
  const proTracker = purchaseTracker(proPlan)
  const { label, buttonText } = proPlan
  const handleSuccess = useHandleSuccess(proPlan, proTracker)

  const buyPro = () => {
    setOpen(true)
  }

  return (
    <>
      <PopoverButton
        variant="outlined"
        sx={{ mx: 1 }}
        onClick={popover ? undefined : stopPropagation(buyPro)}
        renderPopover={() => <Popover onConfirmFHR={confirmProFHR} onBuyNow={buyPro} title={label} />}
        onRenderPopover={proTracker.view}
      >
        {props.children || buttonText}
      </PopoverButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogContent>
          <CheckoutApolloProvider>
            <Checkout
              onSuccess={handleSuccess}
              onCancel={() => setOpen(false)}
              onDisplay={() => proTracker.click()}
              onCustomerInfoComplete={() => proTracker.step(1)}
              onShippingInfoComplete={() => proTracker.step(2)}
              onPaymentInfoComplete={() => proTracker.step(3)}
            />
          </CheckoutApolloProvider>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PurchasePro
