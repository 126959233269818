import { FlexCol, FlexRow } from '../Flex'

import Desktop from './desktop'
import Mobile from './mobile'

const Footer: React.FC<React.PropsWithChildren<{ hiker?: boolean }>> = ({ hiker = false }) => {
  return (
    <FlexCol alignItems="stretch" position="fixed" width="100vw" bottom={0} sx={{ pointerEvents: 'none' }}>
      <FlexRow sx={{ display: { lg: 'none' } }}>
        <Mobile hiker={hiker} width="100vw" style={{ paddingTop: 100, transform: 'translate3d(0, 30%, 0)' }} />
      </FlexRow>
      <FlexRow sx={{ display: { lg: 'flex', xs: 'none' } }}>
        <Desktop
          hiker={hiker}
          width="100vw"
          style={{ filter: 'drop-shadow(0 0 1rem rgba(0, 0, 0, 0.6))', paddingTop: 100 }}
        />
      </FlexRow>
    </FlexCol>
  )
}

export { Footer }
