import { FlexCol } from '../components/Flex'

import { BadgeCards, FadeIn } from '../components'
import CustomBasePage from '../CustomBasePage'
import { SubscriptionDetails } from '../Subscriptions'
import UserInfoDisplay from '../UserInfoDisplay'

const Badges = () => {
  return (
    <CustomBasePage>
      <FlexCol>
        <FadeIn>
          <UserInfoDisplay>
            <SubscriptionDetails />
          </UserInfoDisplay>
        </FadeIn>
      </FlexCol>

      <BadgeCards />
    </CustomBasePage>
  )
}

export default Badges
