import Fuse from 'fuse.js'

export type IQuestion = { question: string; answer?: string; answerLink?: string }

const search = (questions: IQuestion[]) =>
  new Fuse(questions, {
    keys: ['question', 'answer'],
  })

export default search
