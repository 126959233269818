import 'react-html5-camera-photo/build/css/index.css'

import { Cancel, Check, ClearRounded, ExpandMore, HourglassFull, VerifiedUser } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material'
import { KycDocument, useKycByIdQuery } from '@xyo-network/api-checkout-apollo'
import cx from 'classnames'
import capitalize from 'lodash/capitalize'
import flow from 'lodash/flow'
import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'
import { FC, MouseEventHandler, useState } from 'react'

import { SlideInUp } from '../Animate'

const readable = flow(lowerCase, capitalize)

export const KYCExpandList = ({
  expanded,
  onClick,
}: {
  expanded: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}) => {
  return (
    <IconButton
      className={cx('expand', {
        ['expandOpen']: expanded,
      })}
      onClick={onClick}
      aria-expanded={expanded}
      aria-label="show more"
    >
      <ExpandMore />
    </IconButton>
  )
}

export const KYCList = ({ documents }: { documents?: (KycDocument | null)[] }) => {
  const [activeItem, setActiveItem] = useState<KycDocument | null>(null)
  const { data } = useKycByIdQuery({
    skip: !activeItem?.id,
    variables: { id: activeItem?.id ?? '' },
  })
  return (
    <>
      <List>
        {documents?.map((kyc, i) => {
          const type = kyc?.rejectionReason?.message ?? kyc?.documentType ?? ''
          const primary = type.split('-').map(capitalize).join(' ')
          let secondary = ''
          if (kyc && kyc.createdTime) {
            secondary = new Date(kyc.createdTime).toLocaleString()
          }
          if (kyc && kyc.processedTime) {
            secondary = new Date(kyc.processedTime).toLocaleString()
          }
          if (!kyc) return null
          return (
            <SlideInUp delay={200 * i} key={kyc.id}>
              <ListItem button onClick={() => setActiveItem(kyc)}>
                <Tooltip title={capitalize(kyc.status ?? '')}>
                  <ListItemAvatar>
                    <KycDocumentStatusIcon kyc={kyc} />
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText primary={primary} secondary={secondary} />
              </ListItem>
            </SlideInUp>
          )
        })}
      </List>
      <Dialog open={Boolean(activeItem)} onClose={() => setActiveItem(null)} maxWidth="sm" fullWidth>
        <DialogTitle>{readable(activeItem?.documentType ?? '')}</DialogTitle>
        <Divider />
        <CardMedia image={data?.kycDocumentById?.download} style={{ paddingTop: '56.6%', position: 'relative' }}>
          <Skeleton
            variant="rectangular"
            height="100%"
            width="100%"
            style={{ left: 0, position: 'absolute', top: 0 }}
          />
        </CardMedia>
        <Divider />
        <DialogContent>
          <Typography variant="subtitle1" paragraph>
            ANALYSIS
          </Typography>
          <Typography paragraph>
            This document was <strong>{activeItem?.status}</strong>{' '}
            {activeItem?.processedTime ? `on ${new Date(activeItem?.processedTime).toLocaleString()}` : ''}
          </Typography>
          {activeItem?.rejectionReason?.type ? (
            <Typography>
              <strong>Rejection Reason:</strong> {readable(activeItem?.rejectionReason?.type)}
            </Typography>
          ) : null}
          {activeItem?.rejectionReason?.message ? (
            <Typography paragraph>
              <strong>Rejection Message:</strong> {activeItem?.rejectionReason?.message}
            </Typography>
          ) : null}
          <Typography paragraph>Analysis Score {activeItem?.documentMatches?.score}%</Typography>
          <Typography></Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.containsImage} /> This image contains one or more
            faces
          </Typography>
          <Typography>
            <StatusIcon value={!activeItem?.documentMatches?.data?.isPublishedOnline} /> Document was not found online
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.isIdentityDocument} /> This image looks like an ID
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.hasMinimalAge} /> Subject has minimum legal age
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.dateOfBirth} /> Date of birth{' '}
            <em>{activeItem?.documentMatches?.data?.dateOfBirth}</em>
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.expiryDate} /> Expiry date{' '}
            <em>{activeItem?.documentMatches?.data?.dateOfBirth}</em>
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.issueDate} /> Issue date{' '}
            <em>{activeItem?.documentMatches?.data?.issueDate}</em>
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.firstName} /> First name match{' '}
            <em>{activeItem?.documentMatches?.data?.firstName}</em>
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.lastName} /> Last name match{' '}
            <em>{activeItem?.documentMatches?.data?.lastName}</em>
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions onClick={() => setActiveItem(null)}>
          <Button>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const KycDocumentStatusIcon = ({ kyc }: { kyc: KycDocument }) => {
  if (kyc.status === 'accepted')
    return (
      <Avatar sx={{ bgcolor: 'secondary.main', color: 'white' }}>
        <VerifiedUser />
      </Avatar>
    )
  if (kyc.status === 'in-progress' || kyc.status === 'pending')
    return (
      <Avatar sx={{ bgcolor: 'warning.main', color: 'white' }}>
        <HourglassFull style={{ color: 'white' }} />
      </Avatar>
    )
  if (kyc.status === 'rejected')
    return (
      <Avatar sx={{ bgcolor: 'primary.main', color: 'white' }}>
        <ClearRounded style={{ color: 'white' }} />
      </Avatar>
    )
  return null
}

export const SuccessContent: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Box textAlign="center">
      <Box display="inline-block" mt={4} mb={2} borderRadius="50%" bgcolor="secondary.main" p={2}>
        <VerifiedUser color="action" style={{ color: 'white', height: 120, width: 120 }} />
      </Box>
      {children}
    </Box>
  )
}

const StatusIcon = ({ value }: { value: boolean }) => {
  if (value) return <Check className="text-success" />
  return <Cancel className="text-danger" />
}

export const ProcessingContent: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Box textAlign="center">
      <Box display="inline-block" mt={4} mb={2} borderRadius="50%" bgcolor="warning.main" p={2}>
        <HourglassFull color="action" className="spin" style={{ height: 120, width: 120 }} />
      </Box>
      {children}
    </Box>
  )
}

export const ErrorContent: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Box textAlign="center">
      <Box display="inline-block" mt={4} mb={2} borderRadius="50%" bgcolor="primary.main" p={2}>
        <ClearRounded color="action" style={{ height: 120, width: 120 }} />
      </Box>
      {children}
    </Box>
  )
}
