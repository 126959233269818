import { AssetTitleIcon, LocaleNumber, Table, TableProps } from '../Table'
import { Balance } from './useBalances'

const BalancesTable: React.FC<React.PropsWithChildren<TableProps<Balance>>> = (props) => {
  return (
    <div>
      <Table<Balance> {...props}>
        <AssetTitleIcon title="Asset" />
        <LocaleNumber title="Collected" source="collected" />
        <LocaleNumber title="Pending" source="pending" />
        <LocaleNumber title="Redeemed" source="withdrawn" />
      </Table>
    </div>
  )
}

export default BalancesTable
