import { Poll } from '@mui/icons-material'
import { Box, Button } from '@mui/material'

import bitlabsLogo from '../../assets/bitlabs.svg'
import inbrainLogo from '../../assets/inbrain.png'
import pollfishLogo from '../../assets/pollfish.png'
import { usePollFish } from '../../context'
import {
  trackSurveyBitlabsEvent,
  trackSurveyInbrainEvent,
  trackSurveyPollfishEvent,
  trackSurveyPrecisionEvent
} from '../../utils'
import { useBitlabs } from './BitlabsProvider'
import { useInbrain } from './InbrainProvider'
import { usePrecisionSample } from './PrecisionSampleProvider'
import { useSurveyProvider } from './SurveyProviderProvider'
import { showDisclaimer } from './showDisclaimer'

export const SurveyButtons = () => {
  const [open, setOpen] = useSurveyProvider()
  const setPrecisionOpen = usePrecisionSample()[1]
  const setInbrainOpen = useInbrain()[1]
  const setBitlabsOpen = useBitlabs()[1]
  const pollFish = usePollFish()
  const handleInbrain = async () => {
    setOpen(false)
    await showDisclaimer()
    setInbrainOpen(true)
    trackSurveyInbrainEvent('dialog', {})
  }
  const handleBitlabs = async () => {
    setOpen(false)
    await showDisclaimer()
    setBitlabsOpen(true)
    trackSurveyBitlabsEvent('dialog', {})
  }
  const handlePrecision = async () => {
    setOpen(false)
    await showDisclaimer()
    setPrecisionOpen(true)
    trackSurveyPrecisionEvent('dialog', {})
  }
  const handlePollfish = async () => {
    setOpen(false)
    if (pollFish) {
      await showDisclaimer()
      pollFish
        .initialize()
        .then(() => pollFish.showFullSurvey())
        .catch((err) => console.warn(err))
      trackSurveyPollfishEvent('dialog', {})
    }
  }

  return (
    <>
      <Box py={2}>
        <Button fullWidth variant="contained" color="primary" onClick={handleInbrain} size="large" style={{ color: '#fff' }}>
          <img src={inbrainLogo} alt="inBrain" style={{ filter: 'brightness(100)', height: 16, marginRight: 4, opacity: 0.8 }} /> inBrain
        </Button>
      </Box>
      <Box py={2}>
        <Button fullWidth variant="contained" color="primary" onClick={handlePollfish} size="large" style={{ color: '#fff' }}>
          <img src={pollfishLogo} alt="Pollfish" style={{ height: 16, marginRight: 4, opacity: 0.8, transform: 'translateY(2px)' }} /> Pollfish
        </Button>
      </Box>
      <Box py={2}>
        <Button fullWidth variant="contained" color="primary" onClick={handlePrecision} size="large" style={{ color: '#fff' }}>
          <Poll style={{ fill: '#fff', marginRight: 4, opacity: 0.8 }} /> Precision
        </Button>
      </Box>
      <Box py={2}>
        <Button fullWidth variant="contained" color="primary" onClick={handleBitlabs} size="large" style={{ color: '#fff' }}>
          <img src={bitlabsLogo} alt="Bitlabs" style={{ height: 16, marginRight: 4, opacity: 0.8 }} /> Bitlabs
        </Button>
      </Box>
    </>
  )
}

