import { Route, Routes } from 'react-router-dom'

import { SideNav } from '../../components'

const SideNavRouter = () => {
  return (
    <Routes>
      <Route path="/geoclaims" element={<SideNav />} />
      <Route path="/geomines" element={<SideNav />} />
      <Route path="/geodrops" element={<SideNav />} />
      <Route path="/hodl/history" element={<SideNav />} />
      <Route path="/videoads" element={<SideNav />} />
      <Route path="/audioads" element={<SideNav />} />
      {/* <Route path="/bigwins" element={<SideNav />} /> */}
      <Route path="/background" element={<SideNav />} />
      <Route path="/surveys" element={<SideNav />} />
      <Route path="/account_v3" element={<SideNav />} />
      {/* <Route path="/account_v3b" exact component={SideNav} /> */}
      <Route path="/rewarded/visits" element={<SideNav />} />
      <Route path="/rewarded/scans" element={<SideNav />} />
      {/* <Route path="/rewarded/validations" exact component={SideNav} /> */}
      {/* <Route path="/rewarded/verifications" exact component={SideNav} /> */}
      <Route path="/sweepstakes/history" element={<SideNav />} />
      <Route path="*" element={<></>} />
    </Routes>
  )
}

export default SideNavRouter
