import { Box, Button, Card, CardHeader, LinearProgress, Slide, TextField } from '@mui/material'
import { ChangeEventHandler } from 'react'

interface Props {
  email: string
  setEmail?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  loading: boolean
  setPassword: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  handleForgot: () => void
  signIn: () => void
}

const ForgotForm: React.FC<React.PropsWithChildren<Props>> = ({ email, setEmail, signIn, loading, handleForgot }) => {
  return (
    <Slide direction="up" in={true}>
      <Card component="form" onSubmit={handleForgot}>
        <CardHeader style={{ alignItems: 'center' }} title="Reset password" />
        <Box px={2}>
          <TextField
            type="email"
            name="email"
            value={email}
            onChange={setEmail}
            margin="normal"
            variant="outlined"
            label="Email"
            fullWidth
            autoFocus
          />
        </Box>
        <Box p={2} pt={3} display="flex">
          <Box flex={1} />
          <Button variant="contained" style={{ position: 'relative' }} onClick={signIn}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            style={{ marginLeft: 4, position: 'relative' }}
          >
            {loading ? <LinearProgress style={{ left: 0, position: 'absolute', top: 0, width: '100%' }} /> : null}
            Submit
          </Button>
        </Box>
      </Card>
    </Slide>
  )
}

export default ForgotForm
