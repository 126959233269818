import { List } from '@mui/material'

import adPlay from '../../assets/adPlay.png'
import audioPlay from '../../assets/audioPlay.png'
import bigWinStars from '../../assets/bigWinStars@3x.png'
import hodlCoins from '../../assets/coinsOrange@3x.png'
import botImage from '../../assets/bot.png'
import dropsImage from '../../assets/drops.png'
import claimsImage from '../../assets/geoclaim.png'
import axeImage from '../../assets/pickaxe.png'
import sweepstakes from '../../assets/sweepstakesIcon.png'
import scans from '../Rewards/scansGreen.png'
import survey from '../Rewards/surveyGreen.png'
import visits from '../Rewards/visitsGreen.png'
import HistoryListItem from './HistoryListItem'

const HistoryLinks = ({ pl }: { pl?: number }) => {
  return (
    <List disablePadding component="div" sx={{ width: '100%' }}>
      <HistoryListItem to="/geoclaims" title="Geoclaims" imgSrc={claimsImage} sx={{ pl }} />
      <HistoryListItem to="/geomines" title="Geomines" imgSrc={axeImage} sx={{ pl }} />
      <HistoryListItem to="/geodrops" title="Geodrops" imgSrc={dropsImage} sx={{ pl }} />
      <HistoryListItem to="/background" title="Background Rewards" imgSrc={botImage} sx={{ pl }} />
      <HistoryListItem to="/videoads" title="Ad Rewards" imgSrc={adPlay} sx={{ pl }} />
      <HistoryListItem to="/audioads" title="Audio Rewards" imgSrc={audioPlay} sx={{ pl }} />
      {/* <HistoryListItem to="/bigwins" title="Big Geomine Witnesses" imgSrc={bigWinStars} sx={{ pl }} /> */}
      <HistoryListItem to="/surveys" title="Surveys" imgSrc={survey} sx={{ pl }} />
      <HistoryListItem to="/rewarded/visits" title="Rewarded Visits" imgSrc={visits} sx={{ pl }} />
      <HistoryListItem to="/rewarded/scans" title="Rewarded Scans" imgSrc={scans} sx={{ pl }} />
      <HistoryListItem to="/sweepstakes/history" title="Sweepstakes History" imgSrc={sweepstakes} sx={{ pl }} />
      <HistoryListItem to="/hodl/history" title="HODL History" imgSrc={hodlCoins} sx={{ pl }} />
      {/* <HistoryListItem to="/rewarded/verifications" title="Rewarded Verifications" imgSrc={verifications} /> */}
    </List>
  )
}

export default HistoryLinks
