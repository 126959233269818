import { Checkbox, Grid, MenuItem, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

import { Form, FormField } from './Form'

const required = (v: any) => (v ? '' : 'Required')
const requiredIfNotSame = (v: any, vs: any) => (vs.same ? '' : v ? '' : 'Required')

const addressInfoValidator = {
  'billing.address': requiredIfNotSame,
  'billing.city': requiredIfNotSame,
  'billing.country': requiredIfNotSame,
  'billing.postalCode': requiredIfNotSame,
  'billing.region': requiredIfNotSame,
  'shipping.address': required,
  'shipping.city': required,
  'shipping.country': required,
  'shipping.postalCode': required,
  'shipping.region': required,
}

const AddressForm = ({ onSubmit, initial, id }: any) => {
  return (
    <Form id={id} onSubmitForm={onSubmit} initial={initial} validator={addressInfoValidator}>
      {({ values }: any) => (
        <>
          <h4>Shipping Address</h4>
          <AddressFields type="shipping" values={values.shipping} />
          <div className="d-flex align-items-center mb-3">
            <FormField id="same" name="same" type="checkbox" component={Checkbox} />
            <label htmlFor="same" className="mb-0 text-dark">
              Same as billing address
            </label>
          </div>
          {values.same === true ? (
            <></>
          ) : (
            <>
              <h4>Billing Address</h4>
              <AddressFields type="billing" values={values.billing} />
            </>
          )}
        </>
      )}
    </Form>
  )
}

const AddressFields = ({ type, values }: any) => {
  const [countries, setCountries] = useState([])
  const [regions, setRegions] = useState([])

  useEffect(() => {
    import(/* webpackChunkName: "countries" */ 'country-region-data')
      .then((data) => {
        const countries = data.allCountries.map(([countryName, countryShortCode, regions]) => ({
          countryName,
          countryShortCode,
          regions,
        }))
        const us = countries.find(({ countryShortCode }) => countryShortCode === 'US')
        setCountries([us].concat(countries) as any)
      })
      .catch(() => setCountries([{ countryName: 'United States', countryShortCode: 'US', regions: [] }] as any))
  }, [])

  useEffect(() => {
    if (countries && values && values.country) {
      const country = countries.find(({ countryShortCode }) => values.country === countryShortCode) as any
      if (country) setRegions(country.regions.map(([name, shortCode]: [string, string]) => ({ name, shortCode })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, values && values.country])

  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <FormField
          id="address"
          name={`${type}.address`}
          component={TextField}
          autoComplete={`${type} address-line1`}
          autoFocus
          label="Address"
          variant="outlined"
          margin="normal"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          id="address2"
          name={`${type}.address2`}
          autoComplete={`${type} address-line2`}
          component={TextField}
          label="Address Line 2"
          variant="outlined"
          margin="normal"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField
          id="country"
          fullWidth
          name={`${type}.country`}
          autoComplete={`${type} country-name`}
          className="col"
          component={SelectCountry}
          variant="outlined"
          label="Country"
          margin="normal"
          countries={countries}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField
          id="region"
          fullWidth
          name={`${type}.region`}
          autoComplete={`${type} region`}
          className="col"
          component={SelectRegion}
          regions={regions}
          label="State"
          variant="outlined"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField
          id="city"
          name={`${type}.city`}
          autoComplete={`${type} locality`}
          fullWidth
          className="col"
          component={TextField}
          label="City"
          variant="outlined"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField
          id="postalCode"
          fullWidth
          name={`${type}.postalCode`}
          autoComplete={`${type} postal-code`}
          className="col"
          component={TextField}
          label="Zip"
          variant="outlined"
          margin="normal"
        />
      </Grid>
    </Grid>
  )
}

const SelectCountry = ({ countries, ...props }: any) => {
  const handleChange = (ev: any, element: any) => {
    props.onChange(ev, element.props.value)
  }
  return (
    <TextField select {...props} onChange={handleChange}>
      <MenuItem value="">Choose...</MenuItem>
      {countries.map(({ countryName, countryShortCode }: any, i: any) => (
        <MenuItem key={`${countryShortCode}_${i}`} value={countryShortCode}>
          {countryName}
        </MenuItem>
      ))}
    </TextField>
  )
}

const SelectRegion = ({ regions, ...props }: any) => {
  const handleChange = (ev: any, element: any) => {
    props.onChange(ev, element.props.value)
  }

  return (
    <TextField select {...props} onChange={handleChange}>
      <MenuItem value="">Choose...</MenuItem>
      {regions.map(({ name, shortCode }: any) => (
        <MenuItem key={shortCode} value={shortCode}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default AddressForm
