import { useQuery } from '@apollo/react-hooks'
import Map from '@mui/icons-material/Map'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { BoxProps } from '@mui/material/Box'
import { FlexCol, FlexRow } from '../../../components/Flex'

import { BoundsMap, CustomListItem } from '../../../components'
import { COLLECTIONS_BY_DAY_DAILY_STATS } from './gql'
import { numberFormat } from '../../../utils'
import { useState } from 'react'

const InfoSkeleton: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  return (
    <FlexCol {...props}>
      {' '}
      <Skeleton
        variant="text"
        width={80}
        classes={{ root: 'ml-auto' }}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
      />
      <Skeleton
        variant="text"
        width={60}
        classes={{ root: 'ml-auto' }}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      />
      <Skeleton
        variant="text"
        width={60}
        classes={{ root: 'ml-auto' }}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      />
    </FlexCol>
  )
}

const DeferedBoundsMap: React.FC<React.PropsWithChildren<{ yyyymmdd: number, style: any }>> = ({ yyyymmdd, style }) => {
  const [skip, setSkip] = useState(true)
  const { data, loading } = useQuery(COLLECTIONS_BY_DAY_DAILY_STATS, { skip, variables: { start: yyyymmdd, end: yyyymmdd + 1 }})
  if (loading) return <Skeleton variant="rectangular" height='100%' width='100%' className="skeleton" /> 
  if (data) {
    const coordinates = data?.collectionsByDay?.[0]?.coordinates
    if (!coordinates) return null
    return <BoundsMap coordinates={coordinates} style={style} />
  }
  return <FlexRow height='100%' width='100%' style={{ backgroundColor: '#ddd' }}>
    <Button onClick={() => setSkip(false)}><Map /> Load Map</Button>
  </FlexRow>
}

const CollectionByDay: React.FC<React.PropsWithChildren<{
  day?: Date
  count?: number
  collected?: number
  xyoCollected?: number
  coordinates?: number[][][]
  yyyymmdd: number
  loading: boolean
}>> = ({ day, count, collected, xyoCollected, yyyymmdd, loading }) => {
  return (
    <CustomListItem>
      {loading ? (
        <Skeleton variant="rectangular" height={140} width={140} className="skeleton" />
      ) : yyyymmdd && count ? (
        <FlexRow height={140} width={140}>
          <DeferedBoundsMap yyyymmdd={yyyymmdd} style={{ height: '100%', width: '100%' }} />
        </FlexRow>
      ) : null}
      <FlexCol alignItems="flex-end">
        {loading ? (
          <InfoSkeleton />
        ) : (
          <FlexCol alignItems="flex-end">
            <Typography variant="h6">Geomined: {numberFormat(collected || 0)}</Typography>
            {/* {xyoCollected && collected ? (
              <FlexCol alignItems="flex-end">
                <Typography variant="body2">Start: {numberFormat(xyoCollected - collected)}</Typography>
                <Typography variant="body2">End: {numberFormat(xyoCollected)}</Typography>
              </FlexCol>
            ) : null} */}
            <Typography variant="body1">
              Count: <span>{numberFormat(count || 0, 0)}</span>
            </Typography>
            <Typography variant="subtitle2">{day?.toLocaleDateString()}</Typography>
          </FlexCol>
        )}
      </FlexCol>
    </CustomListItem>
  )
}

export default CollectionByDay
