import { AlertColor } from '@mui/material'

import PrecisionMessage from './PrecisionMessage'

function getPrecisionMessageOpts(opts: { type?: AlertColor; title?: string } = {}): {
  severity?: AlertColor
  message?: JSX.Element | null
} {
  const type = (opts.type || '').toLowerCase()
  const severity = type === 'success' ? 'success' : 'error'
  const message = PrecisionMessage(opts)
  return { message, severity }
}

export default getPrecisionMessageOpts
