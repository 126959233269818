import { Add } from '@mui/icons-material'
import {
  AlertTitle,
  Button,
  ButtonBase,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  PaperProps,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import {
  AccountDocument,
  findMainSubscription,
  isActiveRebillySubscription,
  Maybe,
  PaymentCard,
  useAccountQuery,
  useDeactivatePaymentCardMutation,
} from '@xyo-network/api-checkout-apollo'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import { MouseEventHandler, ReactElement, ReactNode, useState } from 'react'
import { FaCcAmex, FaCcDinersClub, FaCcMastercard, FaCcVisa, FaCreditCard } from 'react-icons/fa'
import { IconBaseProps } from 'react-icons/lib'

import logo from '../../assets/CoinLogoWithText2.png'
import { checkoutClient } from '../../lib'
import { parseError } from '../../utils'
import { FlexCol, FlexRow } from '../Flex'
import { SelectItem } from '../Select'
import { useSnackbar } from '../Snackables'

const toExpiration = ({ expMonth, expYear }: { expMonth?: number | null; expYear?: number | null }) =>
  `${String(expMonth).padStart(2, '0')}/${expYear}`

const hiddenPrefix = '****-****-****-'

interface CreditCardProps extends PaperProps {
  card?: PaymentCard
  onClick?: () => void
}

interface CreditCardIconProps extends IconBaseProps {
  brand?: string | null
}

const CreditCardIcon: React.FC<React.PropsWithChildren<CreditCardIconProps>> = ({ brand, ...props }) => {
  switch (brand?.toLowerCase()) {
    case 'american express':
      return <FaCcAmex {...props} />
    case 'mastercard':
    case 'maestro':
      return <FaCcMastercard {...props} />
    case 'visa':
      return <FaCcVisa {...props} />
    case 'diners club':
      return <FaCcDinersClub {...props} />
    default:
      return <FaCreditCard {...props} />
  }
}

export const CreditCard: React.FC<React.PropsWithChildren<CreditCardProps>> = ({ card = {}, onClick, style, ...props }) => {
  const { last4, expMonth, expYear, brand, status, bankName } = card
  return (
    <ButtonBase
      onClick={onClick}
      component={Paper}
      style={{
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        ...style,
      }}
      {...props}
    >
      <FlexCol alignItems="stretch">
        <FlexRow
          position="absolute"
          height={300}
          width={400}
          overflow="hidden"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '300px',
            opacity: 0.4,
            transform: 'translate3d(-120px, 40px, 0) scaleX(-1)',
            transition: 'all 0.3s ease-in',
          }}
        />
        <FlexCol alignItems="stretch" margin={3}>
          <FlexRow justifyContent="flex-start">
            <Typography variant="h6">{bankName}</Typography>
          </FlexRow>
          <FlexRow>
            <Typography>{status !== 'active' && status !== 'inactive' ? <span className="text-capitalize">{status}</span> : null}</Typography>
          </FlexRow>
          <FlexRow>
            <Typography variant="h4">
              {hiddenPrefix}
              {last4}
            </Typography>
          </FlexRow>
          <FlexRow justifyContent="space-between" alignItems="flex-start">
            <FlexCol>
              <Typography variant="h6">Expiration</Typography>
              <Typography variant="h6">{toExpiration({ expMonth, expYear })}</Typography>
            </FlexCol>
            <FlexCol>
              <Typography variant="h6">Card Type</Typography>
              <CreditCardIcon brand={brand ?? undefined} size={24} />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </ButtonBase>
  )
}

interface CreditCardDetailsProps {
  loading?: boolean
  card: PaymentCard
  onClose?: () => void
  onSave?: () => void
}

export const CreditCardDetails = ({ card, onClose, loading, onSave }: CreditCardDetailsProps) => {
  const { last4, expMonth, expYear, brand, status, bankName } = card
  const [mutate] = useDeactivatePaymentCardMutation({
    client: checkoutClient,
    refetchQueries: [{ query: AccountDocument }],
  })
  const accountQuery = useAccountQuery({
    client: checkoutClient,
  })
  const [deactivating, setDeactivating] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const { setSnackbar } = useSnackbar()[1]
  const subscription = findMainSubscription((accountQuery.data?.account?.customer?.subscriptions ?? []).filter(isActiveRebillySubscription))
  const onCancel = () => {
    setConfirm(false)
  }
  const onDeactivate = () => {
    if (subscription) {
      const error = new Error('You have an active subscription. Your subscription must be canceled before deactivating payments.')
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(error),
        severity: 'error',
      })
      return
    }

    setConfirm(true)
  }
  const onConfirm = async () => {
    try {
      setDeactivating(true)
      if (!card.id) throw new Error('No card specified')
      await mutate({ variables: { paymentCardId: card.id } })
      setSnackbar({
        autoHideDuration: 10000,
        message: <AlertTitle>Payment card deactivated.</AlertTitle>,
        severity: 'success',
      })
      setDeactivating(false)
      if (onClose) {
        onClose()
      }
    } catch (e) {
      const error = e as Error
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(error),
        severity: 'error',
      })
      setDeactivating(false)
    }
  }
  return (
    <FlexCol padding={4} alignItems="stretch">
      <FlexCol alignItems="stretch">
        <FlexRow justifyContent="flex-start">
          <Typography variant="h6">{bankName}</Typography>
        </FlexRow>
        <FlexRow>
          <Typography>{status !== 'active' ? <span className="text-capitalize">{status}</span> : null}</Typography>
        </FlexRow>
        <FlexRow>
          <Typography variant="h4">
            {hiddenPrefix}
            {last4}
          </Typography>
        </FlexRow>
      </FlexCol>
      <DialogContent>
        <FlexRow justifyContent="space-between" alignItems="flex-start">
          <FlexCol>
            <Typography variant="h6">Expiration</Typography>
            <Typography variant="h6">{toExpiration({ expMonth, expYear })}</Typography>
          </FlexCol>
          <FlexCol>
            <Typography variant="h6">Card Type</Typography>
            <CreditCardIcon brand={brand} size={24} />
          </FlexCol>
        </FlexRow>
      </DialogContent>
      <Typography>{confirm ? 'Are you sure you would like to deactivate this payment method?' : ''}</Typography>
      <DialogActions>
        <Button color="primary" onClick={confirm ? onCancel : onClose}>
          {confirm ? 'Cancel' : 'Close'}
        </Button>
        <Button color="secondary" onClick={confirm ? onConfirm : onDeactivate} disabled={loading || deactivating} variant="contained">
          {deactivating ? 'Deactivating...' : confirm ? 'Confirm' : 'Deactivate'}
        </Button>
        {onSave && (
          <Button color="primary" onClick={onSave} disabled={loading} variant="contained">
            {loading ? 'Saving...' : 'Save'}
          </Button>
        )}
      </DialogActions>
    </FlexCol>
  )
}

interface SelectPaymentCardProps {
  cards?: Maybe<Maybe<PaymentCard>[]>
  onChange?: (card: Maybe<PaymentCard>) => void
  onAdd?: () => void
}

export const SelectPaymentCard = ({ onChange, onAdd, cards }: SelectPaymentCardProps) => {
  const cardMap = keyBy(cards, 'id')
  const [value, setValue] = useState(find(cards, (card) => card?.status === 'active')?.id ?? '')
  const handleChange = (ev: SelectChangeEvent<unknown>, node: ReactNode) => {
    const { props } = node as ReactElement
    onChange?.(cardMap[props.value])
    setValue(props.value)
  }
  const handleAdd: MouseEventHandler<HTMLLIElement> = (ev) => {
    ev.stopPropagation()
    onAdd?.()
  }
  return (
    <SelectItem onChange={handleChange} value={value} fullWidth>
      {(cards || []).map((card) =>
        card?.id ? (
          <MenuItem key={card.id} value={card.id} disabled={card.status !== 'active'}>
            <CreditCardItem {...card} />
          </MenuItem>
        ) : null,
      )}
      {onAdd && (
        <div>
          <MenuItem onClick={handleAdd}>
            <ListItemText primary="Add Payment Card" />
            <ListItemIcon>
              <Add />
            </ListItemIcon>
          </MenuItem>
        </div>
      )}
    </SelectItem>
  )
}

interface CreditCardItemProps {
  last4?: string | null
  brand?: string | null
  expMonth?: number | null
  expYear?: number | null
  onClick?: () => void
}

export const CreditCardItem = ({ last4, brand, expMonth, expYear, onClick }: CreditCardItemProps) => (
  <FlexRow onClick={onClick} flexGrow={1}>
    <ListItemText primary={`${hiddenPrefix}${last4}`} secondary={toExpiration({ expMonth, expYear })} />
    <ListItemIcon>
      <CreditCardIcon brand={brand} size={24} />
    </ListItemIcon>
  </FlexRow>
)
