import { BitlabsSurvey, Survey, useListSurveysQuery } from '@coin-microservices/coin-surveys-react-hooks'
import { Refresh } from '@mui/icons-material'
import { Divider, IconButton, TablePagination } from '@mui/material'
import times from 'lodash/times'
import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'

import { CustomList, CustomListHeader, TableCursorActions, useDisplaySuccess, useScrollContext } from '../../../components'
import { FlexCol, FlexRow } from '../../../components/Flex'
import { usePollFish } from '../../../context'
import logger from '../../../Log'
import NoSurveysCompleted from './NoItems'
import SurveyButton from './SurveyButton'
import SurveyCompleted from './SurveyCompleted'

const SurveyHistoryList = () => {
  const pollFish = usePollFish()
  const { nonce } = pollFish
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [cursorByPage, setCusorByPage] = useState<Record<number, Record<string, unknown>>>({ 1: {} })
  const variables = useMemo(() => ({ ...cursorByPage[page], limit: perPage, nonce }), [page, perPage, nonce, cursorByPage])
  const { data, loading, refetch } = useListSurveysQuery({
    notifyOnNetworkStatusChange: true,
    variables,
  })
  const { remoteRowCount, rowData } = useMemo(() => {
    const remoteRowCount = data?.listSurveys?.total ?? 0
    const rowData = data?.listSurveys?.data ?? []
    return { remoteRowCount, rowData }
  }, [data])

  logger().log(pollFish, remoteRowCount, rowData)

  const changePage = (p: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    const cursorNext = data?.listSurveys?.cursorNext
    if (!cursorByPage[p] && cursorNext) {
      setCusorByPage((cursor) => ({ ...cursor, [p]: { cursorNext } }))
    }
    setPage(p)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }

  useDisplaySuccess(refetch)

  return (
    <CustomList
      header={
        <CustomListHeader margin={1} title="Surveys Attempted">
          <FlexRow>
            <FlexRow sx={{ display: { md: 'flex', xs: 'none' } }} marginRight={1}>
              {(rowData || []).length ? <SurveyButton /> : null}
            </FlexRow>
            <IconButton disabled={loading} onClick={() => refetch(variables)}>
              <Refresh />
            </IconButton>
          </FlexRow>
        </CustomListHeader>
      }
    >
      {loading ? (
        times(perPage).map((a, i) => (
          <FlexCol key={i} alignItems="stretch">
            <SurveyCompleted loading={true} />
            <Divider />
          </FlexCol>
        ))
      ) : (rowData || []).length ? (
        (rowData || []).map((survey, i) => (
          <FlexCol key={survey?.id || i} alignItems="stretch">
            <SurveyCompleted {...survey} />
            <Divider />
          </FlexCol>
        ))
      ) : (
        <NoSurveysCompleted />
      )}
      {remoteRowCount > 0 ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={remoteRowCount}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TableCursorActions}
        />
      ) : null}
    </CustomList>
  )
}

export default SurveyHistoryList
