import { geomineCountFeatureImage } from './features'
import { StatCard, StatCardProps } from '../Stats'

import { useUserInfo } from '../../../User'

const GeominesCard: React.FC<React.PropsWithChildren<StatCardProps>> = (props) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      image={geomineCountFeatureImage}
      whole
      title="Geomines"
      subtitle="All time"
      action="View More"
      actionTo="/geomines"
      value={loading ? undefined : userInfo?.xyoGeomined ?? 0}
      description="COIN"
      {...props}
    />
  )
}

export default GeominesCard
