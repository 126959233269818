import { Box, Slide } from '@mui/material'
import { Children, FC } from 'react'

export const Stepper: FC<React.PropsWithChildren<{ step: number; height: number }>> = ({ children, step, height }) => {
  return (
    <Box position="relative" width="100%" overflow="hidden">
      {Children.map(children, (child, i) => (
        <Slide key={i} in={step === i} direction={step >= i ? 'right' : 'left'}>
          <Box position="absolute" width="100%">
            {child}
          </Box>
        </Slide>
      ))}
      <Box
        style={{
          height,
          transition: 'all 0.2s ease-in',
        }}
      />
    </Box>
  )
}
