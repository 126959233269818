import { Fab } from "@mui/material";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FlexRow } from "../Flex";

const SurveyFab = () => {
  return (
    <FlexRow position="fixed" padding={4} bottom={0} right={0} zIndex={1}>
      <Fab
        LinkComponent={Link}
        color="primary"
        sx={{
          border: "3px solid #eee",
          boxSizing: "border-box",
          color: "#eee",
        }}
        {...{ to: '/earn' }}
      >
        <FaPlus />
      </Fab>
    </FlexRow>
  );
};

export default SurveyFab;
