import React from 'react'

import { BossProvider } from '../../../context'
import CustomBasePage from '../../../CustomBasePage'
import AudioAdsList from './List'

const AudioAdsPage: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BossProvider>
    <CustomBasePage>
      <AudioAdsList sx={{ my: 2 }} />
    </CustomBasePage>
  </BossProvider>
)

export default AudioAdsPage
