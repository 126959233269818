import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import { useMemo } from 'react'

import { getCurrentCampaign } from '../../../utils'
import { CLAIM_CAMPAIGN_STATS } from './gql'

const useCampaigns = () => {
  const { data, loading, error } = useQuery(CLAIM_CAMPAIGN_STATS)
  const campaigns = useMemo(
    () =>
      uniq(
        [getCurrentCampaign()].concat(
          (get(data, 'claimCampaignStats') || [])
            .map(({ campaign }: { campaign: number }) => campaign)
            .sort((a: number, b: number) => b - a)
        )
      ),
    [data]
  )

  return { campaigns, error, loading }
}

export default useCampaigns
