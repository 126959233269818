import gql from 'graphql-tag'

import redeemFragment from './redeemFragment'

const redeemRetryMutation = gql`
  mutation RetryRedeemRequest($reference: String!) {
    retryRedeemRequest(reference: $reference) {
      ...Redeem
    }
  }
  ${redeemFragment}
`

export default redeemRetryMutation
