import gql from 'graphql-tag'

const pairInfoQuery = gql`
  query PaieInfo {
    pair(address: "0xa986f2a12d85c44429f574ba50c0e21052b18ba1") {
      totalSupply
      getReserves {
        reserve0(unit: ether)
        reserve1(unit: ether)
      }
    }
  }
`

const updateEthAddress = gql`
  mutation UpdateEthAddress($address: String!) {
    updateEthAddress(address: $address) {
      id
      uid
      type
      ethAddress
    }
  }
`

const MyVerifiedAddressesWithTokenAndPairBalancesDocument = gql`
  query MyVerifiedAddressesWithTokenAndPairBalances {
    hodlCoinbaseBalance(currency: "XYO") {
      id
      name
      balance
      updated
      balanceHodl
      updatedHodl
      refreshCount
      refreshReset
    }
    verifiedAddresses {
      ...VerifiedAddress
      ethBalance(unit: ether)
      xyoBalance: contractBalance(unit: ether, contractAddress: "0x55296f69f40ea6d20e478533c15a6b08b654e758")
      xyoPairBalance: pairBalance(
        contractAddress: "0x55296f69f40ea6d20e478533c15a6b08b654e758"
        pairAddress: "0xa986f2a12d85c44429f574ba50c0e21052b18ba1"
        unit: ether
      )
      xyoLpBalance: contractBalance(unit: ether, contractAddress: "0xa986f2a12d85c44429f574ba50c0e21052b18ba1")
      xyoPoolBalance: poolBalance(contractAddress: "0x55296f69f40Ea6d20E478533C15A6B08B654E758", unit: ether)
      poolPositions(contractAddress: "0x55296f69f40Ea6d20E478533C15A6B08B654E758") {
        ...PoolPosition
      }
    }
  }

  fragment VerifiedAddress on VerifiedAddress {
    id
    uid
    type
    address
    signature
    name
    notes
    fhr {
      uid
      total
      balance
      hasSold
    }
  }

  fragment PoolPosition on UniswapV3NFTPosition {
    token0 {
      name
      symbol
      chainId
    }
    token1 {
      name
      symbol
      chainId
    }
    price0 {
      upper
      current
      lower
    }
    price1 {
      upper
      current
      lower
    }
    tokenURI
    amount0
    amount1
    ratio0
    ratio1
    tokenId
  }
`

export { MyVerifiedAddressesWithTokenAndPairBalancesDocument, pairInfoQuery, updateEthAddress }
