import gql from 'graphql-tag'

import redeemOptionFragment from './redeemOptionFragment'

const redeemOptionsQuery = gql`
  query RedeemOptions {
    redeemOptions {
      ...RedeemOption
    }
  }
  ${redeemOptionFragment}
`

export default redeemOptionsQuery
