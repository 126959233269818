import { FlexCol } from '../../../components/Flex'

import { BossProvider } from '../../../context'
import CustomBasePage from '../../../CustomBasePage'
import GeoclaimList from './List'

const GeoclaimsPage = () => (
  <BossProvider>
    <CustomBasePage>
      <FlexCol marginY={2} alignItems="stretch">
        <GeoclaimList />
      </FlexCol>
    </CustomBasePage>
  </BossProvider>
)

export default GeoclaimsPage
