import { useAllCurrentSweepstakeOptionsQuery } from '@coin-microservices/sweepstakes-react-hooks'
import { Box } from '@mui/material'
import { ReactElement } from 'react'

import DarkSkeleton from './DarkSkeleton'
import LargeListItemText from './LargeListItemText'
import useSweepstakesDetailsListItemDrawingText from './useSweepstakesDetailsListItemDrawingText'
import useSweepstakesDetailsListItemEntriesText from './useSweepstakesDetailsListItemEntriesText'
import useSweepstakesDetailsListItemPrimaryText from './useSweepstakesDetailsListItemPrimaryText'

const SweepstakesDetailsLargeListItemText = ({ action }: { action?: ReactElement }) => {
  const { loading } = useAllCurrentSweepstakeOptionsQuery()
  const primary = useSweepstakesDetailsListItemPrimaryText()
  const drawing = useSweepstakesDetailsListItemDrawingText()
  const entries = useSweepstakesDetailsListItemEntriesText(true)

  return loading ? (
    <LargeListItemText
      primary={
        <Box mb={1}>
          <DarkSkeleton variant="rectangular" width={110} height={18} />
        </Box>
      }
      secondary={<DarkSkeleton variant="rectangular" width={90} height={14} />}
    />
  ) : (
    <LargeListItemText
      primary={primary}
      primaryTypographyProps={{
        style: {
          fontWeight: 'bold',
          lineHeight: '1em',
          // textAlign: 'center',
        },
      }}
      secondary={
        <>
          {drawing}
          <br />
          <strong>{entries}</strong> {action}
        </>
      }
    />
  )
}

export default SweepstakesDetailsLargeListItemText
