import { Maybe, PaymentCard } from '@xyo-network/api-checkout-apollo'

import ConfirmUpgradeContext, { IUpdgradeTableData } from './ConfirmUpgradeContext'

const useConfirmPaymentPrompt = () => {
  const setData = ConfirmUpgradeContext.useContext()[1]
  const confirmPaymentPrompt = (
    dataPromise: Promise<Maybe<IUpdgradeTableData | undefined>>,
    cardsPromise: Promise<Maybe<Maybe<PaymentCard>[]> | undefined>,
    title?: string
  ) => {
    return new Promise<{ method: string; paymentCardId: string } | undefined>((res) => {
      const done = (value?: { method: string; paymentCardId: string }) => {
        setData({ done, error: '', loading: false, open: false })
        res(value)
      }

      setData({ done, loading: true, open: true, title })

      Promise.all([dataPromise, cardsPromise])
        .then(([data, cards]) => {
          setData({ cards, data, done, loading: false, open: true })
        })
        .catch(() => {
          done()
        })
    })
  }

  return confirmPaymentPrompt
}

export default useConfirmPaymentPrompt
