import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@mui/material/styles'
import { Invoice, PaymentCard, Transaction } from '@xyo-network/api-checkout-apollo'
import times from 'lodash/times'

import { usePayInvoice } from '../../checkout'
import { lightTheme } from '../../themes'
import { trackPayEvent } from '../../utils'
import InvoiceListItem from './InvoiceListItem'

const shouldPay: Record<string, boolean> = {
  'past-due': true,
}

interface InvoiceListProps {
  perPage: number
  loading: boolean
  cards: PaymentCard[]
  invoices: Invoice[]
  onClick?: (invoice: Invoice) => void
  onInvoicePaid?: (invoice: Invoice) => void
  onAllInvoicesPaid?: () => void
}

export const InvoiceList = ({
  perPage,
  loading,
  cards,
  invoices,
  onClick,
  onInvoicePaid,
  onAllInvoicesPaid,
}: InvoiceListProps) => {
  const payInvoice = usePayInvoice(cards)
  const paymentTransactionHandler = (invoice: Invoice, transaction: Transaction) => {
    if (transaction) {
      trackPayEvent('past-due-paid', {})
    }
    if (transaction && onInvoicePaid) {
      onInvoicePaid(invoice)
    }
    if (transaction && invoices.length === 1) {
      trackPayEvent('past-due-all-paid', {})
    }
    if (transaction && onAllInvoicesPaid && invoices.length === 1) {
      onAllInvoicesPaid()
    }
  }
  return (
    <ThemeProvider theme={lightTheme}>
      <Paper>
        <List disablePadding>
          {loading ? (
            times(perPage).map((_, i) => <InvoiceListItem key={i} />)
          ) : invoices?.length ? (
            invoices.map?.((invoice) => (
              <InvoiceListItem
                key={invoice.id}
                invoice={invoice}
                onClick={onClick ? () => onClick(invoice) : undefined}
                action={
                  invoice?.status && shouldPay[invoice?.status] ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() =>
                        payInvoice(invoice).then((transaction) => paymentTransactionHandler(invoice, transaction))
                      }
                    >
                      Pay Invoice
                    </Button>
                  ) : null
                }
              />
            ))
          ) : (
            <NoInvoicesFound />
          )}
        </List>
      </Paper>
    </ThemeProvider>
  )
}

const NoInvoicesFound = () => {
  return (
    <div className="list-group-item text-center py-5 rounded-0-sm">
      <h4>No Invoices Found</h4>
    </div>
  )
}

export default InvoiceList
