import { useAllCurrentSweepstakeOptionsQuery } from "@coin-microservices/sweepstakes-react-hooks";
import { ArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  BoxProps,
  useMediaQuery,
  useTheme,
  ButtonProps,
  List,
  ListItem,
  ListItemAvatar,
  Divider,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { Link as ReactLink } from "react-router-dom";

import { LowercaseButton } from "../../componentsPure";
import { useUser, useUserInfo } from "../../User";
import { SweepstakesEntryCount, SweepstakesEntryStep } from "./context";
import { sweepstakesEvent } from "./event";
import { pluralEntries, useSweepstakesOptionsAmountPerEntry } from "./options";
import { formatNumber, getDiscountedTotal } from "./utils";

import black75Discount from './assets/black75Discount@3x.png'
import blackTicket from './assets/blackTicket@3x.png'
import blueTicket from './assets/blueTicket@3x.png'
import green25Discount from './assets/green25Discount@3x.png'
import greenTicket from './assets/greenTicket@3x.png'
import orangeTicket from './assets/orangeTicket@3x.png'
import purple50Discount from './assets/purple50Discount@3x.png'
import purpleTicket from './assets/purpleTicket@3x.png'
import redTicket from './assets/redTicket@3x.png'

export const SweepstakesBackButton = () => {
  const { loading } = useAllCurrentSweepstakeOptionsQuery();
  const [step, setStep] = SweepstakesEntryStep.useContext();
  const onBack = () => {
    sweepstakesEvent("back", "pressed", { value: step - 1 });
    setStep(step - 1);
  };
  return (
    <Button
      variant="outlined"
      size="small"
      disabled={loading}
      style={{ height: "100%", maxWidth: 30, minWidth: "initial" }}
      onClick={onBack}
    >
      <ArrowLeft />
    </Button>
  );
};

const entryOptions = [
  {entryCount: 1, image: orangeTicket, badge: ''},
  {entryCount: 10, image: redTicket, badge: ''},
  {entryCount: 100, image: blueTicket, badge: ''},
  {entryCount: 1000, image: greenTicket, badge: green25Discount},
  {entryCount: 10000, image: purpleTicket, badge: purple50Discount},
  {entryCount: 100000, image: blackTicket, badge: black75Discount},
]

export const SweepstakesEntryList = () => {
  const { user, initialized } = useUser();
  const { loading } = useAllCurrentSweepstakeOptionsQuery();
  const amountPerEntry = useSweepstakesOptionsAmountPerEntry();
  const [selectedCount, setEntryCount] = SweepstakesEntryCount.useContext();
  const [step, setStep] = SweepstakesEntryStep.useContext();
  const handleSetEntryCount = (n: number) => () => {
    sweepstakesEvent("setEntryCount", "pressed", { value: n });
    setEntryCount(n);
    setStep(step + 1);
  };
  if (!initialized) return null;
  if (!user) return <SweepstakesLoginButton />;

  return (
    <List disablePadding>
      {entryOptions.map(({ entryCount, image, badge }, i) => <Box key={entryCount}>
        {i ? <Divider /> : null}
        <SweepstakesEntryListItem
          disabled={loading}
          selected={entryCount === selectedCount}
          entryCount={entryCount}
          image={image}
          badge={badge}
          amountPerEntry={amountPerEntry}
          onClick={handleSetEntryCount(entryCount)}
        />
        </Box>)}
    </List>
  );
};

const SweepstakesEntryListItem = ({
  image,
  badge,
  disabled,
  selected,
  amountPerEntry,
  entryCount,
  onClick,
}: {
  disabled: boolean;
  selected: boolean;
  amountPerEntry: number;
  entryCount: number;
  image: string;
  badge: string;
  onClick: () => void;
}) => {

  const { userInfo } = useUserInfo();
  const balance = userInfo?.xyoCollected ?? 0;
  const { total } = getDiscountedTotal(entryCount, amountPerEntry)
  const insufficientBalance = balance < total
  const primary = `${formatNumber(entryCount)} ${pluralEntries(entryCount)}`
  const secondary = `${formatNumber(total)} COIN`
  return (
    <ListItem button onClick={onClick} disabled={disabled || insufficientBalance} selected={selected} sx={{
      "&.Mui-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.4)"
      },
      "&.Mui-selected:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.4)"
      }
    }}>
      {image ? <ListItemAvatar>
        <img src={image} alt={primary} style={{ height: 60, width: 80, objectFit: 'contain' }} />
      </ListItemAvatar> : null}
      <ListItemText primary={<strong>{primary}</strong>} secondary={secondary} sx={{ m: 0 }} />
      {badge ? <ListItemSecondaryAction>
        <img src={badge} alt={primary} style={{ height: 64, width: 64, display: 'block' }} />
      </ListItemSecondaryAction> : null}
    </ListItem>
  );
};

export const SweepstakesEntryButtons = () => {
  const { user, initialized } = useUser();
  const { loading } = useAllCurrentSweepstakeOptionsQuery();
  const amountPerEntry = useSweepstakesOptionsAmountPerEntry();
  const setEntryCount = SweepstakesEntryCount.useContext()[1];
  const [step, setStep] = SweepstakesEntryStep.useContext();
  const handleSetEntryCount = (n: number) => () => {
    sweepstakesEvent("setEntryCount", "pressed", { value: n });
    setEntryCount(n);
    setStep(step + 1);
  };
  if (!initialized) return null;
  if (!user) return <SweepstakesLoginButton />;

  return (
    <Box display="flex">
      <Box p={1} flexGrow={1}>
        <SweepstakesEntryButton
          entryCount={1}
          disabled={loading}
          amountPerEntry={amountPerEntry}
          onClick={handleSetEntryCount(1)}
        />
      </Box>
      <Box p={1} flexGrow={1}>
        <SweepstakesEntryButton
          entryCount={10}
          disabled={loading}
          amountPerEntry={amountPerEntry}
          onClick={handleSetEntryCount(10)}
        />
      </Box>
      <Box p={1} flexGrow={1}>
        <SweepstakesEntryButton
          entryCount={100}
          disabled={loading}
          amountPerEntry={amountPerEntry}
          onClick={handleSetEntryCount(100)}
        />
      </Box>
    </Box>
  );
};

const SweepstakesEntryButton = ({
  disabled,
  amountPerEntry,
  entryCount,
  onClick,
}: {
  disabled: boolean;
  amountPerEntry: number;
  entryCount: number;
  onClick: () => void;
}) => {
  return (
    <SuccessButton
      fullWidth
      variant="contained"
      color="secondary"
      size="small"
      onClick={onClick}
      style={{ minHeight: 48, overflow: "hidden", position: "relative" }}
      disabled={disabled}
      disableElevation
    >
      <FontBox style={{ paddingBottom: 14 }}>
        {amountPerEntry * entryCount}{" "}
        <span style={{ fontWeight: "normal" }}>COIN</span>
      </FontBox>
      <EntryCountLabel entryCount={entryCount} />
    </SuccessButton>
  );
};

const SweepstakesLoginButton = () => {
  return (
    <Box p={1}>
      <SuccessButton
        fullWidth
        variant="contained"
        color="secondary"
        size="small"
        disableElevation
        onClick={() => sweepstakesEvent("goToLogin", "pressed", {})}
        {...{ component: ReactLink, to: "/auth?redirect=/sweepstakes" }}
      >
        Login for entries with COIN
      </SuccessButton>
    </Box>
  );
};

export const EntryCountLabel = ({
  entryCount,
  hideCount,
}: {
  entryCount: number;
  hideCount?: boolean;
}) => {
  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      textAlign="center"
      fontSize={10}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        lineHeight: 1,
        padding: "2px 0",
        textTransform: "uppercase",
      }}
    >
      {hideCount ? "" : entryCount} {pluralEntries(entryCount)}
    </Box>
  );
};

const FontBox = (props: BoxProps) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const base = {
    fontSize: 14,
    lineHeight: 1,
  };
  return (
    <Box
      {...props}
      style={{ ...props.style, ...base, ...(isMdUp ? { fontSize: 16 } : {}) }}
    />
  );
};

export const SuccessButton = (props: ButtonProps) => {
  return (
    <LowercaseButton {...props} style={{ color: "#fff", ...props.style }} />
  );
};
