import { ExpandLess, ExpandMore } from '@mui/icons-material'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem, { ListItemProps } from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { MouseEventHandler, ReactNode, useState } from 'react'
import { FaCog, FaEnvelopeOpenText, FaHistory, FaImage, FaPlus, FaPlusCircle, FaQuestion, FaShieldAlt, FaSignOutAlt, FaUser, FaUsers, FaWallet } from 'react-icons/fa'
import { Link as ReactLink } from 'react-router-dom'

import { useUser } from '../../User'
import { FlexRow } from '../Flex'
import HistoryLinks from './HistoryLinks'
import NftLinks from './NftLinks'

interface CustomListItemProps extends ListItemProps {
  to?: string
  icon: ReactNode
}

const opts: object = { button: true }
const CustomListItem: React.FC<React.PropsWithChildren<CustomListItemProps>> = ({ children, icon, title, to, ...props }) => {
  const item = (
    <ListItem {...props} {...opts} sx={{ color: 'primary.main' }}>
      <FlexRow>
        {icon}
        <ListItemText primary={title} sx={{ ml: 2 }} />
        {children}
      </FlexRow>
    </ListItem>
  )
  if (to)
    return (
      <Link to={to} sx={{ display: 'block' }} component={ReactLink}>
        {item}
      </Link>
    )
  return item
}

const Sidebar = () => {
  const { user, onLogout } = useUser()
  const [open, setOpen] = useState('')

  const handleClick = (ev: React.MouseEvent, value: string) => {
    ev.stopPropagation()
    setOpen(v => v === value ? '' : value)
  }

  return (
    <>
      <List sx={{ width: '100%' }}>
        <CustomListItem to="/account" title="Account" icon={<FaUser />} />
        <CustomListItem to="/redeem" title="Redeem" icon={<FaEnvelopeOpenText />} />
        <CustomListItem to="/hodl" title="External Wallets" icon={<FaWallet />} />
        <CustomListItem onClick={(e) => handleClick(e, 'history')} title="History" icon={<FaHistory />}>
          <FlexRow>{open === 'history' ? <ExpandLess /> : <ExpandMore />}</FlexRow>
        </CustomListItem>
        <Collapse in={open === 'history'} timeout="auto" unmountOnExit>
          <HistoryLinks pl={4} />
        </Collapse>
        <CustomListItem to="/earn" title="Earn" icon={<FaPlusCircle />} />
        <CustomListItem to="/referrals" title="Referrals" icon={<FaUsers />} />
        <CustomListItem to="/badges" title="Badges" icon={<FaShieldAlt />} />
        <CustomListItem onClick={(e) => handleClick(e, 'nfts')} title="NFTs" icon={<FaImage />}>
          <FlexRow>{open === 'nfts' ? <ExpandLess /> : <ExpandMore />}</FlexRow>
        </CustomListItem>
        <Collapse in={open === 'nfts'} timeout="auto" unmountOnExit>
          <NftLinks pl={4} />
        </Collapse>
      </List>
      <Divider />
      <List sx={{ width: '100%' }}>
        <CustomListItem to="/faq" title="FAQ" icon={<FaQuestion />} />
        {user && <CustomListItem to="/settings" title="Settings" icon={<FaCog />} />}
        {user && <CustomListItem to="/redeem/details" title="Redeem Details" icon={<FaEnvelopeOpenText />} />}
        {user && <CustomListItem onClick={onLogout} title="Logout" icon={<FaSignOutAlt />} />}
      </List>
    </>
  )
}

export default Sidebar
