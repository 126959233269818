import Dialog from '@mui/material/Dialog'
import { SweepstakesEntryDialogOpen, SweepstakesSize, SweepstakesThemeProvider } from './context'
import { SweepstakesEntryForm } from './Form'
import { SweepstakesOptionsContext } from './options'
import { SweepstakesEntrySelected } from './selected'


const EntryDialog = () => {
  const [open, setOpen] = SweepstakesEntryDialogOpen.useContext()
  const [selected] = SweepstakesEntrySelected.useContext()

  return (
    <SweepstakesThemeProvider>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" PaperProps={{ sx: { width: '100%' } }} keepMounted>
        <SweepstakesSize.Provider initial="">
          <SweepstakesOptionsContext.Provider value={selected}>
            <SweepstakesEntryForm />
          </SweepstakesOptionsContext.Provider>
        </SweepstakesSize.Provider>
      </Dialog>
    </SweepstakesThemeProvider>
  )
}

export default EntryDialog
