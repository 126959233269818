import DialogContentText from '@mui/material/DialogContentText'
import { useNavigate } from 'react-router-dom'
import { BaseVerifyRequest } from '../components'
import { verifyDevice } from '../context'

const VerifyDevice = () => {
  const navigate = useNavigate()
  const onClose = () => {
    navigate('/')
  }

  return (
    <BaseVerifyRequest
      verify={verifyDevice}
      titles={{
        error: 'Device Verification Error',
        loading: 'Device Verification',
        success: 'Device Verification Complete',
      }}
      content={{
        success: () =>
          <DialogContentText>You have successfully verified your new device. You may now use it to Redeem, Geodrop, and Geoclaim.</DialogContentText>
      }}
      onClose={onClose}
    />
  )
}

export default VerifyDevice
