import { Dialog } from '@mui/material'

import { useUpgrade } from '../../../store'
import UpgradeModalContent from './UpgradeModalContent'

const UpgradeModal = () => {
  const [open, { closeUpgrade }] = useUpgrade()
  return (
    <Dialog open={!!open} onClose={closeUpgrade} maxWidth="sm" fullWidth>
      <UpgradeModalContent open={open} />
    </Dialog>
  )
}

export default UpgradeModal
