import { FlexRow } from '../../../components/Flex'
import { lazy, Suspense } from 'react'

import { CheckoutProps } from './CheckoutProps'

const CheckoutLazy = lazy(() => import(/* webpackChunkName: "checkout" */ './Checkout'))

const Checkout: React.FC<React.PropsWithChildren<CheckoutProps>> = (props) => {
  return (
    <Suspense fallback={<FlexRow />}>
      <CheckoutLazy {...props} />
    </Suspense>
  )
}

export { Checkout }
export type { CheckoutProps }
