import { Button, ButtonProps } from "@mui/material";

const LowercaseButton = (props: ButtonProps) => (
  <Button
    {...props}
    style={{ fontWeight: "bold", textTransform: "initial", ...props.style }}
  />
);

export default LowercaseButton;
