import * as mixpanel from 'mixpanel-browser'
import { parse } from 'querystring'
import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import { redirect_uri, RedirectScreenLogo, SplashScreenLogo } from './components'
import { ErrorBoundary } from './componentsPure'
import { getNfcRefistrationInviteUrl } from './context'
import logger from './Log'
import Main from './Main'
import * as serviceWorker from './serviceWorker'

mixpanel.init('ec7d7e395d2b3db6b5a37b9cefde7c64', { debug: true, persistence: 'localStorage', track_pageview: true })

const root = createRoot(document.getElementById('web-root')!)

try {
  if (
    // window.location.host === 'localhost:3000' ||
    window.location.host === 'x.coinapp.co'
  ) {
    getNfcRefistrationInviteUrl()
      .then((inviteUrl) => {
        root.render(
          <RedirectScreenLogo
            error={new Error('Not redirected automatically?')}
            message={`Redirecting to ${inviteUrl}`}
            onClick={() => window.open(inviteUrl)}
          />,
        )
        window.location.href = inviteUrl
      })
      .catch((err) => {
        logger().log(err)
        root.render(
          <RedirectScreenLogo
            error={new Error('Not redirected automatically?')}
            message={'Redirecting to https://coinapp.co'}
            onClick={() => window.open('https://coinapp.co')}
          />,
        )
        window.location.href = 'https://coinapp.co'
      })
  } else if (window.location.search.indexOf('ug') !== -1 && window.location.search.indexOf('provider') !== -1) {
    try {
      const windowOpener = window?.opener
      windowOpener?.displaySurvey(parse(window.location.search.replace('?', '')))
    } catch (e) {
      // logger().log(e)
    }
    window.close()
  } else if (window.location.href.indexOf('tiktok') !== -1 && window.location.search.indexOf('code') !== -1) {
    try {
      const windowOpener = window?.opener
      const options = parse(window.location.search.replace('?', ''))
      windowOpener?.handleTikTokOauth(options)
    } catch (e) {
      logger().log(e)
    }
    // window.close()
  } else if (window.location.search.indexOf('state=') !== -1 && window.location.search.indexOf('code') !== -1) {
    try {
      const windowOpener = window?.opener
      const options = parse(window.location.search.replace('?', ''))
      windowOpener?.handleCoinbaseOauth({
        ...options,
        redirect_uri,
      })
    } catch (e) {
      // logger().log(e)
    }
    window.close()
  } else {
    root.render(
      <Main>
        <Suspense fallback={<SplashScreenLogo message="loading..." />}>
          <ErrorBoundary fallback={(error: Error) => <SplashScreenLogo error={error.message} />}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ErrorBoundary>
        </Suspense>
      </Main>,
    )
  }
} catch (e) {
  logger().log(e)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
