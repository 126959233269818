import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { BoxProps } from '@mui/material/Box'
import { FlexCol, FlexGrowCol, FlexRow } from '../../../components/Flex'
import flow from 'lodash/flow'
import get from 'lodash/get'
import identity from 'lodash/identity'

import { CustomListItem, PlanBox } from '../../../components'
import { fork, toLocaleString, toMoment } from '../lib'
import BackgroundRewardPercent from './BackgroundRewardPercent'

interface Props extends BoxProps {
  loading?: boolean
  backgroundReward?: unknown
}

const InfoSkeleton: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <FlexGrowCol alignItems="flex-start" padding={1}>
      <Skeleton variant="text" width={180} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
      <Skeleton variant="text" width={160} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      <Skeleton variant="text" width={160} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
    </FlexGrowCol>
  )
}

const BackgroundRewards: React.FC<React.PropsWithChildren<Props>> = ({ loading = false, backgroundReward, ...props }) => {
  const plan = get(backgroundReward, 'plan')
  const amount = get(backgroundReward, 'amount')
  const secondsElapsed = get(backgroundReward, 'secondsElapsed') ?? 0
  const secondsRewarded = get(backgroundReward, 'secondsRewarded')
  const xyoCollected = get(backgroundReward, 'xyoCollected')
  const date = toMoment(get(backgroundReward, 'date'))

  return (
    <CustomListItem {...props}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={80}
          height={80}
          className="skeleton"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        />
      ) : plan ? (
        <PlanBox plan={plan} />
      ) : null}
      {loading ? (
        <InfoSkeleton />
      ) : (
        <FlexGrowCol alignItems="flex-start" padding={1}>
          <Typography variant="h6">{toLocaleString(amount)} COIN</Typography>
          {secondsRewarded ? <Typography variant="body2">Time Rewarded: {humanize(secondsRewarded)}</Typography> : null}
          {xyoCollected ? (<Typography variant="body2">Running Total: {toLocaleString(xyoCollected)}</Typography>) : null}
          {date ? (
            <FlexRow>
              <Typography variant="subtitle2">Date:&nbsp;{date.toDate().toLocaleString()}</Typography>
            </FlexRow>
          ) : null}
        </FlexGrowCol>
      )}
      <FlexCol marginX={2}>
        <BackgroundRewardPercent
          loading={loading}
          percent={toFixed(((secondsRewarded || 0) / secondsElapsed || 1) * 100)}
        />
      </FlexCol>
      <Divider variant="fullWidth" />
    </CustomListItem>
  )
}

const isFloored = (n: number) => Math.floor(n) === n
const toFixed = fork(
  isFloored,
  identity,
  flow(Number, (n) => n.toFixed(2))
)
// const humanize = identity
const humanize = (secs: number) => {
  let amount = secs
  let unit = 'second'
  if (secs > 60 * 60 * 24) {
    amount = secs / 60 / 60 / 24
    unit = 'day'
  } else if (secs > 60 * 60) {
    amount = secs / 60 / 60
    unit = 'hour'
  } else if (secs > 60) {
    amount = secs / 60
    unit = 'minute'
  }
  return `${toFixed(amount)} ${unit}${amount === 1 ? '' : 's'}`
}

export default BackgroundRewards
