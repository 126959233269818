import React, { useEffect, useState } from 'react'

import { ErrorBannerContext } from './ErrorBanner'

const ErrorBannerProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [error, setError] = useState<string | null>(null)
  const removeError = () => setError(null)
  const setOnline = () => setError(null)
  const setOffline = () => setError('Network connection not found')
  useEffect(() => {
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)
    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])

  return <ErrorBannerContext.Provider value={{ error, removeError, setError }}>{children}</ErrorBannerContext.Provider>
}

export default ErrorBannerProvider
