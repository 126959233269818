import { ThemeProvider } from '@mui/material/styles'
import get from 'lodash/get'

import coinLogo from '../assets/CoinNoText.svg'
import { PopoverButton } from '../components'
import { getUpgradeToMasterDetails } from '../plans'
import { lightTheme } from '../themes'
import ChangeSubscription from './ChangeSubscription'
import useMySubscriptionMaster from './useMySubscriptionMaster'

const UpgradePlanToMaster = () => {
  const { data, isMasterRoute, isImpossibleReason, isUnavailableReason, subscription, userInfo } =
    useMySubscriptionMaster()
  const account = data?.account
  const upgradePlan = getUpgradeToMasterDetails()
  if (!account) return null
  if (!userInfo) return null
  if (!isMasterRoute) return null
  const error = (
    <PopoverButton
      onClick={() => {
        return
      }}
      onRenderPopover={() => {
        return
      }}
      renderPopover={() => (
        <ThemeProvider theme={lightTheme}>
          <div className="d-flex flex-wrap" style={{ maxWidth: 400 }}>
            <div className="col-12 text-center mb-2">
              <img style={{ height: 80 }} alt="COIN Logo" src={coinLogo} />
            </div>
            <div className="col-12 text-center text-dark">
              <p>{isImpossibleReason || isUnavailableReason}</p>
            </div>
          </div>
        </ThemeProvider>
      )}
    >
      {upgradePlan.buttonText}
    </PopoverButton>
  )
  if (isImpossibleReason) return error
  if (isUnavailableReason && !get(userInfo, 'masterOk')) {
    return error
  }
  return (
    <ChangeSubscription subscription={subscription} cards={account.customer?.paymentCards} planId={upgradePlan.planId}>
      {upgradePlan.buttonText}
    </ChangeSubscription>
  )
}

export default UpgradePlanToMaster
