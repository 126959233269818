import { useContext, useMemo } from 'react'

import isActiveRequest from './isActiveRequest'
import RequestsContext from './RequestsContext'

const useActiveRequests = () => {
  const { loading, data = [] } = useContext(RequestsContext)
  const active = useMemo(() => data.filter(isActiveRequest), [data])
  return { active, loading }
}

export default useActiveRequests
