import { List } from '@mui/material'
import { FlexCol } from '../../../components/Flex'

import {
  CustomList,
  EntryDialog,
  EntryHistory,
  SweepstakesHistoryDrawer,
  SweepstakesHistoryFab,
  SweepstakesProvider,
  SweepstakesSize,
} from '../../../components'
import CustomBasePage from '../../../CustomBasePage'

const SweepstakesHistory = () => {
  return (
    <SweepstakesProvider>
      <SweepstakesSize.Provider initial="lg">
        <CustomBasePage>
          <FlexCol marginY={2} alignItems="stretch">
            <CustomList title="Sweepstakes History">
              <List>
                <EntryHistory />
              </List>
            </CustomList>
          </FlexCol>
        </CustomBasePage>
      </SweepstakesSize.Provider>
      <SweepstakesHistoryDrawer />
      <SweepstakesHistoryFab style={{ position: 'fixed', right: 0, top: '50%' }} />
      <EntryDialog />
    </SweepstakesProvider>
  )
}

export default SweepstakesHistory
