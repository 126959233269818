import { Box, Button, Card, CardHeader, FormHelperText, LinearProgress, Slide, TextField } from '@mui/material'
import { ChangeEventHandler } from 'react'

interface Props {
  title: string
  email: string
  password: string
  setEmail?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  loading: boolean
  setPassword: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  handleSubmit: () => void
  error: string
}

const AuthForm: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  email,
  password,
  setEmail,
  loading,
  setPassword,
  handleSubmit,
  error,
  children,
}) => {
  return (
    <Slide direction="up" in={true}>
      <Card component="form" onSubmit={handleSubmit}>
        <CardHeader title={title} />
        <Box px={2}>
          <TextField
            type="email"
            name="email"
            value={email}
            onChange={setEmail}
            margin="normal"
            variant="outlined"
            label="Email"
            fullWidth
            autoFocus
          />
          <TextField
            type="password"
            name="password"
            value={password}
            onChange={setPassword}
            margin="normal"
            variant="outlined"
            label="Password"
            fullWidth
          />
          {error ? <FormHelperText error={Boolean(error)}>{error}</FormHelperText> : null}
        </Box>
        <Box p={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ position: 'relative' }}
            disabled={loading}
            fullWidth
          >
            {loading ? <LinearProgress style={{ left: 0, position: 'absolute', top: 0, width: '100%' }} /> : null}
            Submit
          </Button>
        </Box>
        {children}
      </Card>
    </Slide>
  )
}

export default AuthForm
