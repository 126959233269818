import { Route, Routes } from 'react-router-dom'

import { BottomNav, SurveyNav } from '../../components'

const BottomNavRouter = () => {
  return (
    <Routes>
      {/* <Route path="/create-drops" element={<BottomNav />} />
      <Route path="/geoclaims" element={<BottomNav />} />
      <Route path="/geomines" element={<BottomNav />} />
      <Route path="/geodrops" element={<BottomNav />} />
      <Route path="/videoads" element={<BottomNav />} />
      <Route path="/audioads" element={<BottomNav />} /> */}
      {/* <Route path="/bigwins" element={<BottomNav />} /> */}
      {/* <Route path="/background" element={<BottomNav />} /> */}
      {/* <Route path="/surveys" element={<BottomNav />} /> */}
      {/* <Route path="/rewarded/*" element={<BottomNav />} />
      <Route path="/sweepstakes/history" element={<BottomNav />} />
      <Route path="/account_v3" element={<BottomNav />} /> */}
      {/* <Route path="/account_v3b" exact component={BottomNav} /> */}
      <Route path="/surveys" element={<><BottomNav /><SurveyNav /></>} />
      <Route path="/surveys/*" element={<><BottomNav /><SurveyNav /></>} />
      <Route path="*" element={<></>} />
    </Routes>
  )
}

export default BottomNavRouter
