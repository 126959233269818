import ConfirmUpgradeContext from './ConfirmUpgradeContext'
import ConfirmUpgradeDialog from './ConfirmUpgradeDialog'

const ConfirmUpgradeProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <ConfirmUpgradeContext.Provider>
      {children}
      <ConfirmUpgradeDialog />
    </ConfirmUpgradeContext.Provider>
  )
}

export default ConfirmUpgradeProvider
