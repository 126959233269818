import { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FlexCol, FlexRow } from '../Flex'

interface Props extends BoxProps {
  subtitle?: string
}

const CustomListHeader: React.FC<React.PropsWithChildren<Props>> = ({ children, title, subtitle, ...props }) => {
  return (
    <FlexRow margin={1} justifyContent="space-between" {...props}>
      <FlexCol alignItems="flex-start">
        <Typography variant="h2">{title}</Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </FlexCol>
      {children}
    </FlexRow>
  )
}

export default CustomListHeader
