import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@mui/material/styles'
import { FlexCol } from '../../components/Flex'
import { lightTheme } from '../../themes'

import ListHeader from './CustomListHeader'
import CustomListProps from './CustomListProps'

const CustomList: React.FC<React.PropsWithChildren<CustomListProps>> = ({ title, header, children, ...props }) => {
  return (
    <FlexCol alignItems="stretch" {...props}>
      {header ? header : <ListHeader title={title} />}
      <ThemeProvider theme={lightTheme}>
        <Paper>
          <Divider />
          {children}
        </Paper>
      </ThemeProvider>
    </FlexCol>
  )
}

export default CustomList
