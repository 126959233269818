import curry from 'lodash/curry'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import logger from '../Log'

type IGTag = (key: string, trackingId: string, config?: unknown) => void

type IImpressionItem = {
  id: string
  name: string
  list_name?: string
  brand?: string
  category?: string
  variant?: string
  list_position?: number
  quantity?: number
  price?: number
}

declare global {
  interface Window {
    gtag?: IGTag
  }
}

export const gTag: IGTag = (...args) => {
  const argsStr = JSON.stringify(args).replace(/^\[/, '(').replace(/\]$/, ')')

  if (process.env.NODE_ENV === 'production') {
    try {
      if (window.gtag) {
        window.gtag(...args)
      }
    } catch (e) {
      logger().error('gtag err', e)
    }
  } else {
    logger().log(`gtag${argsStr}`)
  }
}

export class GTagEcommerce {
  constructor(public items: IImpressionItem[]) {}
  view() {
    gTag('event', 'view_item_list', {
      items: this.items,
    })
  }
  click() {
    gTag('event', 'select_content', {
      content_type: 'product',
      items: this.items,
    })
  }
  step(checkout_step: number, checkout_option = '', value?: number | string) {
    const options = {
      checkout_option,
      checkout_step,
      value: typeof value === 'string' || typeof value === 'number' ? value : undefined,
    }

    gTag('event', 'set_checkout_option', options)
  }
  purchase(transaction_id: string, value: number) {
    gTag('event', 'purchase', {
      items: this.items,
      transaction_id,
      value,
    })
  }
}

export const gtagCategoricalEvent = curry(
  (event_category: string, event_action: string, event_label: string, rest: Record<string, unknown>) => {
    gTag('event', event_action, {
      event_category,
      event_label,
      ...rest,
    })
  }
)

export const useTracking = (trackingId: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID) => {
  const location = useLocation()

  useEffect(() => {
    if (!trackingId) {
      logger().log('Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.')
      return
    }

    if (location?.pathname) {
      gTag('config', trackingId, { page_path: location.pathname })
    }
  }, [trackingId, location])
}
