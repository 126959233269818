import { createContext } from 'react'

import { checkoutActions, ICheckoutActions, ICheckoutState, initialState } from '../reducer'

const CheckoutDataContext = createContext<ICheckoutState & ICheckoutActions>({
  ...initialState,
  ...checkoutActions,
})

export default CheckoutDataContext
