import LinearProgress from '@mui/material/LinearProgress'
import Button, { ButtonProps } from '@mui/material/Button'
import cx from 'classnames'
export * from './Icon'

interface Props extends ButtonProps {
  loading?: boolean
}

const LoadingButton: React.FC<React.PropsWithChildren<Props>> = ({ children, disabled, loading, className, ...props }) => {
  return (
    <Button
      color="primary"
      disabled={disabled || loading}
      className={cx('d-flex align-items-center', className)}
      {...props}
    >
      {loading ? <LinearProgress sx={{ left: 0, position: 'absolute', right: 0, top: 0 }} /> : null}
      {children}
    </Button>
  )
}

export default LoadingButton
