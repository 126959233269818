import gql from 'graphql-tag'

const VIDEO_ADS = gql`
  query VideoAdsHistoryList($page: Int, $perPage: Int, $field: String) {
    videoAdsHistoryList(pagination: { page: $page, perPage: $perPage }, sort: { field: $field, order: -1 }) {
      total
      page
      data {
        ...VideoAdsHistory
      }
    }
  }

  fragment VideoAdsHistory on VideoAdsHistory {
    _id
    uid
    amount
    xyoCollected
    adQueue
    adsViewed
    adsCollected
    date
    yyyymmdd
  }
`

export { VIDEO_ADS }
