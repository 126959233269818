const fork = (condition: any, truthy: any, falsy: any) => {
  return function (...args: any[]) {
    if (condition(...args)) {
      return truthy(...args)
    } else {
      return falsy(...args)
    }
  }
}

export default fork
