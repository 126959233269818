import NoItemsPage from '../../../NoItems'

const NoAudioAds = () => (
  <NoItemsPage
    message="No Audio Ads Found"
    href="https://support.coinapp.co/hc/en-us/categories/360002099633"
    buttonText="Read more about audio ads"
  />
)

export default NoAudioAds
