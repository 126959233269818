import { AddressInput, CustomerInput } from '@xyo-network/api-checkout-apollo'
import { getAuth } from 'firebase/auth'
import invoke from 'lodash/invoke'
import pick from 'lodash/pick'
import { FC, useEffect, useReducer, useRef } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { StepForm } from '../../components'
import { localJSONStore, parseError, toFirstAndLastName, trackError } from '../../utils'
import { CheckoutProps } from '../components'
import { addPaymentCard, createPaymentToken, PaymentInfo } from '../lib'
import { checkoutActions, checkoutReducer, IAddressData, initialState } from '../reducer'
import CheckoutDataContext from './CheckoutDataContext'

const infoStore = localJSONStore<CustomerInput>('customerData')
const addressStore = localJSONStore<IAddressData>('addressData')
const creditCardStore = localJSONStore<PaymentInfo>('creditCardData')

const PaymentTokenProvider: FC<React.PropsWithChildren<CheckoutProps>> = ({ onSuccess, children, authorize, ...props }) => {
  const [state, dispatch] = useReducer(checkoutReducer, initialState)
  const dispatcher = useRef(dispatch)
  const actions = bindActionCreators(checkoutActions, dispatcher.current as Dispatch<AnyAction>)

  const setInfoDataThunk = (infoData: CustomerInput) => {
    try {
      actions.setLoading()
      infoStore.to(infoData)
      invoke(props, 'onCustomerInfoComplete', { infoData })
      actions.setAllData({ infoData, loading: false, step: 1 })
    } catch (e) {
      const err = e as Error
      actions.setError(err.message)
    }
  }

  const setAddressDataThunk = (addressData: IAddressData) => {
    try {
      actions.setLoading()
      addressStore.to(addressData)
      const { infoData } = state
      const { same, shipping } = addressData
      const billing = same ? shipping : addressData.billing
      actions.setAllData({ addressData, loading: false, step: 2 })
      invoke(props, 'onShippingInfoComplete', {
        billing,
        infoData,
        shipping,
      })
    } catch (e) {
      const err = e as Error
      actions.setError(err.message)
    }
  }

  const setCreditCardDataThunk = async (creditCardData: PaymentInfo) => {
    try {
      actions.setLoading()
      const save = pick(creditCardData, ['method', 'ccNumber', 'ccExpMonth', 'ccExpYear', 'ccCvv'])
      save.ccCvv = ''
      creditCardStore.to(save)

      const { addressData } = state
      const same = addressData?.same
      const infoData = state.infoData as CustomerInput
      const shipping = addressData?.shipping as AddressInput
      const billing = same ? shipping : (addressData?.billing as AddressInput)
      const emails = [
        {
          label: 'main',
          primary: true,
          value: infoData?.email,
        },
      ]
      const phoneNumbers = infoData?.phone
        ? [
            {
              label: 'main',
              primary: true,
              value: infoData?.phone,
            },
          ]
        : []
      invoke(props, 'onPaymentInfoComplete', {
        billing,
        creditCardData,
        infoData,
        shipping,
      })
      // trackEvent(
      //   'order-data-complete',
      //   toCheckoutData({ infoData, addressData, creditCardData }),
      // )
      const tokenData = await createPaymentToken(creditCardData, {
        ...billing,
        ...infoData,
        emails,
        phoneNumbers,
      })
      if (!infoData) throw new Error('Error creating customer')
      if (!tokenData) throw new Error('Error creating payment token')
      if (!infoData.email) throw new Error('Error creating customer, email is required')
      const paymentCardId = await addPaymentCard(tokenData.id, infoData as CustomerInput, authorize)
      if (!paymentCardId) throw new Error('Error adding payment')
      await onSuccess?.(paymentCardId, {
        billing,
        creditCardData,
        infoData,
        shipping,
      })
    } catch (e) {
      const err = e as Error
      trackError('setCreditCardData error', err)
      actions.setError(parseError(err.message))
    }
  }

  useEffect(() => {
    try {
      const user = getAuth().currentUser
      const info = infoStore.from()
      const infoData = user
        ? {
            email: user.email || '',
            phone: info ? info.phone : '',
            ...toFirstAndLastName(user.displayName),
          }
        : {}
      const addressData = addressStore.from()
      const creditCardData = creditCardStore.from()
      actions.setAllData({ addressData, creditCardData, infoData })
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      dispatcher.current = () => {
        console.warn('Tried to dispatch after dismount')
      }
    }
  }, [])

  return (
    <CheckoutDataContext.Provider
      value={{
        ...state,
        ...actions,
        setAddressDataThunk,
        setCreditCardDataThunk,
        setInfoDataThunk,
      }}
    >
      <StepForm prev={state.prev} step={state.step}>
        {children}
      </StepForm>
    </CheckoutDataContext.Provider>
  )
}

export default PaymentTokenProvider
