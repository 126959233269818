import { Link, Typography } from "@mui/material";
import { DownloadButtonBox } from "../components/Buttons/DownloadButtons";
import { FlexCol, FlexRow } from "../components/Flex";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CoinLogo from "../assets/CoinLogo";
import CustomBasePage from "../CustomBasePage";

const AppLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [deepLink, setDeepLink] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const deep = location.pathname.replace(/\/appLink\//i, "");
    if (deep) {
      setDeepLink(deep);
    }
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    ) {
      if (deep === "redeem") {
        navigate("/redeem");
      } else {
        setIsDesktop(true);
      }
    }
    window.location.href = `coinapp://${deep}`;
  }, [location, navigate]);
  return (
    <CustomBasePage>
      <FlexCol>
        <FlexRow>
          <CoinLogo style={{ height: 120 }} />
        </FlexRow>
        <FlexRow>
          <h1 style={{ fontWeight: 100 }}>GET PAID TO EXPLORE THE WORLD!</h1>
        </FlexRow>
        <FlexRow>
          {isDesktop ? (
            <Typography variant="body1">
              Please open this link on your mobile device.
            </Typography>
          ) : (
            <Typography variant="body1">
              Not redirected automatically?{" "}
              <Link href={`coinapp://${deepLink}`}>Click Here</Link>
            </Typography>
          )}
        </FlexRow>
        <DownloadButtonBox
          iosLink="https://itunes.apple.com/app/id1450443351"
          androidLink="https://play.google.com/store/apps/details?id=network.xyo.coin"
        />
      </FlexCol>
    </CustomBasePage>
  );
};

export default AppLink;
