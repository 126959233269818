import { createContext } from 'react'

import UserContextProps from './UserContextProps'

const defaultUserContext = {
  initialized: false,
  skipRedeemSubscription: {
    current: '',
  },
  tokenLogin: false,
  user: null,
}

const UserContext = createContext<UserContextProps>(defaultUserContext)

export default UserContext
