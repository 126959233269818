import { BoxProps } from '@mui/material/Box'
import { FlexRow } from '../../components/Flex'

import SmartProfileImage from './SmartProfileImage'

const UserAvator: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  return (
    <FlexRow {...props}>
      <SmartProfileImage sx={{ margin: 2 }} />
    </FlexRow>
  )
}

export default UserAvator
