import { Info as InfoIcon } from '@mui/icons-material'
import { CircularProgress, TextField, Tooltip, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '../components/Flex'

import CustomBasePage from '../CustomBasePage'
import { useUserRedeemDetailsInfo } from '../User'

export default () => {
  return <RedeemDetailsPage />
}

const RedeemDetailsPage = () => {
  const { details, loading } = useUserRedeemDetailsInfo()

  return (
    <CustomBasePage>
      <Typography variant="h2" marginY={4}>
        Redeem Details
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <FlexCol paddingTop={1} alignItems="stretch">
          <DisabledField tooltip="Current Coinbase Email" label="Coinbase Email" value={details?.coinbaseEmail} />
          <DisabledField tooltip="Current ERC-20 Wallet" label="ERC-20 Wallet" value={details?.ethAddress} />
          <DisabledField tooltip="Current NFT Wallet" label="NFT Wallet" value={details?.nftAddress} />
          <DisabledField
            tooltip="Current Shipping Address"
            label="Shipping Address"
            value={details?.shipStreetAddress}
          />
          <DisabledField tooltip="Current Phone Number" label="Phone Number" value={details?.phoneNumber} />
        </FlexCol>
      )}
    </CustomBasePage>
  )
}

const DisabledField = ({ tooltip, value, label }: { tooltip: string; value: string; label: string }) => {
  return (
    <FlexRow marginY={2}>
      <Tooltip enterDelay={200} title={tooltip}>
        <TextField
          variant="outlined"
          label={
            <FlexRow>
              <Typography marginRight={1}>{label}</Typography>
              <InfoIcon />
            </FlexRow>
          }
          value={value || ''}
          InputProps={{
            disabled: true,
          }}
          fullWidth
        ></TextField>
      </Tooltip>
    </FlexRow>
  )
}
