import pickBy from 'lodash/fp/pickBy'
import { parse, ParsedUrlQueryInput, stringify } from 'querystring'

const isUtmParam = (key?: string | null) => (key || '').indexOf('utm_') === 0
const pickUtmParams = pickBy((_, key) => isUtmParam(key))

export const fromSearch = (search: string) => {
  return parse(search.replace(/^\?/, ''))
}

export const toSearch = (params?: ParsedUrlQueryInput) => {
  return stringify(params)
}

const initialSearchParams = fromSearch(window.location.search)

export const utmParams = pickUtmParams(initialSearchParams)

if (initialSearchParams.userGroup) {
  // Does not follow utm_X add it anyway for appsflyer
  utmParams.userGroup = initialSearchParams.userGroup
  utmParams.media_source = initialSearchParams.utm_source || ''
  utmParams.campaign = initialSearchParams.utm_campaign || ''
  utmParams.ad_id = initialSearchParams.utm_content || ''
}
