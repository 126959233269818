import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BaseVerifyRequest, VerifyRedeemSuccess } from '../components'
import { verifyRedeem } from '../context'

export default () => {
  const navigate = useNavigate()
  const onClose = () => {
    navigate('/redeem')
  }
  const [value, setValue] = useState('')
  return (
    <BaseVerifyRequest
      verify={verifyRedeem}
      titles={{
        error: 'Redeem Verification Error',
        loading: 'Redeem Verification',
        success: ({ state }) => (state === 'verifying' ? 'Almost Done' : 'Redeem Verification Complete'),
      }}
      content={{
        success: ({ type, state, options, shippingSku }, { reVerify, loading }: any) => {
          return (
            <VerifyRedeemSuccess
              options={options}
              type={type}
              state={state}
              loading={loading}
              value={value || shippingSku}
              onChange={(variant: { shippingSku: string }) => {
                setValue(variant.shippingSku)
                reVerify(variant.shippingSku)
              }}
            />
          )
        },
      }}
      onClose={onClose}
    />
  )
}
