import Button from '@mui/material/Button'

import { FlexRow } from '../../components/Flex'
import CustomBasePage from '../../CustomBasePage'

interface Routes {
  name: string
  url: string
}

const routes: Routes[] = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'FAQ',
    url: '/faq',
  },
  {
    name: 'Plans',
    url: '/plans',
  },
  {
    name: 'Free Trial',
    url: '/freetrial',
  },
  {
    name: 'Devices',
    url: '/devices',
  },
  {
    name: 'App Link',
    url: '/applink',
  },
  {
    name: 'Success',
    url: '/success',
  },
  {
    name: 'Auth',
    url: '/auth',
  },
  {
    name: 'Invite',
    url: '/invite',
  },
  {
    name: 'Auth Action',
    url: '/auth/action',
  },
  {
    name: 'Account',
    url: '/account',
  },
  {
    name: 'Invoices',
    url: '/invoices',
  },
  {
    name: 'Badges',
    url: '/badges',
  },
  {
    name: 'Invoice Past Due',
    url: '/invoices-past-due',
  },
  {
    name: 'Subscriptions',
    url: '/subscriptions',
  },
  {
    name: 'Account Delete',
    url: '/account/delete',
  },
  {
    name: 'Verify',
    url: '/verify',
  },
  {
    name: 'External Wallets',
    url: '/hodl',
  },
  {
    name: 'Redeem Verify',
    url: '/redeem/verify',
  },
  {
    name: 'Sweepstakes',
    url: '/sweepstakes',
  },
  {
    name: 'Sweepstakes History',
    url: '/sweepstakes/history',
  },
  {
    name: 'Withdraw',
    url: '/withdraw',
  },
  {
    name: 'Redeem',
    url: '/redeem',
  },
  {
    name: 'Change Email',
    url: '/change/email',
  },
  {
    name: 'Refund',
    url: '/refund',
  },
  {
    name: 'Identity',
    url: '/identity',
  },
  {
    name: 'Master',
    url: '/master',
  },
  {
    name: 'Geoclaims',
    url: '/geoclaims',
  },
  {
    name: 'Geomines',
    url: '/geomines',
  },
  {
    name: 'Geodrops',
    url: '/geodrops',
  },
  {
    name: 'Video Ads',
    url: '/videoads',
  },
  // {
  //   name: 'Big Wins',
  //   url: '/bigwins',
  // },
  {
    name: 'Background',
    url: '/background',
  },
  {
    name: 'Referrals',
    url: '/referrals',
  },
  {
    name: 'Referrer',
    url: '/referrer',
  },
  {
    name: 'Settings',
    url: '/settings',
  },
  {
    name: 'Surveys',
    url: '/surveys',
  },
  {
    name: 'Opinion',
    url: '/opinion',
  },
  {
    name: 'Rewarded Visits',
    url: '/rewarded/visits',
  },
  {
    name: 'Rewarded Scans',
    url: '/rewarded/scans',
  },
  // {
  //   name: 'Rewarded Validations',
  //   url: '/rewarded/validations',
  // },
  // {
  //   name: 'Rewarded Verifications',
  //   url: '/rewarded/verifications',
  // },
  // {
  //   name: 'Create Geodrop',
  //   url: '/geodrops/create',
  // },
  {
    name: 'Premium',
    url: '/premium',
  },
  {
    name: 'Thank You',
    url: '/thank-you',
  },
  {
    name: '404',
    url: '/blahblahblah',
  },
]

const TestPage = () => {
  return (
    <CustomBasePage>
      <FlexRow flexWrap="wrap">
        {routes.map((route, index) => {
          return (
            <Button variant="outlined" sx={{ margin: 2 }} key={index} onClick={() => window.open(route.url)}>
              {route.name}
            </Button>
          )
        })}
      </FlexRow>
    </CustomBasePage>
  )
}

export default TestPage
