import gql from 'graphql-tag'

const redeemFragment = gql`
  fragment Redeem on RedeemRequest {
    id
    wallet
    updated
    reference
    ownerUID
    created
    confirmation
    shipFullName
    shipStreetAddress
    shipStreetAddress2
    shipCity
    shipState
    shipZipcode
    shipCountry
    shipPhoneNumber
    notes
    sent
    coinType
    status
    contact
    type
    fromLegacy
    imported
    itemId
    quantity
    uid
    state
    xyoCost
    xyoERC20Requested
    email
    timestamp
    ethAddress
    date
    currentTime
    yyyymmdd
    transactionHash
    confirmation
    useCoinbase
  }
`

export default redeemFragment
