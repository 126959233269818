export function formatNumber (n: number) {
  return n.toLocaleString(undefined, { maximumFractionDigits: 0 })
}

export function getDiscountedTotal (entryCount: number, amountPerEntry: number) {
  const discount = getDiscount(entryCount)
  const totalEntries = entryCount * (1 - discount)
  const discountedEntries = entryCount * discount
  const total = totalEntries * amountPerEntry 
  const discounted = discountedEntries * amountPerEntry
  return { total, discounted, totalEntries, discountedEntries }
}

function getDiscount (entryCount: number) {
  switch (entryCount) {
    case 1000: return .25
    case 10000: return .5
    case 100000: return .75
    default: return 0
  }
}
