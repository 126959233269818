import Typography from '@mui/material/Typography'
import { BoxProps } from '@mui/material/Box'
import { FlexRow } from '../components/Flex'
import capitalize from 'lodash/capitalize'

import { PlanIcon } from '../components'
import useMySubscription from './useMySubscription'

const SubscriptionInfo: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  const { activeSubscription, isFHR, inTrial } = useMySubscription()
  return (
    <FlexRow alignItems="center" {...props}>
      <Typography marginRight={1} fontWeight={700}>
        Plan:
      </Typography>
      <Typography marginRight={1}>{capitalize(activeSubscription) || 'Basic'}</Typography>
      {isFHR ? <Typography marginRight={1}>FHR</Typography> : null}
      {inTrial ? <Typography marginRight={1}>(Trial)</Typography> : null}
      <PlanIcon plan={activeSubscription} style={{ height: 18 }} />
    </FlexRow>
  )
}

export default SubscriptionInfo
