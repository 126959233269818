import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import omitBy from 'lodash/omitBy'
import set from 'lodash/set'
import React, { DetailedHTMLProps, FormEvent, FormHTMLAttributes, useEffect, useState } from 'react'

import { FormContext } from './FormContext'

export interface FormProps<V = unknown> extends Omit<DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'children'> {
  onSubmitForm?: (values: V) => void
  initial?: any
  validator?: unknown
}

type WithCbChildren = {
  children?: ((d: unknown) => JSX.Element) | (JSX.Element | null)[]
}

const Form: React.FC<FormProps & WithCbChildren>= ({ initial, onSubmitForm, children, validator, ...props }) => {
  const [{ values, errors, touched }, setState] = useState({
    errors: {},
    touched: {},
    values: initial || {},
  })
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    const e = computeAllErrors({ errors, touched, values }, validator)
    if (Object.keys(e).length) {
      setState((s) => ({ ...s, errors: e }))
      return
    }
    onSubmitForm?.(values)
  }
  const handleChange = (event: any, value: any) => {
    const name = typeof event === 'string' ? event : event.target.name
    const text = value !== undefined ? value : event.target.value
    setState((s) => ({
      ...s,
      touched: { ...set(s.touched, name, true) },
      values: { ...set(s.values, name, text) },
    }))
  }
  const handleBlur = (event: any) => {
    const name = event.target.name
    setState((s) => ({ ...s, touched: { ...set(s.touched, name, true) } }))
  }
  const handleError = (name: any, value: any) => {
    setState((s) => ({
      ...s,
      errors: {
        ...set(s.errors, name, value && value.message ? value.message : value),
      },
    }))
  }
  useEffect(() => {
    if (initial) {
      setState((s) => ({ ...s, values: initial }))
    }
  }, [initial])
  useEffect(() => {
    setState((s) => ({ ...s, errors: computeTouchedErrors(s, validator) }))
  }, [touched, values, validator])
  return (
    <FormContext.Provider value={{ errors, handleBlur, handleChange, handleError, touched, values }}>
      <form onSubmit={handleSubmit} {...props}>
        {typeof children === 'function' ? children({ errors, handleChange, touched, values }) : children}
      </form>
    </FormContext.Provider>
  )
}

const computeErrors = (fn: any) => (state: any, validator: any) =>
  validator
    ? omitBy(
        mapValues(validator, (f, n) => f && fn(state, f, n)),
        (v) => !v
      )
    : {}

const computeAllErrors = computeErrors((state: any, fn: any, name: any) => {
  return fn(get(state.values, name), state.values) || ''
})

const computeTouchedErrors = computeErrors((state: any, fn: any, name: any) => {
  if (get(state.errors, name) || get(state.touched, name)) {
    return fn(get(state.values, name), state.values) || ''
  }
  return ''
})

export default Form
