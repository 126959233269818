import { Mutation, Query } from '@apollo/react-components'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TablePagination from '@mui/material/TablePagination'
import { ThemeProvider } from '@mui/material/styles'
import { Invoice, PaymentCard } from '@xyo-network/api-checkout-apollo'
import first from 'lodash/first'
import keyBy from 'lodash/keyBy'
import { useState } from 'react'

import { CheckoutApolloProvider } from '../../checkout'
import {
  CustomList,
  CustomListHeader,
  InvoiceList,
  LoadingButton,
  MY_PAST_DUE_INVOICES,
  TablePaginationActions,
  usePageQueryParams,
} from '../../components'
import { swalError, swalSuccess } from '../../componentsPure'
import { BossProvider } from '../../context'
import CustomBasePage from '../../CustomBasePage'
import logger from '../../Log'
import { redeemHistoryQuery, redeemRetryMutation } from '../../store'
import { useUserInfo } from '../../User'
import { parseError, forget } from '../../utils'
import { lightTheme } from '../../themes'

interface PastDueInvoiceResult {
  me: {
    invoicesPastDue: {
      data: Invoice[]
      total: number
    }
    paymentCards: {
      data: PaymentCard[]
      total: number
    }
  }
}

const Invoices = ({ onAllInvoicesPaid }: { onAllInvoicesPaid?: () => void }) => {
  const { query, handleChangePage, handleChangeRowsPerPage } = usePageQueryParams()
  return (
    <CustomList
      header={
        <CustomListHeader
          title="Invoices"
          subtitle="Past due invoices must be paid to continue subscription and redeem rewards"
        />
      }
    >
      <Query<PastDueInvoiceResult>
        query={MY_PAST_DUE_INVOICES}
        variables={{
          pagination: { page: query.page, perPage: query.perPage },
        }}
      >
        {({ data, loading }) => {
          const customer = data?.me
          const invoices = customer?.invoicesPastDue?.data ?? []
          const total = customer?.invoicesPastDue?.total
          const cards = customer?.paymentCards?.data ?? []
          return (
            <div>
              <InvoiceList
                perPage={query.perPage}
                loading={loading}
                invoices={invoices}
                onAllInvoicesPaid={onAllInvoicesPaid}
                cards={cards}
              />
              {total ? (
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={total}
                  rowsPerPage={query.perPage}
                  page={query.page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                ></TablePagination>
              ) : null}
            </div>
          )
        }}
      </Query>
    </CustomList>
  )
}

interface RedeemHistoryResult {
  redeemOptions: {
    id: string
    reference: string
    itemId: string
    imageUrl: string
    title: string
  }[]
  myRedeemHistory: {
    data: {
      id: string
      state: string
      status: string
      xyoCost: number
      itemId: string
      xyoCollected: number
      reference: string
    }[]
  }
}

interface RedeemHistoryMutationResult {
  id: string
}

const RedeemDenied = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { userInfo } = useUserInfo()
  return (
    <ThemeProvider theme={lightTheme}>
      <BossProvider>
        <Query<RedeemHistoryResult> query={redeemHistoryQuery} variables={{ sort: { field: 'date', order: -1 } }}>
          {({ data, loading }) => {
            if (loading) return null
            const redeems = data?.myRedeemHistory?.data ?? []
            const redeem = first(redeems)
            if (!redeem) return null
            if (redeem.state !== 'denied' || redeem.status !== 'denied') {
              logger().log('last redeem not denied')
              return null
            }

            if (!userInfo?.xyoCollected || userInfo?.xyoCollected < redeem.xyoCost) {
              logger().log('not enough collected to re request')
              return null
            }
            const options = data?.redeemOptions ?? []
            const optionsById = keyBy(options, 'id')
            const reference = redeem?.reference
            const option = optionsById[redeem?.itemId]
            const imageUrl = option?.imageUrl ?? ''
            const title = option?.title ?? ''
            return (
              <Mutation<RedeemHistoryMutationResult>
                mutation={redeemRetryMutation}
                onError={(e) => {
                  onClose()
                  forget(swalError(parseError(e.message)))
                }}
                onCompleted={() => {
                  onClose()
                  forget(swalSuccess(`Your request for ${title} has been resubmitted.`))
                }}
              >
                {(retryRedeem, { loading: updating }) => (
                  <RetryRedeemDialog
                    title={title}
                    imageUrl={imageUrl}
                    loading={updating}
                    onClose={onClose}
                    onConfirm={() =>
                      retryRedeem({
                        variables: { reference },
                      })
                    }
                    open={open}
                  />
                )}
              </Mutation>
            )
          }}
        </Query>
      </BossProvider>
    </ThemeProvider>
  )
}

interface RetryRedeemDialogProps {
  open: boolean
  title: string
  imageUrl: string
  loading: boolean
  onClose: () => void
  onConfirm: () => void
}

const RetryRedeemDialog = ({ open, onClose, title, imageUrl, onConfirm, loading }: RetryRedeemDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div className="d-flex align-items-center">
          {imageUrl ? (
            <Avatar
              src={imageUrl}
              className="mr-2"
              style={{ borderRadius: 0 }}
              imgProps={{ style: { objectFit: 'contain' } }}
            />
          ) : null}
          {title}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Your previously denied request for {title} can now be retried.</DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: 'flex-end' }}>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
        <LoadingButton variant="contained" color="primary" onClick={onConfirm} loading={loading}>
          Request
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const InvoicesPastDue: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [retryRedeemOpen, setRetryRedeemOpen] = useState(false)
  return (
    <CheckoutApolloProvider>
      <CustomBasePage>
        <Invoices onAllInvoicesPaid={() => setRetryRedeemOpen(true)} />
        <RedeemDenied open={retryRedeemOpen} onClose={() => setRetryRedeemOpen(false)} />
      </CustomBasePage>
    </CheckoutApolloProvider>
  )
}

export default InvoicesPastDue
