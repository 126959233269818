import { SVGProps } from 'react'

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 240 190"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>plusUser</title>
      <path
        fill="#fff"
        d="M120,0 C109.003905,0 100,9.003905 100,20 C100,25.91797 102.734375,31.171875 106.875,34.84375 L72.5,94.375 L38.59375,77.34375 C39.49219,75.078125 40,72.578125 40,70 C40,59.003905 30.996095,50 20,50 C9.003905,50 0,59.003905 0,70 C0,80.878905 8.847655,89.82422 19.6875,90 L33.75,150 L206.25,150 L220.3125,90 C231.152345,89.82422 240,80.878905 240,70 C240,59.003905 230.996095,50 220,50 C209.003905,50 200,59.003905 200,70 C200,72.34375 200.48828,74.628905 201.25,76.71875 L167.03125,93.90625 L132.96875,35 C137.20703,31.328125 140,25.996095 140,20 C140,9.003905 130.996095,0 120,0 Z M120,10 C125.58594,10 130,14.414065 130,20 C130,25.58594 125.58594,30 120,30 C114.414065,30 110,25.58594 110,20 C110,14.414065 114.414065,10 120,10 Z M20,60 C25.58594,60 30,64.414065 30,70 C30,75.58594 25.58594,80 20,80 C14.414065,80 10,75.58594 10,70 C10,64.414065 14.414065,60 20,60 Z M220,60 C225.58594,60 230,64.414065 230,70 C230,75.58594 225.58594,80 220,80 C214.414065,80 210,75.58594 210,70 C210,64.414065 214.414065,60 220,60 Z M35,160 L35,185 C35,187.77344 37.246095,190 40,190 L200,190 C202.77344,190 205,187.77344 205,185 L205,160 L35,160 Z"
        id="plusUser"
      ></path>
    </svg>
  )
}
