import fetch from 'isomorphic-fetch'
import get from 'lodash/get'

const retrieveCustomToken = async (token: string) => {
  const res = await fetch(`${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/retrieve-token/${token}`)
  const data = await res.json()
  return get(data, 'customToken')
}

export default retrieveCustomToken
