import { Typography } from '@mui/material'
import moment from 'moment-timezone'
import { useMemo } from 'react'

import { getWinner, useSweepstakesOptions, useSweepstakesOptionsEnded } from '../options'

const useSweepstakesDetailsListItemDrawingText = () => {
  const options = useSweepstakesOptions()
  const ended = useSweepstakesOptionsEnded()
  return useMemo(() => {
    if (options?.userEntry?.winner) {
      const winner = getWinner(options)
      // Make sure confirmed before showing
      if (winner?.confirmed) {
        return <Typography style={{ fontSize: '1.2em', lineHeight: '1em' }}>You Were The Winner!!!</Typography>
      }
    }
    const drawingDate = options?.endDate ? moment(options?.endDate).fromNow() : ''
    if (ended) {
      return `Winner selected ${drawingDate}`
    }
    const drawing = drawingDate ? `Winner drawn ${drawingDate}` : ''
    return drawing
  }, [options, ended])
}

export default useSweepstakesDetailsListItemDrawingText
