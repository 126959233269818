function postOptions(body: unknown, headers: Record<string, unknown>): Record<string, unknown> {
  return {
    body: JSON.stringify(body),
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    method: 'POST',
    mode: 'cors',
  }
}

export default postOptions
