/* eslint-disable no-undef */

import Directions from '@mui/icons-material/Directions'
import Menu from '@mui/icons-material/Menu'
import { ThemeProvider } from '@mui/material/styles'
import { BoxProps } from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import { FlexRow } from '../Flex'
import { cloneElement, createContext, FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react'

import { useAllArticles } from '../../context'
import { PopoverButton } from '../Buttons'
import { HelpLinks } from '../Sidebar'
import FaqAccordionItem from './FaqAccordionItem'
import createQuestionsIndex, { IQuestion } from './search'
import { lightTheme } from '../../themes'

interface QuestionContextProps {
  filter: string
  questions?: IQuestion[]
  categoryId?: string | null
  active?: string | null
  setActive?: (v: string | null) => void
  setCategoryId?: (v: string | null) => void
  setFilter?: (v: string) => void
}

export const Context = createContext<QuestionContextProps>({
  filter: '',
})

export const useQuestions = () => {
  return useContext(Context)
}

export const QuestionsProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [filter, setFilter] = useState<string>('')
  const [categoryId, setCategoryId] = useState<string | null>(null)
  const [active, setActive] = useState<string | null>(null)
  const [allQuestions, setAllQuestions] = useState<IQuestion[]>()
  const articles = useAllArticles()
  const activeQuestions = useMemo(() => {
    return categoryId
      ? (articles.data || []).filter(({ category_id }) => category_id === categoryId)
      : allQuestions
      ? allQuestions?.concat(articles.data || [])
      : []
  }, [allQuestions, articles, categoryId])
  const questionsIndex = useMemo(() => createQuestionsIndex(activeQuestions), [activeQuestions])

  const questions = filter ? questionsIndex.search(filter).map((v) => v.item) : activeQuestions

  useEffect(() => {
    import(/* webpackChunkName: "questions" */ './questions.json')
      .then((questions) => {
        setAllQuestions(questions.default)
      })
      .catch(() => {
        return
      })
  }, [])

  return (
    <Context.Provider
      value={{
        active,
        categoryId,
        filter,
        questions,
        setActive,
        setCategoryId,
        setFilter,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const QuestionsList = ({ children, className }: { className?: string; children: ReactElement }) => {
  const { questions, active, setActive } = useQuestions()
  const handleClick = (q: { question: string }) => () => {
    setActive?.(q.question === active ? null : q.question)
  }
  return (
    <div className={className}>
      {children && questions ? (
        questions.map((q, index) =>
          cloneElement(children, {
            ...q,
            active: q.question === active,
            index,
            key: index,
            onClick: handleClick(q),
          })
        )
      ) : (
        <div className="text-center text-danger">
          <div className="spinner-border" />
        </div>
      )}
    </div>
  )
}

export const QuestionsFilter: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  const { filter, setFilter, categoryId, setCategoryId } = useQuestions()
  const clear = () => {
    setFilter?.('')
    setCategoryId?.(null)
  }
  return (
    <FlexRow component={Paper} {...props}>
      <PopoverButton
        renderPopover={() => (
          <ThemeProvider theme={lightTheme}>
            <HelpLinks categoryId={categoryId} onClick={(id) => setCategoryId?.(categoryId === id ? null : id)} />
          </ThemeProvider>
        )}
      >
        {
          <IconButton aria-label="menu">
            <Menu />
          </IconButton>
        }
      </PopoverButton>
      <InputBase
        value={filter}
        onChange={(ev) => setFilter?.(ev.target.value)}
        placeholder="Type your question here"
        style={{ flexGrow: 1, marginLeft: 12 }}
      />
      {(filter || categoryId) && (
        <IconButton aria-label="clear" onClick={clear}>
          <i className="fa fa-times-circle" />
        </IconButton>
      )}
      <Divider orientation="vertical" />
      <Tooltip title="Contact Support">
        <a href={process.env.REACT_APP_SUPPORT_URI}>
          <IconButton color="primary" aria-label="support">
            <Directions />
          </IconButton>
        </a>
      </Tooltip>
    </FlexRow>
  )
}

export { FaqAccordionItem }
