import { Poll } from '@mui/icons-material'
import { Skeleton, Typography } from '@mui/material'
import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'

import bitlabsLogo from '../../../../assets/bitlabs.svg'
import inbrainLogo from '../../../../assets/inbrain.png'
import { RewardIcon } from '../../../../components'
import { FlexCol, FlexRow } from '../../../../components/Flex'
import { numberFormat } from '../../../../utils'
import Avatar from '../Avatar'

interface SurveyCompletedProps {
  plan?: string | null
  ug?: string | null
  panelist_id?: string | null
  panelistId?: string | null
  evt?: string | null
  created?: string | null
  date?: string | null
  reward_name?: string | null
  reward_value?: number | null
  length_of_interview?: number | null
  reward?: number | null
  rewardType?: string | null
  status?: string | null
  duplicate?: boolean | null
  reason?: string | null
  loading?: boolean | null
  val?: string | null
}

const SurveyCompleted = (props: SurveyCompletedProps) => {
  const { created, date, reward_name, reward_value, length_of_interview, reward, rewardType, status, duplicate, reason, loading, val } = props
  return (
    <FlexRow justifyContent="space-between" padding={2}>
      <SurveyAvatar {...props} />
      <FlexRow>
        {loading ? (
          <FlexCol>
            <Skeleton variant="text" width={80} className="skeleton" />
            <Skeleton variant="text" width={60} className="skeleton" />
            <Skeleton variant="text" width={60} className="skeleton" />
          </FlexCol>
        ) : (
          <FlexCol alignItems="flex-end">
            <Typography variant="h5">
              Earned:{' '}
              {length_of_interview
                ? numberFormat(requestReward({ length_of_interview, status }))
                : val
                ? numberFormat(val)
                : reward
                ? numberFormat(reward)
                : duplicate || status !== 'eligible'
                ? numberFormat(0)
                : numberFormat(reward_value || 0)}
            </Typography>
            {reward_name ? <Typography>{reward_name}</Typography> : null}
            {reason && reason !== 'NONE' ? <Typography color="error">{capitalize(lowerCase(reason))}</Typography> : ''}
            {rewardType ? (
              <FlexCol alignItems="flex-end">
                <Typography>COIN</Typography>
                <Typography>{capitalize(lowerCase(rewardType.replace('profiler', 'profile')))}</Typography>
              </FlexCol>
            ) : null}
            <Typography variant="subtitle2">{formatDate(created || date) || <span>&nbsp;</span>}</Typography>
          </FlexCol>
        )}
      </FlexRow>
    </FlexRow>
  )
}

const SurveyAvatar = ({ loading, plan, ug, panelist_id, panelistId, evt }: SurveyCompletedProps) => {
  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" width={80} height={80} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      ) : plan ? (
        <Avatar name="survey">
          <RewardIcon style={{ width: 32 }} type="survey" variant="white" alt="Surveys" />
        </Avatar>
      ) : ug ? (
        <Avatar name="precision">
          <Poll />
        </Avatar>
      ) : panelist_id ? (
        <Avatar name="feedback">
          <Poll />
        </Avatar>
      ) : panelistId ? (
        <Avatar name="inBrain">
          <img src={inbrainLogo} alt="inBrain" style={{ filter: 'brightness(100)', height: 16, opacity: 0.8 }} />
        </Avatar>
      ) : typeof evt === 'string' ? (
        <Avatar name="bitlabs">
          <img src={bitlabsLogo} alt="Bitlabs" style={{ height: 16, opacity: 0.8 }} />
        </Avatar>
      ) : null}
    </>
  )
}

const COIN = 0.0005
const SPLIT = 0.5

type Reward = { status?: string | null; length_of_interview: number }

function requestReward(request: Reward) {
  return rewardRevenue(request) / (COIN / SPLIT)
}

function loiRevenue(loi: number) {
  if (loi > 10) return 1.5
  if (loi > 5) return 1
  if (loi > 0) return 0.6
  return 0
}

function rewardRevenue(request: Reward) {
  switch (request.status) {
    case 'complete':
      return loiRevenue(request.length_of_interview)
    case 'terminate':
      return 0.05
    default:
      return 0
  }
}

function formatDate(date?: string | null) {
  if (!date) return null
  return new Date(date).toLocaleDateString()
}

export default SurveyCompleted
