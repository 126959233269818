import { MouseEvent, MouseEventHandler } from 'react'

import logger from '../../../Log'
//import { useUser } from '../../../User'
import { SweepstakesCompletedDialogType } from '../Completed'
import { SweepstakesEntryDialogOpen, SweepstakesEntryStep, SweepstakesEntryTermsAgreed, residentStorage } from '../context'
//import { SweepstakesDownloadDialogOpen } from '../Download'
import { emojis } from '../emojis'
import { sweepstakesEvent } from '../event'
import { useSweepstakesOptions, useSweepstakesOptionsEnded } from '../options'
import { popParticles } from '../particle'
import { useResidentsOfUsMessage } from '../Resident'
import { SweepstakesEntrySelected } from '../selected'

const useOnOpenSweepstakes = () => {
  //const { user } = useUser()
  const [selected, setSelected] = SweepstakesEntrySelected.useContext()
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const [, setCompleteDialog] = SweepstakesCompletedDialogType.useContext()
  const [, setEntryTermsAgreed] = SweepstakesEntryTermsAgreed.useContext()
  //const [, setDownloadOpen] = SweepstakesDownloadDialogOpen.useContext()
  const [, setOpen] = SweepstakesEntryDialogOpen.useContext()
  const ended = useSweepstakesOptionsEnded()
  const options = useSweepstakesOptions()
  // const displayResidentsOfUsMessage = useResidentsOfUsMessage()

  const onOpen: MouseEventHandler = async (ev: MouseEvent) => {
    if (ended) {
      console.log('POP PARTICLES', emojis?.data?.emojiOptions, options?.userEntry)
      await popParticles(
        emojis?.data?.emojiOptions?.reduce(
          (acc, { id, icon }) => ({
            ...acc,
            [icon]: options?.userEntry?.reactions?.[id] ?? 0,
          }),
          {}
        ),
        ev.clientX,
        ev.clientY
      ).catch(() => logger().warn('Animation failed'))
      setSelected(options)
      setCompleteDialog('ended')
      return
    }

    if (options?.inactive) {
      setSelected(options)
      setCompleteDialog('inactive')
      return
    }

    if (options?.special) {
      setSelected(options)
      setCompleteDialog('special')
      return
    }

    // if (!user) {
    //   sweepstakesEvent('download', 'opened', {})
    //   setDownloadOpen(true)
    //   return
    // }

    if (options) {
      sweepstakesEvent('dialog', 'opened', {})
      setSelected(options)
      setOpen(true)
    }
  }
  return onOpen
}

export default useOnOpenSweepstakes
