import { RebillyConfigType } from '@xyo-network/api-checkout-apollo/bindings/src/utils/rebilly'

import { getRequestContext } from '../../context'
import { waitFor } from '../../utils'
import { Pickaxe } from './types'

export const rebillyType: RebillyConfigType = (process.env.REACT_APP_REBILLY_TYPE as RebillyConfigType) || 'live'
const apiUrl = getApiUrl(rebillyType)

const pickaxesCache = new Map<string, Promise<PickaxesResult>>()

function getApiUrl(type: RebillyConfigType) {
  switch (type) {
    case 'sandbox':
    case 'dev':
      return 'https://prod.coinapp.co/pickaxes-mumbai'
    case 'local':
      return 'http://localhost:8001'
    default:
      return 'https://prod.coinapp.co/pickaxes'
  }
}

export interface PickaxesResult {
  pickaxeEquipped: string
  records: Pickaxe[]
  mineCount: number
}

export interface PickaxeSyncResult {
  claimed: Pickaxe[]
}

export function clearPickaxeCache() {
  pickaxesCache.clear()
}

export function rmPickaxeCache(key: string) {
  pickaxesCache.delete(key)
}

export async function fetchPickaxe(uniqueId: string): Promise<Pickaxe> {
  const res = await fetch(`${apiUrl}/v1/axe/${uniqueId}`)

  const data = await res.json()

  return data.claim
}

export async function fetchPickaxes(): Promise<PickaxesResult> {
  const { headers } = await getRequestContext()

  const res = await fetch(`${apiUrl}/v1/user/list`, {
    headers,
  })

  const data = await res.json()

  return data
}

export function fetchPickaxesFromCache() {
  const cached = pickaxesCache.get('list')
  if (cached) return cached

  const request = fetchPickaxes()
  pickaxesCache.set('list', request)

  return request
}

export async function syncPickaxes(address?: string): Promise<PickaxeSyncResult> {
  const { headers } = await getRequestContext()

  const res = await fetch(`${apiUrl}/v1/axe/sync`, {
    body: JSON.stringify({ address }),
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })

  const data = res.json()
  return data
}

export async function equipPickaxe(uniqueId: string): Promise<{ success: boolean }> {
  const { headers } = await getRequestContext()

  const res = await fetch(`${apiUrl}/v1/user/equip/${uniqueId}`, {
    headers,
    method: 'POST',
  })

  const data = await res.json()
  if (data.message) {
    throw new Error(data.message)
  }
  return data
}

export async function unequipPickaxe(uniqueId: string): Promise<{ success: boolean }> {
  const { headers } = await getRequestContext()

  const res = await fetch(`${apiUrl}/v1/user/unequip/${uniqueId}`, {
    headers,
    method: 'POST',
  })

  const data = await res.json()
  if (data.message) {
    throw new Error(data.message)
  }
  return data
}

export interface ClaimParams {
  receipt: string
  uid: string
  email: string
  ethAddress: string
}

export async function claimPickaxes(params: ClaimParams): Promise<Pickaxe[]> {
  if (params.receipt === 'test') {
    await waitFor(1000)
    return defaultClaims
  }

  const res = await fetch(`${apiUrl}/v1/claim/rebilly`, {
    body: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  })
  const data = await res.json()
  if (data.message) {
    throw new Error(data.message)
  }

  rmPickaxeCache('list')
  return data.claims
}

export interface CanClaimRandomPickaxeResult {
  totalReceipts: number
  dailyReceipts: number
  error: string
}

export async function canClaimRandomPickaxe(): Promise<CanClaimRandomPickaxeResult> {
  const { headers } = await getRequestContext()
  const res = await fetch(`${apiUrl}/v1/receipt/canclaim`, { headers })
  const data = await res.json()
  if (data.message) {
    throw new Error(data.message)
  }
  return data
}

const defaultClaims: Pickaxe[] = [
  {
    claimedTime: '2023-05-26T16:15:33.929Z',
    claimedUid: 'pw5d7f51zJVfWWErKsYHLotpIL92',
    ethAddress: '',
    forged: '',
    globalWeb: 'https://pickaxes.coinapp.co/images/4010101300000/globalWeb',
    image: 'https://pickaxes.coinapp.co/images/4010101300000/500x500',
    localWeb: 'https://pickaxes.coinapp.co/images/4010101300000/localWeb',
    merged: '',
    mergedUid: '',
    mintState: 'minted',
    minted: true,
    thumb: 'https://pickaxes.coinapp.co/images/4010101300000/100x100',
    tier: '4',
    tierName: 'Epic',
    tokenURI: '',
    uniqueId: '4010101300000',
  },
  {
    claimedTime: '2023-05-26T16:15:33.929Z',
    claimedUid: 'pw5d7f51zJVfWWErKsYHLotpIL92',
    ethAddress: '',
    forged: '',
    globalWeb: 'https://pickaxes.coinapp.co/images/2012101102010/globalWeb',
    image: 'https://pickaxes.coinapp.co/images/2012101102010/500x500',
    localWeb: 'https://pickaxes.coinapp.co/images/2012101102010/localWeb',
    merged: '',
    mergedUid: '',
    mintState: 'minted',
    minted: true,
    thumb: 'https://pickaxes.coinapp.co/images/2012101102010/100x100',
    tier: '2',
    tierName: 'Uncommon',
    tokenURI: '',
    uniqueId: '2012101102010',
  },
]
