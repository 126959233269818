import { SVGProps } from 'react'

export const ProIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 218 210"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>proUser</title>
      <path
        fill="#fff"
        d="M107.309313,0 L57.5727514,52.8671334 L68.6829724,0 L107.309313,0 Z M149.227575,0 L160.552481,52.8671334 L111.352647,0 L149.227575,0 Z M158.280347,64.1318433 L59.8448904,64.1318433 L109.080508,11.1393463 L158.280347,64.1318433 Z M45.9258118,64.1318433 L0.339927285,64.1318433 L58.8251132,2.54306442 L45.9258118,64.1318433 Z M46.462535,73.3012106 L91.0286423,190.927001 L0,73.3012106 L46.462535,73.3012106 Z M56.4098473,73.3012106 L161.751169,73.3012106 L109.080508,210 L56.4098473,73.3012106 Z M172.199425,64.1318433 L159.067544,2.29234183 L217.445383,64.1318433 L172.199425,64.1318433 Z M127.168158,190.837457 L171.716371,73.3012106 L218,73.3012106 L127.168158,190.837457 Z"
        id="proUser"
      ></path>
    </svg>
  )
}
