import { IconButton, IconButtonProps } from '@mui/material'
import { FaArrowLeft, FaCheck, FaPencilAlt, FaTimes } from 'react-icons/fa'

const EditIconButton: React.FC<React.PropsWithChildren<IconButtonProps>> = (props) => (
  <IconButton {...props}>
    <FaPencilAlt fontSize={12} />
  </IconButton>
)

const SaveIconButton: React.FC<React.PropsWithChildren<IconButtonProps>> = (props) => (
  <IconButton {...props}>
    <FaCheck fontSize={12} color="green" />
  </IconButton>
)

const CancelIconButton: React.FC<React.PropsWithChildren<IconButtonProps>> = (props) => (
  <IconButton {...props}>
    <FaTimes fontSize={12} color="red" />
  </IconButton>
)

const BackIconButton: React.FC<React.PropsWithChildren<IconButtonProps>> = (props) => (
  <IconButton {...props}>
    <FaArrowLeft />
  </IconButton>
)

export { BackIconButton, CancelIconButton, EditIconButton, SaveIconButton }
