import { NormalizedCacheObject } from '@apollo/client'
import { ApolloClient, useMutation, useQuery } from '@apollo/react-hooks'

import { upgradeTypeMutation, upgradeTypeQuery } from '../gql'

const makeUseUpgrade = (client?: ApolloClient<NormalizedCacheObject>) => () => {
  const { data } = useQuery(upgradeTypeQuery, { client })
  const [mutate] = useMutation(upgradeTypeMutation)
  const ca = (upgradeType: string) => {
    const action = () => mutate({ variables: { upgradeType } })
    action.type = upgradeType
    return action
  }
  const upgradePlusFHR = ca('UPGRADE_PLUS_FHR')
  const upgradeProFHR = ca('UPGRADE_PRO_FHR')
  const buyPlusFHR = ca('BUY_PLUS_FHR')
  const confirmPlusFHR = ca('confirmPlusFHR')
  const confirmProFHR = ca('confirmProFHR')
  const closeUpgrade = ca('')

  return [
    data?.upgradeType || null,
    {
      buyPlusFHR,
      closeUpgrade,
      confirmPlusFHR,
      confirmProFHR,
      upgradePlusFHR,
      upgradeProFHR,
    },
  ]
}

export default makeUseUpgrade
