import { useQuery } from '@apollo/client'

import { checkoutClient } from '../../lib'
import { USER_ACHIEVMENTS_COLLECTED } from './gql'
import UserAchievementsCollected from './UserAchievmentsCollected'

const useAchievementsCollected = () => {
  return useQuery<UserAchievementsCollected>(USER_ACHIEVMENTS_COLLECTED, {
    client: checkoutClient,
  })
}

export default useAchievementsCollected
