import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { FlexCol } from '../../components/Flex'
import { MouseEventHandler } from 'react'

import { ErrorAlertAction } from '../../componentsPure'
import Logo from './Logo'

interface RedirectScreenProps {
  error?: Error | string
  message?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

const RedirectScreen = ({ error, message, onClick }: RedirectScreenProps) => (
  <Paper>
    <FlexCol>
      <ErrorAlertAction
        className="rounded-bottom"
        error={error}
        action={
          <Button variant="outlined" onClick={onClick}>
            Click Here
          </Button>
        }
      />
      <div className="flex-grow-1" />
      <Logo style={{ height: 160 }} />
      <p className="text-center text-white my-4">{message}</p>
      <div className="flex-grow-1" />
    </FlexCol>
  </Paper>
)

export default RedirectScreen
