import { createContext, PropsWithChildren, ReactElement, useContext, useEffect, useState } from 'react'

function checkIfMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
}

type IPlatform = 'mobile' | 'desktop'

interface ICrossPlatform {
  platform: 'mobile' | 'desktop' | null
}

const CrossPlatformContext = createContext<null | ICrossPlatform>(null)

export const CrossPlatformProvider = ({ children }: PropsWithChildren) => {
  const [platform, setPlatform] = useState<null | IPlatform>(null)

  useEffect(() => {
    setPlatform(checkIfMobile() ? 'mobile' : 'desktop')
  }, [])

  return <CrossPlatformContext.Provider value={{ platform }}>{children}</CrossPlatformContext.Provider>
}

export function useCrossPlatform() {
  const ctx = useContext(CrossPlatformContext)

  if (!ctx) {
    throw new Error('CrossPlatformProvider is not found in parent tree')
  }

  return ctx
}

export interface CrossPlatformProps {
  desktop: ReactElement
  mobile: ReactElement
}

export const CrossPlatform = ({ mobile, desktop }: CrossPlatformProps) => {
  const { platform } = useCrossPlatform()

  if (!platform) {
    return null
  }

  if (platform === 'desktop') {
    return desktop
  }

  return mobile
}
