import { InfoOutlined } from '@mui/icons-material'
import { Box, Button, CardMedia, Grid, Link, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { pickaxeItems } from '@xyo-network/api-checkout-apollo/bindings/src/utils/items'
import times from 'lodash/times'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { CrossPlatformProvider } from '../CrossPlatform'
import NoPickaxes from './NoPickaxes'
import PickaxeCard from './PickaxeCard'
import { PickaxeCardProvider } from './PickaxeCardProvider'
import { Pickaxe } from './types'
import { AsyncPickaxesResult, usePickaxes, useSyncPickaxes } from './usePickaxes'

const PickaxeCards = () => {
  const navigate = useNavigate()
  const [{ data, loading }, { refetch }] = usePickaxes()
  const [syncPickaxes, { syncing }] = useSyncPickaxes({
    onSuccess: refetch,
  })

  const { equippable, merged, claimed } = useMemo(() => {
    const { records, ...rest } = data ?? { pickaxeEquipped: '', records: [] as Pickaxe[] }

    const groups = {
      claimed: { records: [] as Pickaxe[], ...rest },
      equippable: { records: [] as Pickaxe[], ...rest },
      merged: { records: [] as Pickaxe[], ...rest },
    }

    return records.reduce((acc, record) => {
      if (record.mergedUid === record.claimedUid) {
        acc.merged.records.push(record)
      } else if (record.minted) {
        acc.equippable.records.push(record)
      } else {
        acc.claimed.records.push(record)
      }

      return acc
    }, groups)
  }, [data])

  // const items = loading ? times(6).map<Pickaxe | null>(() => null) : data ? data.records || [] : []
  function handleSync() {
    syncPickaxes().catch(() => {
      //
    })
  }

  function handleBuy() {
    navigate('/pickaxes/mint')
  }

  const canRandomMint = data && data.mineCount && data.mineCount > 100

  return (
    <CrossPlatformProvider>
      {canRandomMint ? <Box my={2}>
        <Tooltip
          arrow
          title={
            <Box p={2}>
              <CardMedia component="img" image={pickaxeItems.random1199.image} alt="Random" />
              <Typography paragraph>{randomMintCta}</Typography>
              <Button variant="contained" onClick={handleBuy} fullWidth>
                Buy Now ${pickaxeItems.random1199.amount}
              </Button>
            </Box>
          }
        >
          <Button variant="outlined" onClick={handleBuy} fullWidth>
            Mint Random Pickaxe <InfoOutlined sx={{ ml: 1 }} />
          </Button>
        </Tooltip>
      </Box> : loading ? <Box my={2}>
        <Skeleton variant='rectangular' width='100%' height={40} />
      </Box> : null}
      <Typography paragraph variant="h6">
        Equippable Pickaxes
      </Typography>
      <PickaxeGrid loading={loading} data={equippable} refetch={refetch} />
      {claimed.records.length ? (
        <>
          <Typography paragraph variant="h6">
            Claimed Pickaxes
          </Typography>
          <PickaxeGrid loading={loading} data={claimed} refetch={refetch} />
        </>
      ) : null}
      {merged.records.length ? (
        <>
          <Typography paragraph variant="h6">
            Merged Pickaxes
          </Typography>
          <PickaxeGrid loading={loading} data={merged} refetch={refetch} />
        </>
      ) : null}
      <Button variant="outlined" disabled={syncing} onClick={handleSync}>
        Sync Pickaxes
      </Button>
      <Link
        href="https://support.coinapp.co/hc/en-us/articles/4834350249239"
        target="_blank"
        rel="noreferrer nofollow"
        sx={{ my: 2, textAlign: 'center' }}
      >
        COIN Pickaxes Support
      </Link>
    </CrossPlatformProvider>
  )
}

const PickaxeGrid = ({ data, loading, refetch }: AsyncPickaxesResult & { refetch: () => Promise<void> }) => {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const items = loading ? times(6).map<Pickaxe | null>(() => null) : data ? data.records || [] : []
  const cols: (Pickaxe | null)[][] = times(isMdUp ? 3 : isSmUp ? 2 : 1).map<Pickaxe[]>(() => [])
  items.forEach((item, i) => {
    cols[i % cols.length].push(item)
  })

  const itemSize = {
    md: items.length > 2 ? 4 : items.length > 1 ? 6 : 12,
    sm: items.length > 1 ? 6 : 12,
    xs: 12,
  }

  return (
    <Grid container spacing={2}>
      {items.length ? (
        cols.map((pickaxes, i) => (
          <Grid item xs={itemSize.xs} sm={itemSize.sm} md={itemSize.md} key={i}>
            {pickaxes.map((pickaxe, j) => (
              <Box mb={2} key={j}>
                <PickaxeCardProvider pickaxe={pickaxe} pickaxeEquipped={data?.pickaxeEquipped} loading={loading} refetch={refetch}>
                  <PickaxeCard />
                </PickaxeCardProvider>
              </Box>
            ))}
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <NoPickaxes />
        </Grid>
      )}
    </Grid>
  )
}

const randomMintCta =
  "Don't want to wait to find a Custom Pickaxe in app? You can now claim and mint random pickaxes on demand! Each random mint costs $11.99. This means if you claim anything above Common, you'll be saving on the minting fee. Enjoy!"

export default PickaxeCards
