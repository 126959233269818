import { Avatar, Divider, List, ListItem, ListItemText, Skeleton } from '@mui/material'
import { enhancements as enhancementMap, PickAxeEnhancement } from '@xylabs/pick-axe-components'

import { usePickaxeCard } from './PickaxeCardProvider'

const enhancements = Object.values(enhancementMap)

const arrowUp = {
  color: '#41c48d',
  icon: '▲',
}

const arrowDown = {
  color: '#f25259',
  icon: '▼',
}

export const Enhancements = () => {
  return (
    <List>
      {enhancements.map((enhancement) => (
        <Enhancement key={enhancement.name} enhancement={enhancement} />
      ))}
    </List>
  )
}

const Enhancement = ({ enhancement }: { enhancement: PickAxeEnhancement }) => {
  const { pickaxe } = usePickaxeCard()
  const description = pickaxe?.uniqueId ? enhancement.description(pickaxe?.uniqueId) : <Skeleton variant="text" />
  return (
    <>
      <ListItem key={enhancement.name}>
        <ListItemText
          primary={
            <>
              {description} <EnhancementComparison enhancement={enhancement} />
            </>
          }
        />
        <Avatar src={`https://pickaxes.coinapp.co/benefitIcons/${enhancement.icon}@3x.png`} alt={enhancement.name} />
      </ListItem>
      <Divider />
    </>
  )
}

const EnhancementComparison = ({ enhancement }: { enhancement: PickAxeEnhancement }) => {
  const { pickaxe, pickaxeEquipped } = usePickaxeCard()

  if (!pickaxe?.uniqueId) return null
  if (!pickaxeEquipped) return null

  const comparison = enhancement.benefitComparison(pickaxeEquipped, pickaxe?.uniqueId)

  if (comparison.startsWith('0')) return null

  const arrow = comparison.startsWith('-') ? arrowDown : arrowUp
  return (
    <span style={{ color: arrow.color, whiteSpace: 'nowrap' }}>
      {arrow.icon} {comparison}
    </span>
  )
}
