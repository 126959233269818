import Button from '@mui/material/Button'
import { FlexRow } from '../components/Flex'

import { Plans } from '../components'
import CustomBasePage from '../CustomBasePage'
import { Link } from 'react-router-dom'

export default () => {
  return (
    <CustomBasePage>
      <Plans />
      <FlexRow>
        <Button sx={{ margin: 2 }} variant="outlined" LinkComponent={Link} {...({to: "/"})}>
          Go Home
        </Button>
      </FlexRow>
    </CustomBasePage>
  )
}
