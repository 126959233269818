import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import { checkoutClient } from '../../lib'
import useUser from './useUser'

const useUserRedeemDetailsInfo = () => {
  const { user } = useUser()
  const { loading, data, error } = useQuery(redeemDetails, {
    client: checkoutClient,
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    skip: !user,
  })
  const details = data?.account?.redeemDetails

  return { details, error, loading }
}

const redeemDetails = gql`
  query AccountRedeemDetails {
    account {
      redeemDetails: userInfo {
        # shipFullName
        shipStreetAddress
        # shipStreetAddress2
        # shipCity
        # shipState
        # shipZipcode
        # shipCountry
        phoneNumber
        coinbaseEmail
        ethAddress
        nftAddress
      }
    }
  }
`
export default useUserRedeemDetailsInfo
