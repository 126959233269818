import { useUser } from '../User'

export default () => {
  const { user } = useUser()
  return (
    <div style={{ height: '100vh' }}>
      <iframe
        src={`https://www.opinionetwork.com/reg/signup?rid=20895&ct=usa&extmid=${user?.uid}`}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </div>
  )
}
