import { useFindSweepstakeWinnersQuery } from '@coin-microservices/sweepstakes-react-hooks'
import {
  Avatar,
  Box,
  ButtonBase,
  CardActions,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  TablePagination,
  Typography,
} from '@mui/material'
import times from 'lodash/times'
import { useEffect, useRef, useState, CSSProperties } from 'react'

import { useScrollContext } from '../Scroll'
import { SweepstakesHistoryOpen } from './context'
import { SweepstakesDetailsAvatar } from './Details'
import { pluralEntries, SweepstakesOptionsContext } from './options'

export const SweepstakesHistoryFab = ({ style }: { style?: CSSProperties }) => {
  const [open, setOpen] = SweepstakesHistoryOpen.useContext()
  return (
    <ButtonBase
      onClick={() => setOpen(!open)}
      style={{
        backgroundColor: '#fff',
        border: '2px solid #eee',
        borderRadius: '4px 4px 0 0',
        color: '#333',
        padding: '0 6px',
        transform: 'rotateZ(-90deg) translateX(50%)',
        transformOrigin: 'right bottom',
        transition: open ? 'visibility 0s linear 0s' : 'visibility 0s linear 0.2s',
        visibility: open ? 'hidden' : 'visible',
        zIndex: 2000,
        ...style,
      }}
    >
      Previous Winners
    </ButtonBase>
  )
}

export const SweepstakesHistoryDrawer = () => {
  const perPage = 10
  const [page, setPage] = useState(1)
  const [open, setOpen] = SweepstakesHistoryOpen.useContext()
  const scrollElement = useScrollContext()
  const { data, loading } = useFindSweepstakeWinnersQuery({
    variables: {
      pagination: {
        page,
        perPage,
      },
      sort: {
        field: 'created',
        order: -1,
      },
    },
  })
  const items = data?.findSweepstakeWinners?.data
  const total = data?.findSweepstakeWinners?.total ?? 0
  useDrawerScroller(open)
  const changePage = (p: number) => {
    const scrollElementLocal = scrollElement
    if (scrollElementLocal) {
      scrollElementLocal.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (_event: unknown, newPage: number) => {
    changePage(newPage + 1)
  }
  const rowCount = loading ? 10 : items?.length || 0
  return (
    <>
      <Drawer open={open} anchor="right" onClose={() => setOpen(false)} classes={{ paper: 'overflow-initial' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            maxWidth: 500,
            width: '92vw',
          }}
        >
          <SweepstakesHistoryFab
            style={{
              left: 0,
              position: 'absolute',
              top: '50%',
              transform: 'rotateZ(-90deg) translate3d(50%, -120px, 0)',
              visibility: 'visible',
            }}
          />
          <Box style={{ flexGrow: 1, overflow: 'auto' }}>
            <List>
              {times(rowCount).map((_, i) => (
                <SweepstakesOptionsContext.Provider key={i} value={items?.[i]?.options ?? null}>
                  <SweepstakesWinnerItem item={items?.[i] as Item} loading={loading} />
                </SweepstakesOptionsContext.Provider>
              ))}
            </List>
          </Box>
          <Divider />
          <CardActions>
            <Box flexGrow={1} />
            <TablePagination
              count={total}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={perPage}
              rowsPerPageOptions={[perPage]}
              size="small"
            />
          </CardActions>
        </div>
      </Drawer>
    </>
  )
}

interface Item {
  profileImage?: string
  username?: string
  created?: string
  entries: number
}

const SweepstakesWinnerItem = ({ item, loading }: { item: Item; loading: boolean }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Box>
          {loading ? (
            <Skeleton variant="circular" height={48} width={48} />
          ) : (
            <Avatar
              variant="square"
              src={item?.profileImage ?? ''}
              alt={`${item?.username ?? ''}`}
              imgProps={{ style: { objectFit: 'contain' } }}
              style={{ height: 48, width: 48 }}
            >
              {(item?.username ?? '').slice(0, 2).toUpperCase()}
            </Avatar>
          )}
        </Box>
      </ListItemAvatar>
      <ListItemText
        primary={loading ? <Skeleton width={180} /> : item?.username}
        secondary={
          <>
            <Typography>
              {loading ? <Skeleton width={160} /> : item?.created ? new Date(item?.created).toLocaleString() : ''}
            </Typography>
            <Typography>
              {loading ? (
                <Skeleton width={160} />
              ) : (
                <>
                  {item?.entries} {pluralEntries(item?.entries)}
                </>
              )}
            </Typography>
          </>
        }
      />
      <SweepstakesDetailsAvatar size={48} loading={loading} />
    </ListItem>
  )
}

function useDrawerScroller(open: boolean) {
  const scrollPosition = useRef(0)
  useEffect(() => {
    const root = document.getElementById('root')
    if (open) {
      scrollPosition.current = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.height = '100vh'
      document.body.style.width = '100vw'
      document.body.style.overflow = 'hidden'
      if (root) {
        root.style.height = '100vh'
        root.style.overflow = 'auto'
        root.scrollTop = scrollPosition.current
      }
    } else {
      document.body.style.height = 'initial'
      document.body.style.overflow = 'initial'
      document.body.style.position = 'initial'
      if (root) {
        root.style.height = 'initial'
        root.style.overflow = 'initial'
        root.scrollTop = 0
      }
      window.scrollTo(0, scrollPosition.current)
    }
  }, [open])
}
