import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import { BoxProps } from '@mui/material/Box'
import { FlexCol } from '../../../components/Flex'
import times from 'lodash/times'

import { CustomListItem } from '../../../components'

const RedeemOptionsDirectSkeleton: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  return (
    <FlexCol alignItems="stretch" {...props}>
      {times(8).map((i) => (
        <CustomListItem key={i}>
          <ListItemAvatar>
            <Skeleton variant="circular" height={60} width={60} />
          </ListItemAvatar>
          <ListItemText
            className="ml-1"
            primary={
              <div className="mb-1">
                <Skeleton variant="rectangular" height={24} width={100} />
              </div>
            }
            secondary={<Skeleton variant="rectangular" height={18} width={60} />}
          />
          <ListItemSecondaryAction />
        </CustomListItem>
      ))}
    </FlexCol>
  )
}

export default RedeemOptionsDirectSkeleton
