const toFirstAndLastName = (name: string | null) => {
  const [firstName = '', ...last] = (name || '').split(' ')
  const lastName = last.join(' ')
  return {
    firstName,
    lastName,
  }
}

export default toFirstAndLastName
