import { Info } from '@mui/icons-material'
import { Box, BoxProps, ButtonBase, Card, Skeleton, Tooltip, Typography, TypographyProps } from '@mui/material'
import { ReactNode } from 'react'

import { Position, Token } from './types'
import { formatName, formatSymbol } from './utils'
import { TokenAvatar } from './Token'

export const UniswapV3PositionItem = ({ position, onClick }: { position?: Position; onClick: () => void }) => {
  return (
    <ButtonBase onClick={onClick} style={{ textAlign: 'initial', width: '100%' }}>
      <Card style={{ width: '100%' }}>
        <Box p={1}>
          <TokenBalanceRow amount={position?.amount0} token={position?.token0} />
          <TokenBalanceRow amount={position?.amount1} token={position?.token1} />
        </Box>
      </Card>
    </ButtonBase>
  )
}

export const TokenBalanceRow = ({
  amount,
  token,
  subtitle,
  postFix,
  ...props
}: {
  amount?: string
  token?: Token
  subtitle?: ReactNode
  postFix?: ReactNode
} & BoxProps) => {
  return (
    <Box display="flex" alignItems="center" flex={1} {...props}>
      <Box display="flex" alignItems="center">
        {token ? (
          token.icon ? (
            token.icon
          ) : (
            <TokenAvatar token={token} /> 
          )
        ) : (
          <Skeleton variant="circular" height={12} width={12} />
        )}
      </Box>
      <Typography>{token ? formatName(token) : <Skeleton width={120} />}</Typography>
      <Box flexGrow={1} />
      <Box>
        <TokenBalance token={token} amount={amount} postFix={postFix} />
        {subtitle}
      </Box>
    </Box>
  )
}

export const TokenBalance = ({
  amount,
  token,
  info,
  postFix,
  ...props
}: { amount?: string; token?: Token; info?: ReactNode; postFix?: ReactNode } & TypographyProps) =>
  token ? (
    <Typography {...props} display="flex" alignItems="center" justifyContent="flex-end">
      {info ? (
        <Tooltip title={typeof info === 'string' ? <Typography>{info}</Typography> : info}>
          <Info style={{ paddingRight: 4 }} />
        </Tooltip>
      ) : null}
      <strong style={{ paddingRight: 4 }}>{amount}</strong>
      {formatSymbol(token)}
      {postFix}
    </Typography>
  ) : (
    <Skeleton width={120} />
  )
