import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Link, Typography } from '@mui/material'
import React, { SyntheticEvent } from 'react'

const FaqAccordionItem: React.FC<React.PropsWithChildren<{
  question?: string
  answer?: string
  answerLink?: string
  active?: boolean
  onClick?: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void
}>> = ({ question, answer, answerLink, active, onClick }) => {
  return (
    <Accordion expanded={active} onChange={onClick}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1">{question}</Typography>
      </AccordionSummary>
      {active && (
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{ __html: answer || '' }} />
          {answerLink ? <Link href="https://coinapp.co/free">{answerLink}</Link> : null}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default FaqAccordionItem
