import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { BoxProps } from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { useTheme } from '@mui/material/styles'
import { FlexCol, FlexGrowRow, FlexRow } from '../../components/Flex'

import RewardIcon from './Icon'

interface IconBoxProps extends BoxProps {
  loading?: boolean
  type?: string
  variant?: string
}

const IconBox: React.FC<React.PropsWithChildren<IconBoxProps>> = ({ type, variant, loading, ...props }) => {
  const theme = useTheme()
  return (
    <FlexCol component={Paper} height={80} width={80} alignItems="stretch" {...props}>
      <FlexGrowRow bgcolor={theme.palette.info.main} padding={2}>
        {loading ? <Skeleton variant='rectangular' height={28} width={28} /> : <RewardIcon type={type ?? 'visit'} variant={variant} height={28} />}
      </FlexGrowRow>
      <Typography
        bgcolor={theme.palette.info.dark}
        color={theme.palette.info.contrastText}
        component={FlexRow}
        style={{ textTransform: 'uppercase' }}
        variant="body1"
      >
        {loading ? <Skeleton variant='text' width={60} /> : type}
      </Typography>
    </FlexCol>
  )
}

export default IconBox
