import { FlexCol } from '../../../components/Flex'

import { BossProvider } from '../../../context'
import CustomBasePage from '../../../CustomBasePage'
import HodlList from './List'

const HodlPage = () => {
  return (
    <BossProvider>
      <CustomBasePage>
        <FlexCol marginY={2} alignItems="stretch">
          <HodlList />
        </FlexCol>
      </CustomBasePage>
    </BossProvider>
  )
}

export default HodlPage
