import invoke from 'lodash/invoke'
import React, { Component, createContext, useContext } from 'react'

const jquerySrc = '//ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js'
const coinTracker = '//t.coinapp.co/events.js'
const pollFishSrc = '//storage.googleapis.com/pollfish_production/sdk/webplugin/pollfish.min.js'

const global = window

declare global {
  interface Window {
    Pollfish: {
      hide: () => void
      showFullSurvey: () => Promise<void>
    }

    pollfishConfig: {
      uuid?: string | null
      api_key: string
      debug: boolean
      indicator_position: string
      offerwall: boolean
      ready: (v: unknown) => void
      rewardName: string
      surveyAvailable: (v: unknown) => void
      surveyNotAvailable: (v: unknown) => void
      surveyCompletedCallback: (v: unknown) => void
      userNotEligibleCallback: (v: unknown) => void
    }

    coinTracker: { event: string }[]
  }
}

type PollfishConfig = {
  //
}

const PollFishContext = createContext<{
  nonce?: number
  initialize: (config?: PollfishConfig) => Promise<void>
  showFullSurvey: () => Promise<void>
}>({
  initialize: () => Promise.resolve(),
  showFullSurvey: () => Promise.resolve(),
})
export const usePollFish = () => {
  const context = useContext(PollFishContext)
  // useEffect(() => {
  //   if (context.initialize) {
  //     context.initialize()
  //   }
  // }, [context.initialize])
  return context
}

// PHP http://www.writephponline.com/
// $reward_conversion_hash = base64_encode(hash_hmac("sha1" , "50", "6ed8671b-ee86-4280-8c02-7c28eedb948a", true));
// print($reward_conversion_hash);

export const fullReward = {
  rewardConversion: '2000',
  rewardConversionHash: 'cYGh/mA56xOBLb7cY6QAy1RuaHU=',
}

export const halfReward = {
  rewardConversion: '1001',
  rewardConversionHash: 'M937adsxA2xWye5Nw1T2PNTHIOs=',
}

export const quarterReward = {
  rewardConversion: '500',
  rewardConversionHash: 'sgPA7nReb5hjd+aCQC78HsuTk28=',
}

type PollFishProviderState = {
  error: string
  initializing: boolean
  isReady: boolean
  isSurveyAvailable: boolean
  isSurveyComplete: boolean
  isSurveyNotAvailable: boolean
  isUserNotEligible: boolean
  nonce: number
  survey: unknown
}

export class PollFishProvider extends Component<React.PropsWithChildren<{
    onError?: (_: unknown, e: unknown) => void
    onReady?: ({ showFullSurvey }: { showFullSurvey: () => void }) => void
    config?: {
      uuid?: string | null
      rewardConversion: string
      rewardConversionHash: string
    }
  }>,
  PollFishProviderState
> {
  initializing?: Promise<void>
  state = {
    error: '',
    initializing: false,
    isReady: false,
    isSurveyAvailable: false,
    isSurveyComplete: false,
    isSurveyNotAvailable: false,
    isUserNotEligible: false,
    nonce: 0,
    survey: null,
  }
  unsubscribe: (() => void)[] = []
  componentDidMount() {
    return
  }
  componentWillUnmount() {
    this.unsubscribe.forEach((unsub) => {
      unsub()
    })
    this.unsubscribe = []
  }
  log = (_e: unknown, _m?: unknown) => {
    return
  } //logger().log(...v)
  initialize = (config?: PollfishConfig) => {
    if (this.initializing) return this.initializing
    let clearTimer: (() => void) | null = null
    const onReady = (_v: unknown) => {
      this.onReady()
      if (clearTimer) clearTimer()
    }
    window.pollfishConfig = {
      api_key: '9db6acda-5484-4c96-8213-aedb389ef87f',
      // eslint-disable-next-line no-undef
      debug: process.env.NODE_ENV === 'development',
      indicator_position: 'BOTTOM_RIGHT',
      offerwall: true,
      ready: onReady,
      rewardName: 'COIN',
      surveyAvailable: this.onSurveyAvailable,
      surveyCompletedCallback: this.onSurveyCompletedCallback,
      surveyNotAvailable: this.onSurveyNotAvailable,
      userNotEligibleCallback: this.onUserNotEligibleCallback,
      ...this.props.config,
      ...config,
    }
    this.setState({ initializing: true })
    this.initializing = Promise.resolve()
      .then(() => {
        if (!window.pollfishConfig.uuid) throw new Error('User not initialized')
      })
      .then(() => Promise.all([appendScript(coinTracker), appendScript(jquerySrc)]))
      .then(() => appendScript(pollFishSrc))
      .then(() => {
        const id = setTimeout(() => {
          this.onError(new Error('Surveys are not currently available, please try again later.'))
        }, 10000)
        clearTimer = () => clearTimeout(id)
        this.unsubscribe.push(clearTimer)
      })
      .catch((e) => {
        this.log(e)
        this.setState({ initializing: false })
      })

    return this.initializing
  }
  onReady = () => {
    this.log('onReady')
    this.setState({ error: '', initializing: false, isReady: true }, () =>
      invoke(this.props, 'onReady', this.getProviderValue())
    )
  }
  onError = (e: Error) => {
    this.log('onError', e)
    this.setState({ error: e.message }, () => invoke(this.props, 'onError', this.getProviderValue(), e))
    // trackError('Pollfish error', e)
  }
  onSurveyAvailable = (survey: unknown) => {
    this.log('onSurveyAvailable', survey)
    this.setState({ isSurveyAvailable: true, survey }, () =>
      invoke(this.props, 'onSurveyAvailable', this.getProviderValue(), survey)
    )
  }
  onSurveyNotAvailable = (v: unknown) => {
    this.log('onSurveyNotAvailable', v)
    this.setState({ isSurveyNotAvailable: true }, () =>
      invoke(this.props, 'onSurveyNotAvailable', this.getProviderValue(), v)
    )
    // trackInfo('Pollfish not available', v)
  }
  onSurveyCompletedCallback = (v: unknown) => {
    this.log('onSurveyCompleted', v)
    this.setState({ isSurveyComplete: true }, () => invoke(this.props, 'onSurveyCompleted', this.getProviderValue(), v))
    const id = setTimeout(() => {
      const nonce = this.state.nonce + 1
      this.setState({ nonce })
    }, 1000)
    this.unsubscribe.push(() => clearTimeout(id))
    global.coinTracker = global.coinTracker || []
    global.coinTracker.push({ event: 'convert' })
    // trackInfo('Pollfish survey complete', v)
  }
  onUserNotEligibleCallback = (v: unknown) => {
    this.log('onUserNotEligible', v)
    this.setState({ isUserNotEligible: true }, () =>
      invoke(this.props, 'onUserNotEligible', this.getProviderValue(), v)
    )
    // trackInfo('Pollfish not eligible', v)
  }
  getProviderValue = () => {
    return { ...this.state, ...this.actions }
  }
  actions = {
    hide: () => global.Pollfish.hide(),
    initialize: this.initialize,
    showFullSurvey: () => global.Pollfish.showFullSurvey(),
  }
  render() {
    const { children } = this.props
    return <PollFishContext.Provider value={this.getProviderValue()}>{children}</PollFishContext.Provider>
  }
}

function appendScript(src: string) {
  return new Promise<void>((res, rej) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => res()
    script.onerror = (e) => rej(e)
    document.body.appendChild(script)
  })
}
