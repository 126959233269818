import { redeemFeatureImage } from './features'
import { StatCard, StatCardProps } from '../Stats'

import { useUserInfo } from '../../../User'
import { numberFormat } from '../../../utils'

const RedeemedCard: React.FC<React.PropsWithChildren<StatCardProps>> = (props) => {
  const { userInfo } = useUserInfo()

  return (
    <StatCard
      image={redeemFeatureImage}
      whole
      title="Redeems"
      subtitle={
        userInfo?.xyoPending ? `All time | ${numberFormat(userInfo?.xyoPending ?? 0, 0)} (Pending)` : 'All time'
      }
      action="View More"
      actionTo="/redeem"
      value={userInfo?.xyoRedeemed ?? undefined}
      description="COIN"
      {...props}
    />
  )
}

export default RedeemedCard
