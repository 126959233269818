import {
  collection,
  DocumentData,
  Firestore,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  where,
} from 'firebase/firestore'
import { FC } from 'react'

import { useUser } from '../../User'
import { defaultState, useAsyncControls } from '../../utils'
import { useAsyncEffect } from '../../utils/useAsyncEffect'
import RedeemRequest from '../Redeem/RedeemRequest'
import RequestsContext from './RequestsContext'

const loadUserRequests = (db: Firestore, uid: string) => {
  return query(collection(db, 'coin_withdrawal'), where('ownerUID', '==', uid), orderBy('created', 'desc'))
}

const toArray = (snapshot: QuerySnapshot<DocumentData>) => {
  const results: RedeemRequest[] = []
  snapshot.forEach((doc) => {
    results.push({ id: doc.id, ...doc.data() })
  })
  return results
}

const RequestsProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { user } = useUser()
  const [state, { setData }] = useAsyncControls<RedeemRequest[]>(defaultState)
  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      return await new Promise((resolve, reject) => {
        try {
          if (user) {
            return onSnapshot(loadUserRequests(getFirestore(), user.uid), (snapshot: QuerySnapshot<DocumentData>) => {
              if (mounted()) {
                setData?.(toArray(snapshot))
              }
              resolve()
            })
          }
        } catch (ex) {
          reject(ex)
        }
      })
    },
    [user, setData]
  )
  return <RequestsContext.Provider value={state}>{children}</RequestsContext.Provider>
}

export default RequestsProvider
