import { QueryResult } from '@apollo/client'
import { CircularProgress, Paper, Typography } from '@mui/material'
import { VerifiedAddress } from '@xyo-network/api-address-verifier-apollo'
import { UserInfo } from '@xyo-network/api-checkout-apollo'

import { FlexCol } from '../../components/Flex'
import { Position } from '../../components/UniswapV3/types'
import AddressRow from './AddressRow'

export type VerifiedAddressWithBalance = VerifiedAddress & {
  xyoBalance: number
  xyoLpBalance: number
  poolPositions: Position[]
}

export type IUniDetails = { address?: string | null; xyoLpBalance?: number | null }

export const OnChainSection = ({
  queryResult,
  userInfo,
  setUniDetails,
}: {
  queryResult: QueryResult<{ verifiedAddresses: VerifiedAddressWithBalance[] }>
  userInfo?: UserInfo | null
  uniDetails: IUniDetails | null
  setUniDetails: (details: IUniDetails | null) => void
}) => {
  const { data, loading, error } = queryResult
  return (
    <>
      <FlexCol marginY={2}>
        <Typography variant="h4">Verified Addresses</Typography>
        <Typography textAlign="center">Please note: it may take up to 6 hours for balances to update.</Typography>
      </FlexCol>
      {loading ? <CircularProgress /> : null}
      {error ? <Typography color="error">{error.message}</Typography> : null}
      {loading ? null : data?.verifiedAddresses?.length ? (
        (data?.verifiedAddresses ?? []).map((address, i) => (
          <Paper key={address.id} sx={{ mb: 2, width: '100%' }}>
            <AddressRow i={i} {...address} userInfo={userInfo} setUniDetails={setUniDetails} />
          </Paper>
        ))
      ) : (
        <Paper sx={{ mb: 2, p: 2, width: '100%' }}>
          <Typography textAlign="center">No addresses currently verified</Typography>
        </Paper>
      )}
    </>
  )
}
