import { Check } from '@mui/icons-material'
import { Avatar, Button, Card, CardActions, CardContent, Divider, Slide, Typography, useTheme } from '@mui/material'
import { forget } from '../utils'
import { FlexCol, FlexRow } from '../components/Flex'
import { parse } from 'querystring'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import CustomBasePage from '../CustomBasePage'

interface Config {
  btnText: string
  btnUrl: string
  text: string
}

const tagSubscriber = async (email?: string, tag?: string) => {
  await fetch('https://coin-service-users-server-zatxacztoa-uc.a.run.app/drip/subscriber/tag', {
    body: JSON.stringify({ email, tag }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
}

const ThankYou = () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const [config, setConfig] = useState<Config>()

  useEffect(() => {
    if (!config) {
      const {
        text = 'Thank you for confirming!',
        btnText = 'Continue',
        btnUrl = '',
        email = '',
        tag = '',
      } = parse(search.slice(1))
      if (email && tag) {
        forget(tagSubscriber(email as string, tag as string))
      }
      setConfig({ btnText: btnText as string, btnUrl: btnUrl as string, text: text as string })
      navigate('/thank-you')
    }
  }, [config, search, navigate])

  return (
    <CustomBasePage>
      <FlexRow>
        <Slide in={true} direction="up">
          <div>
            <Card elevation={4}>
              <CardContent>
                <FlexCol>
                  <Typography margin={2} variant="h4">
                    {config?.text}
                  </Typography>
                  <FlexRow margin={2}>
                    <Avatar
                      style={{ height: 128, width: 128 }}
                      sx={{ bgcolor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}
                    >
                      <Check style={{ height: 120, width: 120 }} />
                    </Avatar>
                  </FlexRow>
                  {config?.btnUrl ? (
                    <>
                      <Divider />
                      <CardActions style={{ justifyContent: 'flex-end' }}>
                        <Button
                          component="a"
                          href={config?.btnUrl}
                          rel="noreferrer nofollow"
                          target="_blank"
                          variant="contained"
                          color="secondary"
                        >
                          {config?.btnText}
                        </Button>
                      </CardActions>
                    </>
                  ) : null}
                </FlexCol>
              </CardContent>
            </Card>
          </div>
        </Slide>
      </FlexRow>
    </CustomBasePage>
  )
}

export default ThankYou
