import { Route, Routes } from 'react-router-dom'

import { Footer } from '../../components'

const Empty = () => <></>

const FooterRouter = () => {
  return (
    <Routes>
      <Route path="/account_v4" element={<Empty />} />
      <Route path="/hodl" element={<Empty />} />
      <Route path="/verify" element={<Empty />} />
      <Route path="/opinion" element={<Empty />} />
      <Route path="/identity" element={<Empty />} />
      <Route path="/redeem/verify" element={<Footer hiker={false} />} />
      <Route path="/profile/:username/checkout/success" element={<Footer hiker={false} />} />
      {/* <Route path="/redeem/:asset" exact component={Empty} /> */}
      <Route path="/" element={<Footer hiker={false} />} />
      <Route path="*" element={<></>} />
    </Routes>
  )
}

export default FooterRouter
