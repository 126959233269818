import Input from '@mui/material/Input'
import Button from '@mui/material/Button'
import { BoxProps } from '@mui/material/Box'
import { FlexRow } from '../../components/Flex'
import cx from 'classnames'
import debounce from 'lodash/debounce'
import React, { ChangeEventHandler, KeyboardEventHandler, ReactNode, useEffect, useState } from 'react'

import { swalError } from '../../componentsPure'
import { CancelIconButton, EditIconButton, SaveIconButton } from '../Buttons'

interface EditInAppProps extends BoxProps {
  initial?: ReactNode
  onEdit?: () => void
}

const EditInApp: React.FC<React.PropsWithChildren<EditInAppProps>> = ({ placeholder, initial, onEdit, ...props }) => {
  return (
    <FlexRow onClick={() => onEdit?.()} {...props}>
      {initial ?? (
        <Button size="small" variant="outlined">
          {placeholder}
        </Button>
      )}
      {initial ? (
        <FlexRow marginX={1}>
          <EditIconButton onClick={() => onEdit?.()} size="small" />
        </FlexRow>
      ) : null}
    </FlexRow>
  )
}

const EditInline = ({
  placeholder,
  initial,
  onSave,
  className,
}: {
  placeholder?: string
  className?: string
  initial?: string
  onSave: (value: string) => void
}) => {
  const [value, setValue] = useState(initial || '')
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  useEffect(() => {
    setValue(initial || '')
  }, [initial])
  const handleChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    if (!saving) {
      setValue(ev.target.value)
    }
  }
  const handleEnter: KeyboardEventHandler = async (ev) => {
    if (!saving && ev.key === 'Enter') {
      await handleSave()
    }
  }
  const handleSave = async () => {
    try {
      if (!value) return
      setSaving(true)
      await onSave(value)
      setSaving(false)
    } catch (e) {
      const error = e as Error
      setValue(initial || '')
      setSaving(false)
      await swalError(error.message)
    }
  }
  const handleCancel = () => {
    setEditing(false)
    setValue(initial || '')
  }
  return editing ? (
    <FlexRow>
      <Input
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyPress={handleEnter}
        onBlur={debounce(() => setEditing(false), 200)}
        autoFocus={true}
      />
      {saving ? (
        <span className="spinner-border ml-1" />
      ) : (
        <>
          <CancelIconButton onClick={handleCancel} />
          <SaveIconButton onClick={handleSave} />
        </>
      )}
    </FlexRow>
  ) : (
    <FlexRow>
      <span onClick={() => setEditing(true)}>{value || <span className="text-muted">{placeholder}</span> || ''}</span>
      <EditIconButton className={cx('p-0 ml-1', className)} onClick={() => setEditing(true)} size="small" />
    </FlexRow>
  )
}

export { EditInApp, EditInline }
