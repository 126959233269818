import Button, { ButtonProps } from '@mui/material/Button'
import React from 'react'

import { Logo } from '../logo'

export const FooterLogo: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => (
  <Button {...props}>
    <Logo />
  </Button>
)
