import { SVGProps } from 'react'

export const MasterIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 160 271"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>masterUser</title>
      <polygon
        fill="#fff"
        id="masterUser"
        points="160 110.863636 86.1538462 110.863636 86.1538462 0 0 160.136364 73.8461538 160.136364 73.8461538 271"
      ></polygon>
    </svg>
  )
}
