import hikerImg from './hiker.png'

interface Props extends React.SVGProps<SVGSVGElement> {
  hiker?: boolean
}

const FooterPicture: React.FC<React.PropsWithChildren<Props>> = ({ hiker = false, ...props }) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 375 120" {...props}>
      <defs>
        <linearGradient id="b" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path id="a" d="M0 120h375V24.916C290.767 30.856 176.258 38.146 170.85 39 160.6 39 57.701 24.414 0 15v105z" />
        <linearGradient id="d" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="c"
          d="M0 120h375V32.994L218.604 63.582C208.533 64.739 109.052 11.573 98.476 0 83.999 7.234 0 33.934 0 33.934V120z"
        />
        <path
          id="f"
          d="M0 120h375V32.994L218.604 63.582C208.533 64.739 109.052 11.573 98.476 0 83.999 7.234 0 33.934 0 33.934V120z"
        />
        <linearGradient id="h" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="g"
          d="M0 33.253V120h82.031c-7.681-6.237-22.691-41.489-22.213-45.24.78-6.128 6.481-9.464 11.378-12.09a163.51 163.51 0 0 0 23.686-15.51c1.264-.993 2.576-2.086 3.184-3.697 1.023-2.713-.276-5.758-1.367-8.436-2.88-7.071-4.46-14.83-4.606-22.63C92.005 7.717 95.135 1.995 98.437 0L0 33.253z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#FF9E7E" xlinkHref="#a" />
        <use fill="url(#b)" fillOpacity=".75" xlinkHref="#a" />
        <mask id="e" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <use fill="url(#d)" xlinkHref="#c" />
        <g mask="url(#e)">
          <use fill="#FF9E7E" xlinkHref="#f" />
          <use fill="url(#d)" fillOpacity=".5" xlinkHref="#f" />
        </g>
        <g mask="url(#e)">
          <use fill="#FF9E7E" xlinkHref="#g" />
          <use fill="url(#h)" fillOpacity=".5" xlinkHref="#g" />
        </g>
      </g>
    </svg>
    {hiker === false ? null : (
      <img
        className="d-lg-none"
        src={hikerImg}
        style={{
          bottom: 40,
          left: 20,
          position: 'absolute',
        }}
      />
    )}
  </>
)

export default FooterPicture
