import { Menu } from '@mui/icons-material'
import { Toolbar, ToolbarProps } from '@mui/material'
import Button from '@mui/material/Button'
import { MouseEventHandler } from 'react'
import { FaAngleDown, FaEnvelopeOpenText, FaImage, FaPlusCircle, FaScroll, FaUser, FaWallet } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import { PopoverButton } from '../Buttons'
import { HistoryLinks, NftLinks } from '../Sidebar'

interface Props extends ToolbarProps {
  toggleDrawer: MouseEventHandler<HTMLButtonElement>
}

const SystemToolbar: React.FC<React.PropsWithChildren<Props>> = ({ toggleDrawer, ...props }) => {
  return (
    <Toolbar {...props}>
      <Button LinkComponent={Link} startIcon={<FaUser />} sx={{ display: { lg: 'flex', xs: 'none' }, mx: 1 }} {...{ to: '/' }}>
        Account
      </Button>
      <Button LinkComponent={Link} startIcon={<FaEnvelopeOpenText />} sx={{ display: { sm: 'flex', xs: 'none' }, mx: 1 }} {...{ to: '/redeem' }}>
        Redeem
      </Button>
      <Button
        LinkComponent={Link}
        startIcon={<FaWallet />}
        sx={{ display: { sm: 'flex', xs: 'none' }, mx: 1, whiteSpace: 'nowrap' }}
        {...{ to: '/hodl' }}
      >
        External Wallets
      </Button>
      <Button LinkComponent={Link} startIcon={<FaPlusCircle />} sx={{ display: { sm: 'flex', xs: 'none' }, mx: 1 }} {...{ to: '/earn' }}>
        Earn
      </Button>
      <PopoverButton
        startIcon={<FaImage />}
        endIcon={<FaAngleDown />}
        sx={{ display: { md: 'flex', xs: 'none' } }}
        renderPopover={() => <NftLinks />}
      >
        NFTs
      </PopoverButton>
      <PopoverButton
        startIcon={<FaScroll />}
        endIcon={<FaAngleDown />}
        sx={{ display: { md: 'flex', xs: 'none' } }}
        renderPopover={() => <HistoryLinks />}
      >
        History
      </PopoverButton>
      <Button onClick={toggleDrawer} variant="outlined" sx={{ minWidth: 0, paddingLeft: 1, paddingRight: 1 }}>
        <Menu color="inherit" />
      </Button>
    </Toolbar>
  )
}

export default SystemToolbar
