import { useMemo } from 'react'

import { useSweepstakesOptions } from '../options'

const useSweepstakesDetailsListItemPrimaryText = () => {
  const options = useSweepstakesOptions()
  return useMemo(() => {
    const title = options?.title ?? ''
    const subtitle = options?.subtitle ?? ''
    return `${title} ${subtitle}`
  }, [options])
}

export default useSweepstakesDetailsListItemPrimaryText
