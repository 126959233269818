import Menu from '@mui/material/Menu'
import Button, { ButtonProps } from '@mui/material/Button'
import { MouseEventHandler, ReactNode, useState } from 'react'

interface Props extends ButtonProps {
  renderPopover: (onClick?: MouseEventHandler<HTMLButtonElement>) => ReactNode
  onRenderPopover?: () => void
}

const PopoverButton: React.FC<React.PropsWithChildren<Props>> = ({ children, renderPopover, onClick, onRenderPopover, ...props }) => {
  const [open, setOpen] = useState<(EventTarget & Element) | null>(null)
  const handlePopoverOpen: MouseEventHandler = (ev) => {
    ev.stopPropagation()
    setOpen(ev.currentTarget)
    if (onRenderPopover) onRenderPopover()
  }
  const handlePopoverClose = () => {
    setOpen(null)
  }
  return (
    <>
      <Button onClick={handlePopoverOpen} {...props}>
        {children}
      </Button>
      {renderPopover && (
        <Menu anchorEl={open} open={!!open} onClose={handlePopoverClose} onClick={handlePopoverClose}>
          {renderPopover(onClick)}
        </Menu>
      )}
    </>
  )
}

export default PopoverButton
