import { InternalRefetchQueriesInclude, QueryResult } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import {
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
  TypographyProps,
} from '@mui/material'
import { useState } from 'react'
import { FaCheck, FaSync, FaTrash } from 'react-icons/fa'

import { useCustomTokenContext } from '../../context'
import { parseError } from '../../utils'
import { FlexRow } from '../Flex'
import { useSnackbar } from '../Snackables'
import { TokenBalance, TokenBalanceRow } from '../UniswapV3'
import { CoinbaseAuthButton } from './Auth'
import { COINBASE_BALANCE_REFRESH, COINBASE_REMOVE, readableNumber, token } from './balance'

export const CoinbaseSection = ({ queryResult, refetchQueries }: { queryResult: QueryResult; refetchQueries: InternalRefetchQueriesInclude }) => {
  const { data, loading, error } = queryResult
  const [{ isUsingCustomToken }] = useCustomTokenContext()
  const result = data?.coinbaseBalance ?? data?.hodlCoinbaseBalance
  const showUpdated = process.env.NODE_ENV === 'development' || isUsingCustomToken
  return (
    <>
      <Typography variant="h4" textAlign="center" sx={{ mb: 2 }}>
        Coinbase Account
      </Typography>
      <Paper sx={{ mb: 5, p: 2, width: '100%' }}>
        <FlexRow>
          {loading ? (
            <CircularProgress />
          ) : result ? (
            <Grid container>
              <Grid item xs={11}>
                <TokenBalanceRow
                  sx={{
                    pl: {
                      md: 6,
                    },
                    pr: 1,
                  }}
                  token={{
                    icon: <FaCheck color="green" />,
                    name: result.name || 'Account Connected',
                    symbol: token.symbol,
                  }}
                  amount={readableNumber(result.balanceHodl)}
                  postFix={<CoinbaseRefresh refetchQueries={refetchQueries} />}
                  subtitle={
                    result.balance !== result.balanceHodl ? (
                      <TokenBalance
                        token={token}
                        amount={readableNumber(result.balance)}
                        variant="subtitle1"
                        info={
                          <>
                            <Typography>
                              <strong>Pending Balance</strong>
                            </Typography>
                            <Typography variant="body1">
                              Tokens must be present in wallet for at least 24 hours to be included in the HODL balance.
                            </Typography>
                            {showUpdated && result.updatedHodl ? (
                              <Typography variant="caption">Last updated {new Date(result.updatedHodl).toLocaleString()}</Typography>
                            ) : null}
                          </>
                        }
                      />
                    ) : null
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <CoinbaseRemove refetchQueries={refetchQueries} />
              </Grid>
            </Grid>
          ) : error ? (
            <Typography color="error">{parseError(error.message)}</Typography>
          ) : (
            <>
              <Box flex={1} pr={2}>
                <Typography>Allow access to Coinbase to start earning additional HODL rewards.</Typography>
                <Affiliate sx={{ display: { md: 'block', xs: 'none' } }} />
              </Box>
              <CoinbaseAuthButton refetchQueries={refetchQueries} />
            </>
          )}
        </FlexRow>
        {loading || error || result ? null : <Affiliate sx={{ display: { md: 'none', xs: 'block' } }} />}
      </Paper>
    </>
  )
}

const Affiliate = (props: TypographyProps) => {
  return (
    <Typography variant="caption" {...props}>
      Don&apos;t have a Coinbase account yet?{' '}
      <Link href="https://coinbase-consumer.sjv.io/75DAy5" rel="noreferrer nofollow" target="_blank">
      Sign up and get up to $200 in crypto.
      </Link>
    </Typography>
  )
}

const CoinbaseRefresh = ({ refetchQueries }: { refetchQueries: InternalRefetchQueriesInclude }) => {
  const { setSnackbar } = useSnackbar()[1]
  const [refreshBalance, { loading: refreshing }] = useMutation(COINBASE_BALANCE_REFRESH, {
    onCompleted: (data) => {
      if (data?.refreshCoinbaseBalance?.message) {
        setSnackbar({
          autoHideDuration: 6000,
          message: data?.refreshCoinbaseBalance?.message,
          severity: 'info',
        })
      } else {
        setSnackbar({
          autoHideDuration: 6000,
          message: (
            <>
              <AlertTitle>Balance Updated</AlertTitle>
            </>
          ),
          severity: 'success',
        })
      }
    },
    onError: (err) => {
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(err),
        severity: 'error',
      })
    },
    refetchQueries,
    variables: { currency: 'XYO' },
  })
  return (
    <IconButton onClick={() => refreshBalance()} disabled={refreshing}>
      <FaSync fontSize={14} color="white" />
    </IconButton>
  )
}

const CoinbaseRemove = ({ refetchQueries }: { refetchQueries: InternalRefetchQueriesInclude }) => {
  const [open, setOpen] = useState(false)
  const [remove, { loading: removing }] = useMutation(COINBASE_REMOVE, { refetchQueries })

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
        <DialogTitle>Confirm Coinbase Remove</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to remove account? If connected to a COIN account again, it will take 24 hours for the XYO to become eligible
            for HODL Rewards.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={removing} onClick={() => remove()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => setOpen(true)} disabled={removing}>
        <FaTrash fontSize={14} color="red" />
      </IconButton>
    </>
  )
}
