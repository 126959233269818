import { Avatar, Divider, ListItem, ListItemAvatar, Skeleton, Tooltip, Typography } from '@mui/material'
import { Invoice } from '@xyo-network/api-checkout-apollo'
import cx from 'classnames'
import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'
import { ReactNode } from 'react'

import { invoiceStatusColor, InvoiceStatusIcon } from './Status'

const InvoiceListItem = ({
  invoice,
  action,
  onClick,
}: {
  invoice?: Invoice
  action?: ReactNode
  onClick?: () => void
}) => {
  const description = invoice?.items
    ?.map?.((item) => item?.description ?? '')
    .map((v) => v.split(':')[0])
    .join(', ')
  const statusColor = invoiceStatusColor(invoice?.status)
  return (
    <>
      <ListItem onClick={onClick}>
        {invoice?.status ? (
          <Tooltip title={<Typography>{capitalize(lowerCase(invoice?.status))}</Typography>}>
            <ListItemAvatar>
              <Avatar className={statusColor ? `bg-${statusColor}` : ''}>
                <InvoiceStatusIcon status={invoice?.status} />
              </Avatar>
            </ListItemAvatar>
          </Tooltip>
        ) : (
          <ListItemAvatar>
            <Skeleton variant="circular" height={40} width={40} />
          </ListItemAvatar>
        )}
        <div className="flex-grow-1 overflow-hidden">
          {typeof invoice?.amount === 'number' ? (
            <h4
              className={cx('mb-2', {
                'text-success': invoice?.amount < 0,
              })}
            >
              ${Math.abs(invoice?.amount)}
            </h4>
          ) : (
            <Skeleton
              variant="text"
              width={180}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            />
          )}
          {description ? (
            <p className="mb-0 address">
              <small>{description}</small>
            </p>
          ) : (
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          )}
          {invoice?.paidTime ? (
            <p className="mb-0 address">
              Paid <small>{new Date(invoice?.paidTime).toLocaleString()}</small>
            </p>
          ) : invoice?.dueTime ? (
            <p className="mb-0 address">
              Due Date <small>{new Date(invoice?.dueTime).toLocaleString()}</small>
            </p>
          ) : (
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          )}
          {invoice?.createdTime ? (
            <p className="mb-0 address">
              Created <small>{new Date(invoice?.createdTime).toLocaleString()}</small>
            </p>
          ) : (
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          )}
        </div>
        <div>{action}</div>
      </ListItem>
      <Divider />
    </>
  )
}

export default InvoiceListItem
