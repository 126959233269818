import NoItemsPage from '../../../NoItems'

const NoVideoAds = () => (
  <NoItemsPage
    message="No Video Ads Found"
    href="https://support.coinapp.co/hc/en-us/categories/360002099633"
    buttonText="Read more about video ads"
  />
)

export default NoVideoAds
