import { MenuItem, Select, SelectProps, Typography } from '@mui/material'
import { FlexRow } from '../../../components/Flex'
import { FaCircle } from 'react-icons/fa'

import { addDays, fromYYYYMMDD } from '../../../utils'
import useCampaigns from './useCampaigns'

interface Props extends SelectProps<number> {
  campaign?: number
}

const SelectCampaign: React.FC<React.PropsWithChildren<Props>> = ({ campaign, ...props }) => {
  const { campaigns, loading } = useCampaigns()

  if (loading) return <div className="spinner-border text-danger" />

  return (
    <Select<number> variant="standard" value={campaign} {...props}>
      {campaigns.map((campaign) => (
        <MenuItem key={campaign} value={campaign}>
          <ClaimCampaignTitle campaign={campaign} />
        </MenuItem>
      ))}
    </Select>
  )
}

const ClaimCampaignTitle = ({ campaign }: { campaign: number }) => {
  const start = fromYYYYMMDD(campaign)
  const end = addDays(start, 6)
  return (
    <FlexRow>
      <FaCircle style={{ opacity: end.valueOf() > Date.now() ? 100 : 0 }} />
      <Typography component={FlexRow} marginX={1}>
        {start.toLocaleDateString()}
      </Typography>
    </FlexRow>
  )
}

export default SelectCampaign
