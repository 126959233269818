import { sweepstakesFeatureImage } from './features'
import { StatCard, StatCardProps } from '../Stats'

import { useUserInfo } from '../../../User'

const SweepstakesCard: React.FC<React.PropsWithChildren<StatCardProps>> = (props) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      title="Sweepstakes"
      subtitle="All time"
      action="View More"
      actionTo="/sweepstakes/history"
      value={loading ? undefined : userInfo?.xyoSweepstakes ?? 0}
      image={sweepstakesFeatureImage}
      description="COIN"
      {...props}
    />
  )
}

export default SweepstakesCard
