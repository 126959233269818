import { FlexRow } from '../../../components/Flex'
import { BoxProps } from '@mui/material/Box'

import { QuadkeyMap } from '../../../components'

interface QuadkeyProps extends BoxProps {
  id: string
  quadkey: string
  paid: number
  earned: number
}

const Quadkey: React.FC<React.PropsWithChildren<QuadkeyProps>> = ({ id, quadkey, paid, earned, ...props }) => {
  return (
    <FlexRow height={120} width={120} {...props}>
      <QuadkeyMap quadkey={quadkey} style={{ height: '100%', width: '100%' }} />
    </FlexRow>
  )
}

export default Quadkey
