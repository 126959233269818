import { Box, Button, Card, CardHeader, Slide, Typography } from '@mui/material'

interface Props {
  email: string
  signIn: () => void
}

const ResetSent: React.FC<React.PropsWithChildren<Props>> = ({ email, signIn }) => {
  return (
    <Slide direction="up" in={true}>
      <Card>
        <CardHeader style={{ alignItems: 'center' }} title="Check your email" />
        <Box px={2}>
          <Typography>
            Follow the instructions sent to <strong>{email || 'email'}</strong> to recover your password.
          </Typography>
        </Box>
        <Box p={2} pt={3} display="flex">
          <Box flex={1} />
          <Button variant="contained" color="primary" type="submit" style={{ position: 'relative' }} onClick={signIn}>
            Done
          </Button>
        </Box>
      </Card>
    </Slide>
  )
}

export default ResetSent
