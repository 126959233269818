import { interpolate } from 'flubber'
import { useEffect, useRef, useState } from 'react'
import { animated, config as configs, Spring, SpringConfig, SpringValue, useSpring } from 'react-spring'

interface ITransform {
  [property: string]: string | number
}

const config = { ...configs.wobbly, duration: 600 }

const loading = {
  config,
  from: {
    fill: '#ffffff',
    opacity: 0,
    rotate: 0,
  },
  to: {
    fill: '#ffffff',
    opacity: 0.4,
    rotate: 180,
  },
}

const reloading = {
  ...loading,
  from: {
    ...loading.from,
    rotate: 360,
  },
  to: {
    ...loading.to,
    rotate: 360,
  },
}

const makeTransform = ({ fill = '' }) => ({
  config,
  from: loading.to,
  to: {
    fill,
    opacity: 1,
    rotate: 360,
  },
})

const icons: { [state: string]: { config: SpringConfig; from: ITransform; to: ITransform } } = {
  error: makeTransform({ fill: '#f44336' }),
  loading,
  reloading,
  success: makeTransform({ fill: '#66bb6a' }),
}

const paths: { [state: string]: string } = {
  error: 'M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',
  loading: 'M6 2V8H6.01L6 8.01L10 12L6 16L6.01 16.01H6V22H18V16.01H17.99L18 16L14 12L18 8.01L17.99 8H18V2H6Z',
  reloading: 'M6 2V8H6.01L6 8.01L10 12L6 16L6.01 16.01H6V22H18V16.01H17.99L18 16L14 12L18 8.01L17.99 8H18V2H6Z',
  success: 'M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z',
}

export const VerificationStatusIcon = ({ state = 'loading' }: { state?: string }) => {
  const prev = useRef(state)
  const pending = useRef<string[]>([state])
  const [curr, setCurr] = useState(state)
  const next = prev.current !== 'loading' && curr === 'loading' ? 'reloading' : curr

  const animationProps = useSpring({
    ...icons[next],
    onRest: onAnimationEnd,
  })
  const interpolator = interpolate(paths[prev.current], paths[next])

  function onAnimationEnd() {
    const [queued] = pending.current.splice(0, 1)
    if (queued === curr) {
      onAnimationEnd()
    } else if (queued) {
      setTimeout(() => {
        setCurr(queued)
      }, 200)
    }
  }

  useEffect(() => {
    if (!pending.current.length) {
      setCurr((c) => {
        if (c !== state) {
          pending.current.push(state)
        }
        return state
      })
    } else if (state === 'loading') {
      pending.current = ['loading']
    } else {
      pending.current.push(state)
    }
  }, [state])

  useEffect(() => {
    prev.current = next
  }, [next])

  return (
    <Spring reset from={{ t: 0 }} to={{ t: 1 }} config={config}>
      {(values: { t: SpringValue<number> }) => (
        <svg viewBox="0 0 24 24" style={{ height: '1em', width: '1em' }}>
          <animated.path
            d={values.t.to((t: unknown) => interpolator(t))}
            style={{
              transformOrigin: 'center',
              ...animationProps,
            }}
          />
        </svg>
      )}
    </Spring>
  )
}
