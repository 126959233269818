import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { ReactNode, SyntheticEvent, useState } from 'react'

interface PasswordRequiredDialogProps {
  email: string
  open: boolean
  onClose: () => void
  onConfirmed: () => void
  title: string
  content: ReactNode
}

const PasswordRequiredDialog: React.FC<React.PropsWithChildren<PasswordRequiredDialogProps>> = ({
  email,
  open,
  onClose,
  onConfirmed,
  title,
  content,
}) => {
  const [confirming, setConfirming] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const onConfirm = async (event: SyntheticEvent<unknown>) => {
    event.preventDefault()
    try {
      setError('')
      setConfirming(true)
      if (!password) {
        throw new Error('Password is required.')
      } else {
        try {
          await signInWithEmailAndPassword(getAuth(), email, password)
        } catch (e) {
          throw new Error('Password is invalid.')
        }
      }
      if (typeof onConfirmed === 'function') {
        onConfirmed()
      }
      setConfirming(false)
    } catch (e) {
      const error = e as Error
      setError(error.message)
      setConfirming(false)
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <form onSubmit={onConfirm}>
          <TextField
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            type="password"
            variant="outlined"
            label="Enter Password"
            helperText={error}
            error={Boolean(error)}
            margin="normal"
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions style={{ alignItems: 'flex-end' }}>
        <Box mr={1}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </Box>
        <Button disabled={confirming} onClick={(event) => onConfirm(event)} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PasswordRequiredDialog
