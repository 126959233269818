import { AddressInput } from '@xyo-network/api-checkout-apollo'
import get from 'lodash/get'
import omit from 'lodash/omit'

import { useSnackbar } from '../../../components'
import { IPlanDetails } from '../../../plans'
import { parseError } from '../../../utils'
import { checkoutApollo } from '../../lib'

type ITracker = {
  purchase: (id: string, amount: number) => void
}

const useHandleSuccess = (planDetails: IPlanDetails, tracker: ITracker) => {
  const [, { setSnackbar }] = useSnackbar()
  return async (paymentCardId: any, { infoData, billing, shipping }: any) => {
    try {
      const planId = planDetails.planId
      // swalLoader(<p>Processing Transaction...</p>)
      const transaction = await checkoutApollo(
        planId,
        paymentCardId,
        infoData,

        omit(billing, ['email', 'firstName', 'lastName']) as AddressInput,
        omit(shipping, ['email', 'firstName', 'lastName']) as AddressInput
      )

      setSnackbar({
        autoHideDuration: 6000,
        message: planDetails.successText || 'Successfully upgraded!',
        severity: 'success',
      })
      if (tracker && tracker.purchase)
        tracker.purchase(
          get(transaction, 'data.checkout.transaction.id'),
          get(transaction, 'data.checkout.transaction.amount')
        )
    } catch (e) {
      const error = e as Error
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(error.message),
        severity: 'error',
      })
    }
  }
}

export default useHandleSuccess
