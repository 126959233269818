import ListItem, { ListItemProps } from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Link from '@mui/material/Link'
import { Link as ReactLink } from 'react-router-dom'
import { FlexRow } from '../Flex'
import { ReactNode } from 'react'

interface HistoryListItemProps extends ListItemProps {
  imgSrc: ReactNode
  imgHeight?: number | string
  title: string
  to: string
}

const opts: object = { button: true }
const HistoryListItem: React.FC<React.PropsWithChildren<HistoryListItemProps>> = ({ imgSrc, imgHeight = 18, title, to, ...props }) => {
  return (
    <Link to={to} style={{ display: 'block' }} component={ReactLink}>
      <ListItem {...props} {...opts}>
        <FlexRow>
          {typeof imgSrc === 'string' ? <img src={imgSrc} alt={title} height={imgHeight} /> : imgSrc}
          <ListItemText primary={title} style={{ marginLeft: 16 }} />
        </FlexRow>
      </ListItem>
    </Link>
  )
}

export default HistoryListItem
