import { FlexCol } from '../../../components/Flex'

import { BossProvider } from '../../../context'
import CustomBasePage from '../../../CustomBasePage'
import GeodropsList from './List'

const GeodropsPage = () => {
  console.log('GeodropsPage')
  return (
    <BossProvider>
      <CustomBasePage>
        <FlexCol marginY={2} alignItems="stretch">
          <GeodropsList />
        </FlexCol>
      </CustomBasePage>
    </BossProvider>
  )
}

export default GeodropsPage
