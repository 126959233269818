import gql from 'graphql-tag'

export const token = {
  name: 'XY Oracle',
  symbol: 'XYO',
}

export const COINBASE_BALANCE = gql`
  query CoinbaseBalance($currency: String!) {
    hodlCoinbaseBalance(currency: $currency) {
      id
      balance
      updated
      balanceHodl
      updatedHodl
      refreshCount
      refreshReset
    }
  }
`

export const COINBASE_REMOVE = gql`
  mutation CoinbaseRemove {
    removeCoinbaseAccount
  }
`

export const COINBASE_BALANCE_REFRESH = gql`
  mutation RefreshCoinbaseBalance($currency: String!) {
    refreshCoinbaseBalance(currency: $currency) {
      message
      balance {
        id
        balance
        updated
        balanceHodl
        updatedHodl
        refreshCount
        refreshReset
      }
    }
  }
`

export function readableNumber(n: number) {
  return Number(n || 0).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}
