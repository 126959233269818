import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { BoxProps } from '@mui/material/Box'
import { FlexCol, FlexGrowRow, FlexRow } from '../Flex'

import { PlanIcon } from '../Plans'

interface PlanBox extends BoxProps {
  plan?: string
}

const PlanBox: React.FC<React.PropsWithChildren<PlanBox>> = ({ plan = 'basic', ...props }) => {
  const theme = useTheme()
  return (
    <FlexCol component={Paper} height={80} width={80} alignItems="stretch" {...props}>
      <FlexGrowRow bgcolor={theme.palette.info.main} padding={1}>
        <PlanIcon plan={plan} height={28} />
      </FlexGrowRow>
      <Typography
        bgcolor={theme.palette.info.dark}
        color={theme.palette.info.contrastText}
        component={FlexRow}
        style={{ textTransform: 'uppercase' }}
        variant="body1"
      >
        {plan}
      </Typography>
    </FlexCol>
  )
}

export default PlanBox
