import { Button } from '@mui/material'

import { ErrorAlertAction } from '../../componentsPure'
import { useCustomTokenContext } from '../../context'
import { useUser } from '../../User'

const CustomTokenBanner = () => {
  const { user, onLogout } = useUser()
  const [{ isUsingCustomToken }] = useCustomTokenContext()
  if (!isUsingCustomToken) return null
  return (
    <ErrorAlertAction
      error={`Currently logged in as ${user?.email ?? ''}`}
      action={
        <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onLogout}>
          Logout
        </Button>
      }
    />
  )
}

export default CustomTokenBanner
