import { useAccountQuery } from '@xyo-network/api-checkout-apollo'

import { checkoutClient } from '../../lib'
import useUser from './useUser'

const useUserInfo = () => {
  const { user } = useUser()
  const { loading, data, error, refetch } = useAccountQuery({
    client: checkoutClient,
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    skip: !user,
  })
  const userInfo = data?.account?.userInfo

  return { error, loading, refetch, userInfo }
}

export default useUserInfo
