import { ConfirmUpgradeProvider } from '../checkout'
import { CustomTokenBanner, PlanModalOpenProvider, SnackbarProvider } from '../components'
import { ErrorBannerProvider } from '../componentsPure'
import { CustomTokenProvider, VerifyProvider } from '../context'
import { AddressProvider, UserProvider } from '../User'
import Router from './Router'
import UserProviders from './UserProviders'

const App = () => {
  return (
    <CustomTokenProvider>
      <UserProvider>
        <VerifyProvider>
          <AddressProvider>
            <ErrorBannerProvider>
              <PlanModalOpenProvider>
                <SnackbarProvider>
                  <ConfirmUpgradeProvider>
                    <CustomTokenBanner />
                    <UserProviders>
                      <Router />
                    </UserProviders>
                  </ConfirmUpgradeProvider>
                </SnackbarProvider>
              </PlanModalOpenProvider>
            </ErrorBannerProvider>
          </AddressProvider>
        </VerifyProvider>
      </UserProvider>
    </CustomTokenProvider>
  )
}

export default App
