import { SwipeableDrawer } from '@mui/material'
import { FlexCol } from '../../components/Flex'

import { Sidebar, Snack, SplashScreenLogo } from '../../components'
import { ErrorBanner } from '../../componentsPure'
import { useUser } from '../../User'
import { useBooleanControls, useTracking } from '../../utils'
import BottomNavRouter from './BottomNavRouter'
import FooterRouter from './FooterRouter'
import NavbarRouter from './NavbarRouter'
import PagesRouter from './PagesRouter'
import SideNavRouter from './SideNavRouter'

const Router = () => {
  useTracking()

  const [open, { setTrue, setFalse, toggle }] = useBooleanControls(false)
  const { initialized } = useUser()
  if (!initialized) return <SplashScreenLogo message="Initializing..." />
  return (
    <FlexCol alignItems="stretch">
      <ErrorBanner />
      <NavbarRouter toggle={toggle} />
      <PagesRouter />
      <BottomNavRouter />
      <SideNavRouter />
      <FooterRouter />
      <SwipeableDrawer anchor="right" open={open} onClose={setFalse} onOpen={setTrue}>
        <FlexCol alignItems="flex-start" width={250} onClick={setFalse} onKeyDown={setFalse}>
          <Sidebar />
        </FlexCol>
      </SwipeableDrawer>
      <Snack />
    </FlexCol>
  )
}

export default Router
