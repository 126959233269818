import {
  useAgreedToSweepstakesRequirementsMutation,
  useAllCurrentSweepstakeOptionsQuery,
} from '@coin-microservices/sweepstakes-react-hooks'
import { Box, DialogContentText, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useCallback } from 'react'
import { ChangeEvent } from 'react'

import logger from '../../Log'
import { useSnackbar } from '../Snackables'
import { SuccessButton } from './Buttons'
import { SweepstakesEntryDialogOpen, SweepstakesEntryStep, SweepstakesEntryEligibilityAgreed, residentStorage, RESIDENT_EVENT } from './context'
import { sweepstakesEvent } from './event'

const residentEvent = sweepstakesEvent(RESIDENT_EVENT)

export const SweepstakesRequiredCountryDetails = () => {
  return (
    <Box p={2}>
      <DialogContentText>Are you a resident of the US/UK and at least the age of 18?</DialogContentText>
    </Box>
  )
}

export const useResidentsOfUsMessage = () => {
  const { setSnackbar } = useSnackbar()[1]
  return useCallback(() => {
    setSnackbar({
      autoHideDuration: 6000,
      message: 'For US/UK residents only',
      severity: 'error',
    })
  }, [setSnackbar])
}

export const SweepstakesEligibleAgreement = () => {
  const { loading } = useAllCurrentSweepstakeOptionsQuery()
  const [agreed, setAgreed] = SweepstakesEntryEligibilityAgreed.useContext()
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const checked = ev.target.checked
    const result = checked ? 'yes' : 'no'
    sweepstakesEvent('agreeToEligibility', 'toggle', { value: checked })
    residentStorage.set(result)
    setAgreed(result)
  }
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <FormControlLabel
        disabled={loading}
        classes={{ label: 'line-height-1', root: 'mb-0' }}
        control={<Checkbox checked={agreed === 'yes'} onChange={handleChange} name="eligibility_agreement" color="secondary" />}
        label={
          <Typography variant="caption" style={{ lineHeight: 1 }}>
            I am an age 18+ US/UK Resident
          </Typography>
        }
      />
    </Box>
  )
}

export const EnterRequireCountryActions = () => {
  const { loading } = useAllCurrentSweepstakeOptionsQuery()
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const setDialogOpen = SweepstakesEntryDialogOpen.useContext()[1]
  const displayResidentsOfUsMessage = useResidentsOfUsMessage()
  const [agreedToSweepstakesRequirements] = useAgreedToSweepstakesRequirementsMutation()
  const onNo = async () => {
    try {
      setDialogOpen(false)
      residentEvent('no', {})
      residentStorage.set('no')
      displayResidentsOfUsMessage()
      await agreedToSweepstakesRequirements({ variables: { agreed: false } })
    } catch (e) {
      logger().warn(e)
    }
  }
  const onYes = async () => {
    try {
      setStep(step + 1)
      residentEvent('yes', {})
      residentStorage.set('yes')
      await agreedToSweepstakesRequirements({ variables: { agreed: true } })
    } catch (e) {
      logger().warn(e)
    }
  }
  return (
    <Box px={2} pb={1} display="flex" justifyContent="flex-end">
      <SuccessButton disabled={loading} variant="contained" color="primary" onClick={onNo} disableElevation>
        No
      </SuccessButton>
      <Box ml={1}>
        <SuccessButton disabled={loading} variant="contained" color="secondary" onClick={onYes} disableElevation>
          Yes
        </SuccessButton>
      </Box>
    </Box>
  )
}