const computeRunningTotals = (
  {
    xyoHodlRewards,
    xyoCollected,
  }: {
    xyoHodlRewards?: number
    xyoCollected?: number
  }
) => {
  if (typeof xyoCollected !== 'number') return {}
  return {
    newXyoCollected: xyoCollected,
    prevXyoCollected: xyoCollected - (xyoHodlRewards || 0),
  }
}

export default computeRunningTotals
