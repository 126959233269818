import { geoclaimFeatureImage } from './features'
import { StatCard, StatCardProps } from '../Stats'

import { useUserInfo } from '../../../User'

const GeoclaimedCard: React.FC<React.PropsWithChildren<StatCardProps>> = (props) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      title="Geoclaims"
      subtitle="All time"
      action="View More"
      actionTo="/geoclaims"
      image={geoclaimFeatureImage}
      value={loading ? undefined : userInfo?.xyoGeoclaimed ?? 0}
      description="COIN"
      {...props}
    />
  )
}

export default GeoclaimedCard
