import { AlertTitle, Box, Button, Card, CardHeader, Container, LinearProgress, Link, Slide, TextField, Typography } from '@mui/material'
import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from 'firebase/auth'
import { FormEvent, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSnackbar } from '../components'
import { swalError } from '../componentsPure'
import logger from '../Log'
import { forget, fromSearch, parseError } from '../utils'
import { useAsyncEffect } from '../utils/useAsyncEffect'

const toString = (value?: string | string[] | null) => {
  if (Array.isArray(value)) {
    return value[0]
  }
  return value ? (value as string) : undefined
}

const AuthActions = () => {
  const location = useLocation()
  const { mode, oobCode, continueUrl, lang = 'en' } = useMemo(() => fromSearch(location.search), [location])

  // Handle the user management action.
  switch (mode) {
    case 'resetPassword':
      return <ResetPassword oobCode={toString(oobCode)} continueUrl={toString(continueUrl)} lang={toString(lang)} />
    case 'recoverEmail':
      return <RecoverEmail oobCode={toString(oobCode)} continueUrl={toString(continueUrl)} lang={toString(lang)} />
    case 'verifyEmail':
      return <VerifyEmail oobCode={toString(oobCode)} continueUrl={toString(continueUrl)} lang={toString(lang)} />
    default:
      return null
  }
}

const ResetPassword: React.FC<React.PropsWithChildren<{ oobCode?: string; continueUrl?: string; lang?: string }>> = ({ oobCode, continueUrl }) => {
  const [initialized, setInitialized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({ confirm: '', password: '' })
  const [email, setEmail] = useState('')
  const [complete, setComplete] = useState(false)
  const navigate = useNavigate()

  const onSubmit = async (ev: FormEvent) => {
    ev.preventDefault()
    const password = (ev.target as any).password.value
    const confirm = (ev.target as any).confirm.value
    const passwordError = !password ? 'Password is required' : ''
    const confirmError = !confirm ? 'Confirmation is required' : confirm !== password ? 'Confirmation does not match' : ''
    if (passwordError || confirmError) {
      setErrors({
        confirm: confirmError,
        password: passwordError,
      })
      return
    }

    setLoading(true)
    try {
      if (!oobCode) throw new Error('Missing oobCode')
      await confirmPasswordReset(getAuth(), oobCode, password)
      setLoading(false)
      // if (continueUrl) {
      // setComplete(true)
      // } else {
      await signInWithEmailAndPassword(getAuth(), email, password)
      navigate('/')
      // }
    } catch (e) {
      const error = e as Error
      logger().log(error)
      setLoading(false)
      forget(swalError(error.message))
    }
  }

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      setInitialized(false)
      try {
        if (!oobCode) throw new Error('Missing oobCode')
        const email = await verifyPasswordResetCode(getAuth(), oobCode)
        if (mounted()) {
          setInitialized(true)
          setEmail(email)
        }
      } catch (e) {
        const error = e as Error
        logger().log(error)
        setInitialized(true)
        forget(swalError(error.message))
        navigate('/')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oobCode],
  )

  return (
    <>
      {!initialized ? <LinearProgress /> : null}
      <div className="flex-grow-1" />
      <Container maxWidth="xs">
        <Slide direction="up" in={complete}>
          <Button onClick={() => window.open(continueUrl)} fullWidth>
            Continue
          </Button>
        </Slide>
        <Slide direction="up" in={Boolean(email && !complete)}>
          <Card component="form" onSubmit={onSubmit}>
            <CardHeader title="Reset Password" />
            <Box px={2}>
              <TextField
                label="New Password"
                name="password"
                type="password"
                margin="normal"
                variant="outlined"
                onChange={() => setErrors((e) => ({ ...e, password: '' }))}
                helperText={errors.password}
                error={!!errors.password}
                fullWidth
              />
              <TextField
                label="Confirm Password"
                name="confirm"
                type="password"
                margin="normal"
                variant="outlined"
                onChange={() => setErrors((e) => ({ ...e, confirm: '' }))}
                helperText={errors.confirm}
                error={!!errors.confirm}
                fullWidth
              />
            </Box>
            <Box p={2}>
              <Button variant="contained" color="primary" type="submit" style={{ position: 'relative' }} disabled={loading} fullWidth>
                {loading ? (
                  <LinearProgress
                    style={{
                      left: 0,
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                    }}
                  />
                ) : null}
                Submit
              </Button>
            </Box>
          </Card>
        </Slide>
      </Container>
    </>
  )
}

const RecoverEmail: React.FC<React.PropsWithChildren<{ oobCode?: string; continueUrl?: string; lang?: string }>> = ({ oobCode }) => {
  const [initialized, setInitialized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [complete, setComplete] = useState(false)
  const navigate = useNavigate()

  const confirmResetPassword = async () => {
    try {
      setLoading(true)
      await sendPasswordResetEmail(getAuth(), email)
      setComplete(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      setInitialized(false)
      try {
        if (!oobCode) throw new Error('Missing oobCode')
        const info = await checkActionCode(getAuth(), oobCode)
        if (mounted()) {
          setInitialized(true)
          const email = info?.data?.email
          if (!email) throw new Error('Missing email')
          setEmail(email)
        }
      } catch (e) {
        const error = e as Error
        logger().log(error.message)
        if (mounted()) {
          setInitialized(true)
          forget(swalError(error.message))
          navigate('/')
        }
      }
    },
    [navigate, oobCode],
  )

  return (
    <>
      {!initialized ? <LinearProgress /> : null}
      <div className="flex-grow-1" />
      <Container maxWidth="xs">
        <Slide direction="up" in={complete}>
          <Card>
            <CardHeader style={{ alignItems: 'center' }} title="Check your email" />
            <Box px={2}>
              <Typography>
                Follow the instructions sent to <strong>{email || 'email'}</strong> to recover your password.
              </Typography>
            </Box>
            <Box p={2} pt={3} display="flex">
              <Box flex={1} />
              <Button variant="contained" color="primary" style={{ position: 'relative' }} onClick={() => navigate('/')}>
                Done
              </Button>
            </Box>
          </Card>
        </Slide>
        <Slide direction="up" in={Boolean(email && !complete)}>
          <Card>
            <CardHeader title="Updated email address" />
            <Box px={2} pb={2}>
              <Typography paragraph>
                Your sign-in email address has been changed back to <Link>{email}</Link>.
              </Typography>
              <Typography>
                If you didn&apos;t ask to change your sign-in email, it&apos;s possible someone is trying to access your account and you should{' '}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ position: 'relative' }}
                  onClick={confirmResetPassword}
                  disabled={loading}
                >
                  Change password right away.
                </Button>
              </Typography>
            </Box>
          </Card>
        </Slide>
      </Container>
    </>
  )
}

const VerifyEmail: React.FC<React.PropsWithChildren<{ oobCode?: string; continueUrl?: string; lang?: string }>> = ({ oobCode }) => {
  const { setSnackbar } = useSnackbar()[1]
  const navigate = useNavigate()

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // Try to apply the email verification code.
      try {
        if (!oobCode) throw new Error('Missing oobCode')
        await applyActionCode(getAuth(), oobCode)
      } catch (e) {
        const error = e as Error
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        logger().log(error.message)
        if (mounted()) {
          setSnackbar({
            autoHideDuration: 6000,
            message: parseError(error.message),
            severity: 'error',
          })
          navigate('/')
        }
        return
      }
      if (mounted()) {
        setSnackbar({
          autoHideDuration: 10000,
          message: (
            <>
              <AlertTitle>Email Verified</AlertTitle> You have successfully verified your email.
            </>
          ),
          severity: 'success',
        })
        navigate('/')
      }
    },
    [navigate, oobCode, setSnackbar],
  )

  return null
}

export default AuthActions
