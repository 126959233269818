import { Typography } from '@mui/material'

import useMySubscription from './useMySubscription'

const SubscriptionDetails = () => {
  const { subscription } = useMySubscription()
  const status = subscription?.status
  const billingStatus = subscription?.billingStatus
  return (
    <div>
      {subscription && (
        <>
          {status === 'active' && subscription.startTime && (
            <Typography>
              <strong>Start Date:</strong> {new Date(subscription.startTime).toLocaleString()}
            </Typography>
          )}
          {status === 'active' && subscription.upcomingInvoice && subscription.renewalTime && (
            <Typography>
              <strong>Next Billing Date:</strong> {new Date(subscription.renewalTime).toLocaleString()}
            </Typography>
          )}
          {/* {status === 'active' && subscription.upcomingInvoice && subscription.renewalTime && <p className='mb-0'><strong>Next Billing Amount:</strong> ${subscription.upcomingInvoice.amount || 0}</p>} */}
          {status !== 'active' && status !== 'abandoned' && status !== 'voided' && !subscription.upcomingInvoice && subscription.renewalTime && (
            <Typography>
              <strong>Deactivation Date:</strong> {new Date(subscription.renewalTime).toLocaleString()}
            </Typography>
          )}
          {status !== 'canceled' && (billingStatus === 'unpaid' || billingStatus === 'past-due') && subscription.recentInvoice && (
            <Typography color="error">
              <strong>Recent Invoice Unpaid</strong>
            </Typography>
          )}
        </>
      )}
    </div>
  )
}

export default SubscriptionDetails
