import logo from '../../assets/coinLogoWithTextRed.png'

const Image = () => (
  <div
    style={{
      backgroundImage: `url(${logo})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      height: 45,
      width: 128,
    }}
  />
)

export default Image
