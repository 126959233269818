import { useQuery } from '@apollo/react-hooks'
import { SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'

import { CustomList, CustomListHeader } from '../../../components'
import { ErrorViewer } from '../../../componentsPure'
import { CLAIMS } from './gql'
import ClaimQuadkeyResults from './Item'
import NoGeoClaims from './NoItems'
import SelectCampaign from './SelectCampaign'
import useCampaigns from './useCampaigns'

interface ClaimsResult {
  claimsByCampaign: {
    quadkey: string
    paid: number
    collected: number
  }[]
}

const GeoclaimList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { campaigns, error } = useCampaigns()
  const [campaign, setCampaign] = useState(campaigns[0])
  const handleChange = (event: SelectChangeEvent<number>) => {
    setCampaign(parseInt(`${event.target.value}`))
  }
  useEffect(() => {
    if (campaigns) {
      setCampaign(campaigns[0])
    }
  }, [campaigns])
  const { data, loading } = useQuery<ClaimsResult>(CLAIMS, {
    variables: { campaign },
  })
  const claims = data?.claimsByCampaign ?? []
  if (error) {
    return <ErrorViewer error={error} />
  }
  return (
    <CustomList
      header={
        <CustomListHeader title="Geoclaims">
          <SelectCampaign onChange={handleChange} campaign={campaign} />
        </CustomListHeader>
      }
    >
      {loading && (
        <div className="text-center">
          <div className="spinner-border text-danger" />
        </div>
      )}
      {!loading && !claims.length ? <NoGeoClaims campaign={campaign} /> : null}
      {claims.map((claim) => (
        <ClaimQuadkeyResults
          id={claim.quadkey}
          key={claim.quadkey}
          quadkey={claim.quadkey}
          paid={claim.paid}
          earned={claim.collected}
        />
      ))}
    </CustomList>
  )
}

export default GeoclaimList
