import { useAllCurrentSweepstakeOptionsQuery } from '@coin-microservices/sweepstakes-react-hooks'
import { Box, ListItemText } from '@mui/material'

import DarkSkeleton from './DarkSkeleton'
import useSweepstakesDetailsListItemEntriesText from './useSweepstakesDetailsListItemEntriesText'
import useSweepstakesDetailsListItemPrimaryText from './useSweepstakesDetailsListItemPrimaryText'

const SweepstakesDetailsListItemText = () => {
  const { loading } = useAllCurrentSweepstakeOptionsQuery()
  const primary = useSweepstakesDetailsListItemPrimaryText()
  const entries = useSweepstakesDetailsListItemEntriesText()

  return loading ? (
    <ListItemText
      primary={
        <Box mb={1}>
          <DarkSkeleton variant="rectangular" width={110} height={18} />
        </Box>
      }
      secondary={<DarkSkeleton variant="rectangular" width={90} height={14} />}
    />
  ) : (
    <ListItemText primary={primary} secondary={entries} />
  )
}

export default SweepstakesDetailsListItemText
