import { CircularProgress, LinearProgress, styled } from '@mui/material'

export default ({ loading }: { loading?: boolean }) => {
  return loading ? (
    <div className="d-flex justify-content-center">
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <></>
  )
}

export const AbsoluteCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const AbsoluteLinearProgress = styled(LinearProgress)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`
