import get from 'lodash/get'

import { useForm } from './FormContext'

const FormField = ({ component: Component, name, type, label, helperText, ...props }: any) => {
  const { values, errors, handleChange, handleBlur, handleError } = useForm()
  const options =
    type === 'checkbox' || type === 'radio'
      ? {
          checked: get(values, name) || false,
          onChange: (ev: any) => {
            ;(handleChange as any)(name, ev.target.checked)
          },
        }
      : {
          error: !!get(errors, name),
          helperText: get(errors, name) || helperText,
          onChange: handleChange,
          value: get(values, name) || '',
        }
  return (
    <Component
      onError={(error: any) => (handleError as any)(name, error)}
      {...props}
      // onChange={handleChange}
      name={name}
      onBlur={handleBlur}
      label={label}
      {...options}
    />
  )
}

export default FormField
