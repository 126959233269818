import { Info } from '@mui/icons-material'
import { Box, Grid, IconButton, Menu, MenuItem, TextField, Theme, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useMemo, useState } from 'react'

import { ProfileImage } from '../../../User'
import { getWinner, useSweepstakesOptions } from '../options'

const SweepstakesWinnerDetails = () => {
  // const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const options = useSweepstakesOptions()
  const { username, profileImage, profileMultiplier, daysOld, videoUrl, plan, entries, blockhash, blockhashShort } = useMemo(() => {
    const winner = getWinner(options)
    const blockhashShort = options?.blockhash
      ? `${options.blockhash.slice(0, 10)}...${options.blockhash.slice(-10)}`
      : ''
    return {
      blockhash: options?.blockhash ?? '',
      blockhashShort,
      daysOld: winner?.daysOld ?? 0,
      entries: winner?.entries ?? 0,
      plan: winner?.plan ?? ' ',
      profileImage: winner?.profileImage ?? ' ',
      profileMultiplier: (winner as { profileMultiplier: number })?.profileMultiplier ?? 1,
      username: winner?.username ?? ' ',
      videoUrl: winner?.videoUrl ?? '',
    }
  }, [options])
  return (
    <Box>
      <Box display="flex" flexWrap="nowrap" pr={1}>
        <Box px={1} pb={2}>
          <ProfileImage profileImage={profileImage} profileMultiplier={profileMultiplier} plan={plan} />
        </Box>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Username"
              variant="outlined"
              value={username}
              // InputProps={{
              //   className: classes.input,
              // }}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Days Old"
              variant="outlined"
              value={daysOld}
              // InputProps={{
              //   className: classes.input,
              // }}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Entries"
              variant="outlined"
              value={entries}
              // InputProps={{
              //   className: classes.input,
              // }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box px={1}>
        <TextField
          fullWidth
          disabled
          size="small"
          label="Blockhash"
          variant="outlined"
          margin="normal"
          value={blockhashShort}
          onClick={() => {
            window.open(`https://etherscan.io/block/${blockhash}`)
          }}
          InputProps={{
            // className: classes.input,
            endAdornment: (
              <IconButton
                style={{ color: '#a8c7e0' }}
                onClick={(ev) => {
                  ev.stopPropagation()
                  setAnchorEl(ev.target as HTMLButtonElement)
                }}
              >
                <Info />
              </IconButton>
            ),
          }}
        />
        {videoUrl ? (
          <Box position="relative" width="100%" paddingTop="56.5%" overflow="hidden" borderRadius={4} mt={1}>
            <iframe
              width="100%"
              height="100%"
              style={{ left: 0, position: 'absolute', top: 0 }}
              src={
                videoUrl.indexOf('embed') === -1
                  ? `https://www.youtube.com/embed/${(videoUrl.split('?v=')[1] || '').split('&')[0]}`
                  : videoUrl
              }
              title="Winner video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        ) : null}
      </Box>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          style={{ maxWidth: 300, whiteSpace: 'normal' }}
          onClick={() => window.open(`https://etherscan.io/block/${blockhash}`)}
        >
          <Typography>
            The current Ethereum block hash at the time of drawing the sweepstakes is used as the seed for the random
            number generator that selects the winning entry.
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     input: {
//       [theme.breakpoints.down('sm')]: {
//         fontSize: 12,
//       },
//       [theme.breakpoints.down('md')]: {
//         fontSize: 14,
//       },
//       color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.8)!important' : 'rgba(0, 0, 0, 0.8)!important',
//     },
//   })
// )

export default SweepstakesWinnerDetails
