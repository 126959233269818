import { AlertColor, AlertTitle } from '@mui/material'

const PrecisionMessage = (opts: { type?: AlertColor; title?: string } = {}) => {
  const type = (opts.type || '').toLowerCase()
  const title = decodeURIComponent(opts.title || '')
  // const { reward, date } = opts
  switch ((type || '').toLowerCase()) {
    case 'success':
      return (
        <>
          <AlertTitle>{title}</AlertTitle>
          Survey successful
        </>
      )
    case 'term':
      return (
        <>
          <AlertTitle>{title}</AlertTitle>
          Survey terminated
        </>
      )
    case 'fraud':
      return (
        <>
          <AlertTitle>{title}</AlertTitle>
          Survey fraudulent
        </>
      )
    default:
      return null
  }
}

export default PrecisionMessage
