import { BoxProps } from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import capitalize from 'lodash/capitalize'

import { QuadkeyMap } from '../../../components'
import { FlexCol, FlexRow } from '../../../components/Flex'
import { useUser } from '../../../User'
import { numberFormat } from '../../../utils'
import { computeRunningTotals, parseMessage } from './lib'

interface Props extends BoxProps {
  id?: string
  dropUid?: string
  title?: string
  quadkey?: string
  amount?: number
  email?: string
  dropTime?: Date
  claimedTime?: Date
  collectedXyoCollected?: number
  collectedTime?: Date
  dropXyoCollected?: number
  message?: string
  loading?: boolean
}

const Aspan = styled('span')(
  ({ theme }) => `
& a {
  color: ${theme.palette.primary.main},
}`,
)

const DropQuadkey: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  title,
  dropUid,
  quadkey,
  amount,
  email,
  dropTime,
  claimedTime,
  collectedXyoCollected,
  collectedTime,
  dropXyoCollected,
  message,
  loading,
  ...props
}) => {
  const { user } = useUser()
  const uid = user && user.uid

  const { newXyoCollected, prevXyoCollected } = computeRunningTotals(uid, {
    amount,
    collectedXyoCollected,
    dropUid,
    dropXyoCollected,
  })
  const header = title
    ? title.replace('SPECIAL REWARDS', 'Special Offer')
    : dropUid
    ? dropUid.includes('_')
      ? dropUid
          .replace('CLAIM_REWARDS', 'GEOCLAIM_REWARDS')
          .replace('REFERRER', 'REFERRAL')
          .replace('IRONSRC', 'REWARDED_PLAY')
          .replace('INBRAIN_', '')
          .replace('INBRAIN', '')
          .replace('BITLABS', 'SURVEY')
          .replace('GTV_DROP', 'VERIFICATION_REWARD')
          .replace('ADGEM_ALT_DROP', 'SPECIAL_OFFER')
          .replace('ADGEM_DROP', 'PLAY_REWARD')
          .replace('ADJOE_DROP', 'PLAY_REWARD')
          .replace('SENSE_360_DROP', 'REWARDED_PLAY_DROP')
          .replace('TAPJOY_DROP', 'PLAY_REWARD')
          .replace('ZENDRIVE_DROP', 'DRIVE_ANALYSIS_REWARD')
          .replace('PREMIUM_TASK_BONUS', 'REWARDED_TASK_BOOST')
          .split('_')
          .map(capitalize)
          .join(' ')
          .replace('Sentinel', 'SentinelX')
      : dropUid === uid
      ? 'Dropped'
      : 'Collected'
    : ''
  return (
    <FlexRow justifyContent="flex-start" {...props} padding={2}>
      {quadkey ? (
        <FlexRow height={140} width={140} marginRight={2}>
          <QuadkeyMap quadkey={quadkey} style={{ height: '100%', width: '100%' }} />
        </FlexRow>
      ) : (
        <div />
      )}
      {loading ? (
        <FlexCol alignItems="flex-start">
          <Skeleton variant="text" width={180} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
          <Skeleton variant="text" width={160} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
          <Skeleton variant="text" width={160} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
        </FlexCol>
      ) : (
        <FlexCol alignItems="flex-start">
          <Typography variant="h6">{header}</Typography>
          {/*<Typography variant="body2">Tile: {quadkey ? { quadkey } : null}</Typography>*/}
          {email && <Typography variant="body2">Restricted to email: {email}</Typography>}
          {prevXyoCollected && <Typography variant="body2">Prev Total: {numberFormat(prevXyoCollected)}</Typography>}
          {amount && <Typography variant="body2">Amount: {numberFormat(amount)}</Typography>}
          {newXyoCollected && <Typography variant="body2">New Total: {numberFormat(newXyoCollected)}</Typography>}
          {message && (
            <Typography variant="body2">
              <Aspan
                style={{ wordBreak: 'break-word' }}
                dangerouslySetInnerHTML={{
                  __html: (parseMessage(message) || '').replace('App Install Rewards', 'Play Rewards'),
                }}
              />
            </Typography>
          )}
          <Typography variant="subtitle2">Created: {new Date((dropUid === uid ? dropTime : claimedTime) ?? Date.now()).toLocaleString()}</Typography>
          <Typography variant="subtitle2">
            Collected:{' '}
            {collectedTime
              ? !isNaN(Number(collectedTime))
                ? new Date(Number(collectedTime)).toLocaleString()
                : new Date(collectedTime).toLocaleString()
              : 'Not Collected'}
          </Typography>
        </FlexCol>
      )}
    </FlexRow>
  )
}

export default DropQuadkey
