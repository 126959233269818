import { Box, Container, Fade, Link, Typography, Slide, Card } from '@mui/material'

import { WelcomeText } from '../../components'
import { DownloadButtonBox } from '../../components/Buttons/DownloadButtons'
import { FlexCol } from '../../components/Flex'
import AuthForm from './AuthForm'
import ForgotForm from './ForgotForm'
import ResetSent from './ResetSent'
import useAuthState from './useAuthState'
import './auth.scss'

const Auth = () => {
  const authState = useAuthState()
  return (
    <FlexCol>
      <WelcomeText marginY={2} />
      <Container maxWidth="xs">
        {authState.type === 'sign-up' ? (
          <>
             <Slide direction="up" in={true}>
              <Card>
                <Box textAlign="center" p={2}>
                  <Box py={4}>
                    <Typography paragraph>Don&lsquo;t have an account? Download here and get 1000 COIN to start.</Typography>
                    <DownloadButtonBox iosLink="https://coin.onelink.me/ePJg/w0gm9fht" androidLink="https://coin.onelink.me/ePJg/o020mnuq" />
                  </Box>
                  <Typography onClick={authState.signIn} key="sign-up">
                    Already have an account?{' '}
                    <Typography component="span" className="hover-underline" color="secondary">
                      Sign In
                    </Typography>
                  </Typography>
                </Box>
              </Card>
            </Slide>
          </>
        ) : authState.type === 'sign-in' ? (
          <>
            <AuthForm title="Sign in with email" {...authState} key="sign-in">
              <Box textAlign="center" p={2} pt={0}>
                <Typography onClick={authState.signUp}>
                  Don&apos;t have an account?{' '}
                  <Typography component="span" className="hover-underline" color="secondary">
                    Sign Up
                  </Typography>
                </Typography>
                <Typography>
                  <Link onClick={authState.forgotPassword} className="hover-underline">
                    Forgot Password
                  </Link>
                </Typography>
              </Box>
            </AuthForm>
          </>
        ) : authState.type === 'forgot' ? (
          <ForgotForm {...authState} />
        ) : authState.type === 'reset' ? (
          <ResetSent {...authState} />
        ) : null}
      </Container>
    </FlexCol>
  )
}

export default Auth
