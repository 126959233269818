import coinLogo from '../assets/CoinNoText.svg'
import { isCanceledOrChurned, PayInvoice, PurchasePlus, PurchasePro } from '../checkout'
import { FadeIn, PopoverButton } from '../components'
import CancelSubscription from './CancelSubscription'
import ReactivateSubscription from './ReactivateSubscription'
import SubscriptionActions from './SubscriptionActions'
import UpgradePlanToMaster from './UpgradePlanToMaster'
import UpgradePlanToPro from './UpgradePlanToPro'
import UpgradePlanToProFHR from './UpgradePlanToProFHR'
import useMySubscription from './useMySubscription'

const MySubscriptionDisplay = () => {
  const { data, loading, userInfo, subscription, activeSubscription, tokenLogin, isFHR } = useMySubscription()
  const customer = data?.account?.customer

  // if (loading)
  //   return (
  //     <div className="text-center my-3">
  //       <CircularProgress />
  //     </div>
  //   )
  if (loading) return <div style={{ height: 40 }} />

  return (
    <FadeIn>
      <SubscriptionActions
        isFHR={isFHR}
        activeSubscription={activeSubscription}
        subscriptionInfo={userInfo && userInfo.subscriptionInfo}
        tokenLogin={tokenLogin}
        subscription={subscription}
        customer={customer}
        upgradeToPro={<UpgradePlanToPro />}
        upgradeToMaster={<UpgradePlanToMaster />}
        upgradeFhrPlusToPro={<UpgradePlanToProFHR />}
        purchasePlus={<PurchasePlus popover={true} />}
        purchasePro={<PurchasePro popover={true} />}
        payRecentInvoice={
          userInfo &&
          !userInfo.subActive &&
          customer &&
          subscription &&
          subscription.recentInvoice && (
            <PayInvoice invoiceId={subscription.recentInvoice.id} cards={customer.paymentCards}>
              Pay Invoice
            </PayInvoice>
          )
        }
        cancelSubscription={
          subscription && !isCanceledOrChurned(subscription) ? (
            <CancelSubscription subscription={subscription} />
          ) : userInfo && userInfo.subActive ? (
            <PopoverButton
              color="error"
              renderPopover={() => (
                <div className="d-flex flex-wrap" style={{ maxWidth: 400 }}>
                  <div className="col-12 text-center mb-2">
                    <img style={{ height: 80 }} alt="COIN Logo" src={coinLogo} />
                  </div>
                  <div className="col-12 text-center text-dark">
                    <p>
                      It appears you have purchased your subscription through the app. To upgrade or downgrade open the app, go to the account screen
                      and tap the info icon in the Plan section. To cancel your subscription you must do so through the Playstore or iTunes &amp; App
                      Store settings.
                    </p>
                  </div>
                  {subscription && !isCanceledOrChurned(subscription) ? (
                    <div className="col-12 text-center text-dark">
                      <CancelSubscription subscription={subscription} />
                    </div>
                  ) : null}
                </div>
              )}
              onClick={() => {
                return
              }}
              onRenderPopover={() => {
                return
              }}
            >
              Cancel Subscription
            </PopoverButton>
          ) : null
        }
        reactivateSubscription={subscription && <ReactivateSubscription cards={customer?.paymentCards ?? []} subscription={subscription} />}
      />
    </FadeIn>
  )
}

export default MySubscriptionDisplay
