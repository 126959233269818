import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material'
import { FlexCol, FlexRow } from '../components/Flex'
import { useAccountQuery } from '@xyo-network/api-checkout-apollo'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa'

import { Checkout, CheckoutApolloProvider } from '../checkout'
import { CreditCard, CreditCardDetails, FadeIn } from '../components'
import { ErrorViewer } from '../componentsPure'
import CustomBasePage from '../CustomBasePage'
import { MySubscriptionDisplay, SubscriptionDetails } from '../Subscriptions'
import UserInfoDisplay from '../UserInfoDisplay'

const SettingsPage = () => {
  return (
    <CheckoutApolloProvider>
      <SettingsPageInner />
    </CheckoutApolloProvider>
  )
}

const SettingsPageInner = () => {
  const [cardSelected, setCardSelected] = useState<any>()
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [addOpen, setAddOpen] = useState(false)
  const accountQuery = useAccountQuery()

  return (
    <CustomBasePage>
      <ErrorViewer error={accountQuery.error} />
      <FadeIn>
        <FlexRow paddingBottom={4}>
          <UserInfoDisplay resetPassword>
            <SubscriptionDetails />
          </UserInfoDisplay>
        </FlexRow>
      </FadeIn>
      <FlexRow marginBottom={3}>
        <MySubscriptionDisplay />
      </FlexRow>
      <Box textAlign="center">{accountQuery.loading && <CircularProgress />}</Box>
      <Grid container spacing={2}>
        {accountQuery.loading
          ? []
          : ([] as any)
              .concat(
                (accountQuery.data?.account?.customer?.paymentCards ?? [])
                  .filter((card) => card?.status !== 'deactivated')
                  .sort(cardOrder as any)
                  .map((card) => (
                    <Grid key={card?.id} xs={12} md={6} lg={4} item>
                      <CreditCard
                        key={card?.id}
                        card={card ?? undefined}
                        onClick={() => {
                          setCardSelected(card)
                          setDetailsOpen(true)
                        }}
                      />
                    </Grid>
                  ))
              )
              .concat(
                <Grid key={'add'} id={'add'} xs={12} md={6} lg={4} item>
                  <AddCreditCard onClick={() => setAddOpen(true)} />
                </Grid>
              )}
      </Grid>

      <Dialog open={detailsOpen} onClose={() => setDetailsOpen(false)} maxWidth="sm" fullWidth>
        {cardSelected ? <CreditCardDetails card={cardSelected} onClose={() => setDetailsOpen(false)} /> : null}
      </Dialog>
      <Dialog open={addOpen} onClose={() => setAddOpen(false)} maxWidth="sm" fullWidth>
        <DialogContent>
          <AddCreditCardForm onClose={() => setAddOpen(false)} />
        </DialogContent>
      </Dialog>
    </CustomBasePage>
  )
}

const AddCreditCard: React.FC<React.PropsWithChildren<ButtonBaseProps>> = ({ onClick, style, ...props }) => {
  return (
    <ButtonBase
      focusRipple
      onClick={onClick}
      style={{
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        ...style,
      }}
      {...props}
    >
      <FlexCol width="100%">
        <Typography variant="h4">Add Card</Typography>
        <Typography variant="h4">
          <FaPlus />
        </Typography>
      </FlexCol>
    </ButtonBase>
  )
}

const AddCreditCardForm = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="position-relative overflow-hidden">
      <Checkout onSuccess={onClose} onCancel={onClose} />
    </div>
  )
}

const priority: Record<string, number> = {
  active: 2,
  inactive: 1,
}

function cardOrder(a: { status: string }, b: { status: string }) {
  return (priority[b.status] || 0) - (priority[a.status] || 0)
}

export default SettingsPage
