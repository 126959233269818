import { Dialog, Grid } from '@mui/material'
import { useState } from 'react'

import { UniswapV3PositionDetails } from './PositionDetails'
import { UniswapV3PositionItem } from './PositionItem'
import { Position } from './types'

export const UniswapV3PositionList = ({ positions }: { positions: Position[] }) => {
  const [open, setOpen] = useState(false)
  const [activePosition, setActivePosition] = useState<Position | null>(null)
  const handleSelect = (position: Position) => {
    setActivePosition(position)
    setOpen(true)
  }
  return (
    <>
      {positions.length ? <h4 className="text-center">Uniswap V3</h4> : null}
      <Grid container spacing={2}>
        {positions.map((position, i) => (
          <Grid item xs={12} key={i}>
            <UniswapV3PositionItem position={position} onClick={() => handleSelect(position)} />
          </Grid>
        ))}
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <UniswapV3PositionDetails position={activePosition} />
      </Dialog>
    </>
  )
}
