import { QueryResult } from '@apollo/client'
import { CircularProgress, Grid, Paper, Typography } from '@mui/material'
import sum from 'lodash/sum'

import { readableNumber, token, TokenBalanceRow } from '../../components'
import { FlexRow } from '../../components/Flex'
import { parseError } from '../../utils'

const totalToken = {
  ...token,
  name: `Total ${token.name}`,
}

export const TotalSection = ({ queryResult }: { queryResult: QueryResult }) => {
  const total = getTotalBalance(queryResult)
  return (
    <>
      <Typography variant="h2" textAlign="center">
        HODL Total
      </Typography>
      <Typography textAlign="center" sx={{ mb: 2 }}>
        The total XYO held in Coinbase, Ethereum, Uniswap V2 and V3.
      </Typography>
      <Paper sx={{ mb: 5, p: 2, width: '100%' }}>
        <FlexRow>
          {queryResult.loading ? (
            <CircularProgress />
          ) : (
            <Grid container>
              <Grid item xs={11}>
                <TokenBalanceRow
                  token={totalToken}
                  amount={readableNumber(total)}
                  sx={{
                    pl: {
                      md: 6,
                    },
                    pr: 1,
                  }}
                />
              </Grid>
            </Grid>
          )}
        </FlexRow>
      </Paper>
    </>
  )
}

function getTotalBalance(queryResult: QueryResult) {
  const data = sum([
    getCoinbaseBalance(queryResult),
    getOnChainBalance(queryResult),
    getUniswapV2Balance(queryResult),
    getUniswapV3Balance(queryResult),
  ])
  return data
}

function getCoinbaseBalance(queryResult: QueryResult) {
  const result = queryResult.data?.coinbaseBalance ?? queryResult.data?.hodlCoinbaseBalance
  return result?.balanceHodl ?? 0
}
function getOnChainBalance(queryResult: QueryResult) {
  const addresses = getAddresses(queryResult)
  return sumBy(addresses, 'xyoBalance')
}

function getUniswapV2Balance(queryResult: QueryResult) {
  const addresses = getAddresses(queryResult)
  return sumBy(addresses, 'xyoPairBalance')
}

function getUniswapV3Balance(queryResult: QueryResult) {
  const addresses = getAddresses(queryResult)
  return sumBy(addresses, 'xyoPoolBalance')
}

function sumBy(addresses: IAddress[], key: keyof IAddress) {
  return sum(addresses.map((address) => Number(address[key]) || 0))
}

interface IAddress {
  xyoBalance: string
  xyoLPBalance: string
  xyoPairBalance: string
  xyoPoolBalance: string
}
function getAddresses(queryResult: QueryResult): IAddress[] {
  return queryResult.data?.verifiedAddresses ?? queryResult.data?.userVerifiedAddresses ?? []
}
