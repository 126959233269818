import { useGtvSessionsQuery } from '@coin-microservices/foursquare-react-hooks'
import TablePagination from '@mui/material/TablePagination'
import Button from '@mui/material/Button'
import { FlexRow } from '../../../components/Flex'
import { ChangeEventHandler, useMemo, useState } from 'react'

import { CustomListHeader, TableCursorActions, useScrollContext } from '../../../components'
import { FoursquareProvider } from '../../../context'
import CustomBasePage from '../../../CustomBasePage'
import RewardedVerificationSessionsList from './RewardedVerificationSessionsList'
import RewardedVerificationsSummary from './RewardedVerificationsSummary'

const bonusGameFilter = {
  GT: new Date('2021-03-17T08:00:00.000Z'),
  LT: new Date('2021-04-17T06:59:59.000Z'),
}

const RewardedVerificationsPage = () => {
  const [filterType, setFilterType] = useState<'bonusGame' | 'allTime'>('allTime')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const scrollElement = useScrollContext()

  const handleChangePage = (event: unknown, newPage: number) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }

  const changePage = (p: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }

  const variables = useMemo(() => {
    const filter: { created?: typeof bonusGameFilter } = {}
    if (filterType === 'bonusGame') {
      filter.created = bonusGameFilter
    }
    return {
      filter,
      pagination: { page, perPage },
      sort: { field: 'created', order: -1 },
    }
  }, [page, perPage, filterType])

  const sessionsQuery = useGtvSessionsQuery({ variables })

  const { remoteRowCount, rowData } = useMemo(() => {
    const remoteRowCount = sessionsQuery.data?.gtvSessions?.total ?? 0
    const rowData = sessionsQuery.data?.gtvSessions?.data ?? []
    return { remoteRowCount, rowData }
  }, [sessionsQuery.data])

  return (
    <FoursquareProvider>
      <CustomBasePage>
        <CustomListHeader title="Rewarded Verifications">
          <FlexRow>
            <Button
              sx={{ mx: 1 }}
              onClick={() => setFilterType('allTime')}
              // color={activeTab === 'direct' ? 'secondary' : ''}
              variant={filterType === 'allTime' ? 'contained' : 'text'}
            >
              All-time
            </Button>
            <Button
              sx={{ mx: 1 }}
              onClick={() => setFilterType('bonusGame')}
              // color={activeTab === 'sweepstakes' ? 'secondary' : ''}
              variant={filterType === 'bonusGame' ? 'contained' : 'text'}
            >
              Bonus Game
            </Button>
          </FlexRow>
        </CustomListHeader>
        <RewardedVerificationsSummary filterType={filterType} perPage={perPage} page={page} />
        <RewardedVerificationSessionsList filterType={filterType} marginY={2} rowData={rowData} />
        {remoteRowCount ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={remoteRowCount}
            rowsPerPage={perPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TableCursorActions}
          />
        ) : null}
      </CustomBasePage>
    </FoursquareProvider>
  )
}

export default RewardedVerificationsPage
