type Ctx = { cache: { writeData: (d: { data: { __typename: string; upgradeType: string } }) => void } }

const resolvers = {
  Mutation: {
    setUpgradeType: (_: unknown, { upgradeType }: { upgradeType: string }, { cache }: Ctx) => {
      const data = { __typename: 'UpgradeType', upgradeType }
      cache.writeData({ data })
    },
  },
}

export default resolvers
