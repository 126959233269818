import { Box, Button, Typography } from '@mui/material'

import { ActiveRedeemCard } from '../Redeem'
import useActiveRequests from './useActiveRequests'

const hasWallet = (request: { wallet?: string }) => request && request.wallet

const ActiveRequests = ({ onSubmit }: { onSubmit: () => void }) => {
  const { active = [] } = useActiveRequests()
  const isActionRequired = !active.every(hasWallet)
  return (
    <>
      {active.length ? (
        <Box display="flex" mb={4}>
          <Box flexGrow={1}>
            <Typography>
              <b>Pending</b>
            </Typography>
            {isActionRequired && (
              <Typography>
                <em>
                  <i className="fa fa-info" /> Please provide wallet address
                </em>
              </Typography>
            )}
          </Box>
          {isActionRequired && (
            <Button variant="contained" color="secondary" size="small" disableElevation onClick={onSubmit}>
              Add Wallet
            </Button>
          )}
        </Box>
      ) : null}
      {active.map((request) => (
        <ActiveRedeemCard key={request.id} request={request} onSubmit={onSubmit} />
      ))}
    </>
  )
}

export default ActiveRequests
