import gql from 'graphql-tag'

const BIG_WIN_WITNESSES = gql`
  query BigWinWitnesses($page: Int, $perPage: Int) {
    bigWinsAcknowledged(pagination: { page: $page, perPage: $perPage }, sort: { field: "date", order: -1 }) {
      total
      data {
        _id
        reference
        bigwinAmount
        bigwinPlan
        bigwinDate
        bigwinTier
        bigwinEpoch
        uid
        rank
        reward
        epoch
        date
        yyyymmdd
        plan
        xyoCollected
      }
    }
  }
`

export { BIG_WIN_WITNESSES }
