import { useQuery } from '@apollo/react-hooks'
import Divider from '@mui/material/Divider'
import TablePagination from '@mui/material/TablePagination'
import times from 'lodash/times'
import { ChangeEventHandler, useMemo, useState } from 'react'

import { CustomList, CustomListHeader, TablePaginationActions, useScrollContext } from '../../../components'
import { FlexCol } from '../../../components/Flex'
import { ErrorViewer } from '../../../componentsPure'
import { HODL_HISTORY } from './gql'
import HodlItem from './Item'
import NoHodlHistory from './NoItems'

interface HodlHistory {
  id: string
}

interface HodlHistoryResult {
  hodlHistory: {
    data: HodlHistory[]
    total: number
  }
}

const HodlList = () => {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [filter, setFilter] = useState({})
  const variables = useMemo(
    () => ({
      filter,
      page,
      perPage,
    }),
    [filter, perPage, page],
  )
  const { data, loading, error } = useQuery<HodlHistoryResult>(HODL_HISTORY, { variables })

  const remoteRowCount = data?.hodlHistory?.total
  const list = data?.hodlHistory?.data ?? []

  const changePage = (p: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (_event: unknown, newPage: number) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }

  return (
    <CustomList header={<CustomListHeader sx={{ alignItems: 'stretch', flexDirection: 'column' }} title="HODL History" />}>
      <FlexCol alignItems="stretch">
        {loading ? (
          times(perPage).map((a, i) => (
            <FlexCol key={i} alignItems="stretch">
              <HodlItem loading={true} />
              <Divider />
            </FlexCol>
          ))
        ) : list.length ? (
          list.map((item) => (
            <FlexCol key={item.id} alignItems="stretch">
              <HodlItem {...item} />
              <Divider />
            </FlexCol>
          ))
        ) : error ? (
          <ErrorViewer error={error} />
        ) : (
          <NoHodlHistory />
        )}
        {remoteRowCount ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={remoteRowCount}
            rowsPerPage={variables.perPage}
            page={variables.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          ></TablePagination>
        ) : null}
      </FlexCol>
    </CustomList>
  )
}

export default HodlList
