import { useAllCurrentSweepstakeOptionsQuery } from '@coin-microservices/sweepstakes-react-hooks'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import { Link as ReactLink } from 'react-router-dom'

import { SweepstakesEntryTermsAgreed } from './context'
import { sweepstakesEvent } from './event'

export const SweepstakesDisclaimer = () => {
  return (
    <Typography
      style={{
        color: 'inherit',
        fontSize: 10,
        fontWeight: 'bold',
        opacity: 0.8,
        textTransform: 'uppercase',
      }}
    >
      No purchase is necessary to enter or win
      {/* COIN used for Sweepstakes entries is non-refundable */}
    </Typography>
  )
}

export const SweepstakesTermsAgreement = () => {
  const { loading } = useAllCurrentSweepstakeOptionsQuery()
  const [agreed, setAgreed] = SweepstakesEntryTermsAgreed.useContext()
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const checked = ev.target.checked
    sweepstakesEvent('agreeToTerms', 'toggle', { value: checked })
    setAgreed(checked)
  }
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <FormControlLabel
        disabled={loading}
        classes={{ label: 'line-height-1', root: 'mb-0' }}
        control={<Checkbox checked={agreed} onChange={handleChange} name="terms_agreement" color="secondary" />}
        label={
          <Typography variant="caption" style={{ lineHeight: 1 }}>
            I Agree to the{' '}
            <ReactLink to="/sweepstakes-terms" style={{ color: 'inherit', textDecoration: 'underline' }}>
              Terms and Conditions
            </ReactLink>
          </Typography>
        }
      />
    </Box>
  )
}
