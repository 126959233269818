import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import { FlexCol, FlexRow } from '../../Flex'
import { FaAngleDown } from 'react-icons/fa'

import { StatCardHeader } from './StatCardHeader'
import { StatCardProps } from './StatCardProps'
import { StatCardSummary } from './StatCardSummary'

export const StatCard: React.FC<React.PropsWithChildren<StatCardProps>> = ({
  onRefreshClick,
  onExpandClick,
  onActionClick,
  actionTo,
  actionToOptions,
  action,
  title,
  description,
  subtitle,
  whole,
  image,
  value,
  ...props
}) => {
  return (
    <FlexCol borderRadius={2} overflow="hidden" alignItems="stretch" {...props}>
      {value === undefined ? <LinearProgress /> : null}
      <Paper>
        <FlexCol
          style={{
            backgroundAttachment: 'cover',
            backgroundImage: `url(${image})`,
            backgroundPositionX: 'right',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
          }}
          alignItems="stretch"
        >
          <StatCardHeader
            title={title}
            subtitle={subtitle}
            onRefreshClick={onRefreshClick}
            onActionClick={onActionClick}
            actionTo={actionTo}
            actionToOptions={actionToOptions}
            action={action}
          />
          <StatCardSummary format={whole ? '0,0.a' : '0,0.0a'} value={value} title={description} />
        </FlexCol>
        {onExpandClick ? (
          <FlexRow borderTop="1px solid gray" style={{ opacity: 0.7 }}>
            <IconButton size="small" onClick={onExpandClick}>
              <FaAngleDown size={24} />
            </IconButton>
          </FlexRow>
        ) : null}
      </Paper>
    </FlexCol>
  )
}
