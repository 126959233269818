import swal from 'sweetalert2'

import swalConfirm from './swalConfirm'

const swalProps = {
  buttonsStyling: false,
  customClass: {
    cancelButton: 'btn btn-danger',
    confirmButton: 'btn btn-success',
  },
}

export const swalError = (text: string) => {
  return swal.fire({ text, ...swalProps })
}

export const swalWarning = async (title: string | HTMLElement | JQuery, html?: string | HTMLElement | JQuery) => {
  return await swal.fire({ html, title, ...swalProps })
}

export const swalSuccess = (text: string) => {
  return swal.fire({ text, ...swalProps })
}

export const swalClose = () => {
  swal.close()
}

export { swalConfirm }
