import { Container } from '@mui/material'

import { useSyncPickaxes } from '../../components/Pickaxes'
import { VerifyProvider } from '../../context'
import CustomBasePage from '../../CustomBasePage'
import { SignatureVerification } from '../../SignatureVerification'
import { MyVerifiedAddressesWithTokenAndPairBalancesDocument } from './gql'
import MyVerifiedAddresses from './MyVerifiedAddresses'

const VerifyPage = () => {
  const [syncPickaxes] = useSyncPickaxes()

  function onSuccess(address: string) {
    syncPickaxes(address).catch(() => {
      //
    })
  }

  return (
    <VerifyProvider>
      <CustomBasePage minHeight="calc(100vh - 90px)">
        <Container maxWidth="lg">
          <MyVerifiedAddresses />
          <SignatureVerification
            title="Add New Ethereum Address"
            safeFHR={true}
            refetchQueries={[{ query: MyVerifiedAddressesWithTokenAndPairBalancesDocument }]}
            onSuccess={onSuccess}
          />
        </Container>
      </CustomBasePage>
    </VerifyProvider>
  )
}

export default VerifyPage
