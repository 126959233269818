import { AlertProps } from '@mui/material'
import { createContext, ReactNode } from 'react'

export type SnackbarOptions = {
  severity?: AlertProps['severity']
  loading?: boolean
  autoHideDuration?: number
  message?: ReactNode
  action?: ReactNode
}

const defaultValue = {
  action: null,
  loading: false,
  message: null,
}

const defaultActions = {
  closeSnackbar: () => console.warn('Snackbar provider not initialized'),
  setSnackbar: (_v: SnackbarOptions) => console.warn('Snackbar provider not initialized'),
}

const SnackbarContext = createContext<[SnackbarOptions, typeof defaultActions]>([defaultValue, defaultActions])

export default SnackbarContext
