import { ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { ReactNode } from 'react'

import { SweepstakesDetailsAvatar } from './Details'
import { SweepstakesEnterButton, useOnOpenSweepstakes } from './ListItem'
import { pluralEntries } from './options'

interface Props {
  item: {
    freeEntries?: number | null
    paidEntries?: number | null
    entries?: number | null
    options?: {
      title?: ReactNode
    } | null
    created?: string
  }
  loading: boolean
}

const EntryHistoryListItem = ({ item, loading }: Props) => {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const onOpen = useOnOpenSweepstakes()

  const entriesText = item?.freeEntries
    ? `${item?.freeEntries} free ${pluralEntries(item?.freeEntries)}` + item?.paidEntries
      ? `${item?.paidEntries} paid ${pluralEntries(item?.paidEntries ?? 0)}`
      : ''
    : item?.entries
    ? `${item?.entries} ${pluralEntries(item?.entries)}`
    : ''

  return (
    <ListItem button disabled={loading} onClick={onOpen}>
      <ListItemAvatar>
        <SweepstakesDetailsAvatar size={48} loading={loading} />
      </ListItemAvatar>
      <ListItemText
        primary={loading ? <Skeleton width={180} /> : item?.options?.title}
        secondary={
          <>
            {loading ? <Skeleton width={160} /> : item?.created ? new Date(item?.created).toLocaleString() : ''}
            <br />
            {entriesText}
          </>
        }
      />
      {isSmUp ? (
        <ListItemSecondaryAction>
          <SweepstakesEnterButton onClick={onOpen} loading={loading} />
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  )
}

export default EntryHistoryListItem
