import { Emoji, useUpsertWinnerReactionMutation } from '@coin-microservices/sweepstakes-react-hooks'
import { ArrowLeft } from '@mui/icons-material'
import {
  AlertTitle,
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  IconButtonProps,
  ListItemAvatar,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { LowercaseButton } from '../../componentsPure'
import { localJSONStore, makeContext, parseError } from '../../utils'
import { useSnackbar } from '../Snackables'
import { SweepstakesDetailsAvatar, SweepstakesDetailsLargeListItemText, SweepstakesWinnerDetails } from './Details'
import { emojis } from './emojis'
import { sweepstakesEvent } from './event'
import { getWinner, SweepstakesOptionsContext, useSweepstakesOptions } from './options'
import { SweepstakesEntrySelected } from './selected'
import { Stepper } from './Stepper'

type ICompletedDialogType = 'ended' | 'inactive' | 'special' | ''
export const SweepstakesCompletedDialogType = makeContext<ICompletedDialogType>('', 'entry_complete_dialog')

function completedDialogContent(type: ICompletedDialogType, _title: string) {
  switch (type) {
    case 'ended':
      return "A winner has been selected for this Sweepstakes! An email requesting a video call will be sent to the winner. This person has 7 days to respond and provide the necessary information to collect the prize. If we don't receive a response within that timeframe, another winner will be selected and notified."
    case 'inactive':
      return 'This sweepstakes is currently inactive. Please check back later for more.'
    case 'special':
      return 'This sweepstakes is special. Please view in the app to add more entries.'
    default:
      return ''
  }
}

export const SweepstakesCompletedDialog = () => {
  const [step, setStep] = useState(0)
  const [completeDialogType, setCompleteDialogType] = SweepstakesCompletedDialogType.useContext()
  const [options] = SweepstakesEntrySelected.useContext()
  // const emojis = useEmojiOptionsQuery({ skip: !completeDialogType })
  const winner = getWinner(options)

  const optionTitle = options?.title ?? ''

  const { text } = useMemo(() => {
    const title = `Sweepstakes ${(completeDialogType ?? '')
      .slice(0, 1)
      .toUpperCase()
      .concat((completeDialogType ?? '').slice(1))}`
    const text = completedDialogContent(completeDialogType, optionTitle)
    return { text, title }
  }, [optionTitle, completeDialogType])

  return (
    <SweepstakesOptionsContext.Provider value={options}>
      <Dialog open={Boolean(completeDialogType)} onClose={() => setCompleteDialogType('')}>
        <Box position="relative" display="flex" alignItems="center" px={2} py={1} pr={6}>
          <ListItemAvatar style={{ minWidth: 84 }}>
            <SweepstakesDetailsAvatar size={64} />
          </ListItemAvatar>
          <SweepstakesDetailsLargeListItemText />
        </Box>
        {winner?.['confirmed'] ? (
          <Box p={1}>
            <SweepstakesWinnerDetails />
          </Box>
        ) : (
          <DialogContent style={{ WebkitOverflowScrolling: 'touch', overflowY: 'auto' }}>
            <DialogContentText>{text}</DialogContentText>
          </DialogContent>
        )}
        {winner?.['confirmed'] ? (
          options?.userEntry?.winner ? (
            <ReactionActions />
          ) : (
            <Stepper step={step} height={step === 1 ? 90 : 60}>
              <CompletedActions setStep={setStep} />
              <ReactionActions setStep={setStep} />
            </Stepper>
          )
        ) : null}
      </Dialog>
    </SweepstakesOptionsContext.Provider>
  )
}

const CompletedActions = ({ setStep }: { setStep: (step: number) => void }) => {
  const options = useSweepstakesOptions()
  const winner = getWinner(options)
  const setCompleteDialogType = SweepstakesCompletedDialogType.useContext()[1]

  return (
    <Box px={2} py={1} display="flex" alignItems="center">
      <LowercaseButton variant="outlined" onClick={() => setCompleteDialogType('')}>
        Close
      </LowercaseButton>
      <Box flexGrow={1} />
      {winner?.['confirmed'] && emojis?.data?.emojiOptions?.length ? (
        <LowercaseButton
          variant="contained"
          color="secondary"
          onClick={() => setStep(1)}
          style={{ color: '#fff' }}
          disableElevation
        >
          Congratulate
        </LowercaseButton>
      ) : null}
    </Box>
  )
}

const ReactionActions = ({ setStep }: { setStep?: (step: number) => void }) => {
  const options = useSweepstakesOptions()
  const [upsertReaction, { loading }] = useUpsertWinnerReactionMutation()
  const { setSnackbar } = useSnackbar()[1]
  const [reactions, setReactions] = useReactionCounts(options?.id)

  const handleReaction = async (emoji: Emoji) => {
    try {
      if (loading) return
      if (!emoji || !emoji.id) return
      if (!options || !options.id) return
      sweepstakesEvent('reaction', 'pressed', { value: emoji.id })
      setReactions({
        ...reactions,
        [emoji.id]: (reactions[emoji.id] ?? 0) + 1,
      })
      await upsertReaction({
        variables: {
          emojiName: emoji.id,
          id: options.id,
        },
      })
      sweepstakesEvent('reaction', 'success', { value: emoji.id })
      setSnackbar({
        autoHideDuration: 6000,
        message: (
          <>
            <AlertTitle>Success</AlertTitle> Reacted with {emoji.icon}
          </>
        ),
        severity: 'success',
      })
    } catch (error) {
      const message = parseError(error)
      sweepstakesEvent('reaction', 'error', { value: message })
      setSnackbar({
        autoHideDuration: 6000,
        message,
        severity: 'error',
      })
    }
  }

  return (
    <Box pl={2} py={1} display="flex" alignItems="center">
      {setStep ? (
        <Button
          variant="outlined"
          size="small"
          style={{ height: '100%', maxWidth: 30, minWidth: 'initial' }}
          onClick={() => setStep(0)}
        >
          <ArrowLeft />
        </Button>
      ) : null}
      <Box
        flexGrow={1}
        style={{
          WebkitOverflowScrolling: 'touch',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          // scrollSnapType: 'x mandatory',
        }}
      >
        {emojis?.data?.emojiOptions?.map((emoji) => {
          const isWinner = options?.userEntry?.winner ?? false
          const count = options?.userEntry?.reactions?.[emoji?.id ?? ''] ?? reactions?.[emoji?.id ?? ''] ?? 0
          return (
            <EmojiIconButton
              key={emoji?.id ?? ''}
              onClick={() => handleReaction(emoji)}
              disabled={isWinner}
              count={count}
            >
              {emoji?.icon ?? ''}
            </EmojiIconButton>
          )
        })}
        <Box pr={2} />
      </Box>
    </Box>
  )
}

const EmojiIconButton = ({ count, disabled, onClick, children }: IconButtonProps & { count: number }) => {
  return (
    <Badge
      badgeContent={`${disabled ? '' : '+'}${count}`}
      invisible={!count}
      color="secondary"
      overlap="circular"
      classes={{
        badge: 'text-white',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    >
      <IconButton
        disabled={disabled}
        onClick={onClick}
        style={{
          // paddingLeft: 0,
          // paddingRight: 0,
          // paddingBottom: 0,
          // scrollSnapAlign: 'start',
          color: 'inherit',

          fontSize: 36,
        }}
      >
        {children}
      </IconButton>
    </Badge>
  )
}

type ReactionCounts = { [emoji: string]: number }
function useReactionCounts(optionsId: string) {
  const [reactions, setReactions] = useState<ReactionCounts>({})
  const store = useMemo(() => localJSONStore<ReactionCounts>(`sweepstake_reactions_${optionsId}`), [optionsId])
  useEffect(() => {
    setReactions(store.from() ?? {})
  }, [store])
  return useMemo(() => {
    return [
      reactions,
      (v) => {
        store.to(v)
        setReactions(v)
      },
    ] as [typeof reactions, (v: typeof reactions) => void]
  }, [reactions, store])
}
