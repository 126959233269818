import { Cancel, Check, Remove, WarningOutlined } from '@mui/icons-material'

export const invoiceStatusColor = (status?: string | null) => {
  switch (status) {
    case 'past-due':
      return 'danger'
    case 'paid':
      return 'success'
    case 'unpaid':
      return ''
    case 'canceled':
      return 'danger'
    case 'refunded':
      return ''
  }
}

export const InvoiceStatusIcon = ({ status, ...props }: { status?: string }) => {
  switch (status) {
    case 'past-due':
      return <WarningOutlined {...props} />
    case 'paid':
      return <Check {...props} />
    case 'unpaid':
      return <WarningOutlined {...props} />
    case 'canceled':
      return <Cancel {...props} />
    case 'refunded':
      return <Remove {...props} />
  }
  return null
}
