import { bbox, origin } from 'quadkeytools'

export const polygonFeatureCollection = featureCollection(polygonType)
export const quadkeyFeatureCollection = featureCollection(quadkeyType)

function featureCollection<V = unknown>(fn: (v: V) => GeoJSON.Feature) {
  return function (features: V[]): GeoJSON.GeoJSON {
    return {
      features: (features || []).map(fn),
      type: 'FeatureCollection',
    }
  }
}

function polygonType<P extends { [name: string]: any }>({
  coordinates,
  properties,
}: {
  coordinates: number[][][]
  properties: P
}): GeoJSON.Feature<GeoJSON.Polygon, P> {
  return {
    geometry: {
      coordinates,
      type: 'Polygon',
    },
    properties,
    type: 'Feature',
  }
}

export function quadkeyType(feature: { quadkey: string }) {
  const { min, max } = bbox(feature.quadkey)
  const { lat: latitude, lng: longitude } = origin(feature.quadkey)

  return polygonType({
    coordinates: [
      [
        [min.lng, max.lat],
        [max.lng, max.lat],
        [max.lng, min.lat],
        [min.lng, min.lat],
        [min.lng, max.lat],
      ],
    ],
    properties: {
      latitude,
      longitude,
      ...feature,
    },
  })
}
