import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { FlexRow } from './Flex'

interface Props extends DialogProps {
  link: string
}

const ConfirmAppLinkDialog: React.FC<React.PropsWithChildren<Props>> = ({ link, onClose, children, ...props }) => {
  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle>Continue in App</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <FlexRow justifyContent="space-between" width="100%">
          <Button variant="outlined" onClick={(event) => onClose?.(event, 'escapeKeyDown')}>
            Cancel
          </Button>
          <Button
            variant="contained"
            target="_blank"
            href={`https://my.coinapp.co/appLink${link}`}
            onClick={(event) => onClose?.(event, 'escapeKeyDown')}
          >
            Continue
          </Button>
        </FlexRow>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmAppLinkDialog
