import { getAuth, onAuthStateChanged, User } from 'firebase/auth'

import { swalWarning } from '../componentsPure'
import { trackWarning } from '../utils'

const xyAccount = () => {
  let user: User | null
  let listeners: ((u: User | null) => void)[] = []
  const timerId = setTimeout(async () => {
    user = getAuth().currentUser
    listeners.forEach((listener) => listener(user))
    trackWarning('User not initialized after 15 seconds')

    await swalWarning('This is taking too long, try closing other tabs.')
  }, 10000)
  onAuthStateChanged(getAuth(), (_user) => {
    user = _user
    clearTimeout(timerId)
    listeners.forEach((listener) => listener(user))
  })
  return {
    subscribe: (fn: (user: User | null) => void) => {
      if (typeof fn === 'function') {
        listeners.push(fn)
        if (typeof user !== 'undefined') fn(user)
      }

      return () => {
        listeners = listeners.filter((l) => l === fn)
      }
    },
  }
}

export default xyAccount()
