import { AccountDocument } from '@xyo-network/api-checkout-apollo'

import { checkoutClient } from '../lib'

const refetchAccounts = () => {
  return checkoutClient.query({
    fetchPolicy: 'network-only',
    query: AccountDocument,
  })
}

export default refetchAccounts
