import { ExpandMore } from '@mui/icons-material'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material'
import { MouseEvent, useState } from 'react'

import Achievement from './Achievement'

const BadgeCard: React.FC<React.PropsWithChildren<{ achievement: Achievement }>> = ({ achievement }) => {
  const [open, setOpen] = useState(false)
  const toggle = (event: MouseEvent) => {
    event.stopPropagation()
    setOpen(!open)
  }
  return (
    <Card>
      <Collapse in={open}>
        <CardContent style={{ backgroundColor: 'rgba(0,0,0,0.2)', position: 'relative' }}>
          <CardMedia
            style={{
              // 16:9
              backgroundSize: 'contain',

              height: 0,
              paddingTop: '56.25%',
            }}
            image={achievement?.info?.imageUrl}
            title={achievement?.info?.title}
          />
          {achievement ? null : (
            <Skeleton
              variant="rectangular"
              height="100%"
              width="100%"
              style={{ left: 0, position: 'absolute', top: 0 }}
            />
          )}
        </CardContent>
        <CardContent style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
          <Typography>
            <strong>{achievement?.info?.title ?? ''}</strong>
          </Typography>
          <Typography>{achievement?.info?.description ?? ''}</Typography>
        </CardContent>
        <Divider />
      </Collapse>
      <CardHeader
        onClick={toggle}
        avatar={
          achievement ? (
            <Avatar variant="square" title={achievement?.achievement} src={achievement?.info?.iconUrl}>
              {achievement?.info?.type}
            </Avatar>
          ) : (
            <Skeleton variant="rectangular" width={40} height={40} />
          )
        }
        action={
          <IconButton onClick={toggle}>
            <ExpandMore
              style={{
                opacity: 0.4,
                transform: `rotate3d(${open ? 180 : 0}, 0, 0)`,
                transition: 'all 0.3s ease-in',
              }}
            />
          </IconButton>
        }
        title={
          achievement ? achievement?.info?.title ?? '' : <Skeleton variant="rectangular" style={{ marginBottom: 4 }} />
        }
        subheader={
          achievement ? (
            achievement.date ? (
              new Date(Number(achievement?.date))?.toLocaleString()
            ) : (
              ''
            )
          ) : (
            <Skeleton variant="rectangular" width="80%" />
          )
        }
        titleTypographyProps={{ noWrap: true }}
        subheaderTypographyProps={{ noWrap: true }}
      />
    </Card>
  )
}

export default BadgeCard
