import { useMutation } from '@apollo/react-hooks'
import { Box, Button, Checkbox, FormControlLabel, Link, Menu, Typography } from '@mui/material'
import { getAuth } from 'firebase/auth'
import gql from 'graphql-tag'
import { MouseEvent, useRef, useState } from 'react'

import { FadeIn, PasswordRequiredDialog } from '../components'
import { swalSuccess } from '../componentsPure'
import { BossProvider } from '../context'
import CustomBasePage from '../CustomBasePage'
import logger from '../Log'
import { useUser, useUserInfo } from '../User'
import UserInfoDisplay from '../UserInfoDisplay'
import { gtagCategoricalEvent, forget } from '../utils'

export default () => {
  return (
    <BossProvider>
      <CustomBasePage>
        <AccountDeletePage />
      </CustomBasePage>
    </BossProvider>
  )
}

const accountDeleteEvent = gtagCategoricalEvent('account-delete')

const AccountDeletePage = () => {
  const { user } = useUser()
  const { userInfo } = useUserInfo()
  const xyoCollected = userInfo?.xyoCollected ?? 0
  const activeSubscription = userInfo?.activeSubscription ?? 'Basic'
  const [checked, setChecked] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState<string | null>()
  const [deleting, setDeleting] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState('')
  const [deleteUser] = useMutation(DELETE_USER)
  // const disabled = !checked || activeSubscription !== 'Basic'
  const disabledReason =
    activeSubscription !== 'Basic'
      ? 'All subscriptions must be canceled before account deletion.'
      : !checked
      ? 'Must accept COIN loss terms'
      : ''
  const trackContactSupport = () => {
    accountDeleteEvent('support', activeSubscription, {
      value: xyoCollected,
    })
  }
  const trackShowReason = () => {
    accountDeleteEvent('disabled-reason', activeSubscription, {
      value: disabledReason,
    })
  }
  const openConfirmation = () => {
    setConfirmEmail(user?.email)
    accountDeleteEvent('confirm', activeSubscription, {
      value: xyoCollected,
    })
  }
  const confirmDeleteUser = async (event?: MouseEvent) => {
    event?.preventDefault()
    try {
      setError('')
      setDeleting(true)
      await deleteUser()
      accountDeleteEvent('success', activeSubscription, {
        value: xyoCollected,
      })
      await swalSuccess('Your account was successfully deleted.')
      await getAuth().signOut()
    } catch (e) {
      const error = e as Error
      logger().error(e)
      accountDeleteEvent('error', activeSubscription, {
        value: error.message,
      })
      setError(error.message)
    }
    setDeleting(false)
  }
  return (
    <div>
      {/* <ErrorViewer error={data.error} /> */}
      <FadeIn>
        <div className="mb-5 text-white pb-3" style={{ borderBottom: '2px dashed' }}>
          <UserInfoDisplay />
        </div>
        <div className="text-center">
          <Typography gutterBottom variant="h5">
            By deleting your account you are agreeing to the following
          </Typography>
          <Typography gutterBottom variant="h6">
            1. All rewards will be permanently lost and unrecoverable.
          </Typography>
          {activeSubscription !== 'Basic' ? (
            <>
              <Typography gutterBottom variant="h6" color="error">
                2. All subscriptions must be canceled before account deletion.
              </Typography>
              <Link target="_black" href={process.env.REACT_APP_SUPPORT_URI} onClick={trackContactSupport}>
                Contact Support
              </Link>
              <div />
            </>
          ) : null}
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(ev, value) => setChecked(value)}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                xyoCollected
                  ? `I agree to lose ${xyoCollected.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })} COIN`
                  : 'I agree to terms'
              }
            />
          </div>
          <DisabledReasonButton
            disabledReason={deleting ? 'Delete in progress' : disabledReason}
            onClick={openConfirmation}
            onEnter={trackShowReason}
          >
            Delete Account
          </DisabledReasonButton>
        </div>
      </FadeIn>
      <PasswordRequiredDialog
        title="Confirm Account Deletion"
        content="All rewards will be permanently lost and unrecoverable."
        open={Boolean(confirmEmail)}
        onClose={() => setConfirmEmail(null)}
        email={confirmEmail ?? ''}
        onConfirmed={() => {
          setConfirmEmail('')
          forget(confirmDeleteUser())
        }}
      />
    </div>
  )
}

const DELETE_USER = gql`
  mutation DeleteUser {
    deleteUser {
      ownerUID
    }
  }
`

interface DisabledReasonButton {
  disabledReason?: string
  onClick?: () => void
  onEnter?: () => void
}

const DisabledReasonButton: React.FC<React.PropsWithChildren<DisabledReasonButton>> = ({
  disabledReason,
  onClick,
  onEnter,
  children,
  ...props
}) => {
  const buttonRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  return (
    <>
      <div
        onClick={(_event: MouseEvent) => {
          if (disabledReason) setAnchorEl(buttonRef.current)
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={!!disabledReason}
          ref={buttonRef}
          onClick={onClick}
          {...props}
        >
          {children}
        </Button>
      </div>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onMouseEnter={onEnter}
        anchorEl={anchorEl}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
      >
        <Box px={2}>
          <Typography>{disabledReason}</Typography>
        </Box>
      </Menu>
    </>
  )
}
