import { SurveyProvider } from '../components'
import { halfReward, PollFishProvider } from '../context'
import { useUser } from '../User'
import { useTracking } from '../utils'

const UserProviders = ({ children }: React.PropsWithChildren) => {
  useTracking()

  const { user } = useUser()

  return (
    <PollFishProvider
      // onError={(_, e) => setError(e as Error)}
      onReady={({ showFullSurvey }) => showFullSurvey()}
      config={{
        uuid: user && user.uid,
        // ...quarterReward,
        ...halfReward,
        // ...fullReward,
      }}
    >
      <SurveyProvider>{children}</SurveyProvider>
    </PollFishProvider>
  )
}

export default UserProviders
