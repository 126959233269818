import { delay } from '../utils/delay'
import { getAuth, isSignInWithEmailLink, signInWithCustomToken, signInWithEmailLink, User } from 'firebase/auth'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { swalClose, swalConfirm, swalWarning } from '../componentsPure'
import { getBossClient, useCustomTokenContext, verifyClient } from '../context'
import { checkoutClient } from '../lib'
import logger, { setRollbarPayload } from '../Log'
import { useAsyncEffect } from '../utils/useAsyncEffect'
// import RedeemSubscription from './RedeemSubscription'
import refetchAccounts from './refetchAccounts'
import retrieveCustomToken from './retrieveCustomToken'
import UserContext from './UserContext'
import xyAccount from './xyAccount'

const UserProvider = ({ children }: React.PropsWithChildren) => {
  const skipRedeemSubscription = useRef('')
  const [initialized, setInitialized] = useState(false)
  const [tokenLogin, setTokenLogin] = useState(false)
  const customTokenActions = useCustomTokenContext()[1]
  const [user, setUser] = useState<User | null | undefined>()
  const navigate = useNavigate()
  const bossClient = getBossClient()

  const onLogout = async () => {
    const { value } = await swalConfirm('Are you sure you would like to logout?')
    if (value) {
      await logout()
    }
  }

  const logout = async (to?: string) => {
    try {
      await getAuth().signOut()
      customTokenActions.remove()
      try {
        await checkoutClient.clearStore()
        await bossClient?.clearStore()
        await verifyClient?.clearStore()
      } catch (e) {
        logger().log('Clear stores error', e)
      }
      navigate(to ?? '/auth')
    } catch (e) {
      return
    }
  }

  const loginWithToken = async (token: string, redirect: string, search?: string) => {
    if (tokenLogin) {
      //prevent re-entry
      return
    }
    try {
      setTokenLogin(true)
      const customToken = await retrieveCustomToken(token)
      if (!customToken) throw new Error('Token invalid')
      const timerId = setTimeout(async () => {
        await swalWarning('This is taking too long, try closing other tabs.')
        setTokenLogin(false)
      }, 10000)
      const userAuth = await signInWithCustomToken(getAuth(), customToken)
      const user = userAuth.user
      const email = user.email
      if (!user) throw new Error('Missing User')
      if (!email) throw new Error('Missing Email')
      clearTimeout(timerId)
      if (search && search.indexOf('from=admin') !== -1) {
        customTokenActions.update(email)
      }
      await delay(100)
      try {
        logger().log('Reset checkout store start')
        await checkoutClient.resetStore()
        logger().log('Reset checkout store end')
        logger().log('Reset boss store start')
        await bossClient?.resetStore()
        await refetchAccounts()
        logger().log('Reset boss store end')
      } catch (e) {
        logger().log('Reset stores error', e)
      }
      swalClose()
      if (redirect) navigate(redirect)
    } catch (e) {
      console.log(`ERROR: ${e}`)
      if (search) {
        navigate(`/auth?${search}`)
      } else {
        navigate('/auth')
      }
    } finally {
      setTokenLogin(false)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      const email = window.prompt('Please provide your email for confirmation')
      if (!email) throw new Error('Missing Email')
      try {
        const u = await signInWithEmailLink(getAuth(), email, window.location.href)
        logger().log('Sign in link', u)
      } catch (ex) {
        logger().log('Sign in link error', ex)
      }
    }

    return xyAccount.subscribe((user) => {
      setUser(user)
      setInitialized(true)
    })
  }, [])

  useEffect(() => {
    setRollbarPayload({
      person: {
        uid: user?.uid,
      },
    })
  }, [user])

  return (
    <UserContext.Provider
      value={{
        initialized,
        loginWithToken,
        logout,
        onLogout,
        skipRedeemSubscription,
        tokenLogin,
        user,
      }}
    >
      {initialized ? children : null}
      {/* <RedeemSubscription /> */}
    </UserContext.Provider>
  )
}

export default UserProvider
