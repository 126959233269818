import ethLogo from '../../assets/ethereum.svg'
import uniLogo from '../../assets/uniswap.png'
import xyoLogo from '../../assets/xyo.svg'
import opLogo from '../../assets/op.svg'
import { Token } from './types'

export function formatName(token: Token) {
  return (token?.name ?? '').replace('Wrapped ', '')
}

export function formatSymbol(token: Token) {
  return (token?.symbol ?? '').replace('WETH', 'ETH')
}

export function getLogo(token: Token) {
  if (token.symbol.indexOf('ETH') !== -1) return ethLogo
  if (token.symbol.indexOf('UNI') !== -1) return uniLogo
  if (token.symbol.indexOf('OP') !== -1) return opLogo
  return xyoLogo
}

export function getChainName (chainId?: string|number|null) {
  switch (chainId) {
    case 10:
    case '10':
      return 'optimism'
    default: 
      return 'mainnet'
  }
}

export function getChainLogo (chainId?: string|number|null) {
  switch (chainId) {
    case 10:
    case '10':
      return opLogo
    default: 
      return ''
  }
}