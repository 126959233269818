import { Component, ReactNode } from 'react'

interface State {
  error?: Error
}

interface Props {
  fallback: (error: Error) => ReactNode
}

class ErrorBoundary extends Component<React.PropsWithChildren<Props>, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error: Error) {
    // trackWarning('Error Boundary', error, info)
    if (/Loading chunk (.*) failed./.test(error.message)) {
      this.setState({
        error: {
          message: 'New version found, please reload.',
          name: 'Version Error',
        },
      })
    } else {
      this.setState({
        error: {
          message: 'Unknown error, please reload.',
          name: 'Unknown Error',
        },
      })
    }
  }

  render() {
    const { fallback, children } = this.props
    const { error } = this.state
    if (error) return fallback(error)
    return children
  }
}

export default ErrorBoundary
