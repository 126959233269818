import { CardProps } from '@mui/material'

import PlanCard from './PlanCard'

const ProPlanCard: React.FC<React.PropsWithChildren<CardProps>> = (props) => {
  return (
    <PlanCard
      title="COIN Pro"
      subtitle="Includes all COIN Basic Features"
      price={
        <>
          <strong>$ 34</strong>
          <small>.95/month</small>
        </>
      }
      badge="Most Popular!"
      pitch="COIN Pro is the perfect plan for frequent geominers who want to get more out of their COIN experience."
      features={[
        '3x Geomining Speed',
        '3x Recharge Rate',
        '3x More Big Geomines',
        '12x Geomining Rewards (with or without SentinelX)',
        '3x Additional Geomining Rewards',
        '3x Bonus Drop Power',
        '3x Background Rewards',
        'Geoclaim Shield &amp; Bonus',
        'Auto Collect',
        'Geodropping',
        '+ More',
      ]}
      {...props}
    />
  )
}

export default ProPlanCard
