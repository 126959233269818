export function getProfileTierStyles(profileMultiplier?: number) {
  if (!profileMultiplier || profileMultiplier <= 1) return {}

  const tier = getTierFromMultiplier(profileMultiplier)
  if (!tier) return {}

  return getProfileTierStylesFromTier(tier)
}

export function getProfileTierStylesFromTier (tier: number) {
  const colors = tierColors[tier - 1]
  if (!colors) return {}
  
  return {
    padding: 4, 
    borderRadius: '50%', 
    display: 'inline-block',
    background: 'linear-gradient(to right, var(--tier-color-0) 0%, var(--tier-color-1) 50%, var(--tier-color-2) 100%)',
    '--tier-color-0': colors[0],
    '--tier-color-1': colors[1],
    '--tier-color-2': colors[2],
  }
}

const tierColors = [
  ["#2F9B56", "#95D1A9", "#1F7B40"],
  ["#A7A9AC", "#E7E7E9", "#8A8C8F"],
  ["#B0892E", "#ECEAB0", "#9B6D2D"],
  ["#4A4A4C", "#ACAEB1", "#404041"],
  ["#2586C0", "#B9E4FA", "#1B6299"],
]

function getTierFromMultiplier (multiplier: number) {
  switch (multiplier) {
    case 1.01: return 1
    case 1.02: return 2
    case 1.04: return 3
    case 1.08: return 4
    case 1.16: return 5
  }
}