import { ApolloClient, ApolloProvider, concat, HttpLink, InMemoryCache } from '@apollo/client'
import { coinSurveysUri } from '@coin-microservices/coin-surveys-react-hooks'
import { setContext } from 'apollo-link-context'
import { getAuth } from 'firebase/auth'
import { FC } from 'react'

// const coinSurveysUri = 'http://localhost:8080'

const authLink: any = setContext(async (op, { headers }) => {
  const user = getAuth().currentUser
  const token = user ? await user.getIdToken() : ''
  return {
    headers: {
      ...headers,
      'X-Auth-Token': token,
    },
  }
})

const surveysClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, new HttpLink({ uri: coinSurveysUri })),
})

export const SurveysProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <ApolloProvider client={surveysClient}>{children}</ApolloProvider>
}
