import { FlexCol } from '../../../components/Flex'

import { SurveysProvider } from '../../../context'
import CustomBasePage from '../../../CustomBasePage'
import SurveyHistoryList from './List'

const SurveysPage = () => {
  return (
    <SurveysProvider>
      <CustomBasePage>
        <FlexCol marginY={2} alignItems="stretch">
          <SurveyHistoryList />
        </FlexCol>
      </CustomBasePage>
    </SurveysProvider>
  )
}

export default SurveysPage
