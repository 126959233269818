import cx from 'classnames'
import { cloneElement } from 'react'

import TableProps from './TableProps'

function Table<V>({
  children = [],
  headerClassName,
  data,
  className,
  onClick,
  isActive,
  activeColumn,
  activeColumnClassName = '',
  activeColumnHeaderClassName = '',
}: TableProps<V>) {
  return (
    <table className={cx(className, { 'table-hover cursor-pointer': onClick })}>
      <thead className={headerClassName}>
        <tr>
          {children.map((child, i) => (
            <th
              key={i}
              className={cx({
                [activeColumnHeaderClassName]: activeColumn === i,
              })}
            >
              {child?.props.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(data || []).map((d, i) => (
          <tr
            key={i}
            onClick={() => onClick && onClick(d)}
            className={cx({ 'table-light text-dark': isActive && isActive(d) })}
          >
            {children.map((child, i) => (
              <td key={i} className={cx({ [activeColumnClassName]: activeColumn === i })}>
                {cloneElement(child, { ...d, ...child.props })}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
