import swal from 'sweetalert2'

const swalConfirm = (
  text = '',
  title = 'Are you sure?',
  { confirmButtonText = 'Yes!', cancelButtonText = 'Cancel', html = '', ...options } = {}
) => {
  return swal.fire({
    cancelButtonText,
    confirmButtonText,
    html,
    reverseButtons: true,
    showCancelButton: true,
    text,
    title,
    ...options,
  })
}

export default swalConfirm
