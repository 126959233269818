import { useGtvRewardsQuery } from '@coin-microservices/foursquare-react-hooks/dist/client.generated'
import Grid from '@mui/material/Grid'
import { BoxProps } from '@mui/material/Box'
import { FlexCol } from '../../../components/Flex'
import { useMemo } from 'react'

import { CustomListHeader } from '../../../components'
import { numberFormat } from '../../../utils'
import { StatCard } from '../../../components/Dashboard/Stats'

const bonusGameFilter = {
  GT: new Date('2021-03-17T08:00:00.000Z'),
  LT: new Date('2021-04-17T06:59:59.000Z'),
}

interface Props extends BoxProps {
  filterType: 'allTime' | 'bonusGame'
  page?: number
  perPage?: number
}

const RewardedVerificationsList: React.FC<React.PropsWithChildren<Props>> = ({ filterType, page, perPage, ...props }) => {
  const variables = useMemo(() => {
    const filter = {}
    if (filterType === 'bonusGame') {
      ;(filter as any).created = bonusGameFilter
    }
    return {
      filter,
      pagination: { page, perPage },
      sort: { field: 'created', order: -1 },
    }
  }, [page, perPage, filterType])

  const rewardsQuery = useGtvRewardsQuery({
    variables: { filter: variables.filter },
  })

  const rewardData = rewardsQuery.data?.gtvRewards

  return (
    <FlexCol alignItems="stretch" {...props}>
      <Grid container spacing={2}>
        <Grid xs={12} md={4} item>
          <StatCard
            title="Verifications"
            margin={1}
            borderRadius={0}
            value={rewardsQuery?.loading ? undefined : numberFormat(rewardData?.validated ?? 0, 0)}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <StatCard
            title="Created"
            margin={1}
            borderRadius={0}
            value={rewardsQuery?.loading ? undefined : numberFormat(rewardData?.inserted ?? 0, 0)}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <StatCard
            title="Closed"
            margin={1}
            borderRadius={0}
            value={rewardsQuery?.loading ? undefined : numberFormat(rewardData?.closed ?? 0, 0)}
          />
        </Grid>
      </Grid>
      <CustomListHeader title="Rewards" />
      <Grid container spacing={2}>
        <Grid xs={12} md={4} item>
          <StatCard
            title="Verifications"
            margin={1}
            borderRadius={0}
            value={rewardsQuery?.loading ? undefined : numberFormat(rewardData?.pending)}
            subtitle="COIN"
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <StatCard
            title="Created"
            margin={1}
            borderRadius={0}
            value={rewardsQuery?.loading ? undefined : numberFormat(rewardData?.conditional)}
            subtitle="COIN"
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <StatCard
            title="Closed"
            margin={1}
            borderRadius={0}
            value={rewardsQuery?.loading ? undefined : numberFormat(rewardData?.delivered)}
            subtitle="COIN"
          />
        </Grid>
      </Grid>
    </FlexCol>
  )
}

export default RewardedVerificationsList
