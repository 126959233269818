import { witnessFeatureImage } from './features'
import { StatCard, StatCardProps } from '../Stats'

import { useUserInfo } from '../../../User'

const SurveyCard: React.FC<React.PropsWithChildren<StatCardProps>> = (props) => {
  const { userInfo } = useUserInfo()

  return (
    <StatCard
      image={witnessFeatureImage}
      whole
      title="Surveys"
      subtitle="All time"
      action="View More"
      actionTo="/surveys"
      value={userInfo ? (userInfo?.xyoSurveys ?? 0) - (userInfo?.xyoInbrainRevoked ?? 0) : undefined}
      description="COIN"
      {...props}
    />
  )
}

export default SurveyCard
