import { swalWarning } from '../../componentsPure'
import { localJSONStore } from '../../utils'

const surveyDisclaimer = localJSONStore<{ time: number }>('survey-disclaimer')

export const showDisclaimer = async () => {
  const seen = surveyDisclaimer.from()
  if (seen && seen.time) {
    // pollFish.initialize()
    return
  }
  surveyDisclaimer.to({ time: Date.now() })
  await swalWarning(
    'Warning',
    'Be sure to answer the surveys truthfully. If the provider detects you are randomly answering the questions, you may not get rewarded for your time or may even be rejected from their system and not be allowed to take any further surveys.',
  )
}