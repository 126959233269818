import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import { FlexCol, FlexRow } from '../../../components/Flex'
import { FC, MouseEventHandler, useState } from 'react'

import { PlanModalButton, PopoverButton } from '../../../components'
import { stopPropagation } from '../../../lib'
import { getCoinFreeTrialDetails } from '../../../plans'
//import { useUpgrade } from '../../../store'
import { purchaseTracker } from '../../../utils'
import { CheckoutApolloProvider } from '../../contexts'
import { Checkout } from '../Checkout'
import useHandleSuccess from './useHandleSuccess'

const StartFreeTrial: FC<React.PropsWithChildren<{ popover?: boolean }>> = ({ popover, ...props }) => {
  const [open, setOpen] = useState(false)
  //const [, { confirmProFHR }] = useUpgrade()
  const trialPlan = getCoinFreeTrialDetails()
  const trialTracker = purchaseTracker(trialPlan)
  const { label, buttonText } = trialPlan
  const handleSuccess = useHandleSuccess(trialPlan, trialTracker)

  const renderPopover = (buyNow?: MouseEventHandler<HTMLButtonElement>) => {
    return (
      <FlexCol>
        <FlexRow>
          {label} (<PlanModalButton>More Details</PlanModalButton>)
        </FlexRow>
        <FlexRow marginY={2}>
          <Button variant="outlined" sx={{ mx: 1 }} onClick={buyNow}>
            Buy Now
          </Button>
          {/* <ButtonEx variant="outlined" marginX={1} onClick={confirmProFHR}>
            FHR Member?
          </ButtonEx> */}
        </FlexRow>
      </FlexCol>
    )
  }

  const buyPro = () => {
    setOpen(true)
  }

  return (
    <>
      <PopoverButton
        variant="outlined"
        sx={{ mx: 1 }}
        onClick={popover ? undefined : stopPropagation(buyPro)}
        renderPopover={renderPopover}
        onRenderPopover={trialTracker.view}
      >
        {props.children || buttonText}
      </PopoverButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogContent>
          <CheckoutApolloProvider>
            <Checkout
              onSuccess={handleSuccess}
              onCancel={() => setOpen(false)}
              onDisplay={() => trialTracker.click()}
              onCustomerInfoComplete={() => trialTracker.step(1)}
              onShippingInfoComplete={() => trialTracker.step(2)}
              onPaymentInfoComplete={() => trialTracker.step(3)}
            />
          </CheckoutApolloProvider>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default StartFreeTrial
