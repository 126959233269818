import { useQuery } from '@apollo/react-hooks'
import { Info } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { FlexCol, FlexRow } from '../components/Flex'
import gql from 'graphql-tag'
import get from 'lodash/get'

import { BossProvider } from '../context'
import CustomBasePage from '../CustomBasePage'

export default () => {
  return (
    <BossProvider>
      <ReferralsPage />
    </BossProvider>
  )
}

const ReferralsPage = () => {
  const { data, loading } = useQuery(REFERRALS)

  return (
    <CustomBasePage>
      <Typography variant="h2" marginY={4}>
        Referral Rewards
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <FlexCol paddingTop={1} alignItems="stretch">
          <FlexRow marginY={2}>
            <Tooltip
              enterDelay={200}
              title="Earn Referral Rewards For every new user who installs COIN using your special invite link, you'll receive 10% of the rewards they geomine - for life!"
            >
              <TextField
                variant="outlined"
                label={
                  <FlexRow>
                    <Typography marginRight={1}>Installs</Typography>
                    <Info />
                  </FlexRow>
                }
                value={get(data, 'referrals.installs') || 0}
                InputProps={{
                  disabled: true,
                }}
                fullWidth
              ></TextField>
            </Tooltip>
          </FlexRow>
          <FlexRow marginY={2}>
            <Tooltip
              enterDelay={200}
              title="Help us grow COIN! Each person that installs COIN using your special invite link will receive 1000 for Free (Android Only)"
            >
              <TextField
                variant="outlined"
                label={
                  <FlexRow>
                    <Typography marginRight={1}>Earnings</Typography>
                    <Info />
                  </FlexRow>
                }
                value={`${get(data, 'referrals.earned') || 0}`}
                InputProps={{
                  disabled: true,
                }}
                fullWidth
              ></TextField>
            </Tooltip>
          </FlexRow>
          <Button
            sx={{ my: 2 }}
            variant="outlined"
            href="https://support.coinapp.co/hc/en-us/articles/360034676253"
            target="_blank"
            fullWidth
          >
            More Info
          </Button>
        </FlexCol>
      )}
    </CustomBasePage>
  )
}

const REFERRALS = gql`
  query Referrals {
    referrals {
      installs
      earned
    }
  }
`
