import Grid from '@mui/material/Grid'
import { BoxProps } from '@mui/material/Box'
import { FlexCol } from '../Flex'

import BasicPlanCard from './BasicPlanCard'
import PlusPlanCard from './PlusPlanCard'
import ProPlanCard from './ProPlanCard'

const Plans: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  return (
    <FlexCol {...props}>
      <Grid container position="relative">
        <Grid item xs={12} lg={3} paddingY={2}>
          <BasicPlanCard elevation={2} />
        </Grid>
        <Grid item xs={12} lg={6} paddingBottom={2}>
          <ProPlanCard elevation={4} />
        </Grid>
        <Grid item xs={12} lg={3} paddingY={2}>
          <PlusPlanCard elevation={2} />
        </Grid>
      </Grid>
      <Grid sx={{ display: { lg: 'inherit', xs: 'none' } }} container position="absolute">
        <Grid item xs={12} lg={3} />
        <Grid item xs={12} lg={6} paddingBottom={2}>
          <ProPlanCard elevation={4} />
        </Grid>
        <Grid item xs={12} lg={3} />
      </Grid>
    </FlexCol>
  )
}

export default Plans
