import { Fab } from '@mui/material'
import { FlexRow } from '../Flex'
import { Link } from 'react-router-dom'

import dropsImage from '../../assets/drops.png'
import claimsImage from '../../assets/geoclaim.png'
import axeImage from '../../assets/pickaxe.png'

const BottomNav: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <FlexRow
      sx={{ display: { md: 'inherit', xs: 'none' } }}
      position="fixed"
      width="100vw"
      paddingBottom={4}
      bottom={0}
      zIndex={1}
    >
      <Link to="/geodrops">
        <Fab
          style={{
            background: '#fff',
            border: '3px solid #eee',
            height: 64,
            padding: 14,
            width: 64,
          }}
        >
          <img width="100%" src={dropsImage} alt="Geodrops" />
        </Fab>
      </Link>
      <Link to="/geomines">
        <Fab
          style={{
            background: '#fff',
            border: '4px solid #eee',
            height: 94,
            margin: '0 40px',
            padding: 26,
            width: 94,
          }}
        >
          <img width="100%" src={axeImage} alt="Geomines" />
        </Fab>
      </Link>
      <Link to="/geoclaims">
        <Fab
          style={{
            background: '#fff',
            border: '3px solid #eee',
            height: 64,
            padding: 14,
            width: 64,
          }}
        >
          <img width="100%" src={claimsImage} alt="Geoclaims" />
        </Fab>
      </Link>
    </FlexRow>
  )
}

export default BottomNav
