import { getAuth } from 'firebase/auth'

const getRequestContext = async () => {
  const user = getAuth().currentUser
  if (!user) return {}
  return {
    headers: {
      'X-Auth-Token': await user.getIdToken(),
    },
  }
}

export default getRequestContext
