import { Box, DialogContentText } from '@mui/material'

import { IVariant, VariantSelector } from './Variant'

export interface VerifyRedeemSuccessProps {
  options?: {
    title: string
    imageUrl?: string
    variants?: IVariant[]
  }
  state: string
  type: string
  loading: boolean
  value: string
  onChange: (v: IVariant) => void
}

export const VerifyRedeemSuccess = ({ options, state, type, loading, value, onChange }: VerifyRedeemSuccessProps) => (
  <>
    {options && options.title ? (
      <Box m={-2} mx={-2.5} p={2} style={{ overflowX: 'auto', overscrollBehavior: 'contain' }}>
        <Box position="sticky" left={0}>
          <img
            src={options?.imageUrl}
            alt={options.title}
            style={{
              borderRadius: 0,
              float: 'left',
              marginRight: 16,
              marginTop: '0.5rem',
              objectFit: 'contain',
              width: 50,
              // height: 75,
            }}
          />

          {state === 'verifying' ? (
            <DialogContentText>
              Please select a size for your <strong>{options.title}</strong> and it should be {type === 'ship' ? 'shipped' : 'processed'} within 7
              business days.
            </DialogContentText>
          ) : (
            <DialogContentText>
              You have successfully verified your redeem request for <strong>{options.title}</strong> and it should be{' '}
              {type === 'ship' ? 'shipped' : 'processed'} within 7 business days.
            </DialogContentText>
          )}
        </Box>
        <div style={{ clear: 'both' }} />

        {options.variants ? (
          <VariantSelector helperText="Please confirm size:" loading={loading} value={value} onChange={onChange} variants={options.variants} />
        ) : null}
      </Box>
    ) : (
      <DialogContentText>You have successfully verified your redeem request and it should be processed within 7 business days.</DialogContentText>
    )}
  </>
)
