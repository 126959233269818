import { Address } from '@xyo-network/api-checkout-apollo'
import fetch from 'isomorphic-fetch'
import capitalize from 'lodash/capitalize'
import first from 'lodash/first'
import get from 'lodash/get'

import getLeadSource from './getLeadSource'
import postOptions from './postOptions'

const REBILLY_URL = process.env.REACT_APP_REBILLY_URL
const REBILLY_KEY = process.env.REACT_APP_REBILLY_KEY

export type PaymentInfo = {
  method: string
  ccNumber: string
  ccExpMonth: string
  ccExpYear: string
  ccCvv: string
}

type IPhone = {
  label: string
  primary: boolean
  value: string
}

export const createPaymentToken = async (
  { method, ccNumber, ccExpMonth, ccExpYear, ccCvv }: PaymentInfo,
  billingAddress: Address & { phoneNumbers: IPhone[] }
) => {
  const leadSource = getLeadSource()
  const payloadData = {
    billingAddress,
    leadSource,
    method: method || '',
    paymentInstrument: {
      cvv: ccCvv,
      expMonth: ccExpMonth,
      expYear: ccExpYear,
      pan: ccNumber,
    },
  }
  const response = await fetch(`${REBILLY_URL}/tokens`, postOptions(payloadData, { Authorization: REBILLY_KEY }))
  const data = await response.json()
  const errorDetails = capitalize(first(get(data, 'details')))
  const error = get(data, 'error')
  if (errorDetails) {
    throw new Error(errorDetails)
  }
  if (error) {
    throw new Error(error)
  }
  return data
}
