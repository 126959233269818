import { useAllCurrentSweepstakeOptionsQuery } from '@coin-microservices/sweepstakes-react-hooks'
import { Alarm, Redeem } from '@mui/icons-material'
import { Box, TextField, Typography } from '@mui/material'
import moment from 'moment-timezone'
import { useMemo } from 'react'

import { numberFormat } from '../../../utils'
import { useSweepstakesOptions } from '../options'
import SweepstakesPrizeDetailsLoading from './SweepstakesPrizeDetailsLoading'

const SweepstakesPrizeDetails = () => {
  const options = useSweepstakesOptions()
  const { loading } = useAllCurrentSweepstakeOptionsQuery()
  const [qtyStr, dateStr, dateTz] = useMemo(() => {
    const qtyStr = options?.quantity === 1 ? 1 : numberFormat(options?.quantity ?? 0)
    const end = moment(options?.endDate)
    const dateStr = end.format('MMMM Do YYYY, h:mm:ss a')
    const tz = moment.tz
    if (!tz) return [dateStr, '']
    const dateTz = tz?.zone(tz.guess() ?? '')?.abbr(new Date().getTimezoneOffset())
    return [qtyStr, dateStr, dateTz]
  }, [options?.endDate, options?.quantity])

  if (loading) return <SweepstakesPrizeDetailsLoading />

  return (
    <>
      <TextField
        fullWidth
        disabled
        // label="Prize"
        label={
          <Box component="span" display="flex" alignItems="center">
            <Redeem scale={0.5} style={{ paddingRight: 4 }} /> Prize
          </Box>
        }
        margin="normal"
        variant="outlined"
        value={qtyStr}
        InputProps={{
          endAdornment: <Typography style={{ whiteSpace: 'nowrap' }}>{options?.item ?? ''}</Typography>,
        }}
      />
      <TextField
        fullWidth
        disabled
        // label="Drawing"
        label={
          <Box component="span" display="flex" alignItems="center">
            <Alarm scale={0.5} style={{ paddingRight: 4 }} /> Drawing
          </Box>
        }
        margin="normal"
        variant="outlined"
        value={dateStr}
        classes={{}}
        InputProps={{
          endAdornment: <Typography style={{ whiteSpace: 'nowrap' }}>{dateTz}</Typography>,
        }}
      />
    </>
  )
}

export default SweepstakesPrizeDetails
