import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { FlexCol } from '../../components/Flex'

import { ErrorAlertAction } from '../../componentsPure'
import Logo from './Logo'

const reload = () => {
  window.location.reload()
}

interface Props {
  error?: string
  message?: string
}

const SplashScreen: React.FC<React.PropsWithChildren<Props>> = ({ error, message }) => (
  <FlexCol
    style={{
      height: '100vh',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100vw',
    }}
  >
    <Logo style={{ height: 160 }} />
    <ErrorAlertAction
      className="rounded-bottom"
      error={error ?? ''}
      action={
        <Button variant="outlined" onClick={reload}>
          Reload
        </Button>
      }
    />
    <Typography variant="body1">{message}</Typography>
  </FlexCol>
)

export default SplashScreen
