import get from 'lodash/get'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

import { numberFormat } from '../../utils'

interface LocaleNumberProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  source: string
  className?: string
}

const LocaleNumber: React.FC<React.PropsWithChildren<LocaleNumberProps>> = ({ source, className, ...props }) => {
  const num = get(props, source) || 0

  const value = numberFormat(num)
  return <span className={className}>{value}</span>
}

export default LocaleNumber
