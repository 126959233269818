import { BoxProps, Button } from '@mui/material'

import { FlexCol, FlexRow, PlanModalButton } from '../../../components'

interface Props extends BoxProps {
  onBuyNow?: () => void
  onConfirmFHR?: () => void
}

const BuyNow: React.FC<React.PropsWithChildren<Props>> = ({ title, onBuyNow, ...props }) => {
  return (
    <FlexCol {...props} p={2}>
      <FlexRow pb={2}>
        {title} (<PlanModalButton>More Details</PlanModalButton>)
      </FlexRow>
      <Button variant="outlined" onClick={onBuyNow} fullWidth>
        Buy Now
      </Button>
    </FlexCol>
  )
}

export default BuyNow
