import { getAuth } from 'firebase/auth'
import fetch from 'isomorphic-fetch'
import entries from 'lodash/entries'

import logger from '../Log'
import { gTag, gtagCategoricalEvent, GTagEcommerce } from './gtag'
import { utmParams } from './search'

const ua = navigator.userAgent.toLowerCase()
const isAndroid = ua.indexOf('android') !== -1

export const trackError = (...m: (Error | string)[]) => {
  try {
    logger().error(...m)
  } catch (e) {
    return
  }
}

export const trackWarning = (...m: string[]) => {
  try {
    logger().warn(...m)
  } catch (e) {
    return
  }
}

export const trackInfo = (...m: string[]) => {
  try {
    logger().info(...m)
  } catch (e) {
    return
  }
}

export const trackSurveyEvent = gtagCategoricalEvent('survey')
export const trackSurveyDialogOpenEvent = trackSurveyEvent('dialog-open')
export const trackSurveyDialogCloseEvent = trackSurveyEvent('dialog-close')
export const trackSurveyPrecisionEvent = trackSurveyEvent('precision')
export const trackSurveyPollfishEvent = trackSurveyEvent('pollfish')
export const trackSurveyBitlabsEvent = trackSurveyEvent('bitlabs')
export const trackSurveyInbrainEvent = trackSurveyEvent('inbrain')
export const trackSubscriptionEvent = gtagCategoricalEvent('subscription')
export const trackCancelEvent = trackSubscriptionEvent('cancel')
export const trackReactivateEvent = trackSubscriptionEvent('reactivate')
export const trackChangeEvent = trackSubscriptionEvent('change')
export const trackPayEvent = trackSubscriptionEvent('pay')

async function appsFlyerEvent(eventName: string, eventValue = {}) {
  try {
    const user = getAuth().currentUser
    const customer_user_id = user ? user.uid : null
    if (!customer_user_id) return
    await fetch(`${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/appsflyer`, {
      body: JSON.stringify({
        appId: isAndroid ? 'network.xyo.coin' : 'id1450443351',
        customer_user_id,
        eventName,
        eventValue: {
          ...utmParams,
          ...eventValue,
        },
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
  } catch (e) {
    return
  }
}

type IPlan = {
  planId: string
  amount: number
}
export function purchaseTracker(plan: IPlan) {
  const value = plan.planId
  const products = [{ id: plan.planId, name: plan.planId, price: plan.amount, quantity: 1 }]
  const gtagEcommerce = new GTagEcommerce(products)
  return {
    click: async () => {
      gtagEcommerce.click()
      await appsFlyerEvent('product-click', {})
    },
    purchase: async (id: string, revenue: number) => {
      gtagEcommerce.purchase(id, revenue)
      await appsFlyerEvent('product-purchase', {
        af_revenue: revenue,
      })
    },
    step: async (step: number, option?: string, value?: string | number) => {
      gtagEcommerce.step(step, option, value)
      switch (step) {
        case 1:
          await appsFlyerEvent('checkout-personal-info-complete', {})
          break
        case 2:
          await appsFlyerEvent('checkout-contact-info-complete', {})
          break
        case 3:
          await appsFlyerEvent('checkout-payment-info-complete', {})
          break
        default:
      }
    },
    view: async () => {
      gtagEcommerce.view()
      await appsFlyerEvent('product-view', {})
      gTag('event', 'transaction-view', { value })
    },
  }
}

if (!Array.prototype.fill) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.fill = function () {
    trackWarning('Using fill prototype')
    return this
  }
}

if (!Object.entries) {
  Object.entries = entries
}
