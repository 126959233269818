import { ErrorViewer } from '../../componentsPure'
import Loader from '../Loader'
import BalancesDisplayProps from './BalancesDisplayProps'
import BalancesTable from './BalancesTable'
import useBalances from './useBalances'

const BalancesDisplay: React.FC<React.PropsWithChildren<BalancesDisplayProps>> = ({ isActive, onClick }) => {
  const { data, error, loading } = useBalances()
  return (
    <div>
      <Loader loading={loading} />
      <ErrorViewer error={error} />
      {data?.balances && <BalancesTable isActive={isActive} onClick={onClick} data={data.balances} />}
    </div>
  )
}

export default BalancesDisplay
