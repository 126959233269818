/* eslint-disable complexity */

import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Grid, Link, Typography } from '@mui/material'
import { FlexRow } from '../Flex'
import capitalize from 'lodash/capitalize'
import { FaCheck, FaTimes } from 'react-icons/fa'

import { fromNanoseconds } from '../../utils'
import RedeemRequest from './RedeemRequest'

interface Props {
  active?: boolean
  option?: {
    imageUrl?: string
    title?: string
  }
  request: RedeemRequest
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void
}

const RedeemAccordion: React.FC<React.PropsWithChildren<Props>> = ({ request, option, active, ...props }) => {
  const time = fromNanoseconds(request.updated || request.created).toLocaleString()
  return (
    <Accordion expanded={active} {...props}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {option && option.imageUrl && (
          <Avatar src={option.imageUrl} style={{ borderRadius: 0 }} imgProps={{ style: { objectFit: 'contain' } }} />
        )}
        <FlexRow marginX={2}>
          {request.state === 'completed'
            ? `Redeemed ${(
                Number(request.xyoERC20Requested) ||
                Number(request.xyoCost) ||
                Number(request.sent) ||
                0
              ).toLocaleString()} ${option?.title || ''}`
            : option?.title
            ? `${option?.title} ${capitalize(request.state)}`
            : `Redeem Request ${capitalize(request.state)}`}
          <FlexRow marginX={1}>
            {request.state === 'completed' ||
            request.status === 'completed' ||
            request.state === 'processed' ||
            request.status === 'processed' ? (
              <FaCheck color="green" />
            ) : null}
            {request.state === 'denied' ||
            request.status === 'denied' ||
            request.state === 'expired' ||
            request.status === 'expired' ? (
              <FaTimes color="red" />
            ) : null}
          </FlexRow>
        </FlexRow>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          {(request.ethAddress || request.wallet || request.useCoinbase) && (
            <Typography variant="subtitle1">
              <strong>Wallet: </strong>
              {request.useCoinbase ? (
                'Coinbase'
              ) : (
                <Link
                  className="address"
                  href={`https://etherscan.io/address/${request.ethAddress || request.wallet}`}
                  target="_blank"
                >
                  {request.ethAddress || request.wallet}
                </Link>
              )}
            </Typography>
          )}
          {request.transactionHash && (
            <Typography variant="subtitle1">
              <strong>Transaction: </strong>
              <Link className="address" href={`https://etherscan.io/tx/${request.transactionHash}`} target="_blank">
                {request.transactionHash}
              </Link>
            </Typography>
          )}
          <Typography variant="subtitle1">
            <strong>Receipt: </strong>
            {request.transactionHash ? (
              <Link className="address" href={`https://etherscan.io/tx/${request.transactionHash}`} target="_blank">
                {time}
              </Link>
            ) : (
              <span>{time}</span>
            )}
          </Typography>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default RedeemAccordion
