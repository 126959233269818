import Box, { BoxProps } from '@mui/material/Box'
import Link from '@mui/material/Link'
import { FlexRow } from '../../Flex'
import React from 'react'

export const FooterLinks: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  return (
    <FlexRow flexWrap="wrap" sx={{ smDown: { flexDirection: 'column' } }} {...props}>
      <Box paddingX={2}>
        <Link href="https://support.coinapp.co/hc" target="_blank" variant="body2">
          Support
        </Link>
      </Box>
      <Box paddingX={2}>
        <Link href="https://jobs.xylabs.com" target="_blank" variant="body2">
          Careers
        </Link>
      </Box>
      <Box paddingX={2}>
        <Link href="https://coinapp.co/legal/privacy" target="_blank" variant="body2">
          Privacy Policy
        </Link>
      </Box>
      <Box paddingX={2}>
        <Link href="https://coinapp.co/legal/terms" target="_blank" variant="body2">
          Terms of Service
        </Link>
      </Box>
      <Box paddingX={2}>
        <Link href="https://coinapp.co/legal/sales-terms" target="_blank" variant="body2">
          Terms of Sale
        </Link>
      </Box>
      <Box paddingX={2}>
        <Link href="https://coinapp.co/legal/subscription-terms" target="_blank" variant="body2">
          Subscription Terms
        </Link>
      </Box>
      <Box paddingX={2}>
        <Link href="https://coinapp.co/legal/sweepstakes-terms" target="_blank" variant="body2">
          Sweepstakes Terms
        </Link>
      </Box>
    </FlexRow>
  )
}
