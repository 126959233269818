import { Button, DialogContentText } from '@mui/material'
import { AccountDocument, RebillySubscription, useCancelSubscriptionMutation } from '@xyo-network/api-checkout-apollo'
import { useState } from 'react'

import { ConfirmDialog, useSnackbar } from '../components'
import { parseError, trackCancelEvent, trackWarning } from '../utils'

const CancelSubscription = ({ subscription }: { subscription?: RebillySubscription | null }) => {
  const [open, setOpen] = useState(false)
  const { setSnackbar } = useSnackbar()[1]
  const [mutate, { loading }] = useCancelSubscriptionMutation({
    refetchQueries: [{ query: AccountDocument }],
    variables: { subscriptionId: subscription ? subscription.id : '' },
  })
  const nextBillingDate = subscription && subscription.renewalTime ? new Date(subscription.renewalTime).toLocaleString() : ''

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    trackCancelEvent('canceled', {})
    setOpen(false)
  }

  const cancelSubscription = async () => {
    if (!mutate || !subscription) return
    try {
      trackCancelEvent('confirmed', {})
      await mutate()
      trackCancelEvent('success', {})
      setSnackbar({
        autoHideDuration: 6000,
        message: 'Sorry to see you go... You can reactivate the subscription at anytime.',
        severity: 'success',
      })
    } catch (e) {
      const error = e as Error
      trackWarning('Cancel subscription error', error.message)
      trackCancelEvent('error', { value: error.message })
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(error.message),
        severity: 'error',
      })
    }
  }

  return subscription ? (
    <>
      <Button color="error" onClick={handleOpen}>
        Cancel Subscription
      </Button>
      <ConfirmDialog open={open} loading={loading} onClose={handleClose} onConfirm={cancelSubscription}>
        <DialogContentText>You can still use your current plan until {nextBillingDate}.</DialogContentText>
      </ConfirmDialog>
    </>
  ) : null
}

export default CancelSubscription
