import { Typography } from '@mui/material'
import { FlexCol } from '../../../components/Flex'

import { CustomListItem } from '../../../components'
import { numberFormat } from '../../../utils'
import Quadkey from './Quadkey'

interface Props {
  id: string
  quadkey: string
  paid: number
  earned: number
}

const ClaimQuadkeyResults: React.FC<React.PropsWithChildren<Props>> = ({ id, quadkey, paid, earned }) => {
  return (
    <CustomListItem>
      {quadkey && <Quadkey id={id} quadkey={quadkey} paid={paid} earned={earned} />}
      <FlexCol alignItems="flex-end">
        <Typography variant="h6">Tile: {quadkey}</Typography>
        <Typography variant="body1" noWrap>
          Paid: {numberFormat(paid)}
        </Typography>
        <Typography variant="body1" noWrap>
          Earned: {numberFormat(earned)}
        </Typography>
        {paid <= earned ? (
          <Typography variant="body1" noWrap>
            Gain: {numberFormat(((earned - paid) / paid) * 100)}%
          </Typography>
        ) : null}
      </FlexCol>
    </CustomListItem>
  )
}

export default ClaimQuadkeyResults
