import { FlexRow } from '../../../components/Flex'

import { LoadingButton, useSurveyProvider } from '../../../components'
import { usePollFish } from '../../../context'
import { trackSurveyDialogOpenEvent } from '../../../utils'

const SurveyButton = () => {
  const pollFish = usePollFish()
  const setOpen = useSurveyProvider()[1]
  const setDialogOpen = () => {
    setOpen(true)
    trackSurveyDialogOpenEvent('button', {})
  }
  return (
    <FlexRow>
      <LoadingButton
        onClick={setDialogOpen}
        // pollFish.initialize().then(() => pollFish.showFullSurvey())
        variant="contained"
        color="primary"
        loading={(pollFish as any).initializing}
      >
        Earn COIN Taking Surveys
      </LoadingButton>
    </FlexRow>
  )
}

export default SurveyButton
