import NoItemsPage from '../../../NoItems'

const NoGeoMines = () => (
  <NoItemsPage
    message="No Geomines Found"
    href="https://support.coinapp.co/hc/en-us/articles/360033279773-COIN-User-Guide#EarnAssetsID"
    buttonText="Read more about Geomines"
  />
)

export default NoGeoMines
