import { OutlinedInput, OutlinedInputProps, Select, SelectProps, Theme } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledInput = styled(OutlinedInput)<OutlinedInputProps>(({ theme }) =>
  ({
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    cssError: {},
    cssFocused: {},
    divider: {
      height: 4,
    },
    inputInput: {
      flexGrow: 1,
      width: 'auto',
    },
    inputRoot: {
      '&$cssFocused $cssError': {
        borderColor: `${theme.palette.primary.main} !important`,
        borderWidth: 1,
      },
      '&$cssFocused $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
        borderWidth: 1,
      },
      color: 'currentColor',
      flexWrap: 'wrap',
    },
    notchedOutline: {},
    paper: {
      backgroundColor: '#fff',
      left: 0,
      marginTop: 4,
      maxHeight: 160,
      overflow: 'scroll',
      position: 'absolute',
      right: 0,
      zIndex: 2,
    },
  })
)

export const SelectItem = ({ children, ...props }: SelectProps) => {
  return (
    <Select variant="standard" input={<StyledInput />} {...props}>
      {children}
    </Select>
  )
}
