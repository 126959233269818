import NoItems from '../../../NoItems'

export const NoRewardedVisits = () => (
  <NoItems
    message="No Previous Rewarded Visits Found"
    href="https://support.coinapp.co/hc/en-us/articles/360058812333-Rewarded-Scans"
    buttonText="Read more about rewarded visits"
  />
)

export const NoRewardedScans = () => (
  <NoItems
    message="No Previous Rewarded Scans Found"
    href="https://support.coinapp.co/hc/en-us/articles/360058812333-Rewarded-Scans"
    buttonText="Read more about rewarded scans"
  />
)
