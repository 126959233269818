import { useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { FlexCol, FlexRow } from '../../../components/Flex'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { CustomList, CustomListHeader } from '../../../components'
import CustomBasePage from '../../../CustomBasePage'
import { redeemOptionsQuery } from '../../../store'
import RedeemHistory, { VerifyAddressHistoryDialog } from './RedeemHistory'
import RedeemOptions from './RedeemOptions'

export type RedeemTab = 'direct' | 'sweepstakes'
interface Props {
  initialTab?: RedeemTab
}

const RedeemBody: React.FC<React.PropsWithChildren<Props>> = ({ initialTab = 'direct' }) => {
  return (
    <CustomBasePage>
      <FlexCol marginY={2} alignItems="stretch">
        <RedeemTabView initialTab={initialTab} />
        <RedeemHistory marginY={2} />
        <VerifyAddressHistoryDialog />
      </FlexCol>
    </CustomBasePage>
  )
}

const RedeemTabView: React.FC<React.PropsWithChildren<Props>> = ({ initialTab }) => {
  const [activeTab, setActiveTab] = useState(initialTab)
  const { data, loading } = useQuery(redeemOptionsQuery, {
    errorPolicy: 'all',
  })
  return (
    <CustomList
      header={
        <CustomListHeader title="Redeem Options">
          <FlexRow>
            <Button
              sx={{ mx: 1 }}
              onClick={() => setActiveTab('direct')}
              variant={activeTab === 'direct' ? 'contained' : 'text'}
              component={Link}
              to="/redeem"
            >
              Direct
            </Button>
            <Button
              sx={{ mx: 1 }}
              onClick={() => setActiveTab('sweepstakes')}
              variant={activeTab === 'sweepstakes' ? 'contained' : 'text'}
              component={Link}
              to="/sweepstakes"
            >
              Sweepstakes
            </Button>
          </FlexRow>
        </CustomListHeader>
      }
    >
      <RedeemOptions filter={activeTab} loading={loading} data={data} />
    </CustomList>
  )
}

export default RedeemBody
