import { Suspense } from 'react'

import { BossProvider } from '../../context'
import Loader from '../Loader'
import BalanceDisplayProps from './BalanceDisplayProps'
import BalancesDisplay from './BalancesDisplay'

const Balances: React.FC<React.PropsWithChildren<BalanceDisplayProps>> = (props) => {
  return (
    <BossProvider>
      <Suspense fallback={<Loader loading={true} />}>
        <BalancesDisplay {...props} />
      </Suspense>
    </BossProvider>
  )
}

export default Balances
