import { FlexRow } from '../../../components/Flex'
import { FaCircle } from 'react-icons/fa'

import NoItemsPage from '../../../NoItems'
import { addDays, fromYYYYMMDD } from '../../../utils'

const ClaimCampaignTitle = ({ campaign }: Props) => {
  const start = fromYYYYMMDD(campaign)
  const end = addDays(start, 6)
  return (
    <FlexRow>
      {end.valueOf() > Date.now() ? <FaCircle /> : ''}
      &nbsp;
      {start.toLocaleDateString()}
    </FlexRow>
  )
}

interface Props {
  campaign: number
}

const NoGeoClaims: React.FC<React.PropsWithChildren<Props>> = ({ campaign }) => (
  <NoItemsPage
    message="No Claims Found For Campaign"
    href="https://support.coinapp.co/hc/en-us/categories/360002104613"
    buttonText="Read more about geoclaiming"
  >
    <ClaimCampaignTitle campaign={campaign} />
  </NoItemsPage>
)

export default NoGeoClaims
