import { FlexRow } from '../../components/Flex'

import { CheckoutApolloProvider, UpgradeModal } from '../../checkout'
import { FadeIn, UserTotalsDashboard } from '../../components'
import CustomBasePage from '../../CustomBasePage'
import { MySubscriptionDisplay, SubscriptionDetails } from '../../Subscriptions'
import UserInfoDisplay from '../../UserInfoDisplay'

const AccountPage = () => {
  return (
    <CheckoutApolloProvider>
      <CustomBasePage>
        <FlexRow paddingBottom={4}>
          <FadeIn>
            <UserInfoDisplay>
              <SubscriptionDetails />
            </UserInfoDisplay>
          </FadeIn>
        </FlexRow>

        <FlexRow marginBottom={3}>
          <MySubscriptionDisplay />
        </FlexRow>
        <UserTotalsDashboard />
        <UpgradeModal />
      </CustomBasePage>
    </CheckoutApolloProvider>
  )
}

export default AccountPage
