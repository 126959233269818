import coinLogo from '../assets/CoinNoText.svg'
import { StartFreeTrial } from '../checkout'
import CustomBasePage from '../CustomBasePage'

const FreeTrialTest = () => {
  return (
    <CustomBasePage>
      <div className="row">
        <div className="col-12 text-center mb-5">
          <img style={{ height: 120 }} alt="COIN Logo" src={coinLogo} />
        </div>
        <div className="col-12 text-center mt-4">
          <h1 style={{ fontWeight: 100 }}>GET PAID TO EXPLORE THE WORLD!</h1>
          <StartFreeTrial />
        </div>
      </div>
    </CustomBasePage>
  )
}

export default FreeTrialTest
