import { FlexCol } from '../../../components/Flex'

import { BossProvider } from '../../../context'
import CustomBasePage from '../../../CustomBasePage'
import BackgroundRewardsList from './List'

const BackgroundRewardsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <BossProvider>
      <CustomBasePage>
        <FlexCol marginY={2} alignItems="stretch">
          <BackgroundRewardsList />
        </FlexCol>
      </CustomBasePage>
    </BossProvider>
  )
}

export default BackgroundRewardsPage
