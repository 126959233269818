import { CSSProperties, ReactNode } from 'react'
import { animated, useSpring } from 'react-spring'
export { config } from 'react-spring'

interface Config {
  tension: number
  friction: number
}

interface RunOnEnterParams {
  delay?: number
  config?: Config
  children?: ReactNode
  style?: CSSProperties
}

const runOnEnter =
  (from: CSSProperties, to: CSSProperties) =>
  ({ delay, config, children, style = {} }: RunOnEnterParams) => {
    const springStyle = useSpring({ config, delay, from, to })
    return <animated.div style={{ ...style, ...springStyle }}>{children}</animated.div>
  }

export const FadeIn = runOnEnter({ opacity: 0 }, { opacity: 1 })
export const SlideInUp = runOnEnter({ opacity: 0, transform: 'translate3d(0, 100%, 0)' }, { opacity: 1, transform: 'translate3d(0, 0, 0)' })
export const SlideInDown = runOnEnter({ opacity: 0, transform: 'translate3d(0, -100%, 0)' }, { opacity: 1, transform: 'translate3d(0, 0, 0)' })
export const SlideInLeft = runOnEnter({ opacity: 0, transform: 'translate3d(100%, 0, 0)' }, { opacity: 1, transform: 'translate3d(0, 0, 0)' })
export const SlideInRight = runOnEnter({ opacity: 0, transform: 'translate3d(-100%, 0, 0)' }, { opacity: 1, transform: 'translate3d(0, 0, 0)' })
