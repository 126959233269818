import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import CustomBasePage from '../CustomBasePage'

const getInviteRedirectUri = (id = '') => {
  // eslint-disable-next-line no-undef
  return (process.env.REACT_APP_INVITE_REDIRECT || '').replace('[id]', id)
}

export default () => {
  const { id } = useParams()

  useEffect(() => {
    // window.location.href = getInviteRedirectUri(id)
    setTimeout(() => {
      window.location.href = getInviteRedirectUri(id)
    }, 1000)
  }, [id])

  return (
    <CustomBasePage>
      <h4 className="d-flex align-items-center justify-content-center">
        Redirecting <span className="spinner-border spinner-border-sm ml-2" />
      </h4>
      <h4>
        <small>
          If you are not redirected automatically please <a href={getInviteRedirectUri(id)}>click here</a>.
        </small>
      </h4>
    </CustomBasePage>
  )
}
