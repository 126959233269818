import { BoxProps, Typography } from '@mui/material'
import { FlexGrowCol, FlexRow } from '../../Flex'
import numeral from 'numeral'
import React from 'react'

export interface StatCardSummaryProps extends BoxProps {
  format?: string
  value?: number | string
}

export const StatCardSummary: React.FC<React.PropsWithChildren<StatCardSummaryProps>> = (props) => {
  const { title, value, children, format = '0.0a', ...boxProps } = props
  return (
    <FlexGrowCol alignItems="stretch" justifyContent="flex-start" padding={1} {...boxProps}>
      <FlexRow justifyContent="flex-start" overflow="hidden" marginX={0.5}>
        <Typography variant="h1">{value !== undefined ? (format ? numeral(value).format(format) : value) : '- -'}</Typography>
      </FlexRow>
      <FlexRow justifyContent="flex-start" overflow="hidden" marginX={1} minHeight={24}>
        <Typography variant="subtitle2">{title}</Typography>
      </FlexRow>
      {children}
    </FlexGrowCol>
  )
}
