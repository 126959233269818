import { Box, CardActions, CardHeader, Dialog, DialogContent, DialogContentText, Divider } from '@mui/material'

import { LowercaseButton } from '../../componentsPure'
import { makeContext } from '../../utils'
import { sweepstakesEvent } from './event'

export const SweepstakesFreeEntryDialogOpen = makeContext(false, 'free_entry_dialog_open')

export const SweepstakesFreeEntryButton = () => {
  const setOpen = SweepstakesFreeEntryDialogOpen.useContext()[1]
  const onOpen = () => {
    sweepstakesEvent('freeEntry', 'pressed', {})
    setOpen(true)
  }
  return (
    <LowercaseButton
      style={{ fontWeight: 'normal', opacity: 0.8 }}
      onClick={onOpen}
      variant="outlined"
      size="small"
      color="inherit"
      fullWidth
    >
      Alternative way to enter
    </LowercaseButton>
  )
}

export const SweepstakesFreeEntryDialog = () => {
  const [open, setOpen] = SweepstakesFreeEntryDialogOpen.useContext()
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <CardHeader title="Sweepstakes Free Entries" />
      <Divider />
      <DialogContent style={{ WebkitOverflowScrolling: 'touch', overflowY: 'auto' }}>
        <DialogContentText>
          You may receive one hundred entries for each valid postcard mailed to us for any active Promotion.
        </DialogContentText>
        <DialogContentText>A valid postcard consists of:</DialogContentText>
        <DialogContentText component="div">
          <ul>
            <li>your printed name</li>
            <li>age</li>
            <li>complete mailing address</li>
            <li>a valid telephone number</li>
            <li>a valid email address</li>
            <li>the prize</li>
            <li>drawing date</li>
          </ul>
        </DialogContentText>
        <DialogContentText>
          all legibly printed on a 3.5&quot; x 5&quot; card. This card must be mailed, with first-class postage affixed,
          to XY Labs, 740 13th Street #224, San Diego, CA 92101.
        </DialogContentText>
        <DialogContentText style={{ lineHeight: 1 }}>
          <small>
            The card must be received and given due time to process during the Promotion Period for the Promotion so
            identified. If received during Promotion Period but not processed in time, the entry will be automatically
            applied to a future Promotion if one exists. It is the responsibility of the entrant to know and comply with
            the rules of their locale and our terms and conditions for the Promotion.
          </small>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <CardActions>
        <Box flexGrow={1} />
        <LowercaseButton variant="outlined" onClick={() => setOpen(false)}>
          Close
        </LowercaseButton>
      </CardActions>
    </Dialog>
  )
}
