export const localJSONStore = <V = unknown>(key: string) => {
  const to = (value: V) => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      return
    }
  }
  const from = (): V | null => {
    try {
      const data = localStorage.getItem(key)
      return data ? JSON.parse(data) : null
    } catch (e) {
      return null
    }
  }
  const rm = () => {
    try {
      localStorage.removeItem(key)
    } catch (e) {
      return
    }
  }
  return { from, rm, to }
}
