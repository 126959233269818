import { SVGProps } from 'react'

export const LiteIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 160 208"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>liteUser</title>
      <path
        fill="#fff"
        d="M64,0 C59.581722,0 56,3.581722 56,8 C56,48.456 0,80 0,136 C0,178.542688 46.107968,206.04752 62.343752,207.828128 C62.8883531,207.942875 63.4434418,208 64,208 C68.418278,208 72,204.418278 72,200 C71.9987808,197.593353 70.9141839,195.315127 69.046872,193.796872 C69.0468771,193.791664 69.0468771,193.786456 69.046872,193.781248 C61.766872,187.877248 48,170.749376 48,155.109376 C48,129.437376 72,120 72,120 C58.312,158.72 103.163496,163.398376 112.187504,201.734376 L112.203128,201.734376 C113.015068,205.39049 116.254819,207.993978 120,208 C121.684758,207.995901 123.325219,207.460018 124.687504,206.468752 C124.831743,206.364105 124.972446,206.254668 125.109376,206.140624 C126.411344,205.29508 160,183.080192 160,136 C160,116.779072 150.386656,83.764016 143.468752,69.140624 C143.46304,69.128544 143.458832,69.1058 143.453128,69.093752 C143.447943,69.083322 143.442735,69.0729033 143.437504,69.062496 C142.231991,66.0087348 139.283096,64.0015062 136,64 C132.145004,64.0036352 128.840848,66.7560026 128.140624,70.546872 C128.140604,70.5572907 128.140604,70.5677093 128.140624,70.578128 C128.12108,70.66556 124.446608,86.984184 112,96 C112,58.306932 84.786904,19.3729072 70.437504,3.2656248 C70.2221629,2.97397014 69.9872455,2.69729078 69.734376,2.4375 C68.2308855,0.883413975 66.1623251,0.00413504013 64,0 Z"
        id="liteUser"
      ></path>
    </svg>
  )
}
