import { useAllCurrentSweepstakeOptionsQuery } from '@coin-microservices/sweepstakes-react-hooks'
import { FlexCol } from '../../Flex'
import times from 'lodash/times'

import EntryDialog from '../EntryDialog'
import { SweepstakesOptionsContext } from '../options'
import SweepstakesListItem from './SweepstakesListItem'

const CurrentSweepstakesListItems = () => {
  const optionsQuery = useAllCurrentSweepstakeOptionsQuery()
  const options = optionsQuery?.data?.allCurrentSweepstakeOptions
  const count = options?.length ?? 8

  return (
    <FlexCol alignItems="stretch">
      {times(count).map((_, i) => (
        <SweepstakesOptionsContext.Provider value={options?.[i] ?? null} key={options?.[i]?.id ?? i}>
          <SweepstakesListItem />
        </SweepstakesOptionsContext.Provider>
      ))}

      <EntryDialog />
    </FlexCol>
  )
}

export default CurrentSweepstakesListItems
