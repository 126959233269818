import 'firebase/storage'

import { Circle, Shield } from '@mui/icons-material'
import { Box, BoxProps, CircularProgress, Tooltip } from '@mui/material'
import { ChangeEventHandler } from 'react'

import coinLogo from '../../assets/defaultProfilePic.jpg'
import { PlanIcon } from '../../components'
import AvatarEx from './AvatarEx'
import { getProfileTierStyles } from './profileTierStyles'

export interface ProfileImageProps extends BoxProps {
  kycComplete?: boolean
  profileImage?: string
  plan?: string
  loading?: boolean
  hidePlanIcon?: boolean
  profileMultiplier?: number
}

const ProfileImage: React.FC<React.PropsWithChildren<ProfileImageProps>> = ({
  profileImage,
  kycComplete,
  plan,
  loading,
  hidePlanIcon,
  children,
  profileMultiplier,
  ...props
}) => {
  return (
    <Box {...props} sx={{ position: 'relative', ...props.sx }}>
      <Box style={getProfileTierStyles(profileMultiplier)}>
        <AvatarEx sx={{ height: 80, width: 80 }} variant="circular" src={profileImage} defaultSrc={coinLogo} />
      </Box>
      {loading ? <FullCircularProgress /> : null}
      {children}
      {loading || hidePlanIcon ? null : (
        <Box sx={{ bottom: '-14%', position: 'absolute', right: '-14%' }}>
          <VerifiedUserPlanIcon plan={plan || 'Basic'} kycComplete={kycComplete} />
        </Box>
      )}
    </Box>
  )
}

const FullCircularProgress = () => {
  return (
    <CircularProgress
      style={{
        height: '100%',
        left: 0,
        opacity: 0.5,
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
    />
  )
}

const VerifiedUserPlanIcon = ({ plan, kycComplete }: { plan?: string; kycComplete?: boolean }) => {
  const planIcon = (
    <PlanIcon
      plan={plan || 'Basic'}
      style={{
        bottom: 0,
        height: '60%',
        left: 0,
        margin: 'auto',
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    />
  )

  if (kycComplete) {
    return (
      <Tooltip title="Identity verified" placement="bottom">
        <Box position="relative" display="flex">
          <Shield fontSize="large" color="secondary" />
          {planIcon}
        </Box>
      </Tooltip>
    )
  }

  return (
    <Box position="relative" display="flex">
      <Circle fontSize="large" color="secondary" />
      {planIcon}
    </Box>
  )
}

export default ProfileImage
