import gql from 'graphql-tag'

const AUDIO_ADS = gql`
  query VideoAdsHistoryList($page: Int, $perPage: Int, $field: String) {
    audioAdsHistoryList(pagination: { page: $page, perPage: $perPage }, sort: { field: $field, order: -1 }) {
      total
      page
      data {
        ...AudioAdsHistory
      }
    }
  }

  fragment AudioAdsHistory on AudioAdsHistory {
    _id
    uid
    amount
    clicked
    xyoCollected
    audioAdsHeard
    audioAdsClicked
    audioAdsCollected
    date
    yyyymmdd
  }
`

export { AUDIO_ADS }
