import gql from 'graphql-tag'

const DROP_FRAGMENT = (name: string) => gql`
fragment ${name} on ${name} {
    id
    dropUid
    dropTime
    dropYYYYMMDD
    dropXyoCollected
    quadkey
    fullQuadkey
    latitude
    longitude
    lonlat
    email
    asset
    amount
    fee
    title
    message
    claimed
    collected
    collectedXyoCollected
    receiptDelivered
    claimedTime
    claimedUid
    collectedTime
    receiptMessage
    receiptTime
  }`

const DROPS = gql`
  query UserDrops($page: Int, $perPage: Int, $field: String, $filter: String) {
    drops(filter: $filter, pagination: { page: $page, perPage: $perPage }, sort: { field: $field, order: -1 }) {
      total
      page
      data {
        ...Drop
      }
    }
  }

  ${DROP_FRAGMENT('Drop')}
`

const DROPS_HISTORY = gql`
  query UserDropsHistory($page: Int, $perPage: Int, $field: String, $filter: String) {
    dropsHistory(filter: $filter, pagination: { page: $page, perPage: $perPage }, sort: { field: $field, order: -1 }) {
      total
      page
      data {
        ...DropHistory
      }
    }
  }

  ${DROP_FRAGMENT('DropHistory')}
`

export { DROPS, DROPS_HISTORY }
