import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material'
import { ChangeEventHandler, ReactNode, useState } from 'react'

const defaultTypeFilter = ['initial', 'renewal']
const defaultStatusFilter = ['past-due', 'paid', 'unpaid', 'canceled', 'refunded']

const pageQueryOptions = {
  page: 1,
  perPage: 10,
}

const invoiceQueryOptions = {
  ...pageQueryOptions,
  status: defaultStatusFilter,
  type: defaultTypeFilter,
}

export const useInvoicesQueryParams = () => {
  const [query, setQuery] = useState(invoiceQueryOptions)
  const setStatusFilter = (ev: SelectChangeEvent<string[]>, _child: ReactNode) => {
    const page = 1
    const status = ([] as string[]).concat(ev.target.value)
    setQuery({ ...query, page, status })
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    const page = newPage + 1
    setQuery({ ...query, page })
  }
  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (event) => {
    const page = 1
    const perPage = parseInt(event.target.value, 10)
    setQuery({ ...query, page, perPage })
  }
  return {
    handleChangePage,
    handleChangeRowsPerPage,
    query,
    setQuery,
    setStatusFilter,
  }
}

export function usePageQueryParams() {
  const [query, setQuery] = useState(pageQueryOptions)
  const handleChangePage = (event: unknown, newPage: number) => {
    const page = newPage + 1
    setQuery({ ...query, page })
  }
  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (event) => {
    const page = 1
    const perPage = parseInt(event.target.value, 10)
    setQuery({ ...query, page, perPage })
  }
  return {
    handleChangePage,
    handleChangeRowsPerPage,
    query,
    setQuery,
  }
}

export const InvoiceStatusFilter = ({ value, onChange }: SelectProps<string[]>) => {
  return (
    <FormControl variant="filled" margin="normal" size="small">
      <InputLabel id="invoice-status-filter-label">Status</InputLabel>
      <Select
        variant="standard"
        multiple
        labelId="invoice-status-filter-label"
        id="invoice-status-filter"
        value={value}
        onChange={onChange}
        className="ml-2"
        label="Status"
      >
        <MenuItem value={'paid'}>Paid</MenuItem>
        <MenuItem value={'unpaid'}>Unpaid</MenuItem>
        <MenuItem value={'past-due'}>Past Due</MenuItem>
        <MenuItem value={'canceled'}>Canceled</MenuItem>
        <MenuItem value={'refunded'}>Refunded</MenuItem>
      </Select>
    </FormControl>
  )
}

export const InvoiceTypeFilter = ({ value, onChange }: SelectProps<string>) => {
  return (
    <FormControl variant="filled">
      <InputLabel id="invoice-type-filter-label">Type</InputLabel>
      <Select
        variant="standard"
        multiple
        labelId="invoice-type-filter-label"
        id="invoice-type-filter"
        value={value}
        onChange={onChange}
        className="ml-2"
        label="Type"
      >
        <MenuItem value={'initial'}>Initial</MenuItem>
        <MenuItem value={'renewal'}>Renewal</MenuItem>
      </Select>
    </FormControl>
  )
}
