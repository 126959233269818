export const fromNanoseconds = (date?: null | number | string | Date | { seconds: number; nanoseconds: number }) => {
  if (!date) return new Date()
  if (date instanceof Date) return date
  if (typeof date === 'object' && date.seconds) return new Date((date.seconds + date.nanoseconds * 1e-9) * 1000)
  if (!isNaN(Number(date))) return new Date(Number(date))
  if (typeof date === 'string') return new Date(date)
  return new Date()
}

export const toYYYYMMDD = (date: Date) => {
  return Number(date.toISOString().slice(0, 10).replace(/-/g, ''))
}

export const fromYYYYMMDD = (date: number) => {
  const year = String(date).substring(0, 4)
  const month = String(date).substring(4, 6)
  const day = String(date).substring(6, 8)
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
}

export const DAY_MS = 1000 * 60 * 60 * 24

export function daysAgo(date: Date) {
  if (!date) return 0
  return Math.floor((Date.now() - date.valueOf()) / DAY_MS)
}

export const subTime = (date: Date, time: number) => {
  return new Date(date.valueOf() - time)
}

export function getCurrentCampaign() {
  const start = getCurrentCampaignStart()
  return toYYYYMMDD(start)
}

export function getPreviousCampaign() {
  const lastWeek = addDays(new Date(), -7)
  setUTCMonday(lastWeek)
  return toYYYYMMDD(lastWeek)
}

export function getCurrentCampaignStart() {
  const today = new Date()
  return setUTCMonday(today)
}

export function getCurrentCampaignEnd() {
  const nextWeek = addDays(new Date(), 7)
  return setUTCMonday(nextWeek)
}

export function addDays(date: Date, days = 0) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + days)
}

function setUTCMonday(date: Date) {
  const day = date.getUTCDay() || 7 // Get current day number, converting Sun. to 7
  if (day !== 1) {
    // Only manipulate the date if it isn't Mon.
    date.setUTCHours(-24 * (day - 1), 0, 0, 0) // Set the hours to day number minus 1
  } else {
    date.setUTCHours(0, 0, 0, 0)
  }

  return date
}
