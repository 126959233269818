import { sweepstakesUri } from '@coin-microservices/sweepstakes-react-hooks'

import { makeApolloAuthProvider } from '../../../context'
import { makeContext } from '../../../utils'
import { residentStorage } from './storage'

const uri = sweepstakesUri || 'http://localhost:4000'

export const SweepstakesApolloProvider = makeApolloAuthProvider(uri)

export const SweepstakesEntryStep = makeContext(0, 'entry_step')

export const SweepstakesSize = makeContext('', 'size')

export const SweepstakesEntryCount = makeContext(0, 'entry_count')

export const SweepstakesEntryTermsAgreed = makeContext(false, 'entry_terms_agreed')

export const SweepstakesEntryEligibilityAgreed = makeContext(residentStorage.get(), 'entry_eligibility_agreed')

export const SweepstakesEntryConfirm = makeContext(false, 'entry_confirm')

export const SweepstakesEntryDialogOpen = makeContext(false, 'entry_dialog_open')

export const SweepstakesHistoryOpen = makeContext(false, 'history_open')
