import { useAllCurrentSweepstakeOptionsQuery, useUpsertSweepstakeEntriesMutation } from '@coin-microservices/sweepstakes-react-hooks'
import { Box, AlertTitle } from '@mui/material'

import { SweepstakesConfirmButton, SweepstakesConfirmDetails } from '../Confirm'
import { SuccessButton, SweepstakesBackButton, SweepstakesEntryButtons, SweepstakesEntryList } from './Buttons'
import { SweepstakesEntryCount, SweepstakesEntryDialogOpen, SweepstakesEntryEligibilityAgreed, SweepstakesEntryStep, SweepstakesEntryTermsAgreed } from './context'
import { sweepstakesEvent } from './event'
import { SweepstakesFreeEntryButton } from './FreeEntry'
import { SweepstakesDisclaimer, SweepstakesTermsAgreement } from './Terms'
import { refetchAccounts, useUserInfo } from '../../User'
import { LowercaseButton } from '../../componentsPure'
import { useSnackbar } from '../Snackables'
import { parseError } from '../../utils'
import { useSweepstakesOptions, useSweepstakesOptionsAmountPerEntry } from './options'
import { getDiscountedTotal } from './utils'

export const EnterStepActions = () => {
  return (
    <Box px={2} pb={1}>
      <SweepstakesEnterActions />
    </Box>
  )
}

export const EntryCountStepActions = () => {
  return (
    <Box pb={1}>
      <SweepstakesEntryList />
    </Box>
  )
}

export const EntryConfirmStepActions = () => {
  return (
    <Box pb={1}>
      <Box display="flex" pb={1} px={1}>
        <Box p={1}>
          <SweepstakesBackButton />
        </Box>
        <Box flexGrow={1}>
          <Box display="flex" flexGrow={1} alignItems="center">
            <SweepstakesConfirmDetails />
            <SweepstakesConfirmButton />
          </Box>
        </Box>
      </Box>
      <Box px={2} pt={2} textAlign="center">
        <SweepstakesDisclaimer />
      </Box>
    </Box>
  )
}

function previewBalance(userInfo: { xyoCollected?: number | null } | undefined | null, spent: number) {
  return (userInfo?.xyoCollected ?? 0) - spent
}

export const EntryConfirmCancelActions = () => {
  const setDialogOpen = SweepstakesEntryDialogOpen.useContext()[1]
  const options = useSweepstakesOptions()
  const { userInfo } = useUserInfo()
  const amountPerEntry = useSweepstakesOptionsAmountPerEntry()
  const [entryCount] = SweepstakesEntryCount.useContext()
  const totalAmount = getDiscountedTotal(entryCount, amountPerEntry).total
  const remainingBalance = previewBalance(userInfo, totalAmount)
  const [termsAgreed] = SweepstakesEntryTermsAgreed.useContext()
  const [eligibilityAgreed] = SweepstakesEntryEligibilityAgreed.useContext()
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const [confirm, { loading }] = useUpsertSweepstakeEntriesMutation()
  const { setSnackbar } = useSnackbar()[1]

  const handleConfirm = async () => {
    try {
      sweepstakesEvent('confirm', 'pressed', { value: entryCount })
      await confirm({
        variables: {
          entryCount,
          id: options?.id ?? '',
          source: 'web',
        },
      })
      sweepstakesEvent('confirm', 'success', { value: entryCount })
      setSnackbar({
        autoHideDuration: 6000,
        message: (
          <>
            <AlertTitle>Success</AlertTitle>
            Purchase of {entryCount} {entryCount === 1 ? 'entry' : 'entries'} completed.
          </>
        ),
        severity: 'success',
      })
      setDialogOpen(false)
      setStep(step - 1)
      await refetchAccounts()
    } catch (error) {
      const message = parseError(error)
      sweepstakesEvent('confirm', 'error', { value: message })
      setSnackbar({
        autoHideDuration: 6000,
        message,
        severity: 'error',
      })
    }
  }

  return <Box display="flex" gap={1} py={2}>

    <LowercaseButton 
      variant="outlined" 
      size="small" 
      color="inherit" 
      fullWidth
      style={{ fontWeight: 'normal' }}
      onClick={() => setDialogOpen(false)}
    >Cancel</LowercaseButton>

    <SuccessButton
      variant="contained"
      color="secondary"
      size="small"
      disabled={!termsAgreed || eligibilityAgreed !== 'yes' || !entryCount || loading || remainingBalance < 0}
      onClick={handleConfirm}
      disableElevation
      fullWidth
    >Confirm</SuccessButton>
    
  </Box>
}

const SweepstakesEnterActions = () => {
  const { loading } = useAllCurrentSweepstakeOptionsQuery()
  const [agreed] = SweepstakesEntryTermsAgreed.useContext()
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const onEnter = () => {
    sweepstakesEvent('enter', 'pressed', {})
    setStep(step + 1)
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <SweepstakesTermsAgreement />
      <SuccessButton
        disabled={!agreed || loading}
        variant="contained"
        color="secondary"
        onClick={onEnter}
        disableElevation
      >
        Enter
      </SuccessButton>
    </Box>
  )
}
