import { useAllCurrentSweepstakeOptionsQuery } from '@coin-microservices/sweepstakes-react-hooks'
import { MouseEventHandler } from 'react'

import { SuccessButton } from '../Buttons'
import { getWinner, useSweepstakesOptions, useSweepstakesOptionsEnded } from '../options'

const SweepstakesEnterButton = ({
  size,
  onClick,
  ...props
}: {
  size?: string
  onClick?: MouseEventHandler
  loading?: boolean
}) => {
  const allSweeps = useAllCurrentSweepstakeOptionsQuery()
  const options = useSweepstakesOptions()
  const ended = useSweepstakesOptionsEnded()
  const winner = getWinner(options)
  const backgroundColor = winner?.confirmed ? '#EE7268' : ended ? '#8B8B8B' : ''

  return (
    <SuccessButton
      onClick={onClick}
      variant="contained"
      color={'secondary'}
      disableElevation
      disabled={props.loading || allSweeps.loading}
      size={size === 'small' ? 'small' : 'medium'}
      style={size === 'small' ? { backgroundColor, minWidth: 50, padding: 0 } : { backgroundColor }}
    >
      {winner?.confirmed ? 'Results' : ended ? 'Ended' : 'Enter'}
    </SuccessButton>
  )
}

export default SweepstakesEnterButton
