import { ExitToApp, ExpandMore } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material'
import { buildComparisonWebSvg, buildWebSvg, WebSvgMaxType } from '@xylabs/pick-axe-components'
import { MouseEvent, useMemo, useState } from 'react'

import { LoadingButton } from '../Buttons'
import { CrossPlatform } from '../CrossPlatform'
import { Enhancements } from './Enhancements'
import { OpenSeaIcon } from './OpenSeaIcon'
import { checkIsMinting, usePickaxeCard } from './PickaxeCardProvider'
import { PolygonIcon } from './PolygonIcon'
import { useEquipPickaxe, useUnequipPickaxe } from './usePickaxes'

const CONTRACT_ADDRESS = '0x4670185b40096bf6F327B0461119863b7F38BCf2'

const PickaxeCard: React.FC<React.PropsWithChildren> = () => {
  const { pickaxe } = usePickaxeCard()
  const [open, setOpen] = useState(false)
  const toggle = (event: MouseEvent) => {
    event.stopPropagation()
    setOpen(!open)
  }

  return (
    <Card>
      {/* {isMinting ? <LinearProgress /> : null} */}
      <CardHeader
        onClick={toggle}
        avatar={<PickaxeAvatar />}
        action={
          <Box display="flex" alignItems="center">
            <IconButton onClick={toggle}>
              <ExpandMore
                style={{
                  opacity: 0.4,
                  transform: `rotate3d(${open ? 180 : 0}, 0, 0)`,
                  transition: 'all 0.3s ease-in',
                }}
              />
            </IconButton>
          </Box>
        }
        title={<PickaxeTitle />}
        subheader={<PickaxeSubheader />}
        titleTypographyProps={{ noWrap: true }}
        subheaderTypographyProps={{ noWrap: true }}
      />
      <Collapse in={open}>
        <Divider />
        <CardContent style={{ backgroundColor: 'rgba(0,0,0,0.2)', position: 'relative' }}>
          <PickaxeInfoLinks />
          <Box display="flex" flexWrap="wrap" justifyContent="center" gap={1}>
            <Box flex="300px 1 0" maxWidth="500px">
              <PickaxeMainView />
            </Box>
            <Box flex="300px 1 0" maxWidth="500px">
              <PickaxeWebSvgView />
            </Box>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export const PickaxeAvatar = () => {
  const { pickaxe } = usePickaxeCard()
  return pickaxe ? (
    <Avatar variant="square" title={pickaxe?.uniqueId} src={pickaxe?.thumb}>
      {pickaxe?.uniqueId}
    </Avatar>
  ) : (
    <Skeleton variant="rectangular" width={40} height={40} />
  )
}

export const PickaxeTitle = () => {
  const { pickaxe, pickaxeEquipped } = usePickaxeCard()
  if (!pickaxe) return <Skeleton variant="rectangular" style={{ marginBottom: 4 }} />

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
      {pickaxe?.uniqueId ?? ''}
      {pickaxe?.minted ? <Chip size="small" label="Minted" /> : null}
      {checkIsMinting(pickaxe) ? <Chip size="small" label="Minting" avatar={<CircularProgress style={{ height: '2em', width: '2em' }} />} /> : null}
      {pickaxe?.uniqueId === pickaxeEquipped ? <Chip size="small" label="Equipped" color="secondary" /> : null}
      {pickaxe?.merged ? <Chip size="small" label="Merged" color="primary" /> : null}
      {pickaxe?.forged ? <Chip size="small" label="Forged" color="info" /> : null}
    </Box>
  )
}

export const PickaxeSubheader = () => {
  const { pickaxe } = usePickaxeCard()
  if (!pickaxe) return <Skeleton variant="rectangular" width="80%" />
  if (pickaxe.claimedTime) {
    const ts = Number(pickaxe.claimedTime)
    return <>{new Date(!isNaN(ts) ? ts : pickaxe.claimedTime).toLocaleString()}</>
  }

  return null
}

export const PickaxeMainView = () => {
  const { pickaxe } = usePickaxeCard()

  return (
    <>
      <CardMedia
        style={{
          // 16:9
          backgroundSize: 'contain',
          height: 0,
          paddingTop: '100%',
        }}
        image={pickaxe?.image}
        title={pickaxe?.uniqueId}
      >
        {pickaxe?.image ? null : <Skeleton variant="rectangular" width="100%" height="100%" />}
      </CardMedia>
      <Enhancements />
      <PickaxeActionButton />
    </>
  )
}

const PickaxeWebSvgView = () => {
  const { pickaxe, pickaxeEquipped } = usePickaxeCard()

  const [skillWeb, setSkillWeb] = useState<WebSvgMaxType | 'compare'>('localMax')

  const webSvg = useMemo(() => {
    let svg = ''
    if (skillWeb === 'compare') {
      svg = pickaxe?.uniqueId && pickaxeEquipped ? buildComparisonWebSvg(pickaxeEquipped, pickaxe?.uniqueId) : ''
    } else {
      svg = pickaxe?.uniqueId ? buildWebSvg(pickaxe?.uniqueId, skillWeb) : ''
    }
    return Buffer.from(svg).toString('base64')
  }, [pickaxe?.uniqueId, pickaxeEquipped, skillWeb])

  return (
    <>
      <CardMedia
        style={{
          // 16:9
          backgroundSize: 'contain',
          flex: 1,
          height: 0,
          paddingTop: '100%',
        }}
        image={webSvg ? `data:image/svg+xml;base64,${webSvg}` : ''}
        title={pickaxe?.uniqueId}
      >
        {webSvg ? null : <Skeleton variant="rectangular" width="100%" height="100%" />}
      </CardMedia>
      <Box display="flex" gap={2}>
        <Button variant={skillWeb === 'localMax' ? 'contained' : 'outlined'} fullWidth onClick={() => setSkillWeb('localMax')}>
          {pickaxe?.tierName}
        </Button>
        <Button variant={skillWeb === 'globalMax' ? 'contained' : 'outlined'} fullWidth onClick={() => setSkillWeb('globalMax')}>
          Global
        </Button>
        {pickaxeEquipped && pickaxe?.uniqueId !== pickaxeEquipped ? (
          <Button variant={skillWeb === 'compare' ? 'contained' : 'outlined'} fullWidth onClick={() => setSkillWeb('compare')}>
            Compare
          </Button>
        ) : null}
      </Box>
    </>
  )
}

const PickaxeActionButton = () => {
  const { pickaxe, pickaxeEquipped, refetch } = usePickaxeCard()
  const [handleEquipPickaxe, equipping] = useEquipPickaxe(pickaxe?.uniqueId, { onSuccess: refetch })
  const [handleUnequipPickaxe, unequipping] = useUnequipPickaxe(pickaxe?.uniqueId, { onSuccess: refetch })

  if (!pickaxe) return null

  if (!pickaxe.minted) {
    return (
      <CrossPlatform
        mobile={
          <Tooltip title={<Typography>You will be redirected to the COIN application in order to mint your pickaxe.</Typography>}>
            <Button fullWidth href="coinapp://account" rel="noopener noreferrer" target="_blank">
              Mint Pickaxe <ExitToApp sx={{ ml: 1 }} />
            </Button>
          </Tooltip>
        }
        desktop={
          <Tooltip title={<Typography>Open the COIN App in order to mint this pickaxe</Typography>}>
            <Box>
              <Button fullWidth disabled>
                Mint Pickaxe
              </Button>
            </Box>
          </Tooltip>
        }
      />
    )
  }

  if (pickaxe.uniqueId === pickaxeEquipped) {
    return (
      <LoadingButton fullWidth onClick={handleUnequipPickaxe} loading={unequipping}>
        Unequip Pickaxe
      </LoadingButton>
    )
  }

  if (pickaxe.mergedUid === pickaxe.claimedUid) {
    return (
      <Tooltip title={<Typography>Merged pickaxes cannot be equipped</Typography>}>
        <Box>
          <Button fullWidth disabled>
            Equip Pickaxe
          </Button>
        </Box>
      </Tooltip>
    )
  }

  return (
    <LoadingButton fullWidth onClick={handleEquipPickaxe} loading={equipping}>
      Equip Pickaxe
    </LoadingButton>
  )
}

const PickaxeInfoLinks = () => {
  const { pickaxe } = usePickaxeCard()

  if (!pickaxe) return null
  if (!pickaxe.minted) return null

  return (
    <Box position="absolute" top={0} left={0} display="flex" alignItems="center" p={1}>
      <Tooltip title={<Typography>Polygonscan</Typography>}>
        <IconButton
          href={`https://polygonscan.com/token/${CONTRACT_ADDRESS}?a=${pickaxe.uniqueId}`}
          rel="noopener noreferrer"
          target="_blank"
          size="large"
        >
          <PolygonIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={<Typography>Open Sea</Typography>}>
        <IconButton
          href={`https://opensea.io/assets/matic/${CONTRACT_ADDRESS}/${pickaxe.uniqueId}`}
          rel="noopener noreferrer"
          target="_blank"
          size="large"
        >
          <OpenSeaIcon />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default PickaxeCard
