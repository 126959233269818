import { InternalRefetchQueriesInclude } from '@apollo/client'
import { AlertTitle, Button } from '@mui/material'
import gql from 'graphql-tag'
import qs from 'querystring'

import { verifyClient } from '../../context'
import { useUser } from '../../User'
import { parseError } from '../../utils'
import { useSnackbar } from '../Snackables'
import { readableNumber } from './balance'

const base = 'https://www.coinbase.com/oauth/authorize'
const scopes = [
  'wallet:accounts:read',
  'wallet:addresses:read',
  'wallet:buys:read',
  'wallet:sells:read',
  'wallet:deposits:read',
  'wallet:withdrawals:read',
  'wallet:transactions:read',
]
export const redirect_uri = `${window.location.protocol}//${window.location.host}`
const options = {
  account_currency: 'XYO',
  client_id: process.env.REACT_APP_COINBASE_CLIENT_ID,
  redirect_uri,
  response_type: 'code',
  scope: scopes.join(','),
  state: 'SECURE_RANDOM',
}

const getCoinbaseAuthUri = (state: string) => {
  // eslint-disable-next-line deprecation/deprecation
  return `${base}?${qs.stringify({ ...options, state })}`
}
const win = window as any
const getPopupOptions = ({ width = 600, height = 600 } = {}) => {
  const top = win.top.outerHeight / 2 + win.top.screenY - height / 2
  const left = win.top.outerWidth / 2 + win.top.screenX - width / 2
  const options = {
    height,
    left,
    resizable: 'no',
    scrollbars: 'no',
    top,
    width,
  }
  // eslint-disable-next-line deprecation/deprecation
  return qs.stringify(options, ',')
}

export const CoinbaseAuthButton = ({
  width = 600,
  height = 600,
  refetchQueries = [],
}: { width?: number; height?: number; refetchQueries?: InternalRefetchQueriesInclude } = {}) => {
  const { setSnackbar } = useSnackbar()[1]
  const { user } = useUser()
  if (!user) return null

  const url = getCoinbaseAuthUri(user.uid)
  async function handleOuathRedirect(variables: { code: string; state: string }) {
    try {
      setSnackbar({
        loading: true,
        message: (
          <>
            <AlertTitle>Linking</AlertTitle>
            Coinbase account link in progress.
          </>
        ),
        severity: 'info',
      })
      // await waitFor(2000)
      const { data } = await verifyClient.mutate({
        mutation: VERIFY_COINBASE_CODE,
        refetchQueries,
        variables,
      })
      const result = data.verifyCoinbaseCode
      setSnackbar({
        autoHideDuration: 6000,
        message: (
          <>
            <AlertTitle>Success</AlertTitle>
            {readableNumber(result.balanceHodl ?? 0)} {result.currency ?? ''}
          </>
        ),
        severity: 'success',
      })
    } catch (err) {
      const message = parseError(err)
      setSnackbar({
        autoHideDuration: 6000,
        message,
        severity: 'error',
      })
    }
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      target="popup"
      href={url}
      sx={{ textTransform: 'inherit' }}
      onClick={() => {
        ;(window as any)['handleCoinbaseOauth'] = handleOuathRedirect
        window.open(url, 'popup', getPopupOptions({ height, width }))
        return false
      }}
    >
      <span>Login with Coinbase</span>
    </Button>
  )
}

const VERIFY_COINBASE_CODE = gql`
  mutation verifyCoinbaseCode($code: String!, $state: String!, $redirect_uri: String!) {
    verifyCoinbaseCode(code: $code, uid: $state, redirect_uri: $redirect_uri) {
      currency
      balance
      balanceHodl
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function waitFor(t = 0) {
  return new Promise((r) => setTimeout(() => r(null), t))
}
