import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '../components/Flex'
import { useState } from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'

import { CheckoutApolloProvider } from '../checkout'
import CustomBasePage from '../CustomBasePage'
import { UpgradePlanToPro, useMySubscription } from '../Subscriptions'
import { useUser } from '../User'
import UserInfoDisplay from '../UserInfoDisplay'
import { addDays, fromNanoseconds } from '../utils'

const MasterUpgradeSteps = () => {
  const { user } = useUser()

  const { activeSubscription, userInfo } = useMySubscription()
  const [expanded, setExpanded] = useState<number>()
  if (!userInfo) return null
  const created = fromNanoseconds(userInfo.created)
  const threeMonths = addDays(created, 90)
  const steps = [
    {
      completed: activeSubscription === 'Pro' || activeSubscription === 'Master',
      more: activeSubscription === 'Pro' ? '' : activeSubscription === 'Plus' ? <UpgradePlanToPro /> : '',
      text: 'Account has activated COIN Pro',
    },
    {
      completed: Date.now() > threeMonths.valueOf(),
      more: `Account ${user?.email}, was created on ${created.toLocaleDateString()}.`,
      text: 'COIN Pro active for minimum of 3 months',
    },
    {
      completed: Date.now() > threeMonths.valueOf(),
      more: `Account ${user?.email}, was created on ${created.toLocaleDateString()}.`,
      text: 'Account upgraded to COIN Master',
    },
  ]
  const hasSteps = steps.some(({ completed }) => !completed)
  return (
    <CustomBasePage>
      <FlexRow>
        <UserInfoDisplay />
      </FlexRow>
      <FlexCol marginY={2} alignItems="stretch">
        {hasSteps && <h6>Unlock COIN Master</h6>}
        {steps.map(({ completed, text, more }, i) => (
          <Accordion
            key={text}
            expanded={!!more && expanded === i}
            onClick={() => more && setExpanded(expanded === i ? undefined : i)}
          >
            <AccordionSummary expandIcon={more && <ExpandMore />}>
              <FlexRow>
                {completed ? <FaCheck color="green" /> : <FaTimes color="red" />}
                <Typography variant="subtitle1" marginLeft={1}>
                  {text}
                </Typography>
              </FlexRow>
            </AccordionSummary>
            <AccordionDetails>{more}</AccordionDetails>
          </Accordion>
        ))}
      </FlexCol>
    </CustomBasePage>
  )
}

export default () => {
  return (
    <CheckoutApolloProvider>
      <MasterUpgradeSteps />
    </CheckoutApolloProvider>
  )
}
