import { Invoice, PaymentCard, TransactionDocument } from '@xyo-network/api-checkout-apollo'
import get from 'lodash/get'

import { MY_PAST_DUE_INVOICES, swalLoader } from '../../../components'
import { swalError, swalSuccess } from '../../../componentsPure'
import { checkoutClient } from '../../../lib'
import logger from '../../../Log'
import { refetchAccounts, useUser } from '../../../User'
import { parseError, trackPayEvent, trackWarning, forget } from '../../../utils'
import { redeemSubscription } from '../../lib'
import { useConfirmPaymentPrompt } from '../Upgrade'

const usePayInvoice = (cards: PaymentCard[]) => {
  const { user } = useUser()
  const confirmPaymentPrompt = useConfirmPaymentPrompt()
  const pay = async (invoice: Invoice) => {
    const invoiceId = get(invoice, 'id')
    const email = get(user, 'email')
    if (!invoiceId || !email) return null
    const card = await confirmPaymentPrompt(Promise.resolve(invoice), Promise.resolve(cards), 'Pay Invoice')
    if (!card || !(card as any).paymentCardId) {
      trackPayEvent('canceled', {})
      return null
    }
    try {
      trackPayEvent('confirmed', {})
      forget(swalLoader(<p>Paying Invoice...</p>))
      const result = await checkoutClient.mutate({
        mutation: TransactionDocument,
        variables: { invoiceId, paymentCardId: (card as any).paymentCardId },
      })
      logger().log('Redeem subscription', await redeemSubscription(email))
      logger().log(
        'Refetch account',
        await Promise.all([
          checkoutClient.query({
            fetchPolicy: 'network-only',
            query: MY_PAST_DUE_INVOICES,
          }),
          refetchAccounts(),
        ])
      )
      forget(swalSuccess('Transaction successful!'))
      return result?.data?.createTransaction
    } catch (e) {
      const error = e as Error
      trackWarning('Pay invoice error', error.message)
      trackPayEvent('error', { value: error.message })
      forget(swalError(parseError(error.message)))
      return null
    }
  }
  return pay
}

export default usePayInvoice
