import { lazy } from 'react'
export { default as FourOhFour } from './404'
export { default as AccountDelete } from './accountDelete'
export { default as AppLink } from './appLink'
export { default as AuthActions } from './authActions'
export { default as Badges } from './badges'
export { default as ChangeEmail } from './changeEmail'
export { default as Faq } from './faq'
export { default as FreeTrialTest } from './freeTrialTest'
export { default as Identity } from './identity'
export { default as Invite } from './invite'
export { default as Master } from './master'
export { default as Opinion } from './opinion'
export { default as Plans } from './plans'
export { default as RedeemDetailsPage } from './redeemDetails'
export { default as Referrals } from './referrals'
export { default as Referrer } from './referrer'
export { default as Refund } from './refund'
export { default as Settings } from './settings'
export { default as Success } from './success'
export { default as ThankYou } from './thankYou'
export { default as VerifyDevice } from './verifyDevice'
export { default as VerifyRedeem } from './verifyRedeem'
export { default as WithdrawAsset } from './withdrawAsset'
export const Empty = () => <></>

export const Pickaxes = lazy(() => import('./pickaxes'))
export const PickaxeMint = lazy(() => import('./pickaxesMint'))

export const Helmets = lazy(() => import('./helmets'))
export const HelmetMint = lazy(() => import('./helmetsMint'))

export const Boots = lazy(() => import('./boots'))
export const BootMint = lazy(() => import('./bootsMint'))

export const Profiles = lazy(() => import('./profiles'))
export const ProfileDetails = lazy(() => import('./profileDetails'))
export const ProfileCheckout = lazy(() => import('./profileCheckout'))
export const ProfileCheckoutSuccess = lazy(() => import('./profileCheckoutSuccess'))

export const CoinStatSquareCard = lazy(() => import('../StatCard/routes/CoinStatSquareCard'))
export const CoinStatLandscapeCard = lazy(() => import('../StatCard/routes/CoinStatLandscapeCard'))
export const CoinStatPortraitCard = lazy(() => import('../StatCard/routes/CoinStatPortraitCard'))
export const CoinStatPortraitBlank = lazy(() => import('../StatCard/routes/CoinStatPortraitBlank'))

export * from '../pages'
