import Button from '@mui/material/Button'
import { Invoice, PaymentCard, useInvoiceQuery, useTransactionMutation } from '@xyo-network/api-checkout-apollo'
import get from 'lodash/get'
import { MouseEvent, ReactNode } from 'react'

import { swalLoader } from '../../../components'
import { swalError, swalSuccess } from '../../../componentsPure'
import { refetchAccounts, useUser } from '../../../User'
import { parseError, trackPayEvent, trackWarning, forget } from '../../../utils'
import { redeemSubscription } from '../../lib'
import { useConfirmPaymentPrompt } from '../Upgrade'

interface IOuter {
  invoiceId?: string | null
  invoice?: Invoice
  cards?: (PaymentCard | null)[] | null
  children: ReactNode
}

const PayInvoice = ({ invoiceId, children, cards, ...props }: IOuter) => {
  const confirmPaymentPrompt = useConfirmPaymentPrompt()
  const [payInvoice] = useTransactionMutation()
  const invoiceQuery = useInvoiceQuery({
    skip: !invoiceId || Boolean(props.invoice),
    variables: { id: invoiceId || '' },
  })
  const invoice = props.invoice ?? invoiceQuery.data?.invoice
  const { user } = useUser()
  const pay = async (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    const email = get(user, 'email')
    if (!invoiceId || !payInvoice || !email || !invoice) return
    const card = await confirmPaymentPrompt(Promise.resolve(invoice), Promise.resolve(cards))
    if (!card || !card.paymentCardId) return trackPayEvent('canceled', {})
    try {
      trackPayEvent('confirmed', {})
      forget(swalLoader(<p>Paying Invoice...</p>))

      await payInvoice({
        variables: { invoiceId, paymentCardId: card.paymentCardId },
      })
      await redeemSubscription(email)
      await refetchAccounts()
      forget(swalSuccess('Transaction successful!'))
    } catch (e) {
      const error = e as Error
      trackWarning('Pay invoice error', error.message)
      trackPayEvent('error', { value: error.message })
      forget(swalError(parseError(error.message)))
    }
  }
  return invoiceId ? <Button onClick={pay}>{children}</Button> : <></>
}

export default PayInvoice
