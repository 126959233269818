import BitlabsDialog from './BitlabsDialog'
import { BitlabsProvider } from './BitlabsProvider'
import InbrainDialog from './InbrainDialog'
import { InbrainProvider } from './InbrainProvider'
import PrecisionSampleDialog from './PrecisionSampleDialog'
import { PrecisionSampleProvider } from './PrecisionSampleProvider'
import SurveyProviderDialog from './SurveyProviderDialog'
import { SurveyProviderProvider } from './SurveyProviderProvider'

const SurveyProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <SurveyProviderProvider>
      <PrecisionSampleProvider>
        <BitlabsProvider>
          <InbrainProvider>
            {children}
            <PrecisionSampleDialog />
            <SurveyProviderDialog />
            <BitlabsDialog />
            <InbrainDialog />
          </InbrainProvider>
        </BitlabsProvider>
      </PrecisionSampleProvider>
    </SurveyProviderProvider>
  )
}

export default SurveyProvider
