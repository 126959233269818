import get from 'lodash/get'

import { utmParams } from '../../utils'

function getLeadSource() {
  return {
    affiliate: '{{ad.name}}',
    amount: '',
    campaign: get(utmParams, 'utm_campaign') || '',
    clickId: 'sight',
    content: get(utmParams, 'utm_content') || '',
    currency: '',
    medium: get(utmParams, 'utm_medium') || '',
    path: 'my.coinapp.co',
    salesAgent: 'virtual',
    source: get(utmParams, 'utm_source') || '',
    subAffiliate: 'paid',
    term: get(utmParams, 'utm_term') || '',
  }
}

export default getLeadSource
