export const emojis = {
  data: {
    emojiOptions: [
      { icon: '🍾', id: 'bottle-with-popping-cork' },
      { icon: '😍', id: 'smiling-face-with-heart-eyes' },
      { icon: '🤯', id: 'exploding-head' },
      { icon: '👏', id: 'clapping-hands' },
      { icon: '🥂', id: 'clinking-glasses' },
      { icon: '🤘', id: 'sign-of-the-horns' },
      { icon: '🎉', id: 'party-popper' },
      { icon: '🙌', id: 'raising-hands' },
      { icon: '🍻', id: 'clinking-beer-mugs' },
    ],
  },
}
