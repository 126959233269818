import { createContext, useContext } from 'react'

export interface FormAddressValues {
  address?: string
  address2?: string
  city?: string
  postalCode?: string
  region?: string
  country?: string
}

export interface FormValues {
  shipFullName?: string
  shipping?: FormAddressValues
  billing?: FormAddressValues
  same?: boolean
}

export interface FormContextValues {
  errors?: unknown
  handleBlur?: unknown
  handleChange?: unknown
  handleError?: unknown
  touched?: unknown
  values?: FormValues
}

export const FormContext = createContext<FormContextValues>({})

export const useForm = () => useContext(FormContext)
