import gql from 'graphql-tag'

const CLAIM_CAMPAIGN_STATS = gql`
  query ClaimCampaignStats {
    claimCampaignStats {
      campaign
      userCount
      totalAmount
    }
  }
`

const CLAIMS = gql`
  query ClaimsByCampaign($campaign: Int!) {
    claimsByCampaign(campaign: $campaign) {
      quadkey
      campaign
      paid
      collected
    }
  }
`

export { CLAIM_CAMPAIGN_STATS, CLAIMS }
