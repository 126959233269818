import gql from 'graphql-tag'

const redeemOptionFragment = gql`
  fragment RedeemOption on RedeemOption {
    id
    active
    sortOrder
    variable
    currency
    currencyAmount
    coinAmount
    title
    imageUrl
    description
    processingFeeInDollars
    requiresShippingAddress
    requiresWalletAddress
    activeWhenNoCrypto
    redeemCount
    redeemLimit
  }
`

export default redeemOptionFragment
