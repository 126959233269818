import { QueryHookOptions, useQuery } from '@apollo/react-hooks'

import { BALANCES } from './gql'

export interface Balance {
  icon: string
  asset: string
  title: string
  collected: number
  pending: number
  withdrawn: number
}

interface BalancesProps {
  balances: Balance[]
}

const useBalances = (options?: QueryHookOptions) => useQuery<BalancesProps>(BALANCES, options)

export default useBalances
