import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, LinearProgress } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'

import { parseError, useAsyncControls } from '../../utils'
import { SlideInUp } from '../Animate'
import { AbsoluteLinearProgress } from '../Loader'
import getVerificationCode from './getVerificationCode'
import { VerificationStatusIcon } from './Icon'

export interface BaseVerifyRequestProps<V = unknown> {
  verify?: (code: string, ...rest: string[]) => Promise<V>
  titles?: {
    loading?: ReactNode
    success?: ReactNode | ((v: V) => ReactNode)
    error?: ReactNode
  }
  content?: {
    success?: (v: V, d?: unknown) => ReactNode
  }
  onClose?: () => void
  getCode?: () => string | undefined
}

function BaseVerifyRequest<V>({ verify, titles, content, onClose, getCode = getVerificationCode }: BaseVerifyRequestProps<V>) {
  const [code, setCode] = useState('')
  const [{ loading, data, error }, { setLoading, setError, setData }] = useAsyncControls<V>()
  const state = loading ? 'loading' : error ? 'error' : 'success'

  const close = () => {
    if (loading) return
    setCode('')

    if (!onClose) return
    onClose()
  }

  const execVerify = (code: string, ...args: string[]) => {
    if (!verify) return

    setLoading?.(true)

    verify(code, ...args)
      .then((verified) => {
        return verified
      })
      .then((verified) => {
        // if (!verified) return
        // console.log(verified)
        setData?.(verified)
      })
      .catch((err) => {
        setError?.(parseError(err))
      })
  }

  useEffect(() => {
    const code = getCode()
    setCode(code || '')

    if (!code) return

    execVerify(code)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return code ? (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <Box style={{ position: 'relative' }}>
        {loading ? <AbsoluteLinearProgress /> : null}
        <DialogTitle>
          <Box textAlign="center" fontSize="4em">
            <SlideInUp>
              <VerificationStatusIcon state={state} />
            </SlideInUp>
          </Box>
          {data ? (
            <SlideInUp key="success">{typeof titles?.success === 'function' ? titles?.success?.(data) : titles?.success}</SlideInUp>
          ) : loading ? (
            <SlideInUp key="loading">{titles?.loading}</SlideInUp>
          ) : error ? (
            <SlideInUp key="error">{titles?.error}</SlideInUp>
          ) : null}
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          {data ? (
            <SlideInUp>
              {content?.success?.(data, {
                data,
                error,
                loading,
                reVerify: (...rest: string[]) => execVerify(code, ...rest),
              })}
            </SlideInUp>
          ) : loading ? (
            <DialogContentText>&nbsp;</DialogContentText>
          ) : error ? (
            <SlideInUp>
              <DialogContentText>{error}</DialogContentText>
            </SlideInUp>
          ) : null}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={close} disabled={loading}>
            {error ? 'Okay' : 'Done'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  ) : null
}

export default BaseVerifyRequest
