import { useQuery } from '@apollo/client'
import DialogContentText from '@mui/material/DialogContentText'
import { BoxProps } from '@mui/material/Box'
import { FlexCol } from '../../../components/Flex'
import keyBy from 'lodash/keyBy'
import sum from 'lodash/sum'
import { PropsWithChildren} from 'react'
import { useNavigate } from 'react-router-dom'

import { BaseVerifyRequest, BeforeYouWithdraw, CustomList, FadeIn, RedeemAccordion } from '../../../components'
import RedeemRequest from '../../../components/Redeem/RedeemRequest'
import { verifyAddressHistory } from '../../../context'
import { redeemHistoryQuery } from '../../../store'
import { useUser } from '../../../User'

interface RedeemHistoryResult {
  redeemOptions: {
    id: string
  }
  myRedeemHistory: {
    data: RedeemRequest[]
  }
}

export interface IVerified {
  type: string
  shipFullName: string
  shipStreetAddress: string
  shipStreetAddress2: string
  shipCity: string
  shipState: string
  shipZipcode: string
  shipCountry: string
  shipPhoneNumber: string
  coinbaseEmail: string
  ethAddress: string
  phoneNumber: string
  email: string
}

const RedeemHistory: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  const { user } = useUser()
  const { loading, data } = useQuery<RedeemHistoryResult>(redeemHistoryQuery, {
    variables: { sort: { field: 'date', order: -1 } },
  })
  const requests = loading ? [] : (data && data.myRedeemHistory && data.myRedeemHistory.data) || []
  const options = (data && data.redeemOptions) || []
  const optionsById = keyBy(options, 'id')
  const total = sum(
    requests
      .filter((request) => request && request.status === 'completed')
      .map(({ sent, xyoCost }) => Number(xyoCost) || Number(xyoCost) || Number(sent))
      .filter((v) => !isNaN(v))
  )
  return user ? (
    <FlexCol alignItems="stretch" {...props}>
      <div>
        <FadeIn delay={400} style={{ zIndex: 2 }}>
          <BeforeYouWithdraw requests={requests} loading={loading} />
        </FadeIn>
        <div>
          <div className="mb-2">
            {loading ? (
              <div className="text-center text-danger">
                <div className="spinner-border" />
              </div>
            ) : requests.length ? (
              <CustomList title="Redeem History">
                {requests.map((request) =>
                  request.itemId ? (
                    <RedeemAccordion key={request.id} request={request} option={optionsById[request.itemId]} />
                  ) : null
                )}
              </CustomList>
            ) : null}
          </div>
          {total ? <p>Total: {total.toLocaleString()}</p> : null}
        </div>
      </div>
    </FlexCol>
  ) : (
    <FlexCol />
  )
}

export const VerifyAddressHistoryDialog = () => {
  const navigate = useNavigate()
  return (
    <BaseVerifyRequest
      titles={{
        error: 'Details Verification Error',
        loading: 'Details Verification',
        success: 'Details Verification Complete',
      }}
      content={{
        success: (verified) => <VerifyAddressHistorySuccess verified={verified} />,
      }}
      verify={verifyAddressHistory}
      onClose={() => navigate('/redeem/details')}
    />
  )
}

export const VerifyAddressHistorySuccess = ({ verified }: { verified: IVerified }) => {
  return (
    <>
    <DialogContentText paragraph>
      You have successfully verified your {verifiedAddressChangeType(verified)}
    </DialogContentText>
    <DialogContentText>
      <ChangeDetails verified={verified} />
    </DialogContentText>
    </>
  )
}

const ChangeDetails = ({
  verified,
}: {
  verified: IVerified
}) => {
  switch (verified.type) {
    case 'phonenumber_update':
      return <strong>{verified.phoneNumber}</strong>
    case 'email_address_update':
      return <>
        <BR>Log out of COIN app and log in with your updated email address</BR>
        <strong>{verified.email}</strong>
      </>
    case 'eth_address_update':
    case 'nft_address_update':
      return <strong>{verified.ethAddress}</strong>
    case 'coinbase_email_update':
      return <strong>{verified.coinbaseEmail}</strong>
    case 'shipping_address_update':
      return (
        <>
          <BR>{verified.shipFullName}</BR>
          <BR>{verified.shipStreetAddress}</BR>
          <BR>{verified.shipStreetAddress2}</BR>
          <BR>{`${verified.shipCity || ''}, ${verified.shipState || ''} ${verified.shipZipcode || ''}`}</BR>
          <BR>{verified.shipCountry}</BR>
          <BR>{verified.shipPhoneNumber}</BR>
        </>
      )
    default:
      return <></>
  }
}

const BR = ({ children }: PropsWithChildren) => {
  return children ? (
    <>
      {children}
      <br />
    </>
  ) : null
}

function verifiedAddressChangeType(verified: { type: string }) {
  switch (verified.type) {
    case 'phonenumber_update':
      return 'Phone number'
    case 'email_address_update':
      return 'Email address'
    case 'eth_address_update':
      return 'Ethereum wallet address'
    case 'nft_address_update':
      return 'NFT wallet address'
    case 'coinbase_email_update':
      return 'Coinbase email address'
    case 'shipping_address_update':
      return 'shipping address'
    default:
      return ''
  }
}

export default RedeemHistory
