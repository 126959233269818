import Typography from '@mui/material/Typography'
import Button, { ButtonProps } from '@mui/material/Button'
import { FlexCol, FlexRow } from '../components/Flex'
import { FaBuilding } from 'react-icons/fa'

import coinVertical from '../assets/CoinLogoWithText2.png'
import { FaqAccordionItem, QuestionsFilter, QuestionsList, QuestionsProvider, useScrollTopEffect } from '../components'
import CustomBasePage from '../CustomBasePage'

const SupportLink: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => (
  <Button color="secondary" variant="outlined" href={process.env.REACT_APP_SUPPORT_URI} {...props}>
    For additional help, please contact support.
  </Button>
)

const Faq = () => {
  useScrollTopEffect()
  return (
    <CustomBasePage>
      <FlexCol alignItems="stretch">
        <FlexRow sx={{ display: { lg: 'flex', xs: 'none' } }} marginY={2}>
          <img src={coinVertical} style={{ height: 90 }} alt="Coin logo" />
        </FlexRow>
        <QuestionsProvider>
          <FlexCol alignItems="stretch">
            <Typography variant="h6" textAlign="center" marginY={1}>
              How Can We Help?
            </Typography>
            <QuestionsFilter marginY={2} />
            <QuestionsList>
              <FaqAccordionItem />
            </QuestionsList>
            <FlexCol marginY={2}>
              <Typography variant="h4" color="primary">
                <FaBuilding size={24} /> Office Location
              </Typography>
              <Typography>XY &amp; COIN Headquarters:</Typography>
              <Typography>740 13th Street #224</Typography>
              <Typography>San Diego, CA 92101</Typography>
              <Typography color="primary">(855)346-3160</Typography>
              <SupportLink sx={{ my: 2 }} />
            </FlexCol>
          </FlexCol>
        </QuestionsProvider>
      </FlexCol>
    </CustomBasePage>
  )
}

export default Faq
