import { Close } from '@mui/icons-material'
import { Box, CardHeader, Drawer, IconButton, Tooltip, Typography } from '@mui/material'

import inbrainLogo from '../../assets/inbrain.png'
import { useUser } from '../../User'
import { useInbrain } from './InbrainProvider'

const InbrainDialog = () => {
  const { user } = useUser()
  const [open, setOpen] = useInbrain()
  const close = () => setOpen(false)

  if (!user || !user.uid) return null

  return (
    <Drawer open={open} onClose={close} style={{ zIndex: 98 }} anchor="left">
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <img src={inbrainLogo} alt="inBrain" style={{ filter: 'brightness(100)', height: 16, marginRight: 8, opacity: 0.8 }} /> inBrain
          </Box>
        }
        action={
          <Tooltip title={<Typography>Return to COIN</Typography>}>
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          </Tooltip>
        }
      />
      <iframe
        src={`https://www.surveyb.in/configuration?params=dDhJdjdIUEVyRmVGNlQ3L3BTMU9DV3l1UEdvYVJ5VTBJeUJmaVRvaXA2cGxyNjgrTlovSFdHZkR3eUY1T1ZHWmJ6bm84TGtxMVBKeitZZFZ4bGs2aU9pUDMzNFo0RjFoaWNQSlpDUmJqVHZtNWlxVlVQNndJWWpISnRSUFZvM1dlNlRLL3RENkVvNm1lRDc2ZzErM2h3eHNoQzRDRDN3TnBPZWREL1FUV2FxMEZDSm1idE9jUWUvR1dZdGhuQ2lGWC9hbTd0aXkyNktFTXJ2ckltWVVIdz09&app_uid=${user.uid}`}
        style={{ border: 0, height: 800, width: 400 }}
      ></iframe>
    </Drawer>
  )
}

export default InbrainDialog
