import { ApolloClient, InMemoryCache } from '@apollo/client'

import resolvers from './resolvers'
import { makeUseUpgrade } from './subscriptions'

export * from './gql'

const cache = new InMemoryCache()
const client = new ApolloClient({
  cache,
  resolvers,
})
const useUpgrade = makeUseUpgrade(client as any)
export { useUpgrade }

export default client
