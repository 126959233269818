import { CircularProgress } from '@mui/material'
import { ReactElement } from 'react'
import { Container, render } from 'react-dom'
import swal from 'sweetalert2'

import Main from '../../Main'

const swalLoader = (text: ReactElement) => {
  return swal.fire({
    allowOutsideClick: false,
    showConfirmButton: false,
    willClose: () => {
      const container = document.querySelector('.swal2-container')
      if (container) container.remove()
    },
    willOpen: (element: Container) => {
      render(
        <Main>
          <div className="text-center text-dark">
            {text}
            <div className="text-primary">
              <CircularProgress color="inherit" />
            </div>
          </div>
        </Main>,
        element
      )
    },
  })
}

export default swalLoader
