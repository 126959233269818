import { CardFragmentDoc, InvoiceFragmentDoc } from '@xyo-network/api-checkout-apollo'
import gql from 'graphql-tag'

export const MY_INVOICES = gql`
  query MyInvoices($pagination: Pagination, $filter: InvoiceFilter) {
    me {
      paymentCards {
        ...Card
      }
      invoices(pagination: $pagination, filter: $filter) {
        total
        data {
          type
          ...Invoice
        }
      }
    }
  }

  ${InvoiceFragmentDoc}
  ${CardFragmentDoc}
`

export const MY_PAST_DUE_INVOICES = gql`
  query MyPastDueInvoices($pagination: Pagination) {
    me {
      paymentCards {
        ...Card
      }
      invoicesPastDue(pagination: $pagination) {
        total
        data {
          type
          ...Invoice
        }
      }
    }
  }

  ${InvoiceFragmentDoc}
  ${CardFragmentDoc}
`
