import { FC, SVGProps } from 'react'

import { BasicIcon, LiteIcon, MasterIcon, PlusIcon, ProIcon } from './icons'

const PlanIcon: FC<React.PropsWithChildren<{ plan: string } & SVGProps<SVGSVGElement>>> = ({ plan, ...props }) => {
  switch ((plan || '').toLowerCase()) {
    case 'master':
    case 'Master':
      return <MasterIcon {...props} />
    case 'plus':
    case 'Plus':
      return <PlusIcon {...props} />
    case 'pro':
    case 'Pro':
      return <ProIcon {...props} />
    case 'lite':
    case 'Lite':
      return <LiteIcon {...props} />
    default:
      return <BasicIcon {...props} />
  }
}

export default PlanIcon
