import { Avatar, AvatarProps } from '@mui/material'
import { useEffect, useState } from 'react'

interface Props extends AvatarProps {
  defaultSrc?: string
}

const AvatarEx: React.FC<React.PropsWithChildren<Props>> = ({ src: initialSrc, defaultSrc, ...props }) => {
  const [src, setSrc] = useState<string>()
  useEffect(() => {
    if (initialSrc) {
      const img = document.createElement('img')
      img.onload = () => setSrc(initialSrc)
      img.onerror = () => setSrc(defaultSrc)
      img.src = initialSrc
    }
  }, [initialSrc, defaultSrc])

  return <Avatar src={src} {...props} />
}

export default AvatarEx
