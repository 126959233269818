import { useFindSweepstakeEntryHistoryQuery } from '@coin-microservices/sweepstakes-react-hooks'
import { Divider, TablePagination } from '@mui/material'
import { FlexCol, FlexRow } from '../Flex'
import times from 'lodash/times'
import { ChangeEvent, useState } from 'react'

import NoItems from '../../NoItems'
import { useScrollContext } from '../Scroll'
import { TablePaginationActions } from '../Table'
import EntryHistoryListItem from './EntryHistoryListItem'
import { SweepstakesOptionsContext } from './options'

const EntryHistory = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const scrollElement = useScrollContext()
  const { data, loading } = useFindSweepstakeEntryHistoryQuery({
    variables: {
      pagination: {
        page,
        perPage,
      },
      sort: {
        field: 'created',
        order: -1,
      },
    },
  })
  const items = data?.findSweepstakeEntryHistory?.data
  const total = data?.findSweepstakeEntryHistory?.total ?? 0
  const changePage = (p: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const rowCount = loading ? 10 : items?.length || 0
  return (
    <FlexCol alignItems="stretch">
      {times(rowCount).map((_, i) => (
        <SweepstakesOptionsContext.Provider key={i} value={items?.[i]?.options ?? null}>
          <EntryHistoryListItem item={items?.[i] ?? {}} loading={loading} />
          <Divider />
        </SweepstakesOptionsContext.Provider>
      ))}
      {rowCount === 0 ? (
        <NoItems
          message="No Previous Sweepstakes Found"
          href="https://support.coinapp.co/hc/en-us/articles/360058411214-COIN-Sweepstakes"
          buttonText="Read more about sweepstakes"
        ></NoItems>
      ) : null}
      {total ? (
        <TablePagination
          component={FlexRow}
          justifyContent="flex-end"
          rowsPerPageOptions={[5]}
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      ) : null}
    </FlexCol>
  )
}

export default EntryHistory
