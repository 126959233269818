import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Link from '@mui/material/Link'
import { useState } from 'react'

import AppStore from '../../assets/AppStore'
import PlayStore from '../../assets/PlayStore'
import { FlexRow } from '../Flex'

export interface DownloadButtonBoxProps {
  android?: boolean
  androidLink?: string
  ios?: boolean
  iosLink?: string
  onIosClick?: () => void
  onAndroidClick?: () => void
}

function isDesktop() {
  return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
}

export const DownloadButtonBox: React.FC<React.PropsWithChildren<DownloadButtonBoxProps>> = (props) => {
  const {
    android = true,
    ios = true,
    androidLink = 'https://play.google.com/store/apps/details?id=network.xyo.coin',
    iosLink = 'https://apps.apple.com/us/app/coin-always-be-earning/id1450443351',
    onIosClick,
    onAndroidClick,
    ...rootProps
  } = props

  const [open, setOpen] = useState(false)

  const handleIosClick = (e: any) => {
    if (isDesktop()) {
      e.preventDefault()
      setOpen(true)
    } else {
      if (onIosClick) {
        onIosClick()
      }
    }
  }
  const handleAndroidClick = (e: any) => {
    if (isDesktop()) {
      e.preventDefault()
      setOpen(true)
    } else {
      if (onAndroidClick) {
        onAndroidClick()
      }
    }
  }

  return (
    <FlexRow {...rootProps}>
      {ios ? (
        <FlexRow flexShrink={1} margin={0.5}>
          <Link href={iosLink} target="_blank" onClick={handleIosClick}>
            <AppStore style={{ height: 45 }} />
          </Link>
        </FlexRow>
      ) : null}
      {android ? (
        <FlexRow flexShrink={1} margin={0.5}>
          <Link href={androidLink} target="_blank" onClick={handleAndroidClick}>
            <PlayStore style={{ height: 45 }} />
          </Link>
        </FlexRow>
      ) : null}

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogContent>
          <img src={`${process.env.PUBLIC_URL}/qrcode.svg`} alt="QR Code" style={{ width: '100%' }} />
          <Typography paragraph>Open this link on your mobile device or scan the QR code to download the COIN App</Typography>
          <Button onClick={() => setOpen(false)} variant="outlined">
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </FlexRow>
  )
}
