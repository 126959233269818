import { AccountQuery, RebillySubscription } from '@xyo-network/api-checkout-apollo'
import { ReactNode, useMemo } from 'react'

import { FlexRow } from '../components/Flex'

const SubscriptionActions = ({
  isFHR,
  tokenLogin,
  activeSubscription,
  subscription,
  purchasePlus,
  purchasePro,
  reactivateSubscription,
  cancelSubscription,
  upgradeFhrPlusToPro,
  payRecentInvoice,
  upgradeToPro,
  upgradeToMaster,
  customer,
}: {
  isFHR?: boolean
  tokenLogin?: boolean
  activeSubscription?: string
  subscriptionInfo?: unknown
  subscription?: RebillySubscription | null
  customer?: unknown
  payRecentInvoice?: ReactNode
  purchasePlus?: ReactNode
  purchasePro?: ReactNode
  upgradeToPro?: ReactNode
  upgradeToMaster?: ReactNode
  downgradeToPlus?: ReactNode
  upgradeFhrPlusToPro?: ReactNode
  reactivateSubscription?: ReactNode
  cancelSubscription?: ReactNode
}) => {
  const actions = useMemo(() => {
    const status = subscription && subscription.status
    const billingStatus = subscription && subscription.billingStatus
    if (status === 'canceled' || status === 'churned') {
      return (
        <>
          {reactivateSubscription}
          {cancelSubscription}
        </>
      )
    }
    if (billingStatus === 'unpaid' || billingStatus === 'past-due') {
      return (
        <>
          {cancelSubscription}
          {payRecentInvoice}
        </>
      )
    }
    if (activeSubscription === 'Plus' && isFHR) return upgradeFhrPlusToPro
    if (activeSubscription && subscription && status !== 'abandoned' && status !== 'voided') {
      if (activeSubscription === 'Pro') {
        return (
          <>
            {cancelSubscription}
            {upgradeToMaster}
          </>
        )
      } else if (activeSubscription === 'Plus') {
        return (
          <>
            {cancelSubscription}
            {upgradeToPro}
          </>
        )
      } else return cancelSubscription
    }
    if (tokenLogin) return <div style={{ height: 40 }}></div>
    if (activeSubscription && activeSubscription.toLowerCase() !== 'basic') {
      return <></>
    }
    return (
      <FlexRow>
        {purchasePlus}
        {purchasePro}
      </FlexRow>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscription, isFHR, subscription, customer])
  return <div className="text-center">{actions}</div>
}

export default SubscriptionActions
