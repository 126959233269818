import { ApolloError } from '@apollo/client'
import { Check, ClearRounded, Info } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  Container,
  Divider,
  LinearProgress,
  Slide,
  Tooltip,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import { RefundRequest, useProcessRefundRequestMutation, useRefundRequestQuery } from '@xyo-network/api-checkout-apollo'
import { FC, ReactNode, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CheckoutApolloProvider } from '../checkout'
import { forget } from '../utils'
import { FadeIn, PasswordRequiredDialog } from '../components'
import CustomBasePage from '../CustomBasePage'
import UserInfoDisplay from '../UserInfoDisplay'

export default () => {
  const { id } = useParams()
  return (
    <CheckoutApolloProvider>
      <RefundView id={id} />
    </CheckoutApolloProvider>
  )
}

const RefundView = ({ id = '' }: { id?: string }) => {
  const [confirmEmail, setConfirmEmail] = useState('')
  const { loading, error, data } = useRefundRequestQuery({ skip: !id, variables: { id } })
  const [processRefund, { loading: refunding, error: refundError }] = useProcessRefundRequestMutation({
    variables: { id },
  })
  return (
    <CustomBasePage>
      <FadeIn>
        <div className="mb-5">
          <UserInfoDisplay />
        </div>
      </FadeIn>
      <FadeIn delay={400} style={{ zIndex: 2 }}>
        <Container maxWidth="sm">
          <h1 className="mb-4">Request Refund</h1>
          <Refund
            error={error || refundError}
            loading={loading}
            refunding={refunding}
            refund={data && data.refundRequest}
            handleRefund={() => setConfirmEmail(data?.refundRequest?.email ?? '')}
          />
        </Container>
      </FadeIn>
      <PasswordRequiredDialog
        title="Confirm Refund Request"
        content={
          <>
            <Typography>Enter account password to accept terms and refund</Typography>
            <Typography>
              You will be refunded $
              {num((data?.refundRequest?.eligibleMonths ?? 0) * (data?.refundRequest?.eligibleAmount ?? 0))}
            </Typography>
            {typeof data?.refundRequest?.resetXyoCollectedAmount !== 'number' ||
            data?.refundRequest?.xyoCollected === data?.refundRequest?.resetXyoCollectedAmount ? (
              <Typography gutterBottom>
                Your COIN balance will be remain {num(data?.refundRequest?.xyoCollected)}
              </Typography>
            ) : (
              <Typography gutterBottom>
                Your COIN balance will be adjusted from {num(data?.refundRequest?.xyoCollected)} to{' '}
                {num(data?.refundRequest?.resetXyoCollectedAmount)}
              </Typography>
            )}
          </>
        }
        open={Boolean(confirmEmail)}
        onClose={() => setConfirmEmail('')}
        email={confirmEmail}
        onConfirmed={() => {
          setConfirmEmail('')
          forget(processRefund())
        }}
      />
    </CustomBasePage>
  )
}

interface RefundProps {
  refund?: RefundRequest | null
  loading: boolean
  error?: ApolloError
  refunding: boolean
  handleRefund: () => void
}

const Refund = ({ refund, loading, error, refunding, handleRefund }: RefundProps) => {
  return loading ? (
    <LinearProgress />
  ) : error && error.message ? (
    <ErrorCard>
      <Typography className="text-danger">{error.message}</Typography>
    </ErrorCard>
  ) : refund && refund.refunded ? (
    <SuccessCard>
      <Typography>Refund Success</Typography>
    </SuccessCard>
  ) : refund && refund.accepted ? (
    <SuccessCard>
      <Typography>Refund Accepted</Typography>
    </SuccessCard>
  ) : refund && refund.refunding ? (
    <SuccessCard>
      <Typography>Refund In Progress</Typography>
    </SuccessCard>
  ) : refund && !refund.active ? (
    <ErrorCard>
      <Typography className="text-danger">Refund no longer active</Typography>
    </ErrorCard>
  ) : refund && refund.expireAt && Date.now() > refund.expireAt ? (
    <ErrorCard>
      <Typography className="text-danger">Refund is expired</Typography>
    </ErrorCard>
  ) : refund ? (
    <>
      <Box mb={2}>
        <RefundCard refund={refund} refunding={refunding} handleRefund={handleRefund} />
      </Box>
      <Typography component="p" variant="subtitle1" style={{ textAlign: 'center' }}>
        By accepting this refund, you are agreeing to request no further refunds or charge backs.
      </Typography>
    </>
  ) : (
    <ErrorCard>
      <Typography className="text-danger">No Refund Found</Typography>
    </ErrorCard>
  )
}

interface RefundCardProps {
  refund: RefundRequest
  refunding: boolean
  handleRefund: () => void
}

const RefundCard = ({ refund, refunding, handleRefund }: RefundCardProps) => {
  return (
    <Card>
      <Slide in={true} direction="up">
        <div>
          <Box px={2} pt={1}>
            <TextRow bold title="Start Time" my={1}>
              {date(refund.startTime)}
            </TextRow>
            <Divider />
            <TextRow
              bold
              title="Eligible Months"
              info="Eligibility is based on several factors including but not limited to usage, duration of subscription, geodrops, and redemption."
              my={1}
            >
              {refund.eligibleMonths}
            </TextRow>
            <Divider />
            <TextRow bold title="Eligible Amount" my={1}>
              ${num((refund.eligibleMonths || 0) * (refund.eligibleAmount || 0))}
            </TextRow>
            <Divider />
            {typeof refund.resetXyoCollectedAmount === 'number' ? (
              <TextRow
                bold
                alignItems="flex-start"
                flexDirection="column"
                title="Adjusted COIN Balance"
                info="In order to process the refund, it is necessary to adjust your COIN balance to represent what you would have without the refunded subscription."
                my={1}
              >
                <span>
                  <TextRow title="Current Balance:" mt={1}>
                    {num(refund.xyoCollected)}
                  </TextRow>
                  <TextRow title="Adjusted Balance:">{num(refund.resetXyoCollectedAmount)}</TextRow>
                </span>
              </TextRow>
            ) : null}
            {/* <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControlLabel
                label="Accept COIN Reset"
                labelPlacement="start"
                value={checked}
                onChange={(ev, checked) => setChecked(checked)}
                control={<Checkbox color="primary" />}
              />
            </Box> */}
          </Box>
          <Box px={2} pb={2} textAlign="right">
            {refund.active ? (
              <Button
                color="primary"
                variant="contained"
                style={{ position: 'relative' }}
                disabled={refunding}
                onClick={handleRefund}
              >
                {refunding ? (
                  <Box
                    component={LinearProgress}
                    variant="indeterminate"
                    position="absolute"
                    width="100%"
                    top={0}
                    left={0}
                  />
                ) : null}
                Accept Refund
              </Button>
            ) : null}
          </Box>
        </div>
      </Slide>
    </Card>
  )
}

type TextRowProps = {
  bold?: boolean
  info?: ReactNode
  title?: ReactNode
  value?: ReactNode
} & BoxProps

const TextRow = ({
  bold,
  title,
  value,
  info,
  children,
  alignItems = 'center',
  flexDirection = 'row',
  justifyContent = 'space-between',
  ...props
}: TextRowProps) => {
  const main = <Typography>{bold ? <strong>{title}</strong> : title}</Typography>
  return (
    <Box
      display="flex"
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
      {...props}
    >
      {info ? (
        <Tooltip title={<Typography>{info}</Typography>}>
          <Box display="flex" flexDirection="row" alignItems="center">
            {main}{' '}
            <Box ml={1}>
              <Info fontSize="small" />
            </Box>
          </Box>
        </Tooltip>
      ) : (
        main
      )}
      <Box component={Typography} ml={1} style={{ textAlign: 'right' }}>
        {value || children}
      </Box>
    </Box>
  )
}

const ErrorCard: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Card>
      <CardContent>
        <Slide in={true} direction="up">
          <div>
            <ErrorContent>{children}</ErrorContent>
          </div>
        </Slide>
      </CardContent>
    </Card>
  )
}

const SuccessCard: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Card>
      <CardContent>
        <Slide in={true} direction="up">
          <div>
            <SuccessContent>{children}</SuccessContent>
          </div>
        </Slide>
      </CardContent>
    </Card>
  )
}

const SuccessContent: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div className="text-center">
      <div className="d-inline-block bg-success mt-4 mb-2 rounded-circle">
        <Check className="p-2" style={{ height: 120, width: 120 }} />
      </div>
      {children}
    </div>
  )
}

const ErrorContent: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div className="text-center">
      <div className="d-inline-block bg-danger mt-4 mb-2 rounded-circle">
        <ClearRounded className="p-2" style={{ height: 120, width: 120 }} />
      </div>
      {children}
    </div>
  )
}

function date(v?: Date | string | number | null) {
  if (!v) return ''
  return new Date(v).toLocaleString()
}

function num(v?: string | number | null) {
  const n = Number(v)
  if (isNaN(n)) return 0
  return n.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}
