import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { useUserInfo } from './hooks'

interface AddressContextProps {
  invalid?: string | boolean
  isVerified: boolean
  lastKnownAddress: string
  loading: boolean
  onSubmit?: (wallet: string, isVerified: boolean) => void
  setInvalid?: (invalid: string | boolean) => void
  setLoading: (loading: boolean) => void
  setWallet: (wallet: string) => void
  wallet: string
}

const defaultValue = {
  invalid: false,
  isVerified: false,
  lastKnownAddress: '',
  loading: false,
  setLoading: (loading: boolean) => {
    defaultValue.loading = loading
  },
  setWallet: (wallet: string) => {
    defaultValue.wallet = wallet
  },
  wallet: '',
}

const AddressContext = createContext<AddressContextProps>(defaultValue)

export const useAddress = () => useContext(AddressContext)

export const AddressProvider = ({ children }: { children: ReactNode }) => {
  const { userInfo } = useUserInfo()
  const [invalid, setInvalid] = useState<string | boolean>()
  const [wallet, setWalletValue] = useState('')
  const [isVerified, setWalletVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lastKnownAddress, setLastKnownAddress] = useState('')
  const setWallet = (value: string, verified = false) => {
    setWalletValue(value)
    setWalletVerified(verified)
  }
  useEffect(() => {
    if (userInfo && userInfo.lastKnownAddress) {
      setWallet(userInfo.lastKnownAddress)
      setLastKnownAddress(userInfo.lastKnownAddress)
    }
  }, [userInfo])
  return (
    <AddressContext.Provider
      value={{
        ...defaultValue,
        invalid,
        isVerified,
        lastKnownAddress,
        loading,
        setInvalid,
        setLoading,
        setWallet,
        wallet,
      }}
    >
      {children}
    </AddressContext.Provider>
  )
}
