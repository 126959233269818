import fetch from 'isomorphic-fetch'

const redeemInitialSuccess = async (planType: string, coinUserId: string, rebillyCustomerId: string): Promise<void> => {
  const res = await fetch(`${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/redeem/initial-success`, {
    body: JSON.stringify({
      coinUserId,
      planType,
      rebillyCustomerId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
  const data = await res.json()
  return data
}

export default redeemInitialSuccess
