import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import { FlexRow } from '../Flex'
import { MouseEvent } from 'react'

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props

  function handleFirstPageButtonClick(event: MouseEvent<HTMLButtonElement>) {
    onPageChange(event, 0)
  }

  function handleBackButtonClick(event: MouseEvent<HTMLButtonElement>) {
    onPageChange(event, page - 1)
  }

  function handleNextButtonClick(event: MouseEvent<HTMLButtonElement>) {
    onPageChange(event, page + 1)
  }

  function handleLastPageButtonClick(event: MouseEvent<HTMLButtonElement>) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <FlexRow flexShrink={0}>
      <IconButton
        sx={{ display: { md: 'flex', xs: 'none' } }}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage height={24} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        sx={{ display: { md: 'flex', xs: 'none' } }}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </FlexRow>
  )
}

export default TablePaginationActions
