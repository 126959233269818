import { Info } from '@mui/icons-material'
import { Box, Link, ListItemAvatar, ListItemSecondaryAction } from '@mui/material'

import { infoUri } from '../options'
import SweepstakesDetailsAvatar from './SweepstakesDetailsAvatar'
import SweepstakesDetailsLargeListItemText from './SweepstakesDetailsLargeListItemText'

const SweepstakesDetailsHeader = () => {
  return (
    <Box position="relative" display="flex" alignItems="center" px={2} py={1} pr={6}>
      <ListItemAvatar>
        <SweepstakesDetailsAvatar size={40} />
      </ListItemAvatar>
      <SweepstakesDetailsLargeListItemText />
      <ListItemSecondaryAction>
        <Link href={infoUri} target="_blank" rel="noreferrer nofollow">
          <Info color="action" />
        </Link>
      </ListItemSecondaryAction>
    </Box>
  )
}

export default SweepstakesDetailsHeader
