import { Button, Card, CardActions, CardContent, CardProps, Typography } from '@mui/material'

import { fromNanoseconds } from '../../utils'
import RedeemRequest from './RedeemRequest'

interface Props extends CardProps {
  onUpdateClicked?: () => void
  request: RedeemRequest
}

const ActiveRedeemCard: React.FC<React.PropsWithChildren<Props>> = ({ request, onUpdateClicked }) => {
  return (
    <Card className="mb-3">
      <CardContent>
        <Typography color="textSecondary" gutterBottom className="text-capitalize">
          {request.status}
        </Typography>
        <Typography variant="h6" component="h2" className="address">
          {request.wallet}
        </Typography>
        <Typography variant="body2" component="p">
          {fromNanoseconds(request.updated || request.created).toLocaleString()}
        </Typography>
      </CardContent>
      {request.wallet && (
        <CardActions>
          <Button size="small" onClick={onUpdateClicked}>
            Update
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default ActiveRedeemCard
