import { Query } from '@apollo/react-components'
import { TablePagination } from '@mui/material'
import { Invoice, PaymentCard } from '@xyo-network/api-checkout-apollo'
import { useParams } from 'react-router-dom'

import {
  CustomList,
  CustomListHeader,
  InvoiceList,
  InvoiceStatusFilter,
  MY_INVOICES,
  TablePaginationActions,
  useInvoicesQueryParams,
} from '../../components'

interface InvoiceListWithQueryResult {
  me: {
    invoices: {
      data: Invoice[]
      total: number
    }
    paymentCards: PaymentCard[]
  }
}

const InvoiceListWithQuery = () => {
  const { subscriptionId } = useParams()
  const { query, setStatusFilter, handleChangePage, handleChangeRowsPerPage } = useInvoicesQueryParams()
  return (
    <Query<InvoiceListWithQueryResult>
      query={MY_INVOICES}
      variables={{
        filter: {
          status: query.status,
          subscriptionId: subscriptionId,
          type: query.type,
        },
        pagination: { page: query.page, perPage: query.perPage },
      }}
    >
      {({ data, loading }) => {
        const customer = data?.me
        const invoices = customer?.invoices?.data ?? []
        const total = customer?.invoices?.total
        const cards = customer?.paymentCards ?? []
        return (
          <CustomList
            header={
              <CustomListHeader
                title="Invoices"
                subtitle="Past due invoices must be paid to continue subscription and redeem rewards"
              >
                <InvoiceStatusFilter value={query.status} onChange={setStatusFilter} />
              </CustomListHeader>
            }
          >
            <div>
              <InvoiceList perPage={query.perPage} loading={loading} invoices={invoices} cards={cards} />
              {total ? (
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={total}
                  rowsPerPage={query.perPage}
                  page={query.page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                ></TablePagination>
              ) : null}
            </div>
          </CustomList>
        )
      }}
    </Query>
  )
}

export default InvoiceListWithQuery
