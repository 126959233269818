import { BoxProps } from '@mui/material/Box'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { FlexRow } from '../../Flex'
import React from 'react'

export const Copyright: React.FC<React.PropsWithChildren<BoxProps | TypographyProps>> = (props) => {
  return (
    <Typography component={FlexRow} variant="body2" {...props}>
      COIN © - {new Date().getFullYear()} All Rights Reserved
    </Typography>
  )
}
