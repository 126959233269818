import { Box, Fab, FabProps, styled, Tooltip, Typography } from '@mui/material'

import { AbsoluteCircularProgress } from '../Loader'

export interface LoadingFabProps {
  active?: boolean
  loading?: boolean
  name: string
  description: string
  onClick: () => void
}

const Wrapper = styled(Fab)`
  position: relative;
`

export const LoadingFab = ({ loading, active, name, description, onClick, disabled, ...rest }: FabProps & LoadingFabProps) => {
  const fab = (
    <Wrapper onClick={onClick} color={active ? 'primary' : undefined} disabled={(loading && active) || disabled} {...rest}>
      {loading && active ? <AbsoluteCircularProgress /> : null}
      {name}
    </Wrapper>
  )

  if (description) {
    return (
      <Tooltip title={<Typography>{description}</Typography>}>
        <Box>{fab}</Box>
      </Tooltip>
    )
  }

  return fab
}
