import {
  Address,
  Customer,
  CustomerInput,
  Maybe,
  ShipStationOrder,
  Transaction,
} from '@xyo-network/api-checkout-apollo'
import get from 'lodash/get'
import { Reducer } from 'redux'

import { PaymentInfo } from './lib'

export type IAddressData = {
  shipping: Maybe<Address>
  billing: Maybe<Address>
  same: Maybe<boolean>
}

export type ICheckoutState = {
  addressData: Maybe<IAddressData>
  creditCardData: Maybe<PaymentInfo>
  customer: Maybe<Customer>
  error: Maybe<string>
  infoData: Maybe<Partial<CustomerInput>>
  loading: boolean
  order: Maybe<ShipStationOrder>
  prev: Maybe<number>
  transaction: Maybe<Transaction>
  step: number
}

export const initialState: ICheckoutState = {
  addressData: null,
  creditCardData: null,
  customer: null,
  error: null,
  infoData: null,
  loading: false,
  order: null,
  prev: null,
  step: 0,
  transaction: null,
}

const SET_LOADING = 'SET_LOADING'
const SET_ERROR = 'SET_ERROR'
const SET_INFO = 'SET_INFO'
const SET_ADDRESS = 'SET_ADDRESS'
const SET_CREDIT_CARD = 'SET_CREDIT_CARD'
const SET_CUSTOMER = 'SET_CUSTOMER'
const SET_ORDER = 'SET_ORDER'
const SET_TRANSACTION = 'SET_TRANSACTION'
const SET_ALL_DATA = 'SET_ALL_DATA'
const BACK = 'BACK'

type IAction<V = unknown> = { type: string; payload: V }
const makeAction = <V = unknown>(type: string) => {
  return (payload: V): IAction<V> => ({ payload, type })
}
const goBack = makeAction<void>(BACK)
const setLoading = makeAction<void>(SET_LOADING)
const setError = makeAction<string>(SET_ERROR)
const setInfoData = makeAction<CustomerInput>(SET_INFO)
const setAddressData = makeAction<IAddressData>(SET_ADDRESS)
const setCreditCardData = makeAction<PaymentInfo>(SET_CREDIT_CARD)
const setCustomer = makeAction(SET_CUSTOMER)
const setOrder = makeAction(SET_ORDER)
const setTransaction = makeAction<Transaction>(SET_TRANSACTION)
const setAllData = makeAction<Partial<ICheckoutState>>(SET_ALL_DATA)

const setInfoDataThunk = (_payload: CustomerInput) => {
  // Overwritten in PaymentTokenProvider
}
const setAddressDataThunk = (_payload: IAddressData) => {
  // Overwritten in PaymentTokenProvider
}
const setCreditCardDataThunk = async (_payload: PaymentInfo) => {
  // Overwritten in PaymentTokenProvider
}

export const checkoutReducer: Reducer<ICheckoutState, IAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return { ...state, error: null, loading: true }
    case SET_ERROR: {
      const error = payload as string
      return { ...state, error, loading: false }
    }
    case SET_INFO: {
      const infoData = payload as CustomerInput
      return { ...state, infoData, loading: false, prev: 0, step: 1 }
    }
    case SET_ADDRESS: {
      const addressData = payload as IAddressData
      return { ...state, addressData, loading: false, prev: 1, step: 2 }
    }
    case SET_CREDIT_CARD: {
      const creditCardData = payload as PaymentInfo
      return { ...state, creditCardData, loading: false, prev: 2, step: 3 }
    }
    case SET_CUSTOMER: {
      const customer = payload as Customer
      return { ...state, customer, loading: false }
    }
    case SET_ORDER: {
      const order = payload as ShipStationOrder
      return { ...state, loading: false, order }
    }
    case SET_TRANSACTION: {
      const transaction = payload as Transaction
      return { ...state, loading: false, transaction }
    }
    case SET_ALL_DATA: {
      const data = payload as { step: number }
      return { ...state, ...data, prev: typeof data.step === 'number' ? state.step : state.prev }
    }
    case BACK:
      return { ...state, prev: state.step, step: Math.max(0, state.step - 1) }
    default:
      return state
  }
}

export const toCheckoutData = (state: {
  addressData: { billing: { name: string }; shipping: { name: string } }
  transaction: { id: string }
  items: { id: string }[]
}) => ({
  checkoutData: {
    billingAddress: get(state, 'addressData.billing'),
    contact: { phone: '', ...(get(state, 'infoData') || {}) },
    items: get(state, 'items'),
    shippingAddress: get(state, 'addressData.shipping'),
    transaction: {
      id: get(state, 'transaction.data.checkout.transaction.id'),
      total: Number(get(state, 'transaction.data.checkout.transaction.amount')),
    },
  },
})

export type ICheckoutActions = typeof checkoutActions

export const checkoutActions = {
  goBack,
  setAddressData,
  setAddressDataThunk,
  setAllData,
  setCreditCardData,
  setCreditCardDataThunk,
  setCustomer,
  setError,
  setInfoData,
  setInfoDataThunk,
  setLoading,
  setOrder,
  setTransaction,
}
