import NoItemsPage from '../../../NoItems'

const NoHodlHistory = () => (
  <NoItemsPage
    message="No HODL History Found"
    href="https://support.coinapp.co/hc/en-us/articles/360035370314-HODL-Rewards-Explained-"
    buttonText="Read more about HODL Rewards"
  />
)

export default NoHodlHistory
