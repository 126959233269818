import cloneDeep from 'lodash/cloneDeep'
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material'
import { FlexCol } from '../components/Flex'
import {
  CustomerInput,
  KycDocument,
  KycQuery,
  useCreateCustomerMutation,
  useCreateKycMutation,
  useKycQuery,
} from '@xyo-network/api-checkout-apollo'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import set from 'lodash/set'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { CheckoutApolloProvider } from '../checkout'
import {
  ErrorContent,
  KYCExpandList,
  KYCForm,
  KYCList,
  LoadingButton,
  ProcessingContent,
  SlideInUp,
  SuccessContent,
  useScrollTopEffect,
  useSnackbar,
} from '../components'
import CustomBasePage from '../CustomBasePage'
import logger from '../Log'
import { UserInfoForm } from '../User'
import UserInfoDisplay from '../UserInfoDisplay'
import { parseError, useBooleanControls } from '../utils'
import checkoutClient, { legacyCheckout } from '../lib/checkoutClient'

const Identity = () => {
  useScrollTopEffect()
  return (
    <CustomBasePage>
      <FlexCol>
        <UserInfoDisplay />
        <Grid container>
          <Grid item xs={12} sm={3} />
          <Grid item xs={12} sm={6}>
            <IdentityVerificationCard />
          </Grid>
        </Grid>
      </FlexCol>
    </CustomBasePage>
  )
}

const IdentityVerificationCard = () => {
  const { error, data, refetch, networkStatus } = useKycQuery({
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  })
  const [retry, retryControls] = useBooleanControls(false)
  const [expanded, expandedControls] = useBooleanControls()
  const initializing = networkStatus === 1
  const refetching = networkStatus === 4
  const networkError = networkStatus === 8
  const customerId = data?.me?.id ?? ''
  const kycDocuments = data?.me?.kycDocuments ?? []
  const attempts = data?.me?.kycMaxAttempts?.attempts ?? 0
  const attemptsToday = data?.me?.kycMaxAttempts?.attemptsToday ?? 0
  const maxAttempts = data?.me?.kycMaxAttempts?.maxAttempts ?? 5
  const maxAttemptsToday = data?.me?.kycMaxAttempts?.maxAttemptsToday ?? 3

  const identityDocuments = kycDocuments.filter((v) => v?.documentType === 'identity-proof')
  const inProgress = identityDocuments.find((v) => v?.status === 'in-progress' || v?.status === 'pending')
  const accepted = identityDocuments.find((v) => v?.status === 'accepted')
  const rejected = identityDocuments.find((v) => v?.status === 'rejected')

  useEffect(() => {
    if (!inProgress) return
    const id = setInterval(() => {
      logger().log('refetch')
      refetch()
    }, 10000)
    return () => clearInterval(id)
  }, [refetch, inProgress])

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        {initializing ? (
          <Box textAlign="center" py={5}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <IdentityError message={parseError(error)} />
        ) : networkError ? (
          <IdentityError message="Please check your network connection and try again." />
        ) : !data?.me ? (
          <CreateCustomer />
        ) : accepted ? (
          <IdentityAccepted />
        ) : inProgress ? (
          <IdentityInProgress />
        ) : attempts >= maxAttempts ? (
          <IdentityMaxError title="" max={maxAttempts} />
        ) : attemptsToday >= maxAttemptsToday ? (
          <IdentityMaxTodayError max={maxAttemptsToday} />
        ) : rejected && !retry ? (
          <IdentityRejected rejected={rejected} onRetry={retryControls.setTrue} />
        ) : (
          <IdentitySubmission customerId={customerId} onComplete={retryControls.setFalse} />
        )}
      </CardContent>
      <ButtonBase className="justify-content-end" component={CardActions} onClick={expandedControls.toggle}>
        {identityDocuments.length ? (
          <KYCExpandList
            expanded={expanded}
            onClick={(ev) => {
              ev.stopPropagation()
              expandedControls.toggle()
            }}
          />
        ) : null}
      </ButtonBase>
      <Collapse in={expanded} unmountOnExit>
        {refetching ? <LinearProgress /> : null}
        <CardContent>
          <Typography variant="h5">Verification Attempts</Typography>
          <KYCList documents={identityDocuments} />
        </CardContent>
      </Collapse>
    </Card>
  )
}

const IdentityAccepted = () => {
  return (
    <SlideInUp delay={0}>
      <SuccessContent>
        <Typography variant="h4" className="mb-3">
          Identity Verified
        </Typography>
        <Button component={Link} to="/" color="primary">
          Go Home
        </Button>
      </SuccessContent>
    </SlideInUp>
  )
}

const IdentityRejected: React.FC<React.PropsWithChildren<{ rejected?: KycDocument | null; onRetry: () => void }>> = ({ rejected, onRetry }) => {
  return (
    <SlideInUp delay={0}>
      <ErrorContent>
        <h4 className="mb-4">Document Rejected</h4>
        <p>{get(rejected, 'rejectionReason.message')}</p>
        <Button onClick={onRetry} variant="contained" color="primary">
          Retry
        </Button>
      </ErrorContent>
    </SlideInUp>
  )
}

const IdentityInProgress = () => {
  return (
    <SlideInUp delay={0}>
      <ProcessingContent>
        <Typography variant="h4" className="mb-3">
          Verifying Identity
        </Typography>
      </ProcessingContent>
    </SlideInUp>
  )
}

const IdentitySubmission: React.FC<React.PropsWithChildren<{ customerId: string; onComplete: () => void }>> = ({ customerId, onComplete }) => {
  const [createKyc, create] = useCreateKycMutation({
    client: legacyCheckout,
    update: (cache, { data }) => {
      try {
        const prevData = checkoutClient.cache.readQuery<KycQuery>({ query: KycDocument })
        const prevKycDocuments = prevData?.me?.kycDocuments ?? []
        logger().log({ prevData })
        const newData = cloneDeep({ ...prevData })
        const customerId = data?.createKycDocument?.customerId ?? ''
        const kycDocument = data?.createKycDocument?.kycDocument ?? null
        const kycMaxAttempts = data?.createKycDocument?.kycMaxAttempts ?? makeMaxAttempts()
        const newKycDocuments = ([] as typeof prevKycDocuments).concat(kycDocument || []).concat(prevKycDocuments)
        set(newData, 'me.__typename', 'Customer')
        set(newData, 'me.id', customerId)
        set(newData, 'me.kycDocuments', newKycDocuments)
        set(newData, 'me.kycMaxAttempts', kycMaxAttempts)
        logger().log({ newData })
        checkoutClient.cache.writeQuery({
          data: newData,
          query: KycDocument,
        })
        if (data && isFunction(onComplete)) {
          onComplete(data.createKycDocument)
        }
      } catch (e) {
        const error = e as Error
        logger().warn('Create Doc Cache Update Error', error.message)
      }
    },
  })
  return (
    <SlideInUp delay={0}>
      <KYCForm
        onSubmit={({ file }) =>
          createKyc({
            variables: {
              input: {
                customerId,
                file,
              },
            },
          })
        }
        loading={create.loading}
        error={create.error}
      />
    </SlideInUp>
  )
}

const IdentityError: React.FC<React.PropsWithChildren<{ message?: string }>> = ({ message }) => {
  return (
    <SlideInUp delay={0}>
      <ErrorContent>
        <Typography variant="h4" className="mb-4">
          Error
        </Typography>
        <Typography>{message}</Typography>
      </ErrorContent>
    </SlideInUp>
  )
}

const IdentityMaxError: React.FC<React.PropsWithChildren<{ title?: string; max?: number }>> = ({ max }) => {
  return (
    <SlideInUp delay={0}>
      <ErrorContent>
        <Typography variant="h4" gutterBottom>
          Maximum Attempts
        </Typography>
        <Typography>You have reached the maximum number of allowed attempts.</Typography>
        <Typography variant="h4" gutterBottom color="error">
          <em>{max}</em>
        </Typography>
        <Button component="a" href={process.env.REACT_APP_SUPPORT_URI} target="_blank" color="primary">
          Contact Support
        </Button>
      </ErrorContent>
    </SlideInUp>
  )
}

const IdentityMaxTodayError: React.FC<React.PropsWithChildren<{ max?: number }>> = ({ max }) => {
  return (
    <SlideInUp delay={0}>
      <ErrorContent>
        <Typography variant="h4" gutterBottom>
          Maximum Daily Attempts
        </Typography>
        <Typography>You have reached the maximum number of allowed attempts in one day.</Typography>
        <Typography>Please come back and try again tomorrow.</Typography>
        <Typography variant="h4" gutterBottom color="error">
          <em>{max}</em>
        </Typography>
      </ErrorContent>
    </SlideInUp>
  )
}

const makeMaxAttempts = () => ({ attempts: 0, attemptsToday: 0, maxAttempts: 5, maxAttemptsToday: 3 })

const CreateCustomer = () => {
  const id = 'create-customer-form'
  const [createCustomer, { loading }] = useCreateCustomerMutation()
  const { setSnackbar } = useSnackbar()[1]
  const onSubmit = async (userInfo: CustomerInput) => {
    try {
      await createCustomer({
        update: (cache, { data }) => {
          try {
            const prevData = cache.readQuery<KycQuery>({ query: KycDocument })
            logger().log({ prevData })
            const newData = cloneDeep({ ...prevData })
            const customerId = data?.createCustomer?.id ?? ''
            const firstName = data?.createCustomer?.primaryAddress?.firstName ?? ''
            const lastName = data?.createCustomer?.primaryAddress?.lastName ?? ''
            set(newData, 'me.__typename', 'Customer')
            set(newData, 'me.id', customerId)
            set(newData, 'me.firstName', firstName)
            set(newData, 'me.lastName', lastName)
            set(newData, 'me.kycDocuments', [])
            set(newData, 'me.kycMaxAttempts', makeMaxAttempts())
            logger().log({ newData })
            cache.writeQuery({
              data: newData,
              query: KycDocument,
            })
          } catch (e) {
            const error = e as Error
            logger().warn('Create Doc Cache Update Error', error.message)
          }
        },
        variables: userInfo,
      })
    } catch (e) {
      const message = parseError(e)
      setSnackbar({
        autoHideDuration: 6000,
        message,
        severity: 'error',
      })
    }
  }

  return (
    <>
      <Typography variant="subtitle2">Please enter your first and last name as they appear on your ID.</Typography>
      <UserInfoForm onSubmit={onSubmit} id={id} onlyName />
      <LoadingButton
        loading={loading}
        form={id}
        type="submit"
        variant="contained"
        disableElevation
        color="secondary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Next
      </LoadingButton>
    </>
  )
}

export default () => {
  return (
    <CheckoutApolloProvider>
      <Identity />
    </CheckoutApolloProvider>
  )
}
