import { ListItemAvatar, ListItemSecondaryAction } from '@mui/material'

import { CustomListItem } from '../../CustomList'
import { SweepstakesWhitelistProvider } from '../context'
import { SweepstakesDetailsAvatar, SweepstakesDetailsListItemText } from '../Details'
import { useSweepstakesOptions } from '../options'
import SweepstakesEnterButton from './SweepstakesEnterButton'
import useOnOpenSweestakes from './useOnOpenSweepstakes'

const SweepstakesListItem = () => {
  const options = useSweepstakesOptions()
  const onOpen = useOnOpenSweestakes()

  const item = (
    <CustomListItem onClick={onOpen}>
      <ListItemAvatar style={{ minWidth: 56 }}>
        <SweepstakesDetailsAvatar size={40} />
      </ListItemAvatar>
      <SweepstakesDetailsListItemText />
      <ListItemSecondaryAction>
        <SweepstakesEnterButton onClick={onOpen} />
      </ListItemSecondaryAction>
    </CustomListItem>
  )

  if (options?.test) {
    return <SweepstakesWhitelistProvider>{item}</SweepstakesWhitelistProvider>
  }

  return item
}

export default SweepstakesListItem
