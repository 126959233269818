import moment from 'moment'
import { useLocation } from 'react-router-dom'

import useMySubscription from './useMySubscription'

const useMySubscriptionMaster = () => {
  const location = useLocation()
  const mySubs = useMySubscription()
  const threeMonthsAgo = moment().subtract(3, 'months')
  const isMasterRoute = location.search.indexOf('master_ok') !== -1
  const startTime = moment(mySubs?.subscription?.startTime ?? '')
  const availableTime = moment(startTime).add(3, 'months')
  const isImpossibleReason =
    [
      'XYO-COIN-PRO-34-95',
      'XYO-COIN-PREMIUM-24-95',
      'XYO-COIN-PREMIUM-12-95',
      'XYO-COIN-PRO-3D-TRIAL-34-95',
      'XYO-COIN-PRO-7D-TRIAL-34-95',
    ].indexOf(mySubs?.subscription?.planId ?? '') === -1
      ? 'Current plan is not allowed to upgrade to COIN Master'
      : ''
  const isUnavailableReason = threeMonthsAgo.isBefore(startTime)
    ? `Available for upgrade ${availableTime.fromNow()}`
    : ''
  const isMasterOk = mySubs?.userInfo?.masterOk
  return {
    ...mySubs,
    availableTime: availableTime.fromNow(),
    isImpossibleReason: isImpossibleReason,
    isMasterOk: isMasterOk,
    isMasterRoute: isMasterRoute,
    isUnavailableReason: isUnavailableReason,
  }
}

export default useMySubscriptionMaster
