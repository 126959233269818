import gql from 'graphql-tag'

import redeemFragment from './redeemFragment'
import redeemOptionFragment from './redeemOptionFragment'

const redeemHistoryQuery = gql`
  query RedeemHistory(
    $pagination: Pagination
    $sort: Sort # $filter: RedeemFilter
  ) {
    redeemOptions {
      ...RedeemOption
    }
    myRedeemHistory(pagination: $pagination, sort: $sort) {
      total
      page
      data {
        ...Redeem
      }
    }
  }

  ${redeemFragment}
  ${redeemOptionFragment}
`

export default redeemHistoryQuery
