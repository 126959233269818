import { FlexCol } from '../components/Flex'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { redeemSubscription } from '../checkout'
import { swalError, swalSuccess } from '../componentsPure'
import CustomBasePage from '../CustomBasePage'
import { useAsyncEffect } from '../utils/useAsyncEffect'

const subscriptionIsActive = ({ isActive }: { isActive?: boolean }) => isActive

const useRedeemControl = (email: string, onComplete: () => void) => {
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async (mounted) => {
    try {
      if (!email) throw new Error('Email not supplied')
      const data = await redeemSubscription(email)
      if (data && data.success && data.subscriptions && data.subscriptions.some(subscriptionIsActive)) {
        if (mounted()) {
          setLoading(false)
          await swalSuccess('Your subscription has been confirmed.')
          onComplete()
        }
      } else if (data.userExists) {
        throw new Error('Subscription not found')
      } else {
        throw new Error('You must login to the COIN app before activating your subscription')
      }
    } catch (e) {
      const error = e as Error
      if (mounted()) {
        setLoading(true)
        await swalError(error.message)
        onComplete()
      }
    }
  })
  return { loading }
}

export default () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const match = search.match(/email=([^\&|\?]*)/)
  const email = match ? match[1] : ''
  const { loading } = useRedeemControl(email, () => navigate(`/account?email=${email}`))
  return (
    <CustomBasePage>
      <FlexCol />
    </CustomBasePage>
  )
}
