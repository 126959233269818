import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, styled } from '@mui/material'
import React, { ReactNode } from 'react'

import { AbsoluteLinearProgress } from '../Loader'

interface ConfirmDialogProps {
  loading?: boolean
  onConfirm?: (ev: React.MouseEvent) => void
  onClose?: (ev: React.MouseEvent, reason: 'cancelClick' | 'backdropClick' | 'escapeKeyDown') => void
  confirmButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
  confirm?: ReactNode
  cancel?: ReactNode
  title?: ReactNode
}

export function ConfirmDialog({
  loading,
  open,
  onClose,
  onConfirm,
  children,
  cancelButtonProps,
  confirmButtonProps,
  title = 'Are you sure?',
  confirm = 'Confirm',
  cancel = 'Cancel',
  ...props
}: DialogProps & ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onClose ? (
          <Button onClick={(ev) => onClose(ev, 'cancelClick')} {...cancelButtonProps}>
            {cancel}
          </Button>
        ) : null}
        {onConfirm ? (
          <RelativeButton onClick={onConfirm} variant="contained" color="primary" disabled={loading} {...confirmButtonProps}>
            {loading ? <AbsoluteLinearProgress /> : null}
            {confirm}
          </RelativeButton>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

const RelativeButton = styled(Button)`
  position: relative;
`
