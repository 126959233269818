import flow from 'lodash/flow'

const toLocaleString = flow(Number, (v) =>
  v.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
)

export default toLocaleString
