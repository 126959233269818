import { FC, SVGProps } from 'react'

import scansCircle from './scansCircle.png'
import scansFull from './scansFull.png'
import scansGreen from './scansGreen.png'
import scansWhite from './scansWhite.png'
import surveysCircle from './surveyCircle.png'
import surveysFull from './surveyFull.png'
import surveysGreen from './surveyGreen.png'
import surveysWhite from './surveyWhite.png'
import visitsCircle from './visitsCircle.png'
import visitsFull from './visitsFull.png'
import visitsGreen from './visitsGreen.png'
import visitsWhite from './visitsWhite.png'

// eslint-disable-next-line complexity
function getIconSrc(type: string, variant?: string) {
  if (variant === 'circle') {
    switch (type) {
      case 'scan':
      case 'scans':
        return scansCircle
      case 'visit':
      case 'visits':
        return visitsCircle
      case 'survey':
      case 'surveys':
        return surveysCircle
      default:
        return ''
    }
  }
  if (variant === 'green') {
    switch (type) {
      case 'scan':
      case 'scans':
        return scansGreen
      case 'visit':
      case 'visits':
        return visitsGreen
      case 'survey':
      case 'surveys':
        return surveysGreen
      default:
        return ''
    }
  }
  if (variant === 'white') {
    switch (type) {
      case 'scan':
      case 'scans':
        return scansWhite
      case 'visit':
      case 'visits':
        return visitsWhite
      case 'survey':
      case 'surveys':
        return surveysWhite
      default:
        return ''
    }
  }
  switch (type) {
    case 'scan':
    case 'scans':
      return scansFull
    case 'visit':
    case 'visits':
      return visitsFull
    case 'survey':
    case 'surveys':
      return surveysFull
    default:
      return ''
  }
}

const RewardIcon: FC<React.PropsWithChildren<{ type: string; variant?: string; alt?: string } & SVGProps<HTMLImageElement>>> = ({
  type,
  alt,
  variant,
  ...props
}) => {
  const src = getIconSrc(type, variant)
  if (!src) return null
  return <img src={src} {...props} alt={alt} />
}

export default RewardIcon
