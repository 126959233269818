import { Suspense } from 'react'

import VerifyPage from './VerifyPage'

const LazyVerifyPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Suspense fallback={<div />}>
      <VerifyPage />
    </Suspense>
  )
}

export default LazyVerifyPage
