import staticService from '@mapbox/mapbox-sdk/services/static'
import { origin } from 'quadkeytools'
import { CSSProperties, useMemo } from 'react'

import { polygonFeatureCollection, quadkeyFeatureCollection } from '../../utils'

const mapboxClient = staticService({
  accessToken: process.env.REACT_APP_MAPBOX || '',
})

export const QuadkeyMap = ({ quadkey, style }: { quadkey: string; style?: CSSProperties }) => {
  const imgSrc = useMemo(() => {
    const { lng, lat } = origin(quadkey)
    const features = [{ fill: '#5D3A7B', 'fill-opacity': 0.7, quadkey }]
    const geoJson = quadkeyFeatureCollection(features)
    return `${mapboxClient
      .getStaticImage({
        height: 300,
        overlays: [{ geoJson }],
        ownerId: 'mapbox',
        position: {
          coordinates: [lng, lat],
          zoom: quadkey.length - 2,
        },
        styleId: 'dark-v10',
        width: 300,
      })
      .url()}`
  }, [quadkey])
  return (
    <div
      style={{
        ...style,
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    ></div>
  )
}

export const BoundsMap = ({ coordinates, style }: { coordinates: number[][][]; style?: CSSProperties }) => {
  const imgSrc = useMemo(() => {
    const features = [{ coordinates, properties: { fill: '#5D3A7B', 'fill-opacity': 0.7 } }]
    const geoJson = polygonFeatureCollection(features)

    return `${mapboxClient
      .getStaticImage({
        height: 300,
        overlays: [{ geoJson }],
        ownerId: 'mapbox',
        position: 'auto',
        styleId: 'dark-v10',
        width: 300,
      })
      .url()}`
  }, [coordinates])
  return (
    <div
      style={{
        ...style,
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    ></div>
  )
}

export const GlobalMap = ({ style }: { style?: CSSProperties }) => {
  const imgSrc = useMemo(() => {
    return `${mapboxClient
      .getStaticImage({
        height: 300,
        ownerId: 'mapbox',
        position: {
          coordinates: [-9, -9],
          zoom: 0.02,
        },
        styleId: 'dark-v10',
        width: 300,
      })
      .url()}`
  }, [])
  return (
    <div
      style={{
        ...style,
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    ></div>
  )
}
