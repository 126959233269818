import { Divider } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { FlexCol, FlexRow } from '../Flex'

import CustomListItemProps from './CustomListItemProps'

const HoverFlexCol = styled(FlexCol)<BoxProps>`
&:hover {
  background-color: #88888888;
}`

const CustomListItem: React.FC<React.PropsWithChildren<CustomListItemProps>> = ({ onClick, style, skeleton, children, hideDivider, ...props }) => {
  const Cmp = onClick ? HoverFlexCol : FlexCol
  return (
    <Cmp
      alignItems="stretch"
      style={{ cursor: onClick ? 'pointer' : undefined, ...style }}
      onClick={onClick}
      {...props}
    >
      <FlexRow justifyContent="space-between" padding={2}>
        {children ?? skeleton}
      </FlexRow>
      {hideDivider ? null : <Divider />}
    </Cmp>
  )
}

export default CustomListItem
