import { Box, CardContent, DialogActions, DialogContent } from '@mui/material'

import { EnterStepActions, EntryConfirmStepActions, EntryCountStepActions, EntryConfirmCancelActions } from './Actions'
import { SweepstakesEntryStep } from './context'
import { SweepstakesDetailsHeader, SweepstakesPrizeDetails } from './Details'
import { EnterRequireCountryActions, SweepstakesRequiredCountryDetails, SweepstakesEligibleAgreement } from './Resident'
import { Stepper } from './Stepper'
import { SweepstakesEntryList } from './Buttons'
import { SweepstakesFreeEntryButton } from './FreeEntry'
import { SweepstakesTermsAgreement } from './Terms'

export const SweepstakesEntryForm = () => {
  return (
    <>
      <SweepstakesDetailsHeader />
      <DialogContent sx={{ p: 0 }}>
        <SweepstakesEntryList />
      </DialogContent>
      <DialogActions>
        <Box flex={1}>
          <SweepstakesEligibleAgreement />
          <SweepstakesTermsAgreement />
          <EntryConfirmCancelActions />
          <SweepstakesFreeEntryButton />
        </Box>
      </DialogActions>
    </>
  )
}

const SweepstakesEntryRequiredCountry = () => {
  return (
    <>
      <SweepstakesDetailsHeader />
      <SweepstakesRequiredCountryDetails />
    </>
  )
}

const SweepstakesEntryFormDetails = () => {
  return (
    <>
      <SweepstakesDetailsHeader />
      <Box px={2} pb={1}>
        <SweepstakesPrizeDetails />
      </Box>
    </>
  )
}
