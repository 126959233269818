import { CardProps } from '@mui/material'

import PlanCard from './PlanCard'

const BasicPlanCard: React.FC<React.PropsWithChildren<CardProps>> = (props) => {
  return (
    <PlanCard
      title="COIN Basic"
      subtitle="Core Features"
      price={<strong>Free</strong>}
      features={[
        '1x Geomining Speed',
        '1x Recharge Rate',
        '1x Likelihood of Big Geomines',
        '12x Geomining Rewards (requires SentinelX)',
        'No Additional Geomining Rewards',
        'Auto Collect',
      ]}
      {...props}
    />
  )
}

export default BasicPlanCard
