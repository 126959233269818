import { ApolloClient, ApolloProvider } from '@apollo/client'
import { verifyAddressClient } from '@xyo-network/api-address-verifier-apollo/bindings'

import getRequestContext from './getRequestContext'

export const SIGNING_DATA = 'I <3 XYO'

export const verifyClient = verifyAddressClient('cluster', getRequestContext) as unknown as ApolloClient<any>

export const VerifyProvider = ({ children }: React.PropsWithChildren) => {
  return <ApolloProvider client={verifyClient}>{children}</ApolloProvider>
}
