import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Maybe, PaymentCard } from '@xyo-network/api-checkout-apollo'
import { useState } from 'react'

import { SelectPaymentCard } from '../../../../components'
import { FlexCol, FlexRow } from '../../../../components/Flex'
import { ErrorViewer } from '../../../../componentsPure'
import { useBooleanControls } from '../../../../utils'
import { Checkout } from '../../Checkout'
import { IUpdgradeTableData } from './ConfirmUpgradeContext'
import UpgradeTable from './UpgradeTable'

type ConfirmationProviderProps = {
  data: IUpdgradeTableData
  cards?: Maybe<Maybe<PaymentCard>[]>
  title?: string
  onConfirmed?: (v?: { method: string; paymentCardId: string }) => void
  onCancel?: () => void
}

const ConfirmationProvider = ({ data, cards, title, onConfirmed, onCancel }: ConfirmationProviderProps) => {
  const [selectedCard, setSelectedCard] = useState(cards && cards.find((c) => c?.status === 'active'))
  const [error, setError] = useState('')
  const [add, addControls] = useBooleanControls(false)
  const onSubmit = (paymentCardId: string) => {
    try {
      onConfirmed?.({
        method: 'payment-card',
        paymentCardId,
      })
    } catch (ex) {
      const error = ex as Error
      setError(error.message)
    }
  }
  if (add)
    return (
      <FlexCol alignItems="stretch">
        <ErrorViewer error={error} />
        <Checkout onSuccess={onSubmit} onCancel={addControls.setFalse} />
      </FlexCol>
    )
  return (
    <FlexCol alignItems="stretch">
      <FlexRow mb={2}>
        <Typography variant="h2">{title || 'Confirm Plan Change'}</Typography>
      </FlexRow>
      <SelectPaymentCard cards={cards} onChange={setSelectedCard} onAdd={addControls.setTrue} />
      <FlexRow marginY={3}>
        <UpgradeTable data={data} />
      </FlexRow>
      <FlexRow>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          sx={{ marginLeft: 1 }}
          onClick={() =>
            selectedCard &&
            selectedCard.id &&
            onConfirmed?.({
              method: 'payment-card',
              paymentCardId: selectedCard.id,
            })
          }
        >
          Confirm
        </Button>
      </FlexRow>
    </FlexCol>
  )
}

export default ConfirmationProvider
