import 'react-html5-camera-photo/build/css/index.css'
import './index.scss'

import { ApolloError } from '@apollo/client'
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material'
import { useCallback } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo'

import idPng from '../../assets/ID-good-01.png'
import { swalError } from '../../componentsPure'
import { parseError, useBooleanControls } from '../../utils'
import { LoadingButton } from '../Buttons'
import { Form, FormField } from '../Form'
import CancelIcon from '@mui/icons-material/Cancel'

const initialValues = { file: '', method: 'webcam' }
const idealResolution = { height: 360, width: 640 }
const kycFormValidator = {
  agree: (v: string) => !v,
  file: (v: string) => (v ? '' : 'Image is required'),
}

interface KYCFormProps {
  onSubmit: (values: { file: string }) => void
  loading: boolean
  error?: ApolloError
}

export const KYCForm = ({ onSubmit, loading, error }: KYCFormProps) => {
  const [example, exampleControls] = useBooleanControls(true)
  const [cameraError, cameraErrorControls] = useBooleanControls(false)
  const onCameraStartError = () => {
    cameraErrorControls.setTrue()
  }
  const handleSubmit = (values: unknown) => {
    if (onSubmit) {
      const file = (values as { file: string }).file.split('base64,')[1]
      onSubmit({ file })
    }
  }
  return (
    <Form initial={initialValues} validator={kycFormValidator} onSubmitForm={handleSubmit}>
      {({ values, errors, handleChange }: any) => (
        <>
          <Typography variant="h4" paragraph>
            Verify your identification
          </Typography>
          <Typography paragraph>
            Upload or take a webcam photo of a valid government-issued ID (e.g. driver license, identification card, or
            passport).
          </Typography>
          <Typography color="error">Do not submit a Selfie with your ID. Only submit a clear picture of the ID document alone.</Typography>
          {example ? (
            <>
              <Box my={3}>
                <img src={idPng} alt="Example Identification" style={{ width: '100%' }} />
              </Box>
              <LoadingButton fullWidth variant="contained" color="primary" onClick={exampleControls.setFalse}>
                Continue
              </LoadingButton>
            </>
          ) : (
            <>
              {cameraError ? <Box mb={5} /> : <FormField name="method" component={UploadMethod} />}
              <Box position="relative">
                {cameraError || values.method === 'upload' ? (
                  <FormField name="file" component={FileUpload} onError={(e: Error) => swalError(e.message)} />
                ) : (
                  <FormField name="file" component={WebcamUpload} onCameraStartError={onCameraStartError} />
                )}
                {values.file ? (
                  <>
                    <img
                      src={values.file}
                      style={{
                        height: '100%',
                        left: 0,
                        objectFit: 'cover',
                        pointerEvents: 'none',
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                      }}
                    />
                    <FileRemoveButton onClick={() => handleChange('file', '')} />
                  </>
                ) : null}
              </Box>
              <FormField name="agree" type="checkbox" component={TokenIssuanceAgreement} />
              {errors && errors.agree && (
                <Typography color="error">You must read and accept the XYO Token Issuance Agreement</Typography>
              )}
              {error ? <Typography color="error">{parseError(error)}</Typography> : null}
              <LoadingButton
                fullWidth
                type="submit"
                loading={loading}
                disabled={!values.file}
                variant="contained"
                color="primary"
              >
                Submit
              </LoadingButton>
            </>
          )}
        </>
      )}
    </Form>
  )
}

const UploadMethod = ({ name, value, onChange }: RadioGroupProps) => {
  return (
    <FormControl component="fieldset" style={{ width: '100%' }}>
      <RadioGroup
        sx={{ justifyContent: 'center' }}
        aria-label="method"
        onChange={onChange}
        name={name}
        value={value}
        row
      >
        <FormControlLabel value="webcam" control={<Radio />} label="Use webcam" />
        <FormControlLabel value="upload" control={<Radio />} label="Upload photo" />
      </RadioGroup>
    </FormControl>
  )
}

const TokenIssuanceAgreement = ({ checked, name, onChange }: CheckboxProps) => {
  return (
    <>
      <div>
        <p>
          Please read and agree to the{' '}
          <a
            target="_blank"
            href="https://cdn.xy.company/documents/Token_Purchase_Agreement_March_2019.pdf"
            rel="noreferrer"
          >
            XYO Token Issuance Agreement
          </a>
        </p>
      </div>
      <Box display="flex" alignItems="center" mb={3}>
        <Checkbox id="token-issuance-agreement" name={name} checked={checked} onChange={onChange} />
        <label htmlFor="token-issuance-agreement">
          I have read and agree to the terms of the XYO Token Issuance Agreement
        </label>
      </Box>
    </>
  )
}

interface WebcamUploadProps {
  name: string
  onChange: (name: string, base86Image: string) => void
  onCameraStartError: (e: Error) => void
  onError: (e: Error) => void
}

const WebcamUpload = ({ name, onChange, onCameraStartError, onError }: WebcamUploadProps) => {
  const setValue = useCallback(
    (base86Image: unknown) => {
      onChange(name, base86Image as string)
    },
    [onChange, name]
  )

  const { getRootProps, getInputProps } = useDrop(setValue, onError)

  return (
    <>
      <Camera
        // onTakePhoto={setValue}
        onCameraError={onCameraStartError}
        onTakePhotoAnimationDone={setValue}
        isImageMirror={false}
        idealResolution={idealResolution}
        imageCompression={1}
        imageType={IMAGE_TYPES.JPG}
      />
      <Box {...getRootProps()} sx={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
        <input {...getInputProps()} />
      </Box>
    </>
  )
}

interface FileUploadProps {
  name: string
  onError: (e: Error) => void
  onChange: (name: string, base86Image: string) => void
}

const FileUpload = ({ name, onChange, onError }: FileUploadProps) => {
  const setValue = useCallback(
    (base86Image: unknown) => {
      onChange(name, base86Image as string)
    },
    [onChange, name]
  )

  const { getRootProps, getInputProps } = useDrop(setValue, onError)

  return (
    <section className="kyc-container">
      <div {...getRootProps()} className="dropzone" style={{ width: '100%' }}>
        <input id="upload-identity" {...getInputProps()} />
        <label htmlFor="upload-identity" className="kyc-container-label" onClick={(ev) => ev.stopPropagation()}>
          Drag and drop an image from your computer, or click to select an image from your device. Max filesize 6MB
        </label>
      </div>
    </section>
  )
}

const FileRemoveButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div id="container-circles" style={{ zIndex: 1 }}>
      <div id="outer-circle" onClick={onClick}>
        <Box id="inner-circle" display="flex" alignItems="center" justifyContent="center">
          <CancelIcon color="error" />
        </Box>
      </div>
    </div>
  )
}

const MAX = 5

function useDrop(onSuccess: (image: unknown) => void, onError: (e: Error) => void) {
  const onDropAccepted = useCallback(
    async ([file]: Blob[]) => {
      try {
        const image = await readFile(file)
        onSuccess(image)
      } catch (e) {
        onError(e as Error)
      }
    },
    [onSuccess, onError]
  )
  const onDropRejected = useCallback(([ev]: FileRejection[]) => {
    const [error] = ev.errors || []
    if (error.code === 'file-too-large') {
      onError(new Error(`Image must be smaller than ${MAX} MB`))
    } else if (error) {
      onError(new Error(error.message))
    }
  }, [onError])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    onDropAccepted,
    onDropRejected,
    maxSize: MAX * 1e6,
  })

  return { getInputProps, getRootProps }
}

function readFile(file: Blob) {
  return new Promise((res, rej) => {
    if (!file) rej(new Error('No file supplied'))
    const reader = new FileReader()
    reader.addEventListener('load', () => res(reader.result), false)
    reader.addEventListener('error', (e) => rej(e))
    const { size } = file
    if (size < 4.8e7) {
      reader.readAsDataURL(file)
    } else {
      rej(new Error('File too large'))
    }
  })
}
