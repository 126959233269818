const linkExp = /((http|https)\:\/\/)?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,4}(\?[a-zA-Z0-9\-\_\&\=]+)?)(\/\S*)?/

const parseMessage = (text: string) => {
  const matched = text.match(linkExp)
  if (matched && matched[1]) {
    return text.replace(linkExp, `<a href="${matched[0]}">${matched[0]}</a>`)
  } else if (matched) {
    return text.replace(linkExp, `<a href="//${matched[0]}">${matched[0]}</a>`)
  }
  return text
}

export default parseMessage
