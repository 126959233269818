import { AlertTitle } from '@mui/material'
import { ChangeEventHandler, useState } from 'react'

import { useSnackbar } from '../../components'
import { updateProfileImage } from '../../context'
import { parseError } from '../../utils'
import { useUserInfo } from '../hooks'
import { convertHEIC, cropImage } from './cropImage'
import ProfileImage, { ProfileImageProps } from './ProfileImage'
import { UpdateImageControl } from './UploadImage'

const UpdateableProfileImage = ({ loading, profileImage, ...props }: ProfileImageProps) => {
  const { refetch } = useUserInfo()
  const [previewSrc, setPreviewSrc] = useState('')
  const [saving, setSaving] = useState(false)
  const { setSnackbar } = useSnackbar()[1]

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (ev) => {
    if (ev.target.files) {
      const file = ev.target.files[0]
      const converted = await convertHEIC(file)
      const src = await cropImage(converted)
      setPreviewSrc(src)
    }
  }

  const handleSave = async () => {
    try {
      setSaving(true)
      await updateProfileImage(previewSrc)
      await refetch()
      setPreviewSrc('')
      setSnackbar({
        autoHideDuration: 10000,
        message: (
          <>
            <AlertTitle>Image Updated</AlertTitle> You have successfully updated your profile image.
          </>
        ),
        severity: 'success',
      })
    } catch (err) {
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(err),
        severity: 'error',
      })
    } finally {
      setSaving(false)
    }
  }

  const handleCancel = () => {
    setPreviewSrc('')
  }

  return (
    <ProfileImage {...props} loading={saving || loading} profileImage={previewSrc || profileImage} hidePlanIcon={true}>
      {loading ? null : <UpdateImageControl previewSrc={previewSrc} onChange={handleChange} onSave={handleSave} onCancel={handleCancel} />}
    </ProfileImage>
  )
}

export default UpdateableProfileImage
