import { Skeleton, useTheme } from '@mui/material'
import { FlexCol } from '../../../components/Flex'

const BackgroundRewardPercent: React.FC<React.PropsWithChildren<{ loading: boolean; percent: number }>> = ({ loading, percent }) => {
  const theme = useTheme()
  if (loading)
    return (
      <Skeleton
        variant="circular"
        width={60}
        height={60}
        className="skeleton"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      />
    )

  return percent ? (
    <FlexCol
      bgcolor={theme.palette.info.main}
      color={theme.palette.info.contrastText}
      borderRadius={60}
      height={60}
      lineHeight={1}
      overflow="hidden"
      flexWrap="nowrap"
      width={60}
    >
      <strong>{percent}%</strong>
    </FlexCol>
  ) : null
}

export default BackgroundRewardPercent
