import { Modal } from '@mui/material'
import { FlexRow } from '../Flex'
import { forwardRef } from 'react'

import PlanModalOpenContext from './PlanModalOpenContext'
import Plans from './Plans'

const PlanModal = () => {
  const [open, setOpen] = PlanModalOpenContext.useContext()
  const Body = forwardRef((props, ref) => {
    return (
      <FlexRow ref={ref} style={{ pointerEvents: 'none' }} padding={2} {...props}>
        <Plans />
      </FlexRow>
    )
  })
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Body />
    </Modal>
  )
}

export default PlanModal
