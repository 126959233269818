import { Box, CardHeader, Dialog, Link, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Link as ReactLink, useLocation } from 'react-router-dom'

import appStore from '../../assets/app-store-badge.svg'
import playStore from '../../assets/get-it-on-google-play.svg'
import { makeContext } from '../../utils'
import { sweepstakesEvent } from './event'
import { SweepstakesFreeEntryDialogOpen } from './FreeEntry'

export const SweepstakesDownloadDialogOpen = makeContext(false, 'download_dialog_open')

const appUris = {
  fromAd: 'https://coin.onelink.me/ePJg/9eafc196',
  normal: 'https://coin.onelink.me/ePJg/9ff19d08',
}
const useAppUri = () => {
  const location = useLocation()
  return useMemo(() => {
    const isFromAd = location.search.indexOf('utm') !== -1
    return isFromAd ? appUris.fromAd : appUris.normal
  }, [location.search])
}

export const SweepstakesDownloadDialog = () => {
  const appUri = useAppUri()
  const [open, setOpen] = SweepstakesDownloadDialogOpen.useContext()
  const setFreeEntryOpen = SweepstakesFreeEntryDialogOpen.useContext()[1]
  const handleAlreadyHaveAccount = () => {
    setOpen(false)
    sweepstakesEvent('download', 'have-account', {})
  }
  const handleAlternative = () => {
    setOpen(false)
    setFreeEntryOpen(true)
    sweepstakesEvent('download', 'alternative-entries', {})
  }
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <CardHeader title="Entering is Simple" />
        <Box px={2} style={{ WebkitOverflowScrolling: 'touch', overflowY: 'auto' }}>
          <Typography>Step 1) Download COIN (a free app that allows you to easily earn rewards)</Typography>
          <Box display="flex">
            <Box py={2} pr={1}>
              <a href={appUri}>
                <img style={{ height: 40 }} alt="Available on the AppStore" src={appStore} />
              </a>
            </Box>
            <Box py={2}>
              <a href={appUri}>
                <img style={{ height: 40 }} alt="Get it on Google Play" src={playStore} />
              </a>
            </Box>
          </Box>
          <Typography>Step 2) Earn in-app rewards and use them to enter Sweepstakes</Typography>
        </Box>
        <Box p={2} display="flex">
          <Link
            color="textSecondary"
            style={{
              cursor: 'pointer',
              lineHeight: '1em',
              textDecoration: 'underline',
            }}
            onClick={handleAlternative}
          >
            Alternative ways to enter
          </Link>
          <Box flexGrow={1} />
          <Link
            color="secondary"
            style={{ lineHeight: '1em', textAlign: 'right' }}
            to={'/auth?redirect=/sweepstakes'}
            onClick={handleAlreadyHaveAccount}
            component={ReactLink}
          >
            Already have an account?
          </Link>
        </Box>
      </Dialog>
    </>
  )
}
