import { UpgradeModal } from '../../../checkout'
import { BossProvider } from '../../../context'
import RedeemBody, { RedeemTab } from './RedeemBody'

const RedeemPage: React.FC<React.PropsWithChildren<{ initialTab?: RedeemTab }>> = ({ initialTab }) => {
  return (
    <>
      <BossProvider>
        <RedeemBody initialTab={initialTab} />
      </BossProvider>
      <UpgradeModal />
    </>
  )
}

export default RedeemPage
