import { coinInmarketUri } from '@coin-microservices/coin-inmarket-react-hooks'

import { makeApolloAuthProvider } from './apollo'

// const uri =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:4000'
//     : coinInmarketUri

export const InmarketProvider = makeApolloAuthProvider(coinInmarketUri)
