import { forget } from '../utils'
import Button, { ButtonProps } from '@mui/material/Button'
import {
  PaymentCard,
  RebillySubscription,
  useChangeSubscriptionMutation,
  useChangeSubscriptionPreviewMutation,
} from '@xyo-network/api-checkout-apollo'
import get from 'lodash/get'

import { redeemSubscription, useConfirmPaymentPrompt } from '../checkout'
import { swalLoader } from '../components'
import { swalError, swalSuccess } from '../componentsPure'
import { refetchAccounts, useUser } from '../User'
import { parseError, trackChangeEvent, trackWarning } from '../utils'

interface Props extends ButtonProps {
  planId: string
  cards?: (PaymentCard | null)[] | null
  subscription: RebillySubscription | null
}

const ChangeSubscription: React.FC<React.PropsWithChildren<Props>> = ({ cards, subscription, planId, ...props }) => {
  const confirmPaymentPrompt = useConfirmPaymentPrompt()
  const { user } = useUser()
  const [change] = useChangeSubscriptionMutation({
    variables: {
      paymentCardId: '',
      planId,
      subscriptionId: subscription ? subscription.id : '',
    },
  })
  const [changePreview] = useChangeSubscriptionPreviewMutation({
    variables: {
      planId,
      subscriptionId: subscription ? subscription.id : '',
    },
  })
  const handleClick = async () => {
    const email = get(user, 'email')
    if (!change || !changePreview || !subscription || !email) return
    const subscriptionId = subscription.id
    trackChangeEvent('view', {})
    const preview = changePreview().then((res) => res.data?.changeSubscriptionPreview)

    const card = await confirmPaymentPrompt(preview, Promise.resolve((cards || []).filter((v) => v)))
    if (!card || !card.paymentCardId) return trackChangeEvent('canceled', {})
    await preview
    try {
      trackChangeEvent('confirmed', {})
      forget(swalLoader(<p>Changing Subscription...</p>))
      await change({
        variables: {
          paymentCardId: card.paymentCardId,
          planId,
          subscriptionId,
        },
      })
      await redeemSubscription(email)
      forget(refetchAccounts())
      trackChangeEvent('success', {})
      forget(swalSuccess('Your subscription has been changed successfully!'))
    } catch (e) {
      const error = e as Error
      trackWarning('Change subscription error', error.message)
      trackChangeEvent('error', { value: error.message })
      forget(swalError(parseError(error.message)))
    }
  }

  return subscription && <Button onClick={handleClick} {...props} />
}

export default ChangeSubscription
