import { InmarketReward } from '@coin-microservices/coin-inmarket-react-hooks'
import { Skeleton, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '../../../components/Flex'

import { CustomListItem, CustomListItemProps, RewardIconBox } from '../../../components'
import { numberFormat } from '../../../utils'

interface Props extends CustomListItemProps {
  loading?: boolean
  reward?: InmarketReward
}

const InmarketListItem: React.FC<React.PropsWithChildren<Props>> = ({ reward, loading, ...props }) => {
  const amount = reward?.points ?? reward?.amount ?? 0
  const date = reward?.rewarded ?? reward?.date
  return (
    <CustomListItem {...props}>
      <RewardIconBox
        marginY={3}
        width={80}
        height={80}
        loading={loading}
        type={reward?.type ?? ''}
        variant="white"
        style={{ height: 32 }}
      />
      <FlexCol alignItems="flex-end">
        <Typography variant="h4">Earned: {loading ? <Skeleton variant='text' width={130} /> : numberFormat(amount)}</Typography>
        <Typography>
          {loading ? <Skeleton variant='text' width={100} /> : <small>{date ? new Date(date).toLocaleDateString() : <span>&nbsp;</span>}</small>}
        </Typography>
      </FlexCol>
    </CustomListItem>
  )
}

export default InmarketListItem
