import fetch from 'isomorphic-fetch'

import fireAuth from './fireAuth'

const getOrCreateCoinUser = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/get-or-create/`,
    await fireAuth()
  )
  const data = await res.json()
  return data
}

export default getOrCreateCoinUser
