import gql from 'graphql-tag'

const HODL_FRAGMENT = (name: string) => gql`
fragment ${name} on ${name} {
  id
  uid
  xyoHodlRewards
  xyoTotalHodlRewards
  xyoCollected
  hodlInApp
  hodlVerifiedPair
  hodlVerifiedPairTokens
  hodlTotal
  hodlVerified
  hodlMultiplier
  hodlIsFhr
  date
  yyyymmdd
  helmet
  helmetAmount
}`

const HODL_HISTORY = gql`
  query UserHodlHistory($page: Int, $perPage: Int, $field: String = "date", $filter: HodlHistoryFilter) {
    hodlHistory(filter: $filter, pagination: { page: $page, perPage: $perPage }, sort: { field: $field, order: -1 }) {
      total
      data {
        ...HodlHistory
      }
    }
  }

  ${HODL_FRAGMENT('HodlHistory')}
`

export { HODL_HISTORY }
