import { Poll } from '@mui/icons-material'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { MouseEventHandler, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import bitlabsLogo from '../../assets/bitlabs.svg'
import inbrainLogo from '../../assets/inbrain.png'
import pollfishLogo from '../../assets/pollfish.png'
import { usePollFish } from '../../context'
import {
  trackSurveyBitlabsEvent,
  trackSurveyDialogOpenEvent,
  trackSurveyInbrainEvent,
  trackSurveyPollfishEvent,
  trackSurveyPrecisionEvent,
} from '../../utils'
import { FlexRow } from '../Flex'
import { useBitlabs } from './BitlabsProvider'
import { useInbrain } from './InbrainProvider'
import { usePrecisionSample } from './PrecisionSampleProvider'
import { useSurveyProvider } from './SurveyProviderProvider'
import { showDisclaimer } from './showDisclaimer'

const SurveyNav = () => {
  const { pathname } = useLocation()
  const setPrecisionOpen = usePrecisionSample()[1]
  const setInbrainOpen = useInbrain()[1]
  const setBitlabsOpen = useBitlabs()[1]
  const pollFish = usePollFish()
  const [, setModalOpen] = useSurveyProvider()
  const [open, setOpen] = useState(false)
  const handleDialogOpen = async () => {
    setModalOpen(true)
    trackSurveyDialogOpenEvent('fab', {})
  }
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handlePrecision: MouseEventHandler = async (ev) => {
    if (ev) ev.stopPropagation()
    await showDisclaimer()
    setPrecisionOpen(true)
    trackSurveyPrecisionEvent('fab', {})
  }
  const handlePollfish: MouseEventHandler = async (ev) => {
    if (ev) {
      ev.stopPropagation()
    }

    await showDisclaimer()
    pollFish
      .initialize()
      .then(() => pollFish.showFullSurvey())
      .then(handleClose)
      .catch((err) => console.warn(err))

    trackSurveyPollfishEvent('fab', {})
  }

  const handleBitlabs: MouseEventHandler = async (ev) => {
    if (ev) {
      ev.stopPropagation()
    }

    await showDisclaimer()
    trackSurveyBitlabsEvent('fab', {})
    setBitlabsOpen(true)
  }

  const handleInbrain: MouseEventHandler = async (ev) => {
    if (ev) {
      ev.stopPropagation()
    }

    await showDisclaimer()
    trackSurveyInbrainEvent('fab', {})
    setInbrainOpen(true)
  }

  useEffect(() => {
    setPrecisionOpen(pathname.includes('precision'))
  }, [pathname, setPrecisionOpen])
  return (
    <FlexRow position="fixed" padding={4} bottom={0} right={0} zIndex={1}>
      <SpeedDial
        ariaLabel="Survey providers"
        // hidden={hidden}
        FabProps={{
          sx: {
            border: '3px solid #eee',
            boxSizing: 'border-box',
            color: '#eee',
          },
        }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        onClick={handleDialogOpen}
        open={open}
        direction="up"
      >
        <SpeedDialAction
          icon={<img src={bitlabsLogo} alt="Bitlabs" style={{ height: 16, opacity: 0.8 }} />}
          tooltipTitle="Bitlabs"
          onClick={handleBitlabs}
        />
        <SpeedDialAction
          icon={<img src={pollfishLogo} alt="Pollfish" style={{ height: 16, opacity: 0.8, transform: 'translateY(2px)' }} />}
          tooltipTitle="Pollfish"
          onClick={handlePollfish}
        />
        <SpeedDialAction icon={<Poll />} tooltipTitle="Precision" onClick={handlePrecision} />
        <SpeedDialAction
          icon={<img src={inbrainLogo} alt="inBrain" style={{ filter: 'brightness(100)', height: 16, opacity: 0.8 }} />}
          tooltipTitle="inBrain"
          onClick={handleInbrain}
        />
      </SpeedDial>
    </FlexRow>
  )
}

export default SurveyNav
