import { SVGProps } from 'react'

export const BasicIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 176 184"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>basicUser</title>
      <path
        fill="#fff"
        d="M88,0 C61.488,0 40,21.488 40,48 L40,56 C40,82.512 61.488,104 88,104 C114.512,104 136,82.512 136,56 L136,48 C136,21.488 114.512,0 88,0 Z M87.984376,128 C55.936376,128 14.8163752,145.334752 2.9843752,160.718752 C-4.3276248,170.230752 2.633,184 14.625,184 L161.359376,184 C173.351376,184 180.312,170.230752 173,160.718752 C161.168,145.342752 120.032376,128 87.984376,128 Z"
        id="basicUser"
      ></path>
    </svg>
  )
}
