import { ApolloClient, ApolloProvider, concat, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from 'apollo-link-context'
import { getAuth } from 'firebase/auth'
import { FC } from 'react'

const authLink: any = setContext(async (op, { headers }) => {
  const user = getAuth().currentUser
  const token = user ? await user.getIdToken() : ''
  return {
    headers: {
      ...headers,
      'X-Auth-Token': token
    },
  }
})

export const getClient = (uri: string) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authLink, new HttpLink({ uri })),
  })

export const makeApolloAuthProvider = (uri: string) => {
  const client = getClient(uri)
  const Provider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>
  }
  return Provider
}
