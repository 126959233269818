import { FlexRow } from '../../components/Flex'
import { BoxProps } from '@mui/material/Box'
import { memo } from 'react'

import { parseError } from '../../utils'

/* Called this ErrorViewer to prevent conflict with standard Error class */

interface Props extends BoxProps {
  error?: string | Error
}

const ErrorViewer = memo<Props>(({ error, ...props }) => {
  const message = parseError(error)
  return message ? <FlexRow {...props}>{message}</FlexRow> : null
})

export default ErrorViewer
