import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import times from 'lodash/times'

import Achievement from './Achievement'
import BadgeCard from './BadgeCard'
import NoBadges from './NoBadges'
import rankAcheivements from './rankAcheivements'
import useAchievementsCollected from './useAchievementsCollected'

const BadgeCards = () => {
  const { data, loading } = useAchievementsCollected()
  const ranked = rankAcheivements(data?.account?.userInfo?.achievementsCollected ?? [])

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const items = loading ? times(6).map<Achievement | null>(() => null) : ranked
  const cols: (Achievement | null)[][] = times(isMdUp ? 3 : isSmUp ? 2 : 1).map<Achievement[]>(() => [])
  items.forEach((item, i) => {
    cols[i % cols.length].push(item)
  })

  return (
    <Grid container spacing={2}>
      {items.length ? (
        cols.map((achievements, i) => (
          <Grid item sm={6} md={4} key={i}>
            {achievements
              .filter((achievement) => achievement && achievement.info)
              .map((achievement, j) => {
                return achievement ? (
                  <Box mb={2} key={j}>
                    <BadgeCard achievement={achievement} />
                  </Box>
                ) : null
              })}
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <NoBadges />
        </Grid>
      )}
    </Grid>
  )
}

export default BadgeCards
