import { Typography } from '@mui/material'
import { FlexGrowCol, FlexRow } from './components/Flex'
import { useState } from 'react'

import { ConfirmAppLinkDialog, EditInApp } from './components'
import { SubscriptionInfo, useMySubscription } from './Subscriptions'
import { ResetPassword, UserAvator, useUser } from './User'

const UserInfoDisplay: React.FC<React.PropsWithChildren<{ resetPassword?: boolean }>> = ({ children, resetPassword }) => {
  const { user } = useUser()
  const { userInfo } = useMySubscription()
  const [accountLinkOpen, setAccountLinkOpen] = useState(false)

  return user ? (
    <FlexRow>
      <UserAvator />
      <FlexGrowCol marginLeft={1} alignItems="stretch">
        <FlexRow justifyContent="flex-start">
          <EditInApp
            initial={userInfo?.username ? <Typography variant="h4">{userInfo?.username}</Typography> : undefined}
            placeholder={'Set username'}
            onEdit={() => {
              setAccountLinkOpen(true)
            }}
          />
          <ConfirmAppLinkDialog open={accountLinkOpen} link="/account" onClose={() => setAccountLinkOpen(false)}>
            <Typography>In the app, tap the &apos;Username&apos; field to update it.</Typography>
          </ConfirmAppLinkDialog>
        </FlexRow>
        <FlexRow justifyContent="flex-start">
          <Typography>{user.email}</Typography>
        </FlexRow>
        <FlexRow justifyContent="flex-start"></FlexRow>
        {resetPassword ? <ResetPassword /> : null}
        <FlexRow justifyContent="flex-start" marginY={1} flexDirection="column" alignItems="flex-start">
          <SubscriptionInfo />
          {children}
        </FlexRow>
      </FlexGrowCol>
    </FlexRow>
  ) : null
}

export default UserInfoDisplay
