import { Close } from '@mui/icons-material'
import { CardHeader, Dialog, IconButton, Tooltip, Typography } from '@mui/material'

import { useUser } from '../../User'
import { usePrecisionSample } from './PrecisionSampleProvider'

const PrecisionSampleDialog = () => {
  const { user } = useUser()
  const [open, setOpen] = usePrecisionSample()
  const close = () => setOpen(false)

  if (!user || !user.uid) return null

  return (
    <Dialog fullScreen open={open} onClose={close}>
      <CardHeader
        title="Precision Sample"
        action={
          <Tooltip title={<Typography>Return to COIN</Typography>}>
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          </Tooltip>
        }
      />
      {user?.uid ? (
        <iframe
          src={`https://www.opinionetwork.com/reg/signup?rid=20895&ct=usa&extmid=${user?.uid}`}
          title="Precision Sample"
          height="100%"
          width="100%"
          frameBorder="0"
        />
      ) : null}
    </Dialog>
  )
}

export default PrecisionSampleDialog
