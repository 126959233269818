import { CheckoutApolloProvider } from '../../checkout'
import CustomBasePage from '../../CustomBasePage'
import InvoiceListWithQuery from './InvoiceListWithQuery'

export default () => {
  return (
    <CheckoutApolloProvider>
      <CustomBasePage>
        <InvoiceListWithQuery />
      </CustomBasePage>
    </CheckoutApolloProvider>
  )
}
