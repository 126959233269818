import get from 'lodash/get'

export type IPlanDetails = {
  successText?: string
  planId: string
  annualPlanId?: string
  amount: number
  annualAmount?: number
  annualText?: string
  label?: string
  buttonText?: string
}

export const getCoinFreeTrialDetails = (): IPlanDetails => {
  return {
    amount: Number(get(window, 'coin_trial_amount')) || 0,
    buttonText: get(window, 'coin_trial_button_text') || 'Upgrade to COIN Pro 3 Day Trial',
    label: get(window, 'coin_trial_label') || 'Free Trial',
    planId: get(window, 'coin_trial_plan_id') || 'XYO-COIN-PRO-3D-TRIAL-34-95',
    successText: get(window, 'coin_trial_success_text') || 'Successfully started COIN Pro 3 Day Trial!',
  }
}

export const getCoinPlusPlanDetails = (): IPlanDetails => {
  return {
    amount: Number(get(window, 'coin_plus_amount')) || 24.95,
    annualAmount: Number(get(window, 'coin_plus_annual_amount')) || 249.5,
    annualPlanId: get(window, 'coin_plus_annual_plan_id') || 'COIN-PLUS-ANNUAL-249-50',
    annualText: get(window, 'coin_plus_annual_text') || 'Pay annually $249.50 / yr.',
    buttonText: get(window, 'coin_plus_button_text') || 'Upgrade to COIN Plus',
    label: get(window, 'coin_plus_label') || 'COIN Plus $24.95',
    planId: get(window, 'coin_plus_plan_id') || 'XYO-COIN-PLUS-24-95',
    successText: get(window, 'coin_plus_success_text') || 'Successfully upgraded to COIN Plus!',
  }
}

export const getCoinProPlanDetails = (): IPlanDetails => {
  return {
    amount: Number(get(window, 'coin_pro_amount')) || 34.95,
    annualAmount: Number(get(window, 'coin_pro_annual_amount')) || 349.5,
    annualPlanId: get(window, 'coin_pro_annual_plan_id') || 'COIN-PRO-ANNUAL-349-50',
    annualText: get(window, 'coin_pro_annual_text') || 'Pay annually $349.50 / yr.',
    buttonText: get(window, 'coin_pro_button_text') || 'Upgrade to COIN Pro',
    label: get(window, 'coin_pro_label') || 'COIN Pro $34.95',
    planId: get(window, 'coin_pro_plan_id') || 'XYO-COIN-PRO-34-95',
    successText: get(window, 'coin_pro_success_text') || 'Successfully upgraded to COIN Pro!',
  }
}

export const getCoinMasterPlanDetails = (): IPlanDetails => {
  return {
    amount: Number(get(window, 'coin_master_amount')) || 99.95,
    buttonText: get(window, 'coin_master_button_text') || 'Upgrade to COIN Master',
    label: get(window, 'coin_master_label') || 'COIN Master $99.95',
    planId: get(window, 'coin_master_plan_id') || 'COIN-MASTER-99-95',
    successText: get(window, 'coin_master_success_text') || 'Successfully upgraded to COIN Master!',
  }
}

export const getUpgradeToProDetails = (): IPlanDetails => {
  return {
    amount: Number(get(window, 'coin_upgrade_to_pro_amount')) || 34.95,
    annualPlanId: get(window, 'coin_upgrade_pro_annual_plan_id') || 'COIN-PRO-ANNUAL-349-50',
    buttonText: get(window, 'coin_upgrade_to_pro_button_text') || 'Upgrade to COIN Pro',
    label: get(window, 'coin_upgrade_to_pro_label') || 'COIN Pro Upgrade $34.95',
    planId: get(window, 'coin_upgrade_to_pro_plan_id') || 'XYO-COIN-PRO-34-95',
  }
}

export const getUpgradeToMasterDetails = (): IPlanDetails => {
  return {
    amount: Number(get(window, 'coin_upgrade_to_master_amount')) || 99.95,
    buttonText: get(window, 'coin_upgrade_to_master_button_text') || 'Upgrade to COIN Master',
    label: get(window, 'coin_upgrade_to_master_label') || 'COIN Master Upgrade $99.95',
    planId: get(window, 'coin_upgrade_to_master_plan_id') || 'COIN-MASTER-99-95',
  }
}

export const getCoinPlusPlanFHRDetails = (): IPlanDetails => {
  return {
    amount: Number(get(window, 'coin_plus_fhr_amount')) || 12.95,
    label: get(window, 'coin_plus_fhr_label') || 'COIN Pro FHR Membership $12.95',
    planId: get(window, 'coin_plus_fhr_plan_id') || 'XYO-COIN-PREMIUM-12-95',
    successText: get(window, 'coin_plus_fhr_success_text') || 'Successfully upgraded to COIN Pro FHR!',
  }
}
