import { CardProps } from '@mui/material'

import PlanCard from './PlanCard'

const PlusPlanCard: React.FC<React.PropsWithChildren<CardProps>> = (props) => {
  return (
    <PlanCard
      title="COIN Plus"
      subtitle="Includes all COIN Basic Features"
      price={
        <>
          <strong>$ 24</strong>
          <small>.95/month</small>
        </>
      }
      features={[
        '2x Geomining Speed',
        '2x Recharge Rate',
        '2x More Big Geomines',
        '12x Geomining Rewards (with or without SentinelX)',
        '2x Additional Geomining Rewards',
        '2x Bonus Drop Power',
        '2x Background Rewards',
        'Geoclaim Shield',
        'Auto Collect',
        'Geodropping',
        '+ More',
      ]}
      {...props}
    />
  )
}

export default PlusPlanCard
