import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { BoxProps } from '@mui/material/Box'
import { FlexCol, FlexRow } from '../../../components/Flex'

interface Props extends BoxProps {
  amount?: number
  xyoCollected?: number
  date?: number
}

const VideoAd: React.FC<React.PropsWithChildren<Props>> = ({ amount, xyoCollected, date, ...props }) => {
  const previousTotal = xyoCollected !== undefined && amount !== undefined ? xyoCollected - amount : undefined
  return (
    <FlexRow justifyContent="flex-start" {...props}>
      <FlexCol alignItems="stretch" padding={2}>
        <Typography variant="h5">
          New Total: {xyoCollected?.toLocaleString() ?? <Skeleton variant="text" width={180} />}
        </Typography>
        {xyoCollected !== undefined && amount !== undefined ? (
          <Typography>
            Previous Total: {previousTotal?.toLocaleString() ?? <Skeleton variant="text" width={160} />}
          </Typography>
        ) : null}
        <Typography>Reward: {amount ?? <Skeleton variant="text" width={160} />}</Typography>
        {date ? (
          <Typography variant="subtitle2">
            {date ? new Date(Number(date)).toLocaleString() : <Skeleton variant="text" width={160} />}
          </Typography>
        ) : null}
      </FlexCol>
    </FlexRow>
  )
}

export default VideoAd
