import { useQuery } from '@apollo/react-hooks'
import { Divider, TablePagination } from '@mui/material'
import { FlexCol } from '../../../components/Flex'
import get from 'lodash/get'
import times from 'lodash/times'
import React, { ChangeEventHandler, useMemo, useState } from 'react'

import {
  CustomList,
  CustomListHeader,
  CustomListProps,
  TablePaginationActions,
  useScrollContext,
} from '../../../components'
import { ErrorViewer } from '../../../componentsPure'
import { AUDIO_ADS } from './gql'
import AudioAd from './ListItem'
import NoAudioAds from './NoItems'

interface AudioAdsResult {
  audioAdsHistoryList: {
    total: number
    data: { _id: string; amount?: number; xyoCollected?: number; date?: number }[]
  }
}

const AudioAdsList: React.FC<React.PropsWithChildren<CustomListProps>> = (props) => {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const variables = useMemo(
    () => ({
      field: 'yyyymmdd',
      page,
      perPage,
    }),
    [perPage, page]
  )
  const { data, loading, error } = useQuery<AudioAdsResult>(AUDIO_ADS, { variables })
  const remoteRowCount = data?.audioAdsHistoryList?.total ?? 0
  const changePage = (newPage: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(newPage)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const list = data?.audioAdsHistoryList?.data ?? []

  return (
    <CustomList header={<CustomListHeader title="Audio Ad Rewards" />} {...props}>
      {loading ? (
        times(perPage).map((a, i) => <AudioAd key={i} />)
      ) : list.length ? (
        list.map((item) => (
          <FlexCol key={item._id} alignItems="stretch">
            <AudioAd {...item} />
            <Divider />
          </FlexCol>
        ))
      ) : error ? (
        <ErrorViewer error={error} />
      ) : (
        <NoAudioAds />
      )}
      {remoteRowCount ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={remoteRowCount}
          rowsPerPage={variables.perPage}
          page={variables.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      ) : null}
    </CustomList>
  )
}

export default AudioAdsList
