import { FlexRow } from '../components/Flex'
import { lazy, Suspense } from 'react'

import SignatureVerificationProps from './CoinSignatureVerificationProps'

/**
 * Lazy Loadable Component that pulls in Web3/Crypto dependencies
 */

const SignatureVerificationLazy = lazy(
  () => import(/* webpackChunkName: "signatureVerification" */ './CoinSignatureVerification')
)

const SignatureVerification: React.FC<React.PropsWithChildren<SignatureVerificationProps>> = (props) => {
  return (
    <Suspense fallback={<FlexRow />}>
      <SignatureVerificationLazy {...props} />
    </Suspense>
  )
}

export { SignatureVerification }
