import { Box, Paper } from '@mui/material'

const supportUri = 'https://support.coinapp.co/hc/en-us/articles/12398250894231-COIN-Pickaxes-'

const NoPickaxes = () => {
  return (
    <Paper sx={{ mb: 2 }}>
      <Box p={2} py={4} textAlign="center">
        <h4 className="mb-2">No Pickaxes Found</h4>
        <p className="mb-0">
          Read more about pickaxes{' '}
          <a href={supportUri} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </p>
      </Box>
    </Paper>
  )
}

export default NoPickaxes
