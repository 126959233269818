import gql from 'graphql-tag'

const BALANCES = gql`
  query Balances {
    balances {
      icon
      asset
      title
      collected
      pending
      withdrawn
    }
  }
`

export { BALANCES }
