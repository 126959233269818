import { QueryResult, useQuery } from '@apollo/client'
import { useState } from 'react'

import { CoinbaseSection, UniswapV3PositionList } from '../../components'
import { FlexCol } from '../../components/Flex'
import { Position } from '../../components/UniswapV3/types'
import { verifyClient } from '../../context'
import { useUserInfo } from '../../User'
import { MyVerifiedAddressesWithTokenAndPairBalancesDocument } from './gql'
import { IUniDetails, OnChainSection, VerifiedAddressWithBalance } from './OnChainSection'
import PairInfoDialog from './PairInfoDialog'
import { TotalSection } from './TotalSections'

const MyVerifiedAddresses = () => {
  const queryResult = useQuery(MyVerifiedAddressesWithTokenAndPairBalancesDocument, {
    client: verifyClient,
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
  })
  const positions = getPositions(queryResult)
  const [uniDetails, setUniDetails] = useState<null | IUniDetails>(null)
  const { userInfo } = useUserInfo()
  return (
    <FlexCol marginY={5}>
      <TotalSection queryResult={queryResult} />
      <CoinbaseSection queryResult={queryResult} refetchQueries={[{ query: MyVerifiedAddressesWithTokenAndPairBalancesDocument }]} />
      <OnChainSection queryResult={queryResult} userInfo={userInfo} uniDetails={uniDetails} setUniDetails={setUniDetails} />
      <UniswapV3PositionList positions={positions} />
      <PairInfoDialog uniDetails={uniDetails} setUniDetails={setUniDetails} />
    </FlexCol>
  )
}

function getPositions(
  queryResult: QueryResult<{
    verifiedAddresses?: VerifiedAddressWithBalance[]
    userVerifiedAddresses?: VerifiedAddressWithBalance[]
  }>,
) {
  return (
    (queryResult.data?.verifiedAddresses ?? queryResult.data?.userVerifiedAddresses)?.reduce(
      (acc, v) => acc.concat(v.poolPositions || []),
      [] as Position[],
    ) ?? []
  )
}

export default MyVerifiedAddresses
