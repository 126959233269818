import { Tooltip, Typography } from '@mui/material'
import { useMemo } from 'react'

import { pluralEntries, useSweepstakesOptions, useSweepstakesOptionsEnded } from '../options'
import { formatNumber } from '../utils'

const useSweepstakesDetailsListItemEntriesText = (alwaysShow = false) => {
  const options = useSweepstakesOptions()
  const ended = useSweepstakesOptionsEnded()
  return useMemo(() => {
    const entryCount = options?.userEntry?.entries ?? 0
    const entries = (
      <Tooltip title={<Typography>This may not include any free entries that you have.</Typography>}>
        <span>
          You {ended ? 'had' : 'have'} {formatNumber(entryCount)} {pluralEntries(entryCount)}
        </span>
      </Tooltip>
    )
    if (alwaysShow) return entries
    return entryCount > 0 ? entries : ''
  }, [options, ended, alwaysShow])
}

export default useSweepstakesDetailsListItemEntriesText
