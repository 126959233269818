import {
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  Typography,
} from '@mui/material'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { useState } from 'react'

import { useSnackbar } from '../../components'
import { parseError } from '../../utils'
import { useUser } from '../hooks'

const ResetPassword = () => {
  const { user } = useUser()
  const email = user?.['email'] ?? ''
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setSnackbar } = useSnackbar()[1]
  const handleReset = async () => {
    setLoading(true)
    try {
      if (!email) throw new Error('Must have email')
      await sendPasswordResetEmail(getAuth(), email)
      setSnackbar({
        autoHideDuration: 10000,
        message: (
          <>
            <AlertTitle>Password Reset</AlertTitle> Instructions sent to <strong>{email}</strong>
          </>
        ),
        severity: 'success',
      })
      setOpen(false)
    } catch (err) {
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(err),
        severity: 'error',
      })
    }
    setLoading(false)
  }
  return (
    <>
      <Typography>
        <Link style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
          Reset Password
        </Link>
      </Typography>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <DialogContentText>
            Password reset instructions will be sent to <strong>{email}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={loading} onClick={handleReset} variant="contained" color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ResetPassword
