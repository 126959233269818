import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { FlexRow } from '../Flex'
import { MouseEventHandler } from 'react'

import ContextToolbar from './ContextToolbar'
import SystemToolbar from './SystemToolbar'

const Navbar: React.FC<React.PropsWithChildren<{ toggleDrawer: MouseEventHandler<HTMLButtonElement> }>> = ({ toggleDrawer }) => (
  <AppBar
    position="relative"
    sx={{ background: 'transparent', maxWidth: '100vw', overflow: 'hidden', mb: 4 }}
  >
    <FlexRow>
      <ContextToolbar />
      <Box flexGrow={1} />
      <SystemToolbar toggleDrawer={toggleDrawer} />
    </FlexRow>
  </AppBar>
)

export default Navbar
