import { CircularProgress, Dialog, DialogContent } from '@mui/material'

import { ErrorViewer } from '../../../../componentsPure'
import ConfirmationProvider from './ConfirmationProvider'
import ConfirmUpgradeContext from './ConfirmUpgradeContext'

const ConfirmUpgradeDialog = () => {
  const [{ open, loading, error, title, data, cards, done }, setState] = ConfirmUpgradeContext.useContext()
  const close = () => {
    setState((v) => ({ ...v, open: false }))
  }
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
      <DialogContent>
        {loading ? (
          <CircularProgress color="inherit" />
        ) : error ? (
          <ErrorViewer error={error} />
        ) : data ? (
          <ConfirmationProvider title={title} data={data} cards={cards} onCancel={() => done?.()} onConfirmed={done} />
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmUpgradeDialog
