const computeRunningTotals = (
  uid: string | null | undefined,
  {
    amount,
    dropUid,
    dropXyoCollected,
    collectedXyoCollected,
  }: {
    amount?: number
    dropUid?: string
    dropXyoCollected?: number
    collectedXyoCollected?: number
  }
) => {
  if (!uid) return {}
  if (uid === dropUid) {
    if (typeof dropXyoCollected !== 'number') return {}
    return {
      newXyoCollected: dropXyoCollected,
      prevXyoCollected: dropXyoCollected + (amount || 0),
    }
  }
  if (typeof collectedXyoCollected !== 'number') return {}
  return {
    newXyoCollected: collectedXyoCollected,
    prevXyoCollected: collectedXyoCollected - (amount || 0),
  }
}

export default computeRunningTotals
