import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import get from 'lodash/get'

import { numberFormat } from '../../../../utils'
import { isUpdgradeTableItem, IUpdgradeTableData } from './ConfirmUpgradeContext'

const UpgradeTable = ({ data }: { data: IUpdgradeTableData }) => {
  const amount = get(data, 'lineItemSubtotal') || get(data, 'amount') || 0
  const amountString = numberFormat(amount)
  const currency = get(data, 'lineItemSubtotal.currency') || get(data, 'currency') || 'USD'
  const items = (data.lineItems || data.items || []).filter(isUpdgradeTableItem)
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell align="right">Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(({ description, type, unitPriceAmount, unitPrice }, i) => (
          <TableRow key={i}>
            <TableCell>
              <small>{description}</small>
            </TableCell>
            <TableCell align="right">
              <small>{type === 'debit' ? numberFormat(unitPriceAmount || unitPrice) : `(${numberFormat(unitPriceAmount || unitPrice)})`}</small>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <small>
              Subtotal <strong>{amount > 0 ? '(Due Now)' : '(Billing Credit)'}</strong>
            </small>
          </TableCell>
          <TableCell align="right">
            <small>
              {amountString} {currency}
            </small>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default UpgradeTable
