import { AlertTitle } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { parseError, useAsyncControls } from '../../utils'
import { useSnackbar } from '../Snackables'
import {
  canClaimRandomPickaxe,
  CanClaimRandomPickaxeResult,
  equipPickaxe,
  fetchPickaxesFromCache,
  PickaxesResult,
  rmPickaxeCache,
  syncPickaxes,
  unequipPickaxe,
} from './api'

export interface AsyncPickaxesResult {
  loading?: boolean
  data?: PickaxesResult
  error?: string
}

export function useEquipPickaxe(uniqueId?: string, { onSuccess }: { onSuccess?: () => void } = {}) {
  const [equipping, setEquipping] = useState(false)
  const { setSnackbar } = useSnackbar()[1]

  const handleEquipPickaxe = async () => {
    setEquipping(true)
    try {
      if (!uniqueId) throw new Error('Pickaxe id is required')
      await equipPickaxe(uniqueId)
      rmPickaxeCache('list')
      if (onSuccess) {
        await onSuccess()
      }
      setSnackbar({
        autoHideDuration: 10000,
        message: (
          <>
            <AlertTitle>Pickaxe Equipped</AlertTitle> {uniqueId} has been equipped to your account.
          </>
        ),
        severity: 'success',
      })
    } catch (err) {
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(err),
        severity: 'error',
      })
    } finally {
      setEquipping(false)
    }
  }

  return [handleEquipPickaxe, equipping] as [typeof handleEquipPickaxe, typeof equipping]
}

export function useUnequipPickaxe(uniqueId?: string, { onSuccess }: { onSuccess?: () => void } = {}) {
  const [unequipping, setUnequipping] = useState(false)
  const { setSnackbar } = useSnackbar()[1]

  const handleUnequipPickaxe = async () => {
    setUnequipping(true)
    try {
      if (!uniqueId) throw new Error('Pickaxe id is required')
      await unequipPickaxe(uniqueId)
      rmPickaxeCache('list')
      if (onSuccess) {
        await onSuccess()
      }
      setSnackbar({
        autoHideDuration: 10000,
        message: (
          <>
            <AlertTitle>Pickaxe Unequipped</AlertTitle> {uniqueId} has been unequipped from your account.
          </>
        ),
        severity: 'success',
      })
    } catch (err) {
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(err),
        severity: 'error',
      })
    } finally {
      setUnequipping(false)
    }
  }

  return [handleUnequipPickaxe, unequipping] as [typeof handleUnequipPickaxe, typeof unequipping]
}

export const usePickaxes = (): [AsyncPickaxesResult, { refetch: () => Promise<void> }] => {
  const [state, controls] = useAsyncControls<PickaxesResult>()

  const refetch = async () => {
    await fetchPickaxesFromCache()
      .then((data) => controls.setData?.(data))
      .catch((error) => controls.setError?.(error))
  }

  useEffect(() => {
    controls.setLoading?.(true)
    refetch()
  }, [])

  return [state, { refetch }]
}

export const useSyncPickaxes = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const { setSnackbar } = useSnackbar()[1]
  const [syncing, setSyncing] = useState(false)

  async function syncPickaxesCallback(address?: string) {
    try {
      setSyncing(true)
      const isPickaxeView = location.pathname === '/pickaxes'
      const result = await syncPickaxes(address)
      if (result.claimed && result.claimed.length) {
        rmPickaxeCache('list')
        const uniqueIds = result.claimed.map((result) => result.uniqueId)
        const link = isPickaxeView ? null : <Link to="/pickaxes">View details.</Link>
        setSnackbar({
          autoHideDuration: 10000,
          message: (
            <>
              <AlertTitle>Pickaxe{uniqueIds.length > 1 ? 's' : ''} Found</AlertTitle> {uniqueIds.join(', ')} {uniqueIds.length > 2 ? 'have' : 'has'}{' '}
              been added to your account. {link}
            </>
          ),
          severity: 'success',
        })
      } else if (isPickaxeView) {
        setSnackbar({
          autoHideDuration: 10000,
          message: (
            <>
              <AlertTitle>No Pickaxes Found</AlertTitle>
            </>
          ),
          severity: 'success',
        })
      }
      if (onSuccess) {
        onSuccess()
      }
    } catch (err) {
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(err),
        severity: 'error',
      })
    } finally {
      setSyncing(false)
    }
  }

  const sync = useCallback(syncPickaxesCallback, [])

  return [sync, { syncing }] as [typeof sync, { syncing: boolean }]
}

export const useCanClaimRandomPickaxe = () => {
  const [state, controls] = useAsyncControls<CanClaimRandomPickaxeResult>()

  const refetch = async () => {
    await canClaimRandomPickaxe()
      .then((data) => controls.setData?.(data))
      .catch((error) => controls.setError?.(error))
  }

  useEffect(() => {
    controls.setLoading?.(true)
    refetch()
  }, [])

  return [state, { refetch }] as [typeof state, { refetch: () => void }]
}
