import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

import Achievement from './Achievement'

function rankAcheivements(achievements: Achievement[]) {
  const achievementsByGroup = groupBy(achievements, 'info.group')

  return Object.keys(achievementsByGroup).reduce<Achievement[]>((acc, group) => {
    const achievement = achievementsByGroup[group][0]
    if (achievement?.info?.rank) {
      return acc.concat(orderBy(achievementsByGroup[group], 'info.rank', 'desc')[0])
    }
    return acc.concat(achievementsByGroup[group])
  }, [])
}

export default rankAcheivements
