import { BoxProps } from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { FlexCol, FlexRow } from '../../Flex'
import { MdRefresh } from 'react-icons/md'
import { NavigateOptions, To } from 'react-router-dom'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

export interface StatCardHeaderProps extends BoxProps {
  action?: string
  actionTo?: To
  actionToOptions?: NavigateOptions
  onActionClick?: () => void
  onRefreshClick?: () => void
  subtitle?: string | ReactElement
  title?: string
}

export const StatCardHeader: React.FC<React.PropsWithChildren<StatCardHeaderProps>> = ({
  title,
  subtitle,
  onRefreshClick,
  onActionClick,
  actionTo,
  actionToOptions,
  action,
  ...props
}) => {
  const theme = useTheme()
  return (
    <FlexCol alignItems="stretch" margin={2} justifyContent="flex-start" position="relative" {...props}>
      <FlexRow overflow="hidden" justifyContent="space-between">
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {onRefreshClick ? (
          <IconButton
            size="small"
            onClick={onRefreshClick}
            style={{
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <MdRefresh size={20} />
          </IconButton>
        ) : null}
      </FlexRow>
      <FlexRow justifyContent="flex-start" overflow="hidden" minHeight={32}>
        {typeof subtitle === 'string' ? <Typography variant="subtitle2">{subtitle}</Typography> : subtitle}
        <Button LinkComponent={Link} sx={{ mx: 1 }} onClick={onActionClick} variant="text" color="secondary" size="small" {...({ to: actionTo ?? '#', toOptions: actionToOptions })}>
          {action ? `${action}` : ''}
        </Button>
      </FlexRow>
    </FlexCol>
  )
}
