import { Step, StepLabel, Stepper, styled } from '@mui/material'
import { Children, FC, ReactElement } from 'react'

import { FadeIn } from '../Animate'

const Steps: FC<React.PropsWithChildren<{ step: number; prev?: number | null }>> = ({ children, prev, step }) => {
  const steps = Children.toArray(children) as ReactElement[]
  return (
    <div style={{ isolation: 'isolate' }}>
      <Stepper activeStep={step} sx={{ mb: 3, position: 'sticky', top: 0, zIndex: 1 }}>
        {steps.map((step) => (
          <Wrapper key={step.props.label}>
            <StepLabel>{step.props.label}</StepLabel>
          </Wrapper>
        ))}
      </Stepper>
      {steps.reduce<ReactElement | null>((activeStep, stepCmp, i) => {
        if (activeStep) return activeStep
        if (step === i && typeof prev !== 'number') return <FadeIn key={i}>{stepCmp}</FadeIn>
        if (step === i && step > (prev || 0)) return <FadeIn key={i}>{stepCmp}</FadeIn>
        if (step === i && step <= (prev || 0)) return <FadeIn key={i}>{stepCmp}</FadeIn>
        return null
      }, null) || <div />}
    </div>
  )
}

const Wrapper = styled(Step)`
  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`

export default Steps
