const action = <V = unknown>(type: string) => {
  const actionCreator = (payload: V) => ({
    payload,
    type,
  })
  actionCreator.type = type
  return actionCreator
}

export default action
