import { List, ListItem, ListItemText } from '@mui/material'
import { ReactNode, useState } from 'react'

import { findSupportCategories } from '../../context'
import { useAsyncEffect } from '../../utils/useAsyncEffect'
import { ScrollY } from '../Scroll'

const HelpLinks = ({ categoryId, onClick }: { categoryId?: string | null; onClick: (id: string) => void }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<{ id: string; name: string; icon: ReactNode }[]>([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async (mounted) => {
    const data = await findSupportCategories()
    if (mounted()) {
      setData(data)
      setLoading(false)
    }
  }, [])

  if (loading)
    return (
      <div className="text-center py-5" style={{ minWidth: 200 }}>
        <div className="spinner-border text-danger"></div>
      </div>
    )

  return (
    <ScrollY style={{ maxHeight: 340, paddingBottom: 40 }}>
      <List disablePadding component="div">
        {data.map(({ id, name, icon }) => (
          <ListItem
            button
            key={id}
            onClick={() => onClick && onClick(id)}
            selected={categoryId === id}
            className="text-dark"
          >
            <i className={`${icon} text-danger`} style={{ marginRight: 8 }} />
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </List>
    </ScrollY>
  )
}

export default HelpLinks
