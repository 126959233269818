import hikerImg from './hiker.png'

interface Props extends React.SVGProps<SVGSVGElement> {
  hiker?: boolean
}

const FooterPicture: React.FC<React.PropsWithChildren<Props>> = ({ hiker = false, ...props }) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120" {...props}>
      <defs>
        <linearGradient id="b-desktop" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="a-desktop"
          d="M0 120h1440V24.916C1116.544 30.856 676.831 38.146 656.061 39 616.703 39 221.574 24.414 0 15v105z"
        />
        <linearGradient id="d-desktop" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="c-desktop"
          d="M0 120h1440V32.994L839.438 63.582C800.765 64.739 418.758 11.573 378.148 0 322.554 7.234 0 33.934 0 33.934V120z"
        />
        <path
          id="f-desktop"
          d="M0 120h1440V32.994L839.438 63.582C800.765 64.739 418.758 11.573 378.148 0 322.554 7.234 0 33.934 0 33.934V120z"
        />
        <linearGradient id="h-desktop" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="g-desktop"
          d="M0 33.253V120h315c-29.498-6.237-87.135-41.489-85.298-45.24 2.997-6.128 24.886-9.464 43.692-12.09 31.667-4.421 62.117-9.613 90.954-15.51 4.853-.993 9.89-2.086 12.224-3.697 3.931-2.713-1.06-5.758-5.249-8.436-11.06-7.071-17.127-14.83-17.687-22.63C353.301 7.717 365.317 1.995 378 0L0 33.253z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#FF9E7E" xlinkHref="#a-desktop" />
        <use fill="url(#b-desktop)" fillOpacity=".75" xlinkHref="#a-desktop" />
        <mask id="e-desktop" fill="#fff">
          <use xlinkHref="#c-desktop" />
        </mask>
        <use fill="url(#d-desktop)" xlinkHref="#c-desktop" />
        <g mask="url(#e-desktop)">
          <use fill="#FF9E7E" xlinkHref="#f-desktop" />
          <use fill="url(#d-desktop)" fillOpacity=".5" xlinkHref="#f-desktop" />
        </g>
        <g mask="url(#e-desktop)">
          <use fill="#FF9E7E" xlinkHref="#g-desktop" />
          <use fill="url(#h-desktop)" fillOpacity=".5" xlinkHref="#g-desktop" />
        </g>
      </g>
    </svg>
    {hiker === false ? null : (
      <img
        className="d-none d-md-block"
        src={hikerImg}
        style={{
          bottom: 40,
          left: 20,
          position: 'absolute',
        }}
      />
    )}
  </>
)

export default FooterPicture
