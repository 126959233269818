import { useCallback, useMemo, useState } from 'react'

import ca from './action'

export const defaultState = {
  data: undefined,
  error: '',
  loading: true,
}

const setTrue = ca('SET_TRUE')
const setFalse = ca('SET_FALSE')
const toggle = ca('TOGGLE')

export const booleanReducer = (state = false, { type }: { type: string }) => {
  switch (type) {
    case setTrue.type:
      return true
    case setFalse.type:
      return false
    case toggle.type:
      return !state
    default:
      return state
  }
}

export interface AsyncControlsProps<V = any> {
  data?: V
  error?: string
  loading?: boolean
  setLoading?: (loading?: boolean) => void
  setError?: (error?: string) => void
  setData?: (data?: any) => void
}

export function useAsyncControls<V>(initial: AsyncControlsProps<V> = { loading: false }) {
  const [state, setState] = useState<AsyncControlsProps<V>>(initial)
  return useMemo(() => {
    const setData = (data?: any) => setState(() => ({ data, error: '', loading: false }))
    const setError = (error?: string) => setState(() => ({ data: undefined, error, loading: false }))

    const setLoading = (loading?: boolean) => setState((s) => ({ data: s.data || undefined, error: '', loading: !!loading }))
    return [state, { setData, setError, setLoading }]
  }, [state])
}

export const useBooleanControls = (
  initial = false
): [
  boolean,
  {
    setTrue: () => void
    setFalse: () => void
    toggle: () => void
  }
] => {
  const [state, setState] = useState(initial)
  const setTrue = () => setState(true)
  const setFalse = () => setState(false)
  const toggle = () => setState((v) => !v)
  return [state, { setFalse, setTrue, toggle }]
}

export const useAsyncMethod = (fn: any, initial = { loading: false }) => {
  const [state, controls] = useAsyncControls(initial)
  const method = useCallback(
    async (...args: unknown[]) => {
      try {
        controls.setLoading?.(true)
        const data = await fn(...args)
        controls.setData?.(data)
      } catch (e) {
        const error = e as Error
        controls.setError?.(error.message)
      }
    },
    [fn, controls]
  )
  return [state, method] as [typeof state, typeof method]
}
