import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { AccountDocument, Maybe, PaymentCard, RebillySubscription, useReactivateSubscriptionMutation } from '@xyo-network/api-checkout-apollo'
import { useState } from 'react'

import { Checkout } from '../checkout'
import { ConfirmDialog, useSnackbar } from '../components'
import { checkoutClient } from '../lib'
import { parseError, trackReactivateEvent, trackWarning } from '../utils'

export default ({ subscription, cards }: { subscription: RebillySubscription | null; cards?: Maybe<PaymentCard | undefined>[] }) => {
  const [open, setOpen] = useState(false)
  const { setSnackbar } = useSnackbar()[1]
  const [mutate, { loading }] = useReactivateSubscriptionMutation({
    client: checkoutClient,
    refetchQueries: [{ query: AccountDocument }],
    variables: { subscriptionId: subscription ? subscription.id : '' },
  })

  const activeCard = (cards || []).find((c) => c?.status === 'active')

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    trackReactivateEvent('canceled', {})
    setOpen(false)
  }

  const handleCheckout = () => {
    try {
      //
    } catch (e) {
      const error = e as Error
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(error.message),
        severity: 'error',
      })
    }
  }

  const nextBillingDate = subscription && subscription.renewalTime ? new Date(subscription.renewalTime).toLocaleString() : ''
  const reactivateSubscription = async () => {
    if (!mutate || !subscription) return
    try {
      trackReactivateEvent('confirmed', {})
      await mutate()
      trackReactivateEvent('success', {})
      setSnackbar({
        autoHideDuration: 6000,
        message: 'Glad to see you came back!',
        severity: 'success',
      })
    } catch (e) {
      const error = e as Error
      trackWarning('Reactivate subscription error', error.message)
      trackReactivateEvent('error', { value: error.message })
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(error.message),
        severity: 'error',
      })
    }
  }

  return (
    subscription && (
      <>
        <Button onClick={handleOpen}>Reactivate Subscription</Button>
        <Dialog open={Boolean(!activeCard && open)} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Add Payment Method</DialogTitle>
          <DialogContent>
            <DialogContentText paragraph>An active card is needed in order to reactivate the subscription.</DialogContentText>
            <Checkout onSuccess={handleCheckout} onCancel={handleClose} authorize={true} />
          </DialogContent>
        </Dialog>
        <ConfirmDialog open={Boolean(activeCard && open)} loading={loading} onClose={handleClose} onConfirm={reactivateSubscription}>
          <DialogContentText>You will be charged on {nextBillingDate}</DialogContentText>
        </ConfirmDialog>
      </>
    )
  )
}
