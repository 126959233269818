import { useEffect } from 'react'

import { useSnackbar } from '../Snackables'
import getMessageOpts from './getMessageOpts'

declare global {
  interface Window {
    displaySurvey: (opts: { provider?: string }) => void
  }
}

const useDisplaySuccess = (refetch: () => void) => {
  const { setSnackbar } = useSnackbar()[1]
  useEffect(() => {
    window.displaySurvey = (opts: { provider?: string }) => {
      const { severity, message } = getMessageOpts(opts)
      if (severity && message) {
        setSnackbar({
          autoHideDuration: 6000,
          message,
          severity,
        })
        refetch()
      }
    }
  }, [refetch, setSnackbar])
}

export default useDisplaySuccess
