import { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { FlexCol, FlexGrowRow, FlexRow } from '../../../components/Flex'

interface Props extends BoxProps {
  name: string
}

const Avatar: React.FC<React.PropsWithChildren<Props>> = ({ children, name, ...props }) => {
  const theme = useTheme()
  return (
    <FlexCol
      height={80}
      width={80}
      bgcolor={theme.palette.info.main}
      color={theme.palette.info.contrastText}
      alignItems="stretch"
      {...props}
    >
      <FlexGrowRow>{children}</FlexGrowRow>
      <FlexRow component={Typography} bgcolor={theme.palette.info.dark}>
        {name || 'basic'}
      </FlexRow>
    </FlexCol>
  )
}

export default Avatar
