import Button from '@mui/material/Button'

import { swalConfirm } from '../componentsPure'
import { getUpgradeToProDetails } from '../plans'
import { useUpgrade } from '../store'

const UpgradePlanToProFHR = () => {
  const upgrade = useUpgrade()
  const upgradePlan = getUpgradeToProDetails()
  const { buyPlusFHR, closeUpgrade } = upgrade[1]
  const upgradeFhrPlusToPro = async () => {
    closeUpgrade()
    const { value } = await swalConfirm(undefined, 'Congratulations!', {
      confirmButtonText: 'Continue',
      html: 'As a verified FHR member you are eligible for a discounted COIN Pro upgrade for only $12.95 per month for up to 12 months (based on when you started using FHR Subscription benefits) <a href="https://coinappco.zendesk.com/hc/en-us/articles/360050977274" target="_blank" rel="noopener noreferrer">Learn More</a>',
    })
    if (value) buyPlusFHR()
  }

  return <Button onClick={upgradeFhrPlusToPro}>{upgradePlan.buttonText}</Button>
}

export default UpgradePlanToProFHR
