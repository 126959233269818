import gql from 'graphql-tag'

const BACKGROUND_REWARDS = gql`
  query BackgroundRewards($page: Int, $perPage: Int) {
    backgroundRewards(pagination: { page: $page, perPage: $perPage }, sort: { field: "date", order: -1 }) {
      total
      data {
        _id
        uid
        plan
        amount
        secondsElapsed
        secondsRewarded
        xyoCollected
        date
        epoch
        yyyymmdd
      }
    }
  }
`

export { BACKGROUND_REWARDS }
