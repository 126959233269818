import { useQuery } from '@apollo/client'
import { Box, Button, Dialog, DialogContent, Divider, Typography } from '@mui/material'

import { verifyClient } from '../../context'
import { numberFormat } from '../../utils'
import { pairInfoQuery } from './gql'
import uni from './img/uniswap.png'
import { IUniDetails } from './OnChainSection'

const PairInfoDialog = ({
  uniDetails,
  setUniDetails,
}: {
  uniDetails?: IUniDetails | null
  setUniDetails: (v: IUniDetails | null) => void
}) => {
  const tokens = uniDetails?.xyoLpBalance || 0
  const { data } = useQuery(pairInfoQuery, { client: verifyClient })
  const total = data?.pair?.totalSupply / 1e18
  const percent = tokens / total
  const reserve0 = data?.pair?.getReserves?.reserve0 ?? 0
  const reserve1 = data?.pair?.getReserves?.reserve1 ?? 0
  return (
    <Dialog open={Boolean(uniDetails)} onClose={() => setUniDetails(null)} fullWidth maxWidth="xs">
      <Box display="flex" alignItems="center" p={2}>
        <img src={uni} alt="Uniswap" className="mr-1" />
        <Typography variant="h6">Uniswap Pair</Typography>
        <Box flexGrow={1} />
        <Box mx={1}>
          <Button
            component="a"
            href="https://uniswap.exchange/add/0x55296f69f40ea6d20e478533c15a6b08b654e758/ETH"
            target="_blank"
            rel="noopener noreferrer"
          >
            + Liquidity
          </Button>
        </Box>
        <Button
          component="a"
          href="https://uniswap.exchange/swap?inputCurrency=0x55296f69f40ea6d20e478533c15a6b08b654e758&outputCurrency=ETH"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trade
        </Button>
      </Box>
      <Divider />
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Typography>Your position</Typography>
          <Box flexGrow={1} />
          <Typography>
            <strong>{numberFormat(tokens, 4)}</strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography>Your pool share</Typography>
          <Box flexGrow={1} />
          <Typography>
            <strong>{numberFormat(percent * 100, 2)}%</strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography>XYO:</Typography>
          <Box flexGrow={1} />
          <Typography>
            <strong>{numberFormat(percent * reserve0, 0)}</strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography>ETH:</Typography>
          <Box flexGrow={1} />
          <Typography>
            <strong>{numberFormat(percent * reserve1, 4)}</strong>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PairInfoDialog
