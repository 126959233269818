import { FC } from 'react'

import { RequiredEmail } from '../../../User'

const emailWhitelist = [
  'jordan.trouw@xy.company',
  'rphansen91@gmail.com',
  'johnarana@gmail.com',
  'tyleremerson07@gmail.com',
]

const SweepstakesWhitelistProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <RequiredEmail oneOf={emailWhitelist}>{children}</RequiredEmail>
}

export default SweepstakesWhitelistProvider
