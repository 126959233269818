import { useQuery } from '@apollo/react-hooks'
import { TablePagination } from '@mui/material'
import get from 'lodash/get'
import times from 'lodash/times'
import { MouseEvent, useMemo, useState } from 'react'

import { CustomList, TablePaginationActions, useScrollContext } from '../../../components'
import { ErrorViewer } from '../../../componentsPure'
import NoItemsPage from '../../../NoItems'
import { BACKGROUND_REWARDS } from './gql'
import BackgroundRewards from './Item'

const BackgroundRewardsList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const scrollElement = useScrollContext() as any
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const variables = useMemo(
    () => ({
      page,
      perPage,
    }),
    [perPage, page]
  )
  const { data, loading, error } = useQuery(BACKGROUND_REWARDS, { variables })
  const backgroundRewards: { _id: string }[] = get(data, 'backgroundRewards.data') || []
  const remoteRowCount = get(data, 'backgroundRewards.total')
  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    changePage(newPage + 1)
  }

  const changePage = (page: number) => {
    const scrollElementLocal = scrollElement
    if (scrollElementLocal) {
      scrollElementLocal.scrollTop = 0
    }
    setPage(page)
  }
  return (
    <CustomList title="Background Rewards">
      {loading ? (
        times(perPage).map((a, i) => <BackgroundRewards key={i} loading={true} />)
      ) : backgroundRewards.length ? (
        backgroundRewards.map((backgroundReward) => (
          <BackgroundRewards key={backgroundReward._id} backgroundReward={backgroundReward} />
        ))
      ) : error ? (
        <ErrorViewer error={error} />
      ) : (
        <NoBackgroundRewards />
      )}
      {remoteRowCount ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={remoteRowCount}
          rowsPerPage={variables.perPage}
          page={variables.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(event) => {
            setPerPage(parseInt(event.target.value, 10))
            changePage(1)
          }}
          ActionsComponent={TablePaginationActions}
        />
      ) : null}
    </CustomList>
  )
}

const NoBackgroundRewards = () => (
  <NoItemsPage
    message="No Background Rewards Found"
    href="https://support.coinapp.co/hc/en-us/categories/360002104613"
    buttonText="Read more about background rewards"
  />
)

export default BackgroundRewardsList
