import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import { MouseEvent } from 'react'

function TableCursorActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props

  function handleBackButtonClick(event: MouseEvent<HTMLButtonElement>) {
    onPageChange(event, page - 1)
  }

  function handleNextButtonClick(event: MouseEvent<HTMLButtonElement>) {
    onPageChange(event, page + 1)
  }

  return (
    <div
      className="ml-2"
      style={{
        flexShrink: 0,
        maxWidth: '100%',
      }}
    >
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  )
}

export default TableCursorActions
