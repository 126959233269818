import { Info } from '@mui/icons-material'
import { Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { FlexRow } from '../../components/Flex'
import { useRemoveAddressMutation, useTagAddressMutation } from '@xyo-network/api-address-verifier-apollo/bindings'
import { UserInfo } from '@xyo-network/api-checkout-apollo'
import { FaCheck, FaTrash } from 'react-icons/fa'

import { EditInline, SlideInUp } from '../../components'
import { swalConfirm } from '../../componentsPure'
import { verifyClient } from '../../context'
import { numberFormat } from '../../utils'
import { MyVerifiedAddressesWithTokenAndPairBalancesDocument } from './gql'
import MaybeSmall from './MaybeSmall'

interface AddressRowProps {
  i: number
  id: string
  address?: string | null
  name?: string | null
  ethBalance?: string | null
  xyoBalance?: number | null
  xyoLpBalance?: number | null
  userInfo?: UserInfo | null
  setUniDetails: (v: null | { address?: string | null; xyoLpBalance?: number | null }) => void
  fhr?: {
    hasSold?: boolean | null
  } | null
}

const AddressRow = ({
  i,
  id,
  address,
  name,
  fhr,
  ethBalance,
  xyoBalance,
  xyoLpBalance,
  userInfo,
  setUniDetails,
}: AddressRowProps) => {
  // const bossClient = useBossClient()
  const [remove, removing] = useRemoveAddressMutation({
    client: verifyClient,
    refetchQueries: [
      {
        query: MyVerifiedAddressesWithTokenAndPairBalancesDocument,
      },
    ],
    variables: { id },
  })
  const [tag] = useTagAddressMutation({
    client: verifyClient,
  })
  const isPrimary =
    userInfo && (userInfo.ethAddress || userInfo.lastKnownAddress || '').toLowerCase() === (address || '').toLowerCase()
  return (
    <Grid container p={2} sx={{ alignItems: 'center' }}>
      <Grid item xs={11}>
        <Grid container spacing={1} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={6}>
            <SlideInUp delay={200 * i}>
              <FlexRow>
                <strong>
                  <EditInline
                    initial={name || ''}
                    placeholder={'Tag this address'}
                    onSave={(name) => tag({ variables: { id, name } })}
                  />
                </strong>
                &nbsp;{isPrimary && <Chip label="Primary" color="secondary" size="small" />}
                &nbsp;{fhr && !fhr.hasSold && <Chip label="FHR" size="small" color="default" />}
              </FlexRow>
              <FlexRow sx={{ justifyContent: 'flex-start', pl: { md: 6 } }}>
                {isPrimary && <FaCheck color="green" />}&nbsp;<Typography noWrap>{address}</Typography>
              </FlexRow>
            </SlideInUp>
          </Grid>
          <Grid item xs={6} md={2}>
            <FlexRow sx={{ whiteSpace: 'nowrap' }}>
              <MaybeSmall text={numberFormat(ethBalance)} max={8} /> ETH
            </FlexRow>
          </Grid>
          <Grid item xs={6} md={2}>
            <FlexRow sx={{ whiteSpace: 'nowrap' }}>
              <MaybeSmall text={numberFormat(xyoBalance)} max={8} /> XYO
            </FlexRow>
          </Grid>
          <Grid item xs={12} md={2}>
            <FlexRow sx={{ whiteSpace: 'nowrap' }}>
              <MaybeSmall text={numberFormat(xyoLpBalance)} max={8} /> XYO/ETH &nbsp;
              <Info onClick={() => setUniDetails({ address, xyoLpBalance })} />
            </FlexRow>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        {removing.loading ? (
          <div className="spinner-border spinner-border-sm text-danger" />
        ) : removing.data ? null : (
          <Tooltip title="Remove address">
            <IconButton
              onClick={async () => {
                const { value } = await swalConfirm('', 'Are you sure you would like to remove this address?')
                if (value) {
                  await remove()
                }
              }}
            >
              <FaTrash fontSize={14} color="red" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
}

export default AddressRow
