import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";

import ContextToolbar from "./ContextToolbar";
import { FlexRow } from "../Flex";

const LoginNavbar = ({ redirect }: { redirect: string }) => {
  return (
    <AppBar
      sx={{
        mb: 4,
        position: "relative",
        background: "transparent",
        maxWidth: "100vw",
        overflow: "hidden",
      }}
    >
      <FlexRow>
      <ContextToolbar />
      <Box flexGrow={1} />
      <Toolbar>
        <Button
          LinkComponent={Link}
          startIcon={<FaUser />}
          {...({ to: redirect ? `/auth?redirect=${redirect}` : "/auth" })}
        >
          Login
        </Button>
      </Toolbar>
      </FlexRow>
    </AppBar>
  );
};

export default LoginNavbar
