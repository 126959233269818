import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { ChangeEvent, MouseEvent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { redeemInitialSuccess, redeemSubscription } from '../../checkout'
import { useCustomTokenContext } from '../../context'
import { checkoutClient } from '../../lib'
import logger from '../../Log'
import { getOrCreateCoinUser, refetchAccounts, useUser } from '../../User'
import { fromSearch, gTag, gtagCategoricalEvent, toSearch, trackWarning } from '../../utils'
import { swalWarning } from '../../componentsPure'
// import debounceEmailVerification from './debounceEmailVerificationFactory'

const useAuthState = () => {
  const { skipRedeemSubscription } = useUser()
  const navigate = useNavigate()
  const { search } = useLocation()
  const customTokenActions = useCustomTokenContext()[1]
  const { redirect, email: initialEmail, ...params } = fromSearch(search)
  const [{ email, password, loading, type, error }, setState] = useState({
    email: (initialEmail as string) || '',
    error: '',
    loading: false,
    password: '',
    type: 'sign-in',
  })
  const setValue = (name: string, value: string) => setState((v) => ({ ...v, [name]: value }))
  const setEmail = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setValue('email', event?.target?.value)
  const setPassword = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setValue('password', event?.target?.value)
  const forgotPassword = () => setValue('type', 'forgot')
  const signUp = () => setValue('type', 'sign-up')
  const signIn = () => setValue('type', 'sign-in')
  const handleSubmit = async (event?: MouseEvent) => {
    event?.preventDefault()
    try {
      setState((v) => ({
        ...v,
        error: '',
        loading: true,
      }))
      if (!email) throw new Error('Email is required')
      if (!password) throw new Error('Password is required')
      if (type === 'sign-up') {
        // eslint-disable-next-line no-undef
        // if (process.env.NODE_ENV !== 'development') {
        //   await debounceEmailVerification(email as string)
        // }
        // skipRedeemSubscription.current = 'sign-up'
        // gTag('event', 'sign_up')
        // await createUserWithEmailAndPassword(getAuth(), email as string, password)
      } else {
        // Clear out any previous skips
        skipRedeemSubscription.current = ''
        gTag('event', 'login')
        await signInWithEmailAndPassword(getAuth(), email as string, password)
        logger().log('Sign in success')
      }
      const user = getAuth().currentUser
      if (user) {
        await Promise.resolve()//cgetOrCreateCoinUser()
          // .then(data => {
          //   if (!data.exists) {
          //     trackWarning('User created.', data)
          //   } else {
          //     trackWarning('User already exists.', data)
          //   }
          // })
          .then(() => checkoutClient.resetStore())
          .then(() => refetchAccounts())
          .then(() => {
            logger().log('Refetched accounts')
          })
          .catch((err) => {
            trackWarning('User creation error.', err)
          })
        if (type === 'sign-up') {
          await redeemSubscription(email as string)
            .then(async (redeem) => {
              logger().log('redeemInitialSuccess', redeem)
              if (redeem && redeem.result && redeem.result.activeSubscription) {
                await redeemInitialSuccess(redeem.result.activeSubscription, user?.uid, redeem.result.customerId)
              }
            })
            .catch(() => {
              logger().log('Initial redeem error')
            })
        }
      }
      customTokenActions.remove()
      const search = toSearch(params)
      if (search) {
        navigate(`${redirect || '/account'}?${search}`)
      } else {
        navigate(`${redirect || '/account'}`)
      }
      setState((v) => ({ ...v, error: '', loading: false }))
    } catch (e) {
      const error = e as Error
      gtagCategoricalEvent('auth', 'auth-error', 'error', {
        value: error.message,
      })
      setState((v) => ({ ...v, error: error.message, loading: false }))
    }
  }
  const handleForgot = async (event?: MouseEvent) => {
    event?.preventDefault()
    try {
      setState((v) => ({ ...v, error: '', loading: true }))
      await sendPasswordResetEmail(getAuth(), email as string)
      setState((v) => ({ ...v, error: '', loading: false, type: 'reset' }))
    } catch (e) {
      const error = e as Error
      setState((v) => ({ ...v, error: error.message, loading: false }))
    }
  }
  return {
    email,
    error,
    forgotPassword,
    handleForgot,
    handleSubmit,
    loading,
    password,
    setEmail,
    setPassword,
    setValue,
    signIn,
    signUp,
    type,
  }
}

export default useAuthState
