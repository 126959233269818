import { parse } from 'querystring'

const getVerificationCode = () => {
  const { code, verification_code } = parse(window.location.search.replace('?', ''))
  if (Array.isArray(code)) return code[0]
  if (Array.isArray(verification_code)) return verification_code[0]
  return code || verification_code
}

export default getVerificationCode
