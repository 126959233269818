import { ApolloProvider } from '@apollo/client'
import { FC } from 'react'

import { checkoutClient } from '../../lib'

const CheckoutApolloProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <ApolloProvider client={checkoutClient}>{children}</ApolloProvider>
}

export default CheckoutApolloProvider
