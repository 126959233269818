import { BoxProps } from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { FlexCol, FlexRow } from '../../../components/Flex'
import { numberFormat } from '../../../utils'
import { computeRunningTotals } from './lib'

// import xyoImg from '../../../assets/xyo.svg'
// import coinImg from '../../../assets/CoinNoText.svg'
// import uniswapImg from '../../../assets/uniswap.png'

interface HodlItemProps {
  id: string
  uid: string
  xyoHodlRewards: number
  xyoTotalHodlRewards: number
  xyoCollected: number
  hodlTotal: number
  hodlInApp: number
  hodlVerified: number
  hodlVerifiedPair: number
  hodlVerifiedPairTokens: number
  hodlMultiplier: number
  hodlIsFhr: boolean
  date: Date
  yyyymmdd: string
  loading: boolean
}

const Aspan = styled('span')(
  ({ theme }) => `
& a {
  color: ${theme.palette.primary.main},
}`,
)

const HodlItem: React.FC<React.PropsWithChildren<Partial<HodlItemProps> & BoxProps>> = ({
  id,
  uid,
  xyoHodlRewards,
  xyoTotalHodlRewards,
  xyoCollected,
  hodlTotal,
  hodlInApp,
  hodlVerified,
  hodlVerifiedPair,
  hodlVerifiedPairTokens,
  hodlMultiplier,
  hodlIsFhr,
  date,
  yyyymmdd,
  loading,
  ...props
}) => {
  const { prevXyoCollected, newXyoCollected } = computeRunningTotals({ xyoCollected, xyoHodlRewards })
  return (
    <FlexRow justifyContent="flex-start" {...props} padding={2}>
      {loading ? (
        <FlexCol alignItems="flex-start">
          <Skeleton variant="text" width={180} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
          <Skeleton variant="text" width={160} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
          <Skeleton variant="text" width={160} className="skeleton" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
        </FlexCol>
      ) : (
        <FlexCol alignItems="flex-start">
          {/* <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <img src={coinImg} alt="COIN" style={{ height: '1em' }} />
            <strong>COIN App:</strong> {numberFormat(hodlInApp)} COIN 
            <Typography color="secondary" component="span">={numberFormat((hodlMultiplier || 0) * (hodlInApp || 0))} COIN</Typography>
          </Typography>
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <img src={xyoImg} alt="XYO" style={{ height: '1em' }} />
            <strong>Verified Wallets:</strong> {numberFormat(hodlVerified)} XYO
            <Typography color="secondary" component="span">={numberFormat((hodlMultiplier || 0) * (hodlVerified || 0))} COIN</Typography>
          </Typography>
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} paragraph>
            <img src={uniswapImg} alt="Uniswap" style={{ height: '1em' }} /> 
            <strong>Uniswap Positions:</strong>
            <Typography color="secondary" component="span">={numberFormat((hodlMultiplier || 0) * (hodlInApp || 0))} COIN</Typography>
          </Typography> */}

          <Typography variant="body2"><strong>Amount:</strong> {numberFormat(xyoHodlRewards)} COIN</Typography>
          {prevXyoCollected ? <Typography variant="body2"><strong>Prev Total:</strong> {numberFormat(prevXyoCollected)} COIN</Typography> : null}
          {newXyoCollected ? <Typography variant="body2"><strong>New Total:</strong> {numberFormat(newXyoCollected)} COIN</Typography> : null}
          <Typography variant="subtitle2">
            Date:{' '}
            {date
              ? !isNaN(Number(date))
                ? new Date(Number(date)).toLocaleString()
                : new Date(date).toLocaleString()
              : 'Not Collected'}
          </Typography>
        </FlexCol>
      )}
    </FlexRow>
  )
}

export default HodlItem
