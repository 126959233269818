import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, CircularProgress, LinearProgress, TextField, Typography } from '@mui/material'
import gql from 'graphql-tag'
import get from 'lodash/fp/get'
import { ChangeEventHandler, FormEventHandler, useState } from 'react'
import { FlexCol } from '../components/Flex'
import { useSnackbar } from '../components/Snackables/SnackbarProvider'

import { BossProvider } from '../context'
import CustomBasePage from '../CustomBasePage'
import logger from '../Log'
import { useUser } from '../User'
import { parseError } from '../utils'

export default () => {
  return (
    <BossProvider>
      <ReferralsPage />
    </BossProvider>
  )
}

const emailRegEx = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/
const getReferredBy = get('referredBy')
const getEmail = get('email')
const getType = get('type')
const getGift = get('gift')
// const getReferredByType = flow(getType, replace('s2s_', '_'), split('='), first, startCase)

const ReferralsPage = () => {
  const { user } = useUser()
  const { data, loading, refetch } = useQuery(REFERRED_BY)
  const [updating, setUpdating] = useState(false)
  const [updateError, setUpdateError] = useState('')
  const [referrerEmail, setReferrerEmail] = useState('')
  const { setSnackbar } = useSnackbar()[1]
  const [setReferrer] = useMutation(SET_REFERRER)
  const referredBy = getReferredBy(data)
  const referredByEmail = getEmail(referredBy)
  // const referredByType = getReferredByType(referredBy)
  const referredByGift = getGift(referredBy)
  const hasReferredByEmail = Boolean(referredByEmail)

  const hasReferredByGift = referredByGift > 200
  const handleChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setReferrerEmail(ev.target.value)
    if (emailRegEx.test(referrerEmail)) {
      setUpdateError('')
    }
  }
  const handleSubmit: FormEventHandler = async (ev) => {
    ev.preventDefault()
    try {
      setUpdateError('')
      if (hasReferredByEmail) {
        throw new Error('Referrer can only be set once.')
      }
      // if (referredByType && referredByType.indexOf(/organic/i) === -1) {
      //   throw new Error('Not eligible to set referrer.')
      // }
      // if (hasReferredByGift) {
      //   throw new Error('Already received a referral gift.')
      // }

      const trimmedEmail = referrerEmail.trim()
      if (user && user.email === trimmedEmail) {
        throw new Error('You can not be your own referrer.')
      }
      if (!emailRegEx.test(trimmedEmail)) {
        throw new Error('Please enter a valid email')
      }
      setUpdating(true)
      await setReferrer({ variables: { email: trimmedEmail } })
      await refetch({ variables: {} })
      setSnackbar({
        autoHideDuration: 6000,
        message: `Your referrer was succefully set as ${trimmedEmail}`,
        severity: 'success',
      })
    } catch (e) {
      const error = e as Error
      setUpdateError(error.message)
      const message = parseError(error)
      setSnackbar({
        autoHideDuration: 6000,
        message,
        severity: 'error',
      })
    }

    setUpdating(false)
  }
  logger().log(data)
  return (
    <CustomBasePage>
      <FlexCol alignItems="stretch">
        <Typography variant="h4">Referred By</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <FlexCol alignItems="stretch" marginY={2}>
            <form onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                variant="outlined"
                label="Referring email"
                value={referredByEmail || referrerEmail}
                onChange={handleChange}
                placeholder="Enter email"
                error={Boolean(updateError)}
                helperText={parseError(updateError)}
                InputProps={{
                  disabled: hasReferredByEmail || updating,
                }}
                fullWidth
              />
              <Button variant="outlined" type="submit" disabled={updating} fullWidth>
                {updating && (
                  <LinearProgress
                    style={{
                      left: 0,
                      position: 'absolute',
                      right: 0,
                      top: 0,
                    }}
                  />
                )}
                Update Referrer
              </Button>
            </form>
          </FlexCol>
        )}
      </FlexCol>
    </CustomBasePage>
  )
}

const REFERRED_BY = gql`
  query ReferredBy {
    referredBy {
      uid
      type
      gift
      email
    }
  }
`

const SET_REFERRER = gql`
  mutation SetReferrer($email: String!) {
    setReferrer(email: $email) {
      uid
      type
      gift
      email
    }
  }
`
