import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { fetchPickaxe, rmPickaxeCache } from './api'
import { Pickaxe } from './types'

export interface PickaxeCardProps {
  pickaxe: Pickaxe | null
  pickaxeEquipped?: string | undefined
  refetch?: () => Promise<void>
  loading?: boolean
}

export const PickaxeCardContext = createContext<null | PickaxeCardProps>(null)
export const PickaxeCardProvider = ({ children, ...value }: PropsWithChildren<PickaxeCardProps>) => {
  const [pickaxe, setPickaxe] = useState(value.pickaxe)

  useEffect(() => {
    setPickaxe(value.pickaxe)
  }, [value.pickaxe])

  usePickaxeMintingPoll(pickaxe, setPickaxe, value.refetch)

  return <PickaxeCardContext.Provider value={{ ...value, pickaxe }}>{children}</PickaxeCardContext.Provider>
}

const usePickaxeMintingPoll = (pickaxe: Pickaxe | null, setPickaxe: (pickaxe: Pickaxe) => void, refetch?: () => void) => {
  useEffect(() => {
    let mounted = true

    const uniqueId = pickaxe?.uniqueId

    if (uniqueId && checkIsMinting(pickaxe)) {
      const intervalId = setInterval(() => {
        console.log('Polling for', uniqueId)
        fetchPickaxe(uniqueId)
          .then((pickaxe) => {
            if (mounted && pickaxe && !checkIsMinting(pickaxe)) {
              setPickaxe(pickaxe)
              if (refetch) {
                rmPickaxeCache('list')
                refetch()
              }
            }
          })
          .catch((err) => {
            console.log('Error loading pickaxe details', err)
          })
      }, 10000)

      return () => {
        mounted = false
        clearInterval(intervalId)
      }
    }
  }, [pickaxe])
}

export const usePickaxeCard = () => {
  const value = useContext(PickaxeCardContext)
  if (!value) throw new Error('PickaxeCardProvider not found in parent tree')

  return value
}

export const checkIsMinting = (pickaxe: Pickaxe | null) => {
  if (!pickaxe) return false
  return pickaxe.mintState && pickaxe.mintState !== 'error' && pickaxe.mintState !== 'completed' && pickaxe.ethAddress && pickaxe.tokenURI
}
