import { Box, Paper } from '@mui/material'

const supportUri = 'https://support.coinapp.co/hc/en-us/articles/360048287714-Badges-in-COIN'

const NoBadges = () => {
  return (
    <Paper>
      <Box p={2} py={4} textAlign="center">
        <h4 className="mb-2">No Badges Found</h4>
        <p className="mb-0">
          Read more about badges{' '}
          <a href={supportUri} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </p>
      </Box>
    </Paper>
  )
}

export default NoBadges
