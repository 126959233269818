import { geodropFeatureImage } from './features'
import { StatCard, StatCardProps } from '../Stats'

import { useUserInfo } from '../../../User'

const DroppedCard: React.FC<React.PropsWithChildren<StatCardProps>> = (props) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      title="Geodrops"
      subtitle="All time"
      whole
      action="View More"
      actionTo="/geodrops"
      actionToOptions={{
        state: {
          filter: 'dropsCreated',
        },
      }}
      value={loading ? undefined : userInfo?.xyoDropped ?? 0}
      image={geodropFeatureImage}
      description="COIN"
      {...props}
    />
  )
}

export default DroppedCard
