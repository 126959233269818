import { MouseEvent } from 'react'

const stopPropagation = (fn?: () => void) => {
  return (ev: MouseEvent) => {
    if (ev) ev.stopPropagation()
    fn?.()
  }
}

export default stopPropagation
