import { AddAPhoto, Cancel, CheckCircle } from '@mui/icons-material'
import { Box, IconButton, SxProps } from '@mui/material'
import { ChangeEventHandler, CSSProperties, MouseEventHandler, useId } from 'react'

const full: CSSProperties = { height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }
const center: SxProps = { alignItems: 'center', display: 'flex', justifyContent: 'center' }
const hoverOnly: SxProps = { ':hover': { opacity: 1 }, opacity: '0', transition: 'opacity 0.3s ease-in' }

export interface UpdateImageControlProps {
  previewSrc: string
  onChange: ChangeEventHandler<HTMLInputElement>
  onSave: MouseEventHandler
  onCancel: MouseEventHandler
}

export const UpdateImageControl = ({ previewSrc, onChange, onCancel, onSave }: UpdateImageControlProps) => {
  const id = useId()
  return (
    <Box sx={{ ...full }}>
      <input id={id} type="file" accept="image/png, image/jpeg, image/heic" style={{ ...full, opacity: 0 }} onChange={onChange} />
      <IconButton sx={{ ...full, ...center, ...hoverOnly }} component="label" htmlFor={id}>
        <AddAPhoto />
      </IconButton>
      {previewSrc ? (
        <Box sx={{ ...center, bottom: '-14%', position: 'absolute' }}>
          <IconButton color="primary" onClick={onCancel}>
            <Cancel />
          </IconButton>
          <IconButton color="secondary" onClick={onSave}>
            <CheckCircle />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  )
}
