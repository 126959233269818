import { Box, FormHelperText, useMediaQuery } from '@mui/material'

import { LoadingFab } from '../Buttons'

export interface IVariant {
  name: string
  description: string
  shippingSku: string
  productId?: number
}

export interface IVariantSelectorProps {
  helperText?: string
  variants: IVariant[]
  onChange?: (v: IVariant) => void
  value?: string
  loading?: boolean
}

export const VariantSelector = ({ helperText, variants, onChange, value, loading }: IVariantSelectorProps) => {
  const isSm = useMediaQuery('@media (max-width: 445px)')
  return (
    <>
      <Box mt={2.5} mb={1} position="sticky" left={0}>
        <FormHelperText>{helperText}</FormHelperText>
      </Box>
      <Box
        mx={-1}
        style={{
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {variants.map((variant) => (
          <Box p={1} key={variant.shippingSku} display="inline-flex">
            <LoadingFab
              onClick={() => onChange && onChange(variant)}
              active={variant.shippingSku === value}
              description={variant.description}
              name={variant.name}
              loading={loading}
              size={isSm ? 'small' : 'large'}
            />
          </Box>
        ))}
      </Box>
    </>
  )
}
