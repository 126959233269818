import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  darken,
  Divider,
  lighten,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import { FlexRow } from '../Flex'
import { ReactNode } from 'react'
import { FaAngleRight } from 'react-icons/fa'

import { stopPropagation } from '../../lib'

interface Props extends CardProps {
  subtitle?: ReactNode
  price?: ReactNode
  badge?: ReactNode
  pitch?: ReactNode
  features?: ReactNode[]
}

const PlanCard: React.FC<React.PropsWithChildren<Props>> = ({ title, subtitle, price, badge, pitch, features, ...props }) => {
  const theme = useTheme()
  const headerBackgroundColor =
    theme.palette.mode === 'dark'
      ? lighten(theme.palette.background.paper, 0.2)
      : darken(theme.palette.background.paper, 0.2)
  return (
    <Card style={{ pointerEvents: 'all' }} onClick={stopPropagation()} {...props}>
      <CardHeader
        style={{ background: headerBackgroundColor }}
        title={title}
        subheader={subtitle}
        action={
          badge ? (
            <FlexRow
              bgcolor={theme.palette.secondary.main}
              color={theme.palette.secondary.contrastText}
              borderRadius={32}
              padding={1}
              top={theme.spacing(2)}
              right={theme.spacing(2)}
            >
              {badge}
            </FlexRow>
          ) : null
        }
      />
      <CardContent style={{ background: headerBackgroundColor }}>
        <Typography variant="h4">{price}</Typography>
      </CardContent>
      <Divider variant="fullWidth" />
      <CardContent>
        <List component="ul">
          {features?.map((feature, index) => (
            <ListItem alignItems="flex-start" dense key={index}>
              <ListItemIcon style={{ minWidth: 24 }}>
                <FaAngleRight />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
        <Typography marginBottom={2}>{pitch}</Typography>
      </CardContent>
    </Card>
  )
}

export default PlanCard
