import { Typography } from '@mui/material'
import { DownloadButtonBox } from '../components/Buttons/DownloadButtons'
import { FlexCol, FlexRow } from '../components/Flex'
import { useEffect, useState } from 'react'

import coinLogo from '../assets/CoinNoText.svg'
import CustomBasePage from '../CustomBasePage'

const DownloadCoinPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isDesktop, setIsDesktop] = useState(false)
  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) {
      window.location.href = 'coinapp://premium'
    } else {
      setIsDesktop(true)
    }
  }, [])
  return (
    <CustomBasePage>
      <FlexCol>
        <FlexRow>
          <img style={{ height: 120 }} alt="COIN Logo" src={coinLogo} />
        </FlexRow>
        <FlexRow>
          <h1 style={{ fontWeight: 100 }}>GET PAID TO EXPLORE THE WORLD!</h1>
        </FlexRow>
        <FlexRow>
          {isDesktop ? <Typography variant="body1">Please open this link on your mobile device.</Typography> : null}
        </FlexRow>
        <DownloadButtonBox
          iosLink="https://itunes.apple.com/app/id1450443351"
          androidLink="https://play.google.com/store/apps/details?id=network.xyo.coin"
        />
      </FlexCol>
    </CustomBasePage>
  )
}

export default DownloadCoinPage
