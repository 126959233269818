import { getAuth } from 'firebase/auth'
import React, { createContext, FC, useContext, useMemo, useState } from 'react'
import { useAsyncEffect } from '../utils/useAsyncEffect'

type ICustomTokenValue = {
  isUsingCustomToken?: string
}

type ICustomTokenActions = {
  update: (token: string) => void
  remove: (token?: string) => void
}

type ICustomTokenContext = [ICustomTokenValue, ICustomTokenActions]

const CustomTokenContext = createContext<null | ICustomTokenContext>(null)
const customTokenStore = {
  expires: 'CUSTOM_TOKEN_EXPIRES',
  expiresMinutes: 20,
  get: async () => {
    const email = localStorage.getItem(customTokenStore.key) ?? ''
    if (!email) return ''

    const expires = localStorage.getItem(customTokenStore.expires) ?? ''

    if (!expires || new Date(expires).valueOf() <= Date.now()) {
      await getAuth().signOut()
      customTokenStore.rm()
      return ''
    }

    return email
  },
  key: 'CUSTOM_TOKEN',
  rm: () => {
    localStorage.removeItem(customTokenStore.key)
    localStorage.removeItem(customTokenStore.expires)
  },
  set: (v: string) => {
    const expires = new Date()
    expires.setMinutes(expires.getMinutes() + customTokenStore.expiresMinutes)
    localStorage.setItem(customTokenStore.key, v)
    localStorage.setItem(customTokenStore.expires, expires.toISOString())
  },
}

export const useCustomTokenContext = () => {
  const value = useContext(CustomTokenContext)
  if (!value) throw new Error('CustomTokenProvider component not found')
  return value
}

export const CustomTokenProvider = ({ children }: React.PropsWithChildren) => {
  const [value, setValue] = useState<ICustomTokenValue>({})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async (mounted) => {
    const isUsingCustomToken = await customTokenStore.get()
    if (mounted()) {
      setValue({ isUsingCustomToken })
    }
  })

  const context = useMemo(() => {
    const actions: ICustomTokenActions = {
      remove: () => {
        customTokenStore.rm()
        setValue({ isUsingCustomToken: '' })
      },
      update: (v: string) => {
        customTokenStore.set(v)
        setValue({ isUsingCustomToken: v })
      },
    }
    return [value, actions] as ICustomTokenContext
  }, [value])

  return <CustomTokenContext.Provider value={context}>{children}</CustomTokenContext.Provider>
}
