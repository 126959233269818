import { Avatar, Box, List, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { useState } from 'react'

import {
  ConfirmAppLinkDialog,
  CurrentSweepstakesListItems,
  CustomListItem,
  SweepstakesProvider,
} from '../../../components'
import RedeemOptionsDirectSkeleton from './RedeemOptionsDirectSkeleton'

interface RedeemOption {
  id: string
  coinAmount: number
  redeemLimit: number
  redeemCount: number
  active: boolean
  title: string
  description: string
  imageUrl: string
  activeWhenNoCrypto: boolean
  variable: boolean
}

interface Props {
  filter?: string
  data?: {
    redeemOptions: RedeemOption[]
  }
  loading?: boolean
}

const RedeemOptions: React.FC<React.PropsWithChildren<Props>> = ({ filter = 'direct', loading, data }) => {
  const [appLinkOpen, setAppLinkOpen] = useState(false)
  const openPurchaseInAppDialog = () => {
    setAppLinkOpen(true)
  }
  return (
    <SweepstakesProvider>
      <List disablePadding>
        {filter === 'sweepstakes' ? (
          <CurrentSweepstakesListItems />
        ) : filter === 'direct' ? (
          loading ? (
            <RedeemOptionsDirectSkeleton />
          ) : (
            data?.redeemOptions
              ?.filter((option) => option.active !== false && !option.activeWhenNoCrypto)
              .filter((option) => option.coinAmount)
              .filter((option) => !option.redeemLimit || option.redeemLimit > option.redeemCount)
              .map((option, i, arr) => {
                const clickable = !(option.redeemLimit && option.redeemLimit <= option.redeemCount)
                return (
                  <CustomListItem key={option.id} onClick={clickable ? openPurchaseInAppDialog : undefined} hideDivider={arr.length - 1 === i}>
                    <ListItemAvatar>
                      <Avatar
                        src={option.imageUrl}
                        style={{ borderRadius: 0 }}
                        imgProps={{
                          style: { objectFit: 'contain' },
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.title}
                      secondary={option.description}
                      // secondary={
                      //   !option.redeemLimit
                      //     ? ''
                      //     : option.redeemLimit <= option.redeemCount
                      //     ? 'Sold Out'
                      //     : `${(
                      //         option.redeemLimit - option.redeemCount
                      //       ).toLocaleString(undefined, {
                      //         maximumFractionDigits: 0,
                      //         minimumFractionDigits: 0,
                      //       })} Remaining`
                      // }
                    />
                    {option.coinAmount ? (
                      <Box ml={1}>
                        <Typography noWrap>
                          {option.coinAmount.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          })}{' '}
                          COIN{option.variable ? '+' : ''}
                        </Typography>
                      </Box>
                    ) : null}
                  </CustomListItem>
                )
              })
          )
        ) : null}
      </List>
      <ConfirmAppLinkDialog link="/redeem" open={appLinkOpen} onClose={() => setAppLinkOpen(false)}>
        <ol>
          <li>
            <Typography>In the app, tap the assets bar where the total collected is displayed.</Typography>
          </li>
          <li>
            <Typography>Tap the option you are interested in, and then tap the &apos;Redeem&apos; button.</Typography>
          </li>
        </ol>
      </ConfirmAppLinkDialog>
    </SweepstakesProvider>
  )
}

export default RedeemOptions
