import { FC, Suspense, useEffect } from 'react'

import { ErrorBoundary } from '../../../componentsPure'
import { parseError } from '../../../utils'
import { SplashScreenLogo } from '../../Logo'
import { useSnackbar } from '../../Snackables'
import { SweepstakesCompletedDialog, SweepstakesCompletedDialogType } from '../Completed'
import { SweepstakesDownloadDialog, SweepstakesDownloadDialogOpen } from '../Download'
import { SweepstakesFreeEntryDialog, SweepstakesFreeEntryDialogOpen } from '../FreeEntry'
import { SweepstakesEntrySelected } from '../selected'
import {
  SweepstakesApolloProvider,
  SweepstakesEntryConfirm,
  SweepstakesEntryCount,
  SweepstakesEntryDialogOpen,
  SweepstakesEntryStep,
  SweepstakesEntryTermsAgreed,
  SweepstakesEntryEligibilityAgreed,
  SweepstakesHistoryOpen,
  SweepstakesSize,
} from './context'
import SweepstakesThemeProvider from './SweepstakesThemeProvider'

const SweepstakesProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Suspense fallback={<SplashScreenLogo error="" message="" />}>
      <ErrorBoundary fallback={(error: Error) => <DisplayError error={error} />}>
        <SweepstakesApolloProvider>
          <SweepstakesEntryCount.Provider>
            <SweepstakesEntryConfirm.Provider>
              <SweepstakesHistoryOpen.Provider>
                <SweepstakesEntryTermsAgreed.Provider>
                  <SweepstakesEntryEligibilityAgreed.Provider>
                    <SweepstakesEntryStep.Provider>
                      <SweepstakesEntryDialogOpen.Provider>
                        <SweepstakesDownloadDialogOpen.Provider>
                          <SweepstakesEntrySelected.Provider>
                            <SweepstakesCompletedDialogType.Provider>
                              <SweepstakesFreeEntryDialogOpen.Provider>
                                <SweepstakesSize.Provider>
                                  {children}
                                  <SweepstakesThemeProvider>
                                    <SweepstakesCompletedDialog />
                                    <SweepstakesDownloadDialog />
                                    <SweepstakesFreeEntryDialog />
                                  </SweepstakesThemeProvider>
                                </SweepstakesSize.Provider>
                              </SweepstakesFreeEntryDialogOpen.Provider>
                            </SweepstakesCompletedDialogType.Provider>
                          </SweepstakesEntrySelected.Provider>
                        </SweepstakesDownloadDialogOpen.Provider>
                      </SweepstakesEntryDialogOpen.Provider>
                    </SweepstakesEntryStep.Provider>
                  </SweepstakesEntryEligibilityAgreed.Provider>
                </SweepstakesEntryTermsAgreed.Provider>
              </SweepstakesHistoryOpen.Provider>
            </SweepstakesEntryConfirm.Provider>
          </SweepstakesEntryCount.Provider>
        </SweepstakesApolloProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

function DisplayError({ error }: { error: Error }) {
  const { setSnackbar } = useSnackbar()[1]
  useEffect(() => {
    setSnackbar({
      autoHideDuration: 6000,
      message: parseError(error),
      severity: 'error',
    })
  }, [error, setSnackbar])
  return null
}

export default SweepstakesProvider
