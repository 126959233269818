import { AccountDocument } from '@xyo-network/api-checkout-apollo'
import fetch from 'isomorphic-fetch'

import { checkoutClient } from '../../lib'

type IRedeem = {
  success: boolean
  userExists: boolean
  subscriptions: []
  result: { activeSubscription: string; customerId: string }
}

const redeemSubscription = async (email: string): Promise<IRedeem> => {
  const res = await fetch(
    `${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/redeem/${email}${
      process.env.NODE_ENV === 'development' ? '?mode=sandbox' : ''
    }`
  )
  const data = await res.json()
  await checkoutClient.query({
    fetchPolicy: 'network-only',
    query: AccountDocument,
  })
  return data
}

export default redeemSubscription
