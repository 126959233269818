import { getCheckoutClient } from '@xyo-network/api-checkout-apollo'
import { getAuth } from 'firebase/auth'

type Stage = 'production' | 'development' | 'local' | 'cluster'
const CHECKOUT_API = process.env.REACT_APP_CHECKOUT_API as Stage

const auth = async () => {
  const user = getAuth().currentUser
  const token = user ? await user.getIdToken() : ''
  return {
    headers: {
      'X-Auth-Token': token,
      'X-Stage': process.env.REACT_APP_CHECKOUT_STAGE || (process.env.NODE_ENV === 'development' ? 'sandbox' : 'live'),
    },
  }
}

export const legacyCheckout = getCheckoutClient('production', auth)
const checkoutClient = getCheckoutClient(CHECKOUT_API, auth)

export default checkoutClient
