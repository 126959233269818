import { FC } from 'react'

import PlanModal from './PlanModal'
import PlanModalOpenContext from './PlanModalOpenContext'

const PlanModalOpenProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <PlanModalOpenContext.Provider>
      {children}
      <PlanModal />
    </PlanModalOpenContext.Provider>
  )
}

export default PlanModalOpenProvider
