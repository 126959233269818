import { Typography } from "@mui/material";
import { SurveyButtons } from "../../../components/SurveyNav/SurveyButtons";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import { Wrapper } from "../../../components";
import CustomBasePage from "../../../CustomBasePage";

const Earn = () => {
  return (
    <CustomBasePage container={null}>
      <Wrapper style={{ paddingTop: 48, paddingBottom: 48, marginBottom: 'auto' }}>
        <Container maxWidth="md">
          <Typography variant="h4">Surveys</Typography>
          <SurveyButtons />
          <Typography variant="subtitle1" textAlign="center">
            <Link to="/surveys">See Survey Rewards</Link>
          </Typography>
        </Container>
      </Wrapper>
    </CustomBasePage>
  );
};

export default Earn;
