import negate from 'lodash/negate'
import { useContext } from 'react'

import { RedeemAccordion } from '../Redeem'
import isActiveRequest from './isActiveRequest'
import RequestsContext from './RequestsContext'

const HistoricalRequests = () => {
  const { data = [], loading } = useContext(RequestsContext)
  const historical = data.filter(negate(isActiveRequest))
  return (
    <div className="mb-2">
      {historical.length ? (
        <p>
          <b>Redeem History</b>
        </p>
      ) : (
        ''
      )}
      {loading ? (
        <div className="text-center text-danger">
          <div className="spinner-border" />
        </div>
      ) : (
        historical.map((request) => <RedeemAccordion key={request.id} request={request} />)
      )}
    </div>
  )
}

export default HistoricalRequests
