import { Link } from '@mui/material'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import PlanModalOpenContext from './PlanModalOpenContext'

const PlanModalButton: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const navigate = useNavigate()
  const setOpen = PlanModalOpenContext.useContext()[1]
  const renderPlanModal = useCallback(() => {
    if (window.innerWidth < 992) {
      navigate('/plans')
    } else {
      setOpen(true)
    }
  }, [navigate, setOpen])

  return (
    <Link style={{ cursor: 'pointer' }} onClick={renderPlanModal}>
      {children}
    </Link>
  )
}

export default PlanModalButton
