import fetch from 'isomorphic-fetch'
import keyBy from 'lodash/keyBy'
import orderBy from 'lodash/orderBy'
import { useEffect } from 'react'

import { AsyncControlsProps, useAsyncMethod } from '../utils'

const zenApi = 'https://coinappco.zendesk.com/api/v2'

export const findSupportCategories = async () => {
  const res = await fetch(`${zenApi}/help_center/en-us/categories.json`)
  const data = await res.json()
  return orderBy(data.categories, 'position', 'asc')
    .filter(({ id }) => supportCategoryIconName(id))
    .map((category) => ({
      ...category,
      icon: supportCategoryIconName(category.id),
    }))
}

interface Article {
  section_id: string
  body: string
  title: string
}

interface Section {
  id: string
  category_id: string
}

export const findAllArticles = async () => {
  const articlesPage = async (articles: Article[], page?: number): Promise<Article[]> => {
    const res = await fetch(`${zenApi}/help_center/en-us/articles.json?per_page=100&page=${page}`)
    const data = (await res.json()) as { next_page?: unknown; articles: Article[]; page: number }
    if (data.next_page) {
      return await articlesPage(articles.concat(data.articles), data.page + 1)
    }
    return articles.concat(data.articles)
  }

  const sections = await findSupportSections()
  const sectionsById = keyBy(sections, 'id')
  return (await articlesPage([], 1)).map((article) => {
    const category_id =
      article.section_id && sectionsById[article.section_id] ? sectionsById[article.section_id].category_id : null
    return {
      answer: article.body,
      category_id,
      question: article.title,
    }
  })
}

export async function findSupportArticles() {
  const res = await fetch(`${zenApi}/help_center/en-us/articles.json`)
  const data = await res.json()
  return data
}

export function findSupportSections() {
  const sectionPage = async (sections: Section[], page: number): Promise<Section[]> => {
    const res = await fetch(`${zenApi}/help_center/en-us/sections.json?per_page=100&page=${page}`)
    const data = (await res.json()) as { next_page?: unknown; sections: Section[]; page: number }
    if (data.next_page) {
      return await sectionPage(sections.concat(data.sections), data.page + 1)
    }

    return sections.concat(data.sections)
  }
  return sectionPage([], 1)
}

export async function findSupportCategoryArticles(id: string) {
  const res = await fetch(`${zenApi}/help_center/en-us/categories/${id}/articles.json`)
  const data = await res.json()
  return data
}

export async function helpCenterSearch(query: string) {
  const res = await fetch(`${zenApi}/help_center/articles/search.json?query=${query}`)
  const data = await res.json()
  return data
}

export const useHelpCenterSearch = () => {
  return useAsyncMethod(helpCenterSearch)
}

export const useSupportCategoryArticles = () => {
  return useAsyncMethod(findSupportCategoryArticles)
}

const useAsyncEffect = <V>(fn: () => Promise<V>) => {
  const [state, method] = useAsyncMethod(fn, { loading: true })
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    method()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return state as AsyncControlsProps<V>
}

export const useAllArticles = () => {
  return useAsyncEffect(findAllArticles)
}

const supportCategoryIconName = (id: number) => {
  switch (id) {
    case 360002099393:
      return 'fas fa-question'
    case 360002083674:
      return 'fas fa-book-open'
    case 360002083734:
      return 'fas fa-user-circle'
    case 360002099453:
      return 'fas fa-crown'
    case 360002104613:
      return 'fas fa-map-marked-alt'
    case 360002099613:
      return 'fas fa-user-plus'
    case 360002099633:
      return 'fas fa-parachute-box'
    case 360002083694:
      return 'fas fa-cogs'
    case 360002099433:
      return 'fas fa-wallet'
    case 360002083774:
      return 'fas fa-exclamation-triangle'
    case 360002211173:
      return 'fas fa-users'
    default:
      return ''
  }
}
