type IResult = 'yes' | 'no' | ''

export const RESIDENT_EVENT = 'is-resident-of-us-uk'

export const residentStorage = {
  get: (): IResult => {
    try {
      return (localStorage.getItem(RESIDENT_EVENT) ?? '') as IResult
    } catch (e) {
      return residentStorage.result
    }
  },
  result: '' as IResult,
  set: (status: IResult) => {
    try {
      residentStorage.result = status
      localStorage.setItem(RESIDENT_EVENT, status)
    } catch (e) {
      return
    }
  },
}
